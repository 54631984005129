import type { Option } from '@byecode/ui'
import { Divider, Select } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import type { FlowNode, NodeTypes } from '@lighthouse/shared'
import {
    CollapseBox,
    getIsIncludesDisableDataSourceInWorkFlow,
    getTableIcon,
    multiRecordNodeType,
    nodeTypeOptions,
    SelectDataSource
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { promptSwitchDataSource } from '@/containers/FlowDetail/utils/promptSwitchDataSource'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { SettingRecordContent } from '../../../SettingRecordContent'
import * as SC from '../../styles'

interface CreateRecordActionConfigureProps {
    allParentNodes: FlowNode[]
}

export const CreateRecordActionConfigure: React.FC<CreateRecordActionConfigureProps> = ({ allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const { control, setValue } = useFormContext()

    const dataSourceId: string = useWatch({
        control,
        name: 'config.dataSourceId'
    })

    const options = useMemo(
        () =>
            dataSourceList.reduce<DataSourceAbstract[]>((listDs, item) => {
                const tableIcon = getTableIcon(item)
                if (!getIsIncludesDisableDataSourceInWorkFlow(item)) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [dataSourceList]
    )

    const nodeOptions = useMemo(
        () =>
            allParentNodes.reduce<Option[]>((prev, cur) => {
                if (multiRecordNodeType.has(cur.data.nodeType)) {
                    prev.push({
                        label: cur.data.name,
                        value: cur.id
                    })
                }
                return prev
            }, []),
        [allParentNodes]
    )

    // const allParentFindRecordNodes = useMemo(
    //     () =>
    //         allParentNodes.reduce<FlowNode[]>((prev, cur) => {
    //             if (cur.id === selectParentNodeId || singleRecordNodeType.has(cur.data.nodeType)) {
    //                 prev.push(cur)
    //             }
    //             return prev
    //         }, []),
    //     [allParentNodes, selectParentNodeId]
    // )

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="nodeType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        disabled
                                        value={field.value}
                                        options={nodeTypeOptions}
                                        onChange={val => field.onChange?.(val as NodeTypes)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>选择数据表</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.dataSourceId"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <SelectDataSource
                                            // disabled={!!field.value}
                                            value={field.value}
                                            withinPortal
                                            searchable
                                            placeholder="请选择数据表"
                                            onChange={async dsId => {
                                                if (dsId === field.value) {
                                                    return
                                                }
                                                const isConfirmed = field.value === undefined ? true : await promptSwitchDataSource()
                                                if (isConfirmed) {
                                                    field.onChange(dsId)
                                                    setValue('config.fields', [])
                                                }
                                            }}
                                            dataSourceList={options}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="基于多条记录逐条新增记录">
                <SC.Content>
                    <SC.Description style={{ margin: '0 0 4px 0' }}>
                        从上游节点中选一个「查询多条记录」的节点，基于这些记录逐条新增记录
                    </SC.Description>
                    <SC.FormItem style={{ margin: '4px 0' }}>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>从上游节点选择</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.nodeId"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Select
                                        value={field.value}
                                        placeholder="请选择"
                                        options={nodeOptions}
                                        onChange={val => field.onChange?.(val as NodeTypes)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>

            {dataSourceId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="设置记录内容">
                        <SC.Content>
                            <SettingRecordContent allParentNodes={allParentNodes} dataSourceId={dataSourceId} />
                        </SC.Content>
                    </CollapseBox>
                </>
            )}
        </SC.Container>
    )
}
