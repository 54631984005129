import { IconFont, Input } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

interface SearchProps {
    value: string
    suffix?: React.ReactNode
    clearable?: boolean
    onChange: (value: string) => void
    onBack?: () => void
}

const SCxSearchContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    gap: 8px;
    padding-bottom: 4px;
`

const SCxInput = styled(Input)``

export const Search: React.FunctionComponent<SearchProps> = ({ value, suffix, clearable, onChange }) => {
    return (
        <SCxSearchContainer>
            <SCxInput
                prefix={<IconFont type="Search" color="var(--color-gray-400)" />}
                value={value}
                placeholder="搜索"
                size="md"
                clearable={clearable}
                onClear={() => onChange('')}
                onChange={e => onChange?.(e.currentTarget.value)}
                styles={{
                    wrapper: {
                        borderRadius: 5
                    }
                }}
                style={{
                    width: '100%'
                }}
            />
            {suffix}
        </SCxSearchContainer>
    )
}
