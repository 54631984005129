import { Group } from '@byecode/ui'
import { SelectDataSource } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useNotAggregateDataSourceList } from '@/hooks/useDataSource'

interface DefaultDataSourceProps {}

/** 视图字段 */
export const DefaultDataSource: React.FC<DefaultDataSourceProps> = () => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { control } = useFormContext()
    const dataSourceList = useNotAggregateDataSourceList(appId, envId)

    return (
        <Group label="数据来源">
            <Controller
                name="pointer"
                control={control}
                render={({ field }) => {
                    return (
                        <SelectDataSource
                            withinPortal
                            placeholder="请选择数据表"
                            value={field.value}
                            onChange={field.onChange}
                            searchable
                            width="100%"
                            dataSourceList={dataSourceList}
                        />
                    )
                }}
            />
        </Group>
    )
}
