import { SubFormBlock } from '@lighthouse/block'
import { Toast } from '@lighthouse/bui'
import type { SubFormColumn } from '@lighthouse/core'
import { type SubFormBlockAbstract, type ViewRecordOperateProtocol, RecordOpenType } from '@lighthouse/core'
import {
    aliyunVideoProtocolList,
    fileMaxUploadSize,
    fileSuffixRegex,
    getFileSizeToMB,
    useAtomAction,
    useAtomData,
    useFormModuleSubForm
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useAsync } from 'react-use'

import { pageStackOfFieldBlockAtom } from '@/atoms/application/state'
import { openPageStackAtom } from '@/atoms/page/action'
import { pageAtomFamily, pageStackAtomFamily } from '@/atoms/page/state'
import { stackFactory } from '@/atoms/page/utils'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId, useLanguage, usePreviewType } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlockDefaultValueRender } from '@/hooks/useFieldBlockDefaultValueRender'
import { useFieldBlockMethods } from '@/hooks/useFieldBlockMethods'
import { useFieldInputRenderLabel } from '@/hooks/useParentRecord'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

interface SubFormBlockControllerProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    blockData: SubFormBlockAbstract
    onBlockChange?: (values: SubFormBlockAbstract, origin: SubFormBlockAbstract) => Promise<void> | void
}
const emptyArr: SubFormColumn[] = []

const SubFormBlockController: React.FC<SubFormBlockControllerProps> = ({ blockData, onBlockChange, ...rest }) => {
    const {
        config: { columns = emptyArr, pointer = '' },
        id: blockId
    } = blockData

    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const onVariableValueRender = useFieldBlockDefaultValueRender(pointer)

    const {
        defaultValue: dataList,
        subFormErrors: errors,
        onSubFormInputChange,
        onChangeSubForm,
        onSubFormInputBlur
    } = useFormModuleSubForm(blockId)
    const { pageId } = useCurrentPageContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const previewType = usePreviewType()
    const { rootPageId } = useRootPageContext()
    const stackId = useCurrentStackIdContext()
    const dataSource = useDataSource(appId, envId, pointer)
    const language = useLanguage()
    const onRenderLabel = useFieldInputRenderLabel()

    const fieldBlockValueMap = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[stackId], [stackId])
    )

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )

    const handleOpenPage = useCallback(
        (params: ViewRecordOperateProtocol['creatingConfig']) => {
            const { page, openType = RecordOpenType.page } = params ?? {}
            if (page) {
                openPageStack(stackFactory({ appId, pageId: page, rootPageId, stackDisplayType: openType }))
            }
        },
        [appId, openPageStack, rootPageId]
    )

    const handleFetchSmsCode = useCallback((mobile: string) => srv.getVerifyCode({ mobile, blockId, pageId }), [blockId, pageId])

    /** 获取关联数据源数据 start */
    const { value: relativeDataSourceConfig } = useAsync(
        () =>
            Promise.all(
                columns.map(async column => {
                    const { config, id } = column
                    const { inputType = 'text', fieldPointer = '', initialValue } = config ?? {}
                    const initValue = await onVariableValueRender({
                        id,
                        dsId: pointer,
                        inputType,
                        fieldId: fieldPointer,
                        initialValue,
                        config
                    })
                    return { config, value: initValue.value }
                })
            ),
        [columns, onVariableValueRender, pointer]
    )
    const { onFetchDataSource, onLoadMoreData, relativeDataSource, onFetchCascadeOptions, onFetchPersonOptions } = useFieldBlockMethods(
        relativeDataSourceConfig,
        fieldBlockValueMap
    )

    /** 获取关联数据源数据end */

    const uploadyOptions = useMemo(
        () => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                            return false
                        }

                        return true
                    }
                    return true
                }
            }
        }),
        [pageId, pageName]
    )

    const videoUploadyOption = useMemo(() => {
        return {
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadManagerInAppParams(),
                fileFilter: (file: File | string, index: number) => {
                    if (file instanceof File) {
                        if (file.size > fileMaxUploadSize) {
                            Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                            return false
                        }
                        const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                        if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                            Toast.error('视频格式错误，请上传 MP4 格式的视频')
                            return false
                        }
                        return true
                    }
                    return true
                }
            }
        }
    }, [pageId, pageName])

    return (
        <SubFormBlock
            appId={appId}
            envId={envId}
            dataList={dataList}
            isValid={false}
            blockData={blockData}
            previewType={previewType}
            dataSource={dataSource}
            relativeDataSource={relativeDataSource}
            dataSourceList={dataSourceList}
            language={language}
            errors={errors}
            videoUploadyOption={videoUploadyOption}
            uploadyOptions={uploadyOptions}
            richTextUploadOptions={uploadManagerInAppParams()}
            onChange={onChangeSubForm}
            onBlockChange={onBlockChange}
            onVariableValueRender={onVariableValueRender}
            onInputChange={onSubFormInputChange}
            onOpenPage={handleOpenPage}
            onFetchSmsCode={handleFetchSmsCode}
            onFetchCascadeOptions={onFetchCascadeOptions}
            onFetchDataSource={onFetchDataSource}
            onLoadMoreData={onLoadMoreData}
            onFetchPersonOptions={onFetchPersonOptions}
            onRenderLabel={onRenderLabel}
            onInputBlur={onSubFormInputBlur}
            {...rest}
        />
    )
}

export default SubFormBlockController
