import { Group } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import type {
    BlockTree,
    BlockTreeItem
} from '@/constants/Block/type'

import BlockCard from './BlockCard'

interface KindBlockProps {
    option: BlockTree
    onOpenQuestion?: (v: BlockTreeItem) => void
    onCreate?: (e:React.MouseEvent<HTMLDivElement>, v: BlockTreeItem) => void
}

const SCxContainer = styled.div`
    /* padding: 12px 0; */
`

const SCxTitle = styled.div`
    color: var(--color-gray-500);
    text-align: left;
    /* 12/SC-Medium */
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
`

const SCxContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px 8px;
`

export const KindBlock: React.FunctionComponent<KindBlockProps> = ({ option, onCreate, onOpenQuestion }) => {
    return (
        <SCxContainer>
            <Group
                defaultOpen
                styles={{
                    collapse: {
                        padding: '0 16px!important'
                    },
                    root: {
                        padding: '0px!important'
                    }
                }}
                iconColor="var(--color-gray-400)"
                label={<SCxTitle>{option.name}</SCxTitle>}
            >
                <SCxContent>
                    {option.items.map((item, index) => (
                        <BlockCard data={item} key={item.name} onCreate={onCreate} onOpenQuestion={onOpenQuestion} />
                    ))}
                </SCxContent>
            </Group>
        </SCxContainer>
    )
}
