import { Group, SegmentedControl, singleTextEllipsis, Text } from '@byecode/ui'
import { FilterVisibleModeEnum } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useSetExtendsKeys } from '../DesignSetting/hooks/useMouseEventDistance'
import { InheritLabel } from '../InheritLabel'
import { ConditionFilter } from './ConditionFilter'

export interface VisibilitySettingProps {
    title?: string
    currentDsId?: string
}

const StyledText = styled.div<{ color?: string }>`
    color: ${({ color }) => color};
    font-size: 14px;
    line-height: 16px;
    margin-right: 2px;
    ${singleTextEllipsis()}
`

// const deviceOptions = [
//     {
//         label: '电脑端',
//         value: 'desktop'
//     },
//     {
//         label: '手机端',
//         value: 'mobile'
//     }
// ]

export const VisibilitySetting: React.FC<VisibilitySettingProps> = ({ title = '可见性', currentDsId }) => {
    const { control } = useFormContext()
    const visibility = useWatch({ control, name: 'breakPoint.visibility.visible' })
    const {handleSetBreakKey} = useSetExtendsKeys()
    const isConditionVisibility = visibility === FilterVisibleModeEnum.FILTER

    return (
        <Group label={title}>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <InheritLabel label="显示规则" name='breakPoint.visibility' />
                <Controller
                    name="breakPoint.visibility.visible"
                    render={({ field }) => (
                        <SegmentedControl
                            data={[
                                { label: '显示', value: FilterVisibleModeEnum.VISIBLE },
                                { label: '隐藏', value: FilterVisibleModeEnum.HIDDEN },
                                { label: '按条件', value: FilterVisibleModeEnum.FILTER }
                            ]}
                            value={field.value}
                            onChange={val => {
                                field.onChange(val)
                                handleSetBreakKey('breakPoint.visibility')
                            }}
                        />
                    )}
                />
            </ListItem4ByecodeUi>

            {isConditionVisibility && <ConditionFilter currentDsId={currentDsId} onSetBreakKey={handleSetBreakKey} />}
        </Group>
    )
}
