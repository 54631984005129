import { Button, Divider, Group, IconFont, SegmentedControl, Select } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { CardBlockAbstract, Field, PageAbstract } from '@lighthouse/core'
import { ARRANGEMENT } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import {
    DATE_SYSTEM,
    generateJson,
    getPageDataOptions,
    getSystemOption,
    getUserDatasourceOptions,
    ListItem4ByecodeUi,
    useAtomData,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { getRandomIcon, nanoid } from '@lighthouse/tools'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { uploadManagerInAppParams } from '@/utils/auth'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM, BLOCK_SIZE_OPTIONS, CARD_BLOCK_DIRECTION, CARD_BLOCK_STYLES, LAYOUT_CONTROL } from '../constants'
import { SortableConfigure } from './SortableConfigure'

export interface CardBlockSettingProps {
    blockId: string
    allPages: PageAbstract[]
}

const COLS_OPTIONS = Array.from({ length: 5 }).map((_, i) => ({
    label: `${i + 1} 列`,
    value: (i + 1).toString()
}))

const filterField = (field: Field) => !['file', 'video', 'notes'].includes(field.type)
const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v

/** 卡片block设置 */
export const CardBlockSetting: React.FC<CardBlockSettingProps> = ({ blockId, allPages }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const { control, watch } = useFormContext<CardBlockAbstract['config']>()
    const layout = watch('layout')
    const designProps = getBlockAndPageDesignLimit('card')
    const { fields, remove, move, append } = useFieldArray({ control, name: 'list', keyName: 'key' })

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { distance: 8 }
        })
    )

    const [activeId, setActiveId] = useState<string | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = fields.findIndex(item => item.id === over.id)
            const activeIndex = fields.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                move(activeIndex, overIndex)
            }
        }
    }

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { variableSourceType, datasource } = curr
    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])
    const systemOption = useMemo(() => getSystemOption(DATE_SYSTEM), [])
    const userOption = useMemo(() => {
        const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: filterField
        })
    }, [dataSourceList])
    const { customViewOption } = useVariableCustomViewOption(filterField)

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            variable: { options, userOption, systemOption, viewOption: customViewOption },
            taskList: false,
            bulletList: false,
            orderedList: false,
            codeBlock: false,
            image: false,
            quote: false,
            line: false,
            fontSize: false
        }),
        [customViewOption, options, systemOption, userOption]
    )

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <Group label="设计">
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>布局</div>
                            <Controller
                                control={control}
                                name="layout"
                                render={({ field }) => (
                                    <SegmentedControl {...field} fullWidth data={LAYOUT_CONTROL} style={{ width: 180 }} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        {layout === ARRANGEMENT.fixed && (
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>每行显示</div>
                                <Controller
                                    control={control}
                                    name="cols"
                                    render={({ field }) => (
                                        <Select
                                            value={field.value?.toString()}
                                            onChange={v => field.onChange(Number(v))}
                                            options={COLS_OPTIONS}
                                            style={{ width: 180 }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                        )}
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>排版</div>
                            <Controller
                                control={control}
                                name="direction"
                                render={({ field }) => (
                                    <SegmentedControl {...field} fullWidth data={CARD_BLOCK_DIRECTION} style={{ width: 180 }} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>风格</div>
                            <Controller
                                control={control}
                                name="style"
                                render={({ field }) => (
                                    <SegmentedControl {...field} fullWidth data={CARD_BLOCK_STYLES} style={{ width: 180 }} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>尺寸</div>
                            <Controller
                                control={control}
                                name="size"
                                render={({ field }) => (
                                    <SegmentedControl {...field} fullWidth data={BLOCK_SIZE_OPTIONS} style={{ width: 180 }} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </Group>
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="内容">
                        <div style={{ overflowY: 'auto', margin: '8px 0' }}>
                            <DndContext
                                sensors={sensors}
                                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                                onDragStart={onDragStart}
                                onDragEnd={onDragEnd}
                                onDragCancel={() => setActiveId(null)}
                            >
                                <SortableContext disabled={disabledWithVersion} items={fields} strategy={verticalListSortingStrategy}>
                                    {fields.map((item, index) => (
                                        <SortableConfigure
                                            key={item.id}
                                            disabled={disabledWithVersion}
                                            config={config}
                                            index={index}
                                            dsId={datasource?.id}
                                            id={item.id}
                                            allPages={allPages}
                                            uploadParams={uploadManagerInAppParams()}
                                            onRemove={remove}
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </div>

                        <Button
                            style={{ gap: 6, padding: '0 12px' }}
                            radius={32}
                            onClick={() => {
                                append({
                                    id: nanoid(),
                                    iconColor: '#649BFA',
                                    iconDecoration: { type: 'icon', value: getRandomIcon() },
                                    heading: generateJson('标题'),
                                    introduction: generateJson('请输入描述'),
                                    action: {
                                        type: 'none',
                                        trigger: 'click',
                                        data: {
                                            none: {}
                                        }
                                    }
                                })
                            }}
                            icon={<IconFont size={16} fill="var(--color-gray-500)" type="Add" />}
                        >
                            添加
                        </Button>
                    </Group>

                    <Divider />
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
