import { pointer } from '@byecode/ui'
import { APPLICATION_VERSION_PROD, useAtomData } from '@lighthouse/shared'
import cls from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { currentAppVersionIdAtom } from '@/atoms/application/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import * as CM from '../style'

export type TabType = 'page' | 'layers' | 'component' | 'theme' | null
type TabItem = {
    icon: string
    label: string
    value: TabType
    activeIcon: string
}

interface NavBarProps {
    value: TabType
    onTabChange: (tab: TabType) => void
}

interface NavBarItemProps {
    isActive?: boolean
    value: TabItem
    onTabChange: (tab: TabType) => void
}

const SCxContainer = styled.div`
    width: 72px;
    height: 100%;
    padding: 16px 12px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
`

const SCxTitle = styled.div`
    font-size: 12px;
    line-height: 16px;
    /* or 133% */
    display: flex;
    align-items: center;
    text-align: center;
`
const SCxIconWrapper = styled.div`
    width: 100%;
    padding: 4px 0;
    display: flex;
    justify-content: center;
    border-radius: 8px;
`

const SCxItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    padding: 4px 0;
    color: var(--color-gray-700);
    ${pointer}
    &.active {
        color: var(--color-black);
        font-weight: 500;

        ${SCxIconWrapper} {
            background-color: var(--color-gray-200);
        }
    }

    :hover {
        ${SCxIconWrapper} {
            background-color: var(--color-gray-100);
        }
    }
`

const TAB_LIST: TabItem[] = [
    {
        icon: 'AddCircle',
        label: '添加',
        value: 'component',
        activeIcon: 'AddCircle-1'
    },
    {
        icon: 'WindowFrame',
        label: '页面',
        value: 'page',
        activeIcon: 'WindowFrame-1'
    },
    {
        icon: 'LayersOutline',
        label: '图层',
        value: 'layers',
        activeIcon: 'LayersFilled'
    },
    {
        icon: 'PalleteOutline',
        label: '样式',
        value: 'theme',
        activeIcon: 'Pallete'
    }
]

export const Item: React.FunctionComponent<NavBarItemProps> = ({ isActive, value: item, onTabChange }) => {
    return (
        <SCxItem
            key={item.value}
            className={cls({ active: isActive })}
            onClick={() => {
                onTabChange(isActive ? null : item.value)
            }}
        >
            <SCxIconWrapper>
                <CM.Icon
                    type={isActive ? item.activeIcon : item.icon}
                    size={20}
                    style={{
                        transition: 'all 0.25s ease-in-out'
                    }}
                    color={isActive ? 'var(--color-gray-900)' : 'var(--color-gray-700)'}
                />
            </SCxIconWrapper>
            <SCxTitle>{item.label}</SCxTitle>
        </SCxItem>
    )
}

export const NavBar: React.FC<NavBarProps> = React.memo(({ value, onTabChange }) => {
    return (
        <SCxContainer data-ignore-click-away style={{ backgroundColor: value ? 'var(--color-white)' : undefined }}>
            {TAB_LIST.map(item => {
                return <Item key={item.value} value={item} isActive={value === item.value} onTabChange={onTabChange} />
            })}
        </SCxContainer>
    )
})
