import { Modal } from '@byecode/ui'
import type { ChartBlockAbstract, DataSourceAbstract, Field, FieldADTValue, FilterOption, Pagination } from '@lighthouse/core'
import { DataSourceType, SelectedMode } from '@lighthouse/core'
import type { ColumnEvent, CreateFieldParams, InsertDirection, RowEvent } from '@lighthouse/shared'
import {
    createFieldNeedFetchData,
    dataDrawerPubSub,
    filterBlock,
    generateLinkFilter,
    getFilterBlockItemIdsInFilter,
    SuspendPagination,
    Table,
    updateFieldNeedFetchData,
    useAtomAction,
    useAtomData,
    useHandleAbortPrevious
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import React, { useCallback, useEffect, useId, useMemo, useRef } from 'react'
import { useMount } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { filterBlockOptionsAtom } from '@/atoms/blockRecordsDict/state'
import {
    addAiFieldStatusAtom,
    createFieldAtom,
    createRecordAtom,
    deleteFieldAtom,
    deleteRecordAtom,
    fetchDataSourceAtom,
    loadDataSourceAtom,
    updateCellAtom,
    updateFieldAtom
} from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom, recordPoolAtom } from '@/atoms/dataSource/state'
import { blocksAtom, pageBlocksAtom } from '@/atoms/page/state'
import { filterBlockIdsCacheAtomFamily } from '@/atoms/storage/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

const SCxContainer = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 14px;
`

const SCxFooter = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface ChartDataSourceProps {
    blockData: ChartBlockAbstract
    dataSource: DataSourceAbstract
    pageId: string
    pageRecordId?: string
    parentRecordId?: string
}

export interface ChartDataSourceState {
    selectedRecords: string[]
    selectedMode?: SelectedMode
}

const emptyUploadOption = {
    info: { id: '', label: '', groupId: '' },
    options: {}
}

export const ChartDataSource: React.FC<ChartDataSourceProps> = ({ blockData, dataSource, pageId, pageRecordId, parentRecordId }) => {
    const { id, schema, viewOptions, records, type, name } = dataSource
    const {
        config: { ruleFilter, linkFilterController }
    } = blockData
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const isAggregateDataSource = type === DataSourceType.aggregateDataSource
    const rowTotal = viewOptions?.pagination?.rowTotal ?? 0
    const filterOptions = useAtomData(filterBlockOptionsAtom)
    const filterBlocks = useAtomData(
        pageBlocksAtom(pageId),
        useCallback(s => filterBlock(s, block => block.type === 'filter'), [])
    )
    const uploadId = useId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { filterBlockItemIds } = useMemo(() => getFilterBlockItemIdsInFilter(linkFilterController), [linkFilterController])
    const filterValue = useAtomValue(filterBlockIdsCacheAtomFamily({ appId, envId, pageId, recordId: pageRecordId, filterBlockItemIds }))

    const { run: fetchDataSource, loading } = useAtomAction(fetchDataSourceAtom)
    const { run: loadDataSource } = useAtomAction(loadDataSourceAtom)
    const { run: createField } = useAtomAction(createFieldAtom)
    const { run: updateField } = useAtomAction(updateFieldAtom)
    const { run: deleteField } = useAtomAction(deleteFieldAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)
    const { run: createRecord } = useAtomAction(createRecordAtom)
    const { run: deleteRecord } = useAtomAction(deleteRecordAtom)
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)
    const selectedModeRef = useRef<SelectedMode>()
    const { mutation, abortRef } = useHandleAbortPrevious(fetchDataSource)
    const createFieldParamsRef = useRef<CreateFieldParams>({
        sourceId: '',
        direction: '',
        fieldId: ''
    })
    const [state, setState] = useImmer<ChartDataSourceState>({
        selectedRecords: [],
        selectedMode: undefined
    })

    const { filter, linkFilter } = useMemo(() => {
        const usedFilterOptions = filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
            const id = `${filterItem.blockId}-${filterItem.itemId}`
            const options = filterOptions[id]
            if (options) {
                prev[id] = options
            }
            return prev
        }, {})
        const resolvedFilter = generateLinkFilter({
            filterBlockItemIds,
            filterOptions: usedFilterOptions,
            linkFilterController,
            filterValue,
            filterBlocks
        })
        return { filter: ruleFilter, linkFilter: resolvedFilter }
    }, [filterBlockItemIds, filterBlocks, filterOptions, filterValue, linkFilterController, ruleFilter])

    const { selectedRecords, selectedMode } = state

    const getDataSource = useCallback(() => {
        mutation({
            envId,
            dsId: id,
            pagination: { currentPage: 1, pageSize: 100 },
            currentRecordId: pageRecordId,
            parentRecordId,
            filter,
            linkFilter
        })
    }, [mutation, envId, id, pageRecordId, parentRecordId, filter, linkFilter])

    useMount(() => {
        return () => {
            if (abortRef.current) {
                abortRef.current.abort()
            }
        }
    })

    useEffect(() => {
        if (id) {
            getDataSource()
        }
    }, [id, getDataSource])

    const handleLoadDataSource = useCallback(
        (pagination: Pagination) => {
            return loadDataSource({
                envId,
                dsId: id,
                pagination,
                currentRecordId: pageRecordId,
                parentRecordId,
                filter,
                linkFilter
            })
        },
        [envId, filter, id, linkFilter, loadDataSource, pageRecordId, parentRecordId]
    )

    const handleSelectedRecords = useCallback(
        (selectedRecords: string[]) => {
            setState(draft => {
                draft.selectedRecords = selectedRecords
                draft.selectedMode = SelectedMode.CURRENT_PAGE
            })
            selectedModeRef.current = SelectedMode.CURRENT_PAGE
        },
        [setState]
    )

    const handleCellChange = useCallback(
        async (recordId: string, fieldValue: FieldADTValue) => {
            const isUpdate = await updateCell({ envId, dsId: id, recordId, fieldId: fieldValue.id, value: fieldValue })
            if (isUpdate) {
                dataDrawerPubSub.emit(`${id}-UPDATE`)
            }
            return isUpdate
        },
        [envId, id, updateCell]
    )

    const handleCellUpdate = useCallback(
        async (recordId: string, fieldValue: FieldADTValue) => {
            const isUpdate = await srv.updateCell({
                envId,
                dsId: id,
                id: recordId,
                fieldId: fieldValue.id,
                content: fieldValue
            })
            if (isUpdate) {
                dataDrawerPubSub.emit(`${id}-UPDATE`)
            }
            return isUpdate
        },
        [envId, id]
    )

    const handleCreateRecord = useCallback(
        async (beforeId?: string) => {
            const isCreated = await createRecord({ envId, dsId: id, beforeId })
            if (isCreated) {
                dataDrawerPubSub.emit(`${id}-UPDATE`)
            }
            return isCreated
        },
        [createRecord, envId, id]
    )

    const handleDeleteRecord = useCallback(
        (recordIds: string[]) => {
            return deleteRecord({ envId, dsId: id, recordIds, mode: selectedMode, filter })
        },
        [deleteRecord, envId, filter, id, selectedMode]
    )

    const handleDeleteMultipleRecord = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认删除',
            content: '删除后无法恢复',
            okStatus: 'error',
            okText: '删除'
        })
        if (isConfirm) {
            const isDelete = await handleDeleteRecord(selectedRecords)
            if (isDelete) {
                setState(draft => {
                    draft.selectedRecords = []
                    draft.selectedMode = SelectedMode.CURRENT_PAGE
                })
                selectedModeRef.current = SelectedMode.CURRENT_PAGE
                dataDrawerPubSub.emit(`${id}-UPDATE`)
            }
        }
    }, [handleDeleteRecord, id, selectedRecords, setState])

    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const statusId = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId: id, recordId, fieldId })
            if (isSuccess) {
                addAiFieldStatus({
                    id: statusId,
                    dataSourceId: id,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, id]
    )

    const handleCreateField = useCallback(
        async (field: Field, sourceId: string, direction: InsertDirection) => {
            createFieldParamsRef.current = {
                sourceId,
                direction,
                fieldId: field.id
            }
            const innerType = await createField({
                envId,
                dsId: id,
                config: field,
                sourceId,
                direction
            })

            if (id !== field.dsId || createFieldNeedFetchData.has(field.type)) {
                await mutation({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100 },
                    currentRecordId: pageRecordId,
                    parentRecordId,
                    filter,
                    linkFilter
                })
            }
            return !!innerType
        },
        [createField, envId, filter, id, linkFilter, mutation, pageRecordId, parentRecordId]
    )

    const handleUpdateField = useCallback(
        async (fieldId: string, config: Field) => {
            const isUpdate = await updateField({
                envId,
                dsId: id,
                fieldId,
                config
            })
            const field = schema[fieldId]
            if ((field && field.type !== config.type) || updateFieldNeedFetchData.has(config.type)) {
                await mutation({
                    envId,
                    dsId: id,
                    pagination: { currentPage: 1, pageSize: 100 },
                    currentRecordId: pageRecordId,
                    parentRecordId,
                    filter,
                    linkFilter
                })
            }
            if (isUpdate) {
                dataDrawerPubSub.emit(`${id}-UPDATE`)
            }
            return !!isUpdate
        },
        [envId, filter, id, linkFilter, mutation, pageRecordId, parentRecordId, schema, updateField]
    )

    const handleSelectModeChange = useCallback(
        (val?: SelectedMode) => {
            if (val === SelectedMode.ALL) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.ALL
                    draft.selectedRecords = records
                })
                selectedModeRef.current = SelectedMode.ALL
                return
            }
            if (val === SelectedMode.CURRENT_PAGE) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.CURRENT_PAGE
                    draft.selectedRecords = records
                })
                selectedModeRef.current = SelectedMode.CURRENT_PAGE
                return
            }
            setState(draft => {
                draft.selectedMode = undefined
                draft.selectedRecords = []
            })
            selectedModeRef.current = undefined
        },
        [records, setState]
    )

    const handleDeleteField = useCallback(
        async (field: Field) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除列「${field.name ?? '未命名列'}」？`,
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete = await deleteField({
                    envId,
                    dsId: id,
                    fieldId: field.id
                })
                if (isDelete) {
                    dataDrawerPubSub.emit(`${id}-UPDATE`)
                }
                return !!isDelete
            }
            return false
        },
        [deleteField, envId, id]
    )

    const handleClose = useCallback(() => {
        setState(draft => {
            draft.selectedMode = undefined
            draft.selectedRecords = []
        })
    }, [setState])

    const uploadOptions = useMemo(
        () => ({
            info: { id: uploadId, label: name, groupId: id },
            options: uploadManagerInAppParams()
        }),
        [id, name, uploadId]
    )

    const videoUploadOptions = useMemo(() => {
        return {
            info: { id: uploadId, label: name, groupId: id },
            options: uploadManagerInAppParams()
        }
    }, [id, name, uploadId])

    const rowEvent: RowEvent = useMemo(
        () => ({
            onRecordSelect: handleSelectedRecords,
            onCellChange: handleCellChange,
            onCellUpdate: handleCellUpdate,
            onLoadMoreData: handleLoadDataSource,
            onCreateRecord: handleCreateRecord,
            onAiGeneration: handleAiGeneration
        }),
        [handleCellChange, handleCellUpdate, handleCreateRecord, handleLoadDataSource, handleSelectedRecords, handleAiGeneration]
    )

    const columnEvent: ColumnEvent = useMemo(
        () => ({
            onCreateField: handleCreateField,
            onUpdateField: handleUpdateField,
            onDeleteField: handleDeleteField,
            onSelectModeChange: handleSelectModeChange
        }),
        [handleCreateField, handleUpdateField, handleDeleteField, handleSelectModeChange]
    )

    return (
        <>
            <SCxContainer>
                <Table
                    appId={appId}
                    id={id}
                    headerFixed
                    // contentLoading={loading}
                    // contentLoading={loading}
                    disableFieldCreatable={isAggregateDataSource}
                    disableSelectMode
                    disableSelect={isAggregateDataSource}
                    disableFindUse
                    contentLoading={false}
                    data={dataSource}
                    dataSourceList={dataSourceList}
                    createFieldParams={createFieldParamsRef.current}
                    recordPoolAtom={recordPoolAtom}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    selectedRecords={selectedRecords}
                    // disableFieldCreatable
                    // disableAddRecord
                    pageSize={100}
                    columnEvent={columnEvent}
                    rowEvent={rowEvent}
                    uploadOptions={uploadOptions}
                    videoUploadOptions={videoUploadOptions}
                    richTextUploadOptions={uploadManagerInAppParams()}
                    scrollBarOutside={false}
                />
            </SCxContainer>
            <SCxFooter>
                {selectedRecords.length > 0 && (
                    <SuspendPagination
                        selectIds={selectedRecords}
                        total={rowTotal}
                        mode={selectedMode}
                        enableDelete
                        onDelete={handleDeleteMultipleRecord}
                        onClose={handleClose}
                    />
                )}
            </SCxFooter>
        </>
    )
}
