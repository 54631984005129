import type {
    ApplicationAbstract,
    Brand,
    DataSourceAbstract,
    IconicProtocol,
    SpaceUser,
    SpaceVersion,
    WechatPayOrderInfoProtocol,
    WechatPayStatus,
    WorkSpaceAbstract
} from '@lighthouse/core'
import type { Workspace } from '@lighthouse/shared'

import type { GraphRawData } from '@/containers/AppGraphDetail/types'
import type { HttpResp } from '@/http'
import http from '@/http'

import type { LatestActivityResult, NetworkTraffic, SpaceOrder, SpacePlansResult, SpaceQuota, SpaceVersionInfo } from './types'

/**
 * 创建空间
 * @param payload
 * @returns
 */
export async function createWorkSpace(payload: IconicProtocol) {
    const res = await http.put<unknown, HttpResp<Workspace>>('api/v1/space/createSpace', payload)
    const { id, name, createdTime, icon, createdBy } = res.content
    return {
        id,
        name,
        members: 1,
        createdTime,
        createdBy,
        icon
    }
}

/**
 * 更新空间
 * @param id
 * @param mutator
 */
export async function updateWorkSpace(spaceId: string, payload: WorkSpaceAbstract) {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/space/updateSpace', { ...payload, spaceId })
    return res.success
}

/**
 * 删除空间
 * @param id
 * @param mutator
 */
export async function deleteWorkSpace(spaceId: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp>('api/v1/space/deleteSpace', { params: { spaceId } })
    return res.success
}

/**
 * 获取空间列表
 * @param userId
 * @returns
 */
export async function getWorkSpaceList(): Promise<WorkSpaceAbstract[]> {
    const res = await http.get<unknown, HttpResp<WorkSpaceAbstract[]>>('api/v1/space/listSpace', {
        headers: { 'Content-Type': 'application/json' }
    })
    return res.content
}

/**
 * 获取空间
 * @param spaceId
 * @returns
 */
export async function getWorkSpace(id: string): Promise<WorkSpaceAbstract> {
    const res = await http.get<unknown, HttpResp<WorkSpaceAbstract>>('api/v1/space/getSpace', {
        params: { spaceId: id }
    })
    const { id: spaceId, name, members, createdTime = 0, icon = '', createdBy, applications = [], roleId } = res.content
    return {
        id: spaceId,
        name,
        members,
        createdBy,
        createdTime,
        icon,
        applications,
        roleId
    }
}

/**
 * 获取空间成员
 * @param spaceId
 * @param keyword
 * @returns
 */
export async function getSpaceUsers(spaceId: string, keyword?: string): Promise<SpaceUser[]> {
    const res = await http.get<unknown, HttpResp<SpaceUser[]>>('api/v1/space/getUsers', {
        params: { spaceId, keyword }
    })
    return res.content
}

/**
 * 批量移除空间下的成员
 * @param userIds
 * @returns
 */
export async function removeUsersOfSpace(spaceId: string, userIds: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<boolean>>('api/v1/space/leaveSpace', {
        params: { spaceId, userIds }
    })
    return res.success
}

/**
 * 空间转让
 * @param userId
 * @param spaceId
 * @returns
 */
export async function transferSpace(spaceId: string, userId: string, roleId: string): Promise<boolean> {
    const res = await http.put<unknown, HttpResp<boolean>>('api/v1/space/transferRole', { spaceId, userId, roleId })
    return res.success
}

/**
*
* 添加成员到空间
* @param
* @return {*}

*/
export async function addUsersToSpace(spaceId: string, users: { userId: string; roleId: string }[]): Promise<boolean> {
    const res = await http.put<unknown, HttpResp<boolean>>(
        'api/v1/space/addUsers',
        { spaceId, users },
        {
            headers: { 'Content-Type': 'application/json' }
        }
    )
    return res.success
}

// /**
// *
// * 获取空间邀请链接
// * @param
// * @return {*}

// */
// export async function createShareLink(spaceId: string): Promise<string> {
//     const res = await http.get<unknown, HttpResp<string>>('api/v1/space/createShareLink', {
//         params: { spaceId }
//     })
//     return res.content
// }

/**
*
* 被邀请进入空间
* @param params
* @return {*}

*/
export async function invitationSpace(url: string): Promise<WorkSpaceAbstract> {
    const res = await http.get<unknown, HttpResp<WorkSpaceAbstract>>(url)
    return res.content
}

/**
 * 获取空间下应用列表
 * @param spaceId
 * @returns
 */
export async function getApplicationList(spaceId: string): Promise<ApplicationAbstract[]> {
    const res = await http.get<unknown, HttpResp<ApplicationAbstract[]>>('api/v1/space/listApp', {
        params: { spaceId }
    })
    return res.content
}

/**
 * 应用图
 */
export async function getAppGraph(spaceId: string): Promise<GraphRawData> {
    const res = await http.get<unknown, HttpResp<GraphRawData>>('api/v1/space/getAppGraph', { params: { spaceId } })
    return res.content
}

/**
 * 获取空间下数据源列表
 * @return {*}
 */
export async function listSpaceDs(spaceId: string): Promise<DataSourceAbstract[]> {
    const res = await http.get<unknown, HttpResp<DataSourceAbstract[]>>('/api/v1/space/listDs', { params: { spaceId } })
    return res.content
}
/**
 * 获取空间版本价格
 * @return {*}
 */
export async function getSpacePlans(spaceId: string): Promise<SpacePlansResult> {
    const res = await http.get<unknown, HttpResp<SpacePlansResult>>('/api/v1/space/getSpacePlans', { params: { spaceId } })
    return res.content
}
/**
 * 获取资源配额
 * @return {*}
 */
export async function getUsage(spaceId: string): Promise<SpaceQuota> {
    const res = await http.get<unknown, HttpResp<SpaceQuota>>('/api/v1/space/getUsage', { params: { spaceId } })
    return res.content
}
/**
 * 获取空间配额信息
 * @return {*}
 */
export async function getVersionInfo(spaceId: string, brand?: Brand): Promise<SpaceVersionInfo[]> {
    const res = await http.get<unknown, HttpResp<SpaceVersionInfo[]>>('/api/v1/space/getVersionInfo', { params: { spaceId, brand } })
    return res.content
}
/**
 * 空间版本升级订单
 * @return {*}
 */
export async function upgradeSpacePlan(spaceId: string, level: SpaceVersion): Promise<WechatPayOrderInfoProtocol> {
    const res = await http.post<unknown, HttpResp<WechatPayOrderInfoProtocol>>('/api/v1/pay/upgradeSpacePlan', { spaceId, level })
    return res.content
}
/**
 * 空间版本升级订单检查支付状态
 * @return {*}
 */
export async function getWeChatPayStatus(outTradeNo: string): Promise<WechatPayStatus> {
    const res = await http.get<unknown, HttpResp<WechatPayStatus>>('/api/v1/pay/getWeChatPayStatus', {
        params: {
            outTradeNo
        }
    })
    return res.content
}
/**
 * 空间版本升级订单
 * @return {*}
 */
export async function getOrders(spaceId: string): Promise<SpaceOrder[]> {
    const res = await http.get<unknown, HttpResp<SpaceOrder[]>>('/api/v1/space/getOrders', {
        params: {
            spaceId
        }
    })
    return res.content
}

/** 增购短信包 */
export async function purchaseSms(spaceId: string, pack: 'SMS_1000' | 'SMS_5000' | 'SMS_15000'): Promise<WechatPayOrderInfoProtocol> {
    const res = await http.post<unknown, HttpResp<WechatPayOrderInfoProtocol>>('/api/v1/pay/purchaseSms', { spaceId, pack })
    return res.content
}

/** 活动通知 */
export async function getLatestActivity() {
    const res = await http.get<unknown, HttpResp<LatestActivityResult>>('/api/v1/operation/getLatestActivity')
    return res.content
}

/** 刷新附件配额 */
export async function cleanUpFile(spaceId: string) {
    const res = await http.get<unknown, HttpResp<number>>('/api/v1/space/cleanUp', {
        params: {
            spaceId
        }
    })
    return res.content
}

/** 获得当前网络流量用量 */
export async function getNetworkTraffic(spaceId: string) {
    const res = await http.get<unknown, HttpResp<NetworkTraffic>>('/api/v1/space/getNetworkTraffic', { params: { spaceId } })
    return res.content
}

/** 流量包增购 */
export async function purchaseTraffic(spaceId: string, pack: `GB_${number}`): Promise<WechatPayOrderInfoProtocol> {
    const res = await http.post<unknown, HttpResp<WechatPayOrderInfoProtocol>>('/api/v1/pay/purchaseTraffic', { spaceId, pack })
    return res.content
}
