import { Button, flex, SegmentedControl } from '@byecode/ui'
import styled from 'styled-components'

export const Header = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 4px 12px;
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-white);
    /* &.open {
        border-bottom: 1px solid var(--color-gray-200);
    } */
`

export const WorkBench = styled(Button)`
    padding: 4px;
    width: 28px;
    height: 28px;
`

export const Container = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    font-size: var(--font-size-normal);
    z-index: 12;
    border-bottom: 1px solid var(--color-gray-200);
`

export const PositionCenter = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-white);
`

export const StyledSegmentControl = styled(SegmentedControl)`
    label {
        font-size: 16px;
        &[data-active] {
            color: var(--color-gray-900);
            iconpark-icon {
                color: var(--color-main);
            }
        }
    }
`

export const NavButton = styled(Button)`
    width: 28;
    height: 28;
    .active & {
        color: var(--color-black);
        background-color: var(--color-gray-200);
    }
`
