import type { IndicatorOption } from '@lighthouse/core'

import { chartThemeColorOptions } from '@/components/BlockSettings/constants'

export const DEFAULT_THEME_COLORS = chartThemeColorOptions[0]

export const getIndicatorOption: (params?: Partial<IndicatorOption>) => IndicatorOption = params => ({
    appId: '',
    pointer: '',
    showHeadTitle: true,
    title: '指标卡',
    showLabel: false,
    showThousandthSplit: false,
    showUnit: false,
    linkFilterController: {
        expression: {
            where: 'AND',
            conditions: []
        }
    },
    ...params
})
