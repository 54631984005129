import { collectBlockAndChildren } from '@lighthouse/block'
import { useAtomAction } from '@lighthouse/shared'
import { useAtomCallback } from 'jotai/utils'
import { useCallback } from 'react'

import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, pageListAtom } from '@/atoms/page/state'
import { useBlockActions } from '@/hooks/useBlock'
import * as srv from '@/services'

import type { PageUndoRedoPayload } from './controller'
import {
    BLOCK_NODE_CREATE_ACTION,
    BLOCK_NODE_REMOVE_ACTION,
    BLOCK_UPDATE_ACTION,
    NODE_UPDATE_ACTION,
    PAGE_CONFIG_UPDATE_ACTION,
    pageUndoRedoController
} from './controller'

type Params = {
    rootPageId: string
    pageId: string
    stackId: string
}

/**
 * 页面block及node操作的撤销重做具体处理逻辑
 *
 * @returns {*}
 */
export const usePageUndoRedoHelper = ({ rootPageId, pageId, stackId }: Params) => {
    const { run: createBlock } = useAtomAction(createBlockAtom)
    const { run: removeBlock } = useAtomAction(removeBlockAtom)

    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    const undoHandler = useAtomCallback<void, [PageUndoRedoPayload]>(async (_, set, data) => {
        switch (data.action) {
            case BLOCK_NODE_CREATE_ACTION: {
                set(blocksAtom, draft => {
                    draft[pageId] = data.payload.prev
                })

                removeBlock({
                    rootPageId,
                    stackId,
                    pageId,
                    blocks: data.payload.prev || [],
                    removedBlocks: data.payload.createdBlocks
                })
                break
            }

            case BLOCK_NODE_REMOVE_ACTION: {
                const removedBlocksIds = data.payload.removedBlocks.reduce<string[]>(
                    (total, curr) => [...total, ...collectBlockAndChildren(curr).map(item => item.id)],
                    []
                )
                set(blockCreatingListAtom, s => [...s, ...removedBlocksIds])

                set(blocksAtom, draft => {
                    draft[pageId] = data.payload.prev
                })

                await createBlock({
                    createdBlocks: data.payload.removedBlocks,
                    blocks: data.payload.prev || [],
                    rootPageId,
                    pageId,
                    stackId
                })
                set(blockCreatingListAtom, s => s.filter(id => !removedBlocksIds.includes(id)))

                break
            }

            case BLOCK_UPDATE_ACTION: {
                onUpdateBlock(data.payload.prev, data.payload.next)
                break
            }

            case NODE_UPDATE_ACTION: {
                set(blocksAtom, draft => {
                    draft[pageId] = data.payload.prev
                })
                srv.updateBlock({ pageId, allBlocks: data.payload.prev })
                break
            }

            case PAGE_CONFIG_UPDATE_ACTION: {
                set(pageListAtom, draft => {
                    const index = draft.findIndex(item => item.id === pageId)
                    if (index === -1) {
                        return
                    }

                    draft[index] = data.payload.prev
                })
                break
            }

            default: {
                break
            }
        }
    })

    const redoHandler = useAtomCallback<void, [PageUndoRedoPayload]>(async (_, set, data) => {
        switch (data.action) {
            case BLOCK_NODE_CREATE_ACTION: {
                const createdBlocksIds = data.payload.createdBlocks.reduce<string[]>(
                    (total, curr) => [...total, ...collectBlockAndChildren(curr).map(item => item.id)],
                    []
                )
                set(blockCreatingListAtom, s => [...s, ...createdBlocksIds])

                set(blocksAtom, draft => {
                    draft[pageId] = data.payload.next
                })

                await createBlock({
                    createdBlocks: data.payload.createdBlocks,
                    blocks: data.payload.next || [],
                    rootPageId,
                    pageId,
                    stackId
                })
                set(blockCreatingListAtom, s => s.filter(id => !createdBlocksIds.includes(id)))

                break
            }

            case BLOCK_NODE_REMOVE_ACTION: {
                set(blocksAtom, draft => {
                    draft[pageId] = data.payload.next
                })

                removeBlock({
                    rootPageId,
                    stackId,
                    pageId,
                    blocks: data.payload.next || [],
                    removedBlocks: data.payload.removedBlocks
                })
                break
            }

            case BLOCK_UPDATE_ACTION: {
                onUpdateBlock(data.payload.next, data.payload.prev)
                break
            }

            case NODE_UPDATE_ACTION: {
                set(blocksAtom, draft => {
                    draft[pageId] = data.payload.next
                })

                srv.updateBlock({ pageId, allBlocks: data.payload.next })
                break
            }

            case PAGE_CONFIG_UPDATE_ACTION: {
                set(pageListAtom, draft => {
                    const index = draft.findIndex(item => item.id === pageId)
                    if (index === -1) {
                        return
                    }

                    draft[index] = data.payload.next
                })
                break
            }

            default: {
                break
            }
        }
    })

    const undo = useCallback(() => {
        const res = pageUndoRedoController.undo()
        if (res) {
            undoHandler(res)
        }
    }, [undoHandler])

    const redo = useCallback(() => {
        const res = pageUndoRedoController.redo()
        if (res) {
            redoHandler(res)
        }
    }, [redoHandler])

    return { undo, redo }
}
