import { IconFont } from '@byecode/ui'
import type { DataSourceAbstract, ViewOptions, ViewType } from '@lighthouse/core'
import {
    CAN_SELECT_OPTIONS,
    DataGroupConfigure,
    FieldTypeTag,
    FindUseType,
    getFieldIcon,
    getIsAppointField,
    getIsFindUse,
    getPrimaryDataSourceEnableFieldIds,
    getViewColumns,
    Group4ByecodeUi,
    ListItem4ByecodeUi,
    NO_EDIT_SYSTEM_FIELD,
    pureTextFieldTypes,
    useApplicationContext,
    useFindUseObjectContext
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { FieldSelect } from '../../FieldSelect'
import { ListItemMenu } from '../Common/ListItemMenu'
import { DataSetting } from './DataSetting'
import { DefaultDataSource } from './DataSource'
import { ViewField } from './ViewField'

export interface DataProps {
    viewType: ViewType
    currentTab: string
    dataSource?: DataSourceAbstract
}

export const Data: React.FC<DataProps> = ({ viewType, dataSource }) => {
    const isCalendarView = viewType === 'calendar'
    const isKanbanView = viewType === 'kanban'
    return useMemo(() => {
        if (!dataSource) {
            return <DefaultDataSource />
        }

        return (
            <>
                <DefaultDataSource key="source-setting" />
                <Divider color="var(--color-gray-200)" />
                {isCalendarView && (
                    <>
                        <CalendarConfigure key="calendar-setting" dataSource={dataSource} />
                        <Divider color="var(--color-gray-200)" />
                    </>
                )}
                <DataSetting key="data-setting" dataSource={dataSource} hideSorter={isCalendarView} hidePaging={isCalendarView} />
                <Divider color="var(--color-gray-200)" />
                {isKanbanView && (
                    <>
                        <KanbanConfigure key="kanban-setting" dataSource={dataSource} />
                        <Divider color="var(--color-gray-200)" />
                    </>
                )}
                <ViewField key="field-setting" dataSource={dataSource} />
                <Divider color="var(--color-gray-200)" />
                <VisibilitySetting key="visibility-setting" currentDsId={dataSource.id} />
            </>
        )
    }, [dataSource, isCalendarView, isKanbanView])
}

/**
 * 看板相关数据配置
 * @date 7/3/2023 - 3:38:44 PM
 *
 * @param {{ dataSource?: DataSourceAbstract }} { dataSource }
 * @returns {*}
 */
const KanbanConfigure = ({ dataSource }: { dataSource?: DataSourceAbstract }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const findUseObject = useFindUseObjectContext()
    const dataSourceList = useDataSourceList(appId, envId)
    const { control, getValues, setValue } = useFormContext<ViewOptions>()
    // const kanbanGroupConfigure = getValues('kanbanGroupConfigure')
    const viewFieldSettings = getValues('viewFieldSettings')
    const { schema = {}, viewOptions: { tableProps = [] } = {} } = dataSource ?? {}
    const columns = getViewColumns({
        tableProps,
        value: viewFieldSettings,
        schema
    })

    const { personOptions } = useApplicationContext()

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    const options = useMemo(
        () =>
            columns
                .filter(item => CAN_SELECT_OPTIONS.has(item.type) && primaryDataSourceFieldIds?.has(item.fieldId))
                .map(item => ({
                    label: item.title,
                    value: item.fieldId,
                    icon: <IconFont type={getFieldIcon(item.fieldId, item.type, item.innerType)} size={16} />,
                    extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
                })),
        [columns, primaryDataSourceFieldIds]
    )

    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group4ByecodeUi label="看板分组">
                <Controller
                    name="kanbanGroupConfigure"
                    control={control}
                    render={({ field: { value: groupField, onChange: onGroupFieldChange, ...groupFieldField } }) => {
                        const isHighLight = getIsFindUse({
                            findUseObject,
                            type: FindUseType.FIELD,
                            dsId: dataSource?.id,
                            fieldId: groupField?.groupByFieldId
                        })
                        return (
                            <ListItemMenu
                                compact
                                highlighting={isHighLight}
                                label={
                                    <div>
                                        看板分组依据 <span style={{ color: 'red', verticalAlign: 'text-top' }}>*</span>
                                    </div>
                                }
                                icon={<IconFont size={16} type="Columns" />}
                                width={278}
                                value={
                                    groupField?.groupByFieldId ? (
                                        `按 ${schema[groupField.groupByFieldId]?.name} 分组`
                                    ) : (
                                        <div style={{ color: 'var(--color-gray-400)' }}>选择分组字段以显示看板</div>
                                    )
                                }
                                dropdownProps={{ style: { borderRadius: 8, boxShadow: 'var(--box-shadow)' } }}
                                positionDependencies={[groupField?.groupConfig]}
                                popupContent={
                                    <DataGroupConfigure
                                        schema={schema}
                                        options={options}
                                        value={groupField}
                                        onChange={v => {
                                            onGroupFieldChange(v)
                                        }}
                                        personOptions={personOptions}
                                        selectProps={groupFieldField}
                                    />
                                }
                            />
                        )
                    }}
                />
            </Group4ByecodeUi>
        </>
    )
}

/**
 * 日历视图数据配置
 * @date 9/8/2023 - 3:17:51 PM
 *
 * @param {{ dataSource?: DataSourceAbstract }} { dataSource }
 * @returns {*}
 */
const CalendarConfigure = ({ dataSource }: { dataSource?: DataSourceAbstract }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { schema = {}, viewOptions: { tableProps = [] } = {}, id: dsId } = dataSource ?? {}
    const { control, getValues } = useFormContext<ViewOptions>()
    const viewFieldSettings = getValues('viewFieldSettings')
    const columns = getViewColumns({
        tableProps,
        value: viewFieldSettings,
        schema
    })

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    const titleOptions = useMemo(
        () =>
            columns
                .filter(item => pureTextFieldTypes.has(item.type))
                .map(item => ({
                    label: item.title,
                    value: item.fieldId,
                    icon: getFieldIcon(item.fieldId, item.type, item.innerType),
                    type: item.type,
                    innerType: item.innerType
                })),
        [columns]
    )

    const dateOptions = useMemo(
        () =>
            columns
                .filter(
                    item =>
                        item.type === 'date' &&
                        !getIsAppointField(item.fieldId, NO_EDIT_SYSTEM_FIELD) &&
                        primaryDataSourceFieldIds?.has(item.fieldId)
                )
                .map(item => ({
                    label: item.title,
                    value: item.fieldId,
                    icon: getFieldIcon(item.fieldId, item.type, item.innerType),
                    type: item.type,
                    innerType: item.innerType
                })),
        [columns, primaryDataSourceFieldIds]
    )

    return (
        <>
            <Group4ByecodeUi label="日历配置">
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <div>
                        日程标题 <span style={{ color: 'red', verticalAlign: 'text-top' }}>*</span>
                    </div>
                    <Controller
                        control={control}
                        name="scheduleTitleField"
                        render={({ field }) => (
                            <FieldSelect
                                {...field}
                                style={{ width: 180 }}
                                dsId={dsId}
                                options={titleOptions}
                                placeholder="请选择对应字段"
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <div>
                        开始日期 <span style={{ color: 'red', verticalAlign: 'text-top' }}>*</span>
                    </div>
                    <Controller
                        control={control}
                        name="scheduleStartDateField"
                        render={({ field }) => (
                            <FieldSelect {...field} style={{ width: 180 }} dsId={dsId} options={dateOptions} placeholder="请选择对应字段" />
                        )}
                    />
                </ListItem4ByecodeUi>
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <div>
                        结束日期 <span style={{ color: 'red', verticalAlign: 'text-top' }}>*</span>
                    </div>
                    <Controller
                        control={control}
                        name="scheduleEndDateField"
                        render={({ field }) => (
                            <FieldSelect {...field} style={{ width: 180 }} dsId={dsId} options={dateOptions} placeholder="请选择对应字段" />
                        )}
                    />
                </ListItem4ByecodeUi>
            </Group4ByecodeUi>
        </>
    )
}
