import { Input, Text } from '@byecode/ui'
import type { TextAdvanceOption } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import { useUncontrolled } from '@lighthouse/tools'
import React, { useEffect, useState } from 'react'

import { TextController } from '../TextController'

type TextAdvanceValue = Omit<TextAdvanceOption, 'id'>

interface TextAdvanceConfigureProps {
    defaultValue?: TextAdvanceValue
    value?: TextAdvanceValue
    onChange?: (value: TextAdvanceValue) => void
    onChangeEnd?: (value: TextAdvanceValue) => void
}

export const TextAdvanceConfigure = ({ defaultValue, value, onChange, onChangeEnd }: TextAdvanceConfigureProps) => {
    const [_value, _onChange] = useUncontrolled({ value, onChange, defaultValue, finalValue: { name: '' } })

    const { name, ...rest } = _value

    const [draftName, setDraftName] = useState(name ?? '')

    useEffect(() => {
        setDraftName(value?.name ?? '')
    }, [value?.name])

    const handleNameSave = (v: string) => {
        setDraftName(v)
        _onChange?.({ ..._value, name: v })
        onChangeEnd?.({ ..._value, name: v })
    }

    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>名称</Text>
                <Input
                    autoFocus
                    value={draftName}
                    onChange={e => setDraftName(e.target.value)}
                    onBlur={e => {
                        const { value } = e.currentTarget
                        if (!value) {
                            setDraftName(name ?? '')
                            return
                        }
                        handleNameSave(value)
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            const { value } = e.currentTarget
                            if (!value) {
                                setDraftName(name ?? '')
                                return
                            }
                            handleNameSave(value)
                        }
                    }}
                    onFocus={e => {
                        e.target.select()
                    }}
                    placeholder=""
                    style={{ width: 180 }}
                />
            </ListItem4ByecodeUi>
            <TextController
                defaultValue={defaultValue}
                value={rest}
                onChange={v => _onChange({ ...v, name })}
                onChangeEnd={v => onChangeEnd?.({ ...v, name })}
                fontFamilyInputProps={{ position: 'right-start' }}
                notForm
            />
        </>
    )
}
