import { RIGHT_ASIDE_CLASS, useAtomData } from '@lighthouse/shared'
import type { ScrollAreaProps } from '@mantine/core'
import { Divider, ScrollArea } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'

import { BlockSettingsController } from './BlockSettingController'
import { NavbarSettingController } from './NavbarSettingController'
import { PageBreadcrumbs } from './PageBreadcrumbs'
import { PageSettingController } from './PageSettingController'

const AsideScrollArea: (props: ScrollAreaProps) => React.ReactElement = styled(ScrollArea)`
    position: relative;
    flex: 0 0 361px;
    width: 361px;
    border-left: 1px solid var(--color-gray-200);
    background-color: #fff;

    > .mantine-ScrollArea-viewport > div {
        display: block !important;
        height: 100%;
    }
`

export const SettingAside = () => {
    const asideType = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.state?.asideType, [])
    )

    const content = useMemo(() => {
        switch (asideType) {
            case AsideType.NAVBAR: {
                return <NavbarSettingController />
            }

            case AsideType.BLOCK: {
                return <BlockSettingsController />
            }

            // case AsideType.PAGE_FAB: {
            //     return <PageFabSettingController />
            // }

            default: {
                return <PageSettingController />
            }
        }
    }, [asideType])

    return (
        <AsideScrollArea
            data-ignore-click-away
            className={RIGHT_ASIDE_CLASS}
            scrollHideDelay={0}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.stopPropagation()
            }}
        >
            {/* <ErrorBoundary fallbackRender={ErrorFallback}> */}
            <div style={{ paddingBottom: 100, boxSizing: 'border-box' }}>
                <PageBreadcrumbs />
                <Divider color="var(--color-gray-200)" />
                {content}
            </div>
            {/* </ErrorBoundary> */}
        </AsideScrollArea>
    )
}
