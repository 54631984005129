import { Divider, Group } from '@byecode/ui'
import type { DataSourceAbstract, SubProcessArg } from '@lighthouse/core'
import { type BaseFlowNode, type FlowNode, type NodeTypes, type VariableTriggerConfig,useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import useSWR from 'swr'

import { fetchWorkflowAtom, fetchWorkflowListAtom } from '@/atoms/workflow/action'
import { workflowAtomFamily, workflowListAtom } from '@/atoms/workflow/state'
import { SwitchControl } from '@/components/BlockSettings/Common/SwitchControl'
import * as srv from '@/services'

import * as SC from '../../styles'
import { SubProcessSelector } from '../HandleSubProcessActionConfigure/SubProcessSelector'
import { SettingSubProcessContent } from './SettingSubProcessContent'

interface PaySuccessAfterProps {
    prefixName: string
    dataSource: DataSourceAbstract
    isActionFlow: boolean
    allParentNodes?: FlowNode[]
}

export const PaySuccessAfter: React.FC<PaySuccessAfterProps> = ({ prefixName, dataSource, isActionFlow, allParentNodes }) => {
    const { control, setValue, getValues } = useFormContext()
    const workflows = useAtomValue(workflowListAtom)
    const { run: updateWorkflow } = useAtomAsyncAction(fetchWorkflowListAtom)

    const subProcessId = useWatch({ control, name: `${prefixName}.payConfig.callbackWorkflow.workflowId` })

    // useEffect(() => {
    //     updateWorkflow()
    // }, [updateWorkflow])

    const handleFetchWorkflows = useCallback(() => {
        return updateWorkflow()
    }, [updateWorkflow])

    const subProcessArgs = useMemo(() => {
        const workflow = find(item => item.id === subProcessId && item.type === 'subProcess', workflows)
        if (!workflow) {
            return
        }
        const nodeList = workflow.content?.nodes || []
        const variableNode = find(item => item.data.nodeType === 'ARG_TRIGGER', nodeList) as BaseFlowNode<VariableTriggerConfig>
        if (!variableNode) {
            return
        }
        const { config } = variableNode.data
        if (!config || !config?.args) {
            return
        }
        return config.args
    }, [subProcessId, workflows])

    // useEffect(() => {
    //     const workflow = find(item => item.id === subProcessId && item.type === 'subProcess', workflows)
    //     if (workflow) {
    //         const nodeList = workflow.content?.nodes || []
    //         const variableNode = find(item => item.data.nodeType === 'ARG_TRIGGER', nodeList) as BaseFlowNode<VariableTriggerConfig>
    //         if (!variableNode) {
    //             return
    //         }
    //         const { config } = variableNode.data
    //         if (!config || !config?.args) {
    //             return
    //         }
    //         const data: SubProcessArg[] = getValues(`${prefixName}.payConfig.callbackWorkflow.workflowArgs`)
    //         const newParameters = config.args.map(item => {
    //             const dataItem = find(d => d.id === item.id, data || [])
    //             return {
    //                 ...item,
    //                 value: dataItem?.value
    //             }
    //         })
    //         setValue(`${prefixName}.payConfig.callbackWorkflow.workflowArgs`, newParameters)
    //     }
    // }, [getValues, prefixName, setValue, subProcessId, workflows])

    return (
        <>
            <Group label="支付成功后的行为">
                <Controller
                    name={`${prefixName}.payConfig.autoRedirectAfterPayment`}
                    render={({ field }) => (
                        <SwitchControl
                            label="跳转回原页面"
                            disabled={!isActionFlow}
                            checked={field.value}
                            onChange={e => field.onChange(e.target.checked)}
                        />
                    )}
                />
                <Controller
                    name={`${prefixName}.payConfig.callbackWorkflow.workflowId`}
                    control={control}
                    render={({ field }) => (
                        <SubProcessSelector
                            workflows={workflows}
                            value={field.value}
                            onFetchWorkflows={handleFetchWorkflows}
                            onChange={val => {
                                field.onChange?.(val)
                            }}
                        />
                    )}
                />
            </Group>
            {subProcessId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <Group label="子流程参数值设置" defaultOpen>
                        <SC.Tip>设置子流程的参数值，参数值将会传递到子流程中。</SC.Tip>
                        <Controller
                            name={`${prefixName}.payConfig.callbackWorkflow.workflowArgs`}
                            control={control}
                            render={({ field }) => {
                                const list = (field.value || []) as SubProcessArg[]
                                const data =
                                    subProcessArgs?.map(item => {
                                        const dataItem = find(d => d.id === item.id, list)
                                        return {
                                            ...item,
                                            value: dataItem?.value
                                        }
                                    }) || []
                                return (
                                    <SettingSubProcessContent
                                        data={data}
                                        dataSource={dataSource}
                                        allParentNodes={allParentNodes}
                                        // currentNodeFields={currentNodeFields}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </Group>
                </>
            )}
        </>
    )
}
