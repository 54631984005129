import { Button } from '@byecode/ui/components/Button'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Input } from '@byecode/ui/components/Input'
import type { PopoverDropdownProps } from '@byecode/ui/components/Popover'
import { Popover, usePopoverContext } from '@byecode/ui/components/Popover'
import type { TreeNode } from '@byecode/ui/components/Tree'
import { getNodeLeafsIds, Tree } from '@byecode/ui/components/Tree'
import { useContextPopoverHeight } from '@byecode/ui/hooks/usePopoverHeight'
import { singleTextEllipsis, tinyButtons } from '@byecode/ui/styles/mixins'
import { DataSourceType, PAGE_TYPE } from '@lighthouse/core'
import { getTableIcon, PageIconTypeMap } from '@lighthouse/shared'
import { useUncontrolled } from '@lighthouse/tools'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useAppLanguageList, useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { usePageList } from '@/hooks/usePage'

const StyledTrigger = styled.div<{ disabled?: boolean }>`
    display: flex;
    gap: 4px;
    position: relative;
    background-color: var(--color-gray-100);
    border: 1.5px solid transparent;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    padding-left: 8px;
    height: 32px;
    border-radius: 6px;
    &:hover {
        background-color: var(--color-gray-200);
    }
`

const StyledInput = styled.input`
    opacity: 0;
    appearance: none;
    position: absolute;
    inset: 0;
    pointer-events: none;
`

const StyledPrefix = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-400);
`
const StyledPreviewValue = styled.div`
    flex: 1;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    ${singleTextEllipsis}
`
const StyledSuffix = styled.div`
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color: var(--color-gray-400);
`

const StyledDropdown = styled(Popover.Dropdown)`
    overflow: auto;
    ${tinyButtons}
`

const AutoResizeDropdown = (props: PopoverDropdownProps) => {
    const { context } = usePopoverContext()
    const [maxHeight, ref] = useContextPopoverHeight({ context, initMaxHeight: 640 })

    return <StyledDropdown ref={ref} {...props} style={{ maxHeight }} />
}

function findNode(id: string, tree: TreeNode[]): TreeNode | undefined {
    for (const node of tree) {
        if (node.value === id) {
            return node
        }

        if (node.children) {
            const res = findNode(id, node.children)
            if (res) {
                return res
            }
        }
    }
}

function filterTreeBySearch(search: string, tree: TreeNode[]): TreeNode[] {
    return tree.reduce<TreeNode[]>((total, curr) => {
        if (curr.label.includes(search)) {
            return [...total, curr]
        }

        if (curr.children) {
            const childrenNodes = filterTreeBySearch(search, curr.children)
            if (childrenNodes.length === 0) {
                return total
            }
            return [...total, { ...curr, children: childrenNodes }]
        }

        return total
    }, [])
}

interface TreeSelectProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    defaultValue?: string[]
    value?: string[]
    onChange?: (value: string[]) => void
}

export const TreeSelect = (props: TreeSelectProps) => {
    const { defaultValue, value, onChange, ...rest } = props

    const [_value, _onChange] = useUncontrolled({ value, defaultValue, onChange })

    const [open, setOpen] = useState(false)

    const [search, setSearch] = useState('')

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const allLanguage = useAppLanguageList()
    const allPageList = usePageList()

    const pageTreeData = useMemo(() => {
        return allLanguage.reduce<TreeNode[]>((languageList, currLanguage) => {
            const currentLangPageList = allPageList.filter(item => item.language === currLanguage.lang)
            // 没有页面叶子节点的数据源过滤掉
            if (currentLangPageList.length === 0) {
                return languageList
            }

            const currentLangDefaultPageList = currentLangPageList.filter(item => item.type === PAGE_TYPE.default)
            const currentLangOtherPageList = currentLangPageList.filter(item => item.type !== PAGE_TYPE.default)

            const joinDataSourceList = dataSourceList.filter(item => item.type === DataSourceType.joinDataSource)

            const currentLangDsList = dataSourceList.reduce<TreeNode[]>((total, curr) => {
                const pageList = currentLangOtherPageList.filter(item => item.dsId === curr.id)
                // 没有页面叶子节点的数据源过滤掉
                if (pageList.length === 0) {
                    return total
                }
                const node: TreeNode = {
                    label: curr.name,
                    value: `${currLanguage.lang}-${curr.id}`,
                    icon: <IconFont type={getTableIcon(curr)} fill="var(--color-gray-400)" size={16} />,
                    children: pageList.map(item => ({
                        label: item.name,
                        value: item.id,
                        icon: <IconFont type={PageIconTypeMap[item.type]} fill="var(--color-gray-400)" size={16} />
                    }))
                }

                const isJoinDs = curr.type === DataSourceType.joinDataSource
                // 连接表插入到源数据源节点下，可能total中还没源数据源，放到后面插入
                if (isJoinDs) {
                    // const originDs = total.find(item => item.value === curr.viewOptions.joinConfig?.primaryDsId)
                    // if (originDs) {
                    //     originDs.children?.unshift(node)
                    // }
                    return total
                }

                return [...total, node]
            }, [])

            // 连接表插入到源数据源节点下
            joinDataSourceList.forEach(ds => {
                const originDs = currentLangDsList.find(
                    item =>
                        item.value ===
                        (ds.viewOptions.joinConfig?.primaryDsId && `${`${currLanguage.lang}-${ds.viewOptions.joinConfig?.primaryDsId}`}`)
                )
                if (originDs) {
                    const pageList = currentLangOtherPageList.filter(item => item.dsId === ds.id)
                    // 没有页面叶子节点的数据源过滤掉
                    if (pageList.length === 0) {
                        return
                    }
                    const node: TreeNode = {
                        label: ds.name,
                        value: `${currLanguage.lang}-${ds.id}`,
                        icon: <IconFont type={getTableIcon(ds)} fill="var(--color-gray-400)" size={16} />,
                        children: pageList.map(item => ({
                            label: item.name,
                            value: item.id,
                            icon: <IconFont type={PageIconTypeMap[item.type]} fill="var(--color-gray-400)" size={16} />
                        }))
                    }
                    originDs.children?.unshift(node)
                }
            })

            const rootNode: TreeNode = {
                label: currLanguage.title,
                value: currLanguage.lang,
                children: [
                    {
                        label: '普通页面',
                        value: `${currLanguage.lang}-default`,
                        children: currentLangDefaultPageList.map(item => ({
                            label: item.name,
                            value: item.id,
                            icon: <IconFont type={PageIconTypeMap[item.type]} fill="var(--color-gray-400)" size={16} />
                        }))
                    },
                    {
                        label: '数据页面',
                        value: `${currLanguage.lang}-other`,
                        children: currentLangDsList
                    }
                ]
            }

            return [...languageList, rootNode]
        }, [])
    }, [allLanguage, allPageList, dataSourceList])

    const filterOptions = useMemo(() => {
        if (!search) {
            return pageTreeData
        }

        return filterTreeBySearch(search, pageTreeData)
    }, [pageTreeData, search])

    const [internalChecked, setInternalChecked] = useState<string[]>(
        pageTreeData.reduce<string[]>((total, curr) => [...total, ...getNodeLeafsIds(curr)], [])
    )

    // useEffect(() => {
    //     if (pageTreeData.length > 0) {
    //         setInternalChecked(pageTreeData.reduce<string[]>((total, curr) => [...total, ...getNodeLeafsIds(curr)], []))
    //     }
    // }, [pageTreeData])

    const previewLabel = useMemo(() => {
        if (internalChecked.length === 0) {
            return '未筛选'
        }

        if (internalChecked.length === 1) {
            const [nodeId] = internalChecked
            const node = findNode(nodeId, pageTreeData)
            if (!node) {
                return ''
            }

            return node.label
        }

        return `${internalChecked.length}个页面`
    }, [internalChecked, pageTreeData])

    return (
        <Popover withinPortal opened={open} onChange={setOpen} trapFocus={false} width={296}>
            <Popover.Target>
                <StyledTrigger style={{ width: 116 }} {...rest}>
                    <StyledInput type="search" />
                    <StyledPrefix>
                        <IconFont size={16} type="SearchLine" />
                    </StyledPrefix>
                    <StyledPreviewValue>{previewLabel}</StyledPreviewValue>
                    <StyledSuffix>
                        <IconFont size={16} type="ArrowDownSmall" />
                    </StyledSuffix>
                </StyledTrigger>
            </Popover.Target>
            <AutoResizeDropdown>
                <Input
                    prefix={<IconFont type="Search" />}
                    placeholder="搜索"
                    style={{ margin: '4px 12px 0' }}
                    value={search}
                    onChange={e => {
                        setSearch(e.target.value)
                    }}
                />
                <Tree mt={8} data={filterOptions} checkedNodes={internalChecked} onCheckedNodesChange={setInternalChecked} />
                <Flex gap={12} mt={40} px={16} py={8}>
                    <Button
                        block
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        取消
                    </Button>
                    <Button
                        block
                        type="primary"
                        radius={8}
                        onClick={() => {
                            _onChange(internalChecked)
                            setOpen(false)
                        }}
                    >
                        确定
                    </Button>
                </Flex>
            </AutoResizeDropdown>
        </Popover>
    )
}
