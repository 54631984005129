import type { BaseBreakPointProtocol, ChartViewOptions } from '@lighthouse/core'
import { ChartType } from '@lighthouse/core'

import { DEFAULT_THEME_COLORS } from '../sharedStyle'

/**
 * 生成图标block的配置
 *
 * @param {ChartType} type
 * @returns {ChartOptionProtocol}
 */
export const generateChartBlock = (type: ChartType): ChartViewOptions => {
    const breakPointConfig: BaseBreakPointProtocol = {
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                }
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }

    if (type === ChartType.indicator) {
        return {
            chartType: type,
            appId: '',
            pointer: '',
            showHeadTitle: true,
            title: '指标卡',
            showLabel: false,
            showThousandthSplit: false,
            showUnit: false,
            fieldId: '',
            linkFilterController: {
                expression: {
                    where: 'AND',
                    conditions: []
                }
            },
            ...breakPointConfig
        }
    }
    return {
        appId: '',
        pointer: '',
        chartType: type,
        dimensions: [],
        mainAxis: [],
        secondaryAxis: [],
        theme: DEFAULT_THEME_COLORS.value,
        colors: DEFAULT_THEME_COLORS.colors,
        mainMarkLines: [],
        secondaryMarkLines: [],
        aggregate: true,
        showHeadTitle: true,
        showLabel: false,
        showLegend: true,
        legendTranspose: 'bottom',
        stacking: 'group',
        showGridLine: true,
        xyAxis: 'X',
        sortMode: 'ASC',
        linkFilterController: {
            expression: {
                where: 'AND',
                conditions: []
            }
        },
        ...breakPointConfig
    }
}
