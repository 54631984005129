import { Flex, IconFont, pointer, Popover, Text } from '@byecode/ui'
import type { BaseBreakPointProtocol } from '@lighthouse/core'
import { MAIN_BREAK_POINT } from '@lighthouse/shared'
import { getBrowser } from '@lighthouse/tools'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { usePreviewType } from '@/hooks/useApplication'

import { useSetExtendsKeys } from '../DesignSetting/hooks/useMouseEventDistance'

interface InheritLabelProps {
    label: string
    name: string
}

const SCxMenu = styled.div`
    width: 100%;
`

const SCxPoint = styled(Flex)`
    height: 32px;
    align-items: center;
    ${pointer}
`

const SCxMenuItem = styled.div`
    padding: 5px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    ${pointer}

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const InheritLabel: React.FC<InheritLabelProps> = ({ label, name }) => {
    const previewType = usePreviewType()
    const { control } = useFormContext<BaseBreakPointProtocol>()
    const { handleRemoveBreakKey } = useSetExtendsKeys()
    const textRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const isMac = getBrowser().getBrowserInfo().platform === 'Macintosh'
    const breakKeys = useWatch({ name: 'breakPoint.breakKeys', control })
    const keyBoardKey = isMac ? 'Option' : 'Alt'

    const breakKey = useMemo(() => {
        const list = name.split('.')
        return list.includes('breakPoint') ? list.slice(1).join('.') : name
    }, [name])

    const isBreak = useMemo(() => {
        return breakKeys?.includes(breakKey) && previewType !== MAIN_BREAK_POINT
    }, [breakKey, breakKeys, previewType])

    const handleReset = useCallback(() => {
        handleRemoveBreakKey(breakKey)
        setOpen(false)
    }, [breakKey, handleRemoveBreakKey])

    const breakColor = useMemo(() => {
        if (isBreak) {
            return 'var(--color-main)'
        }
    }, [isBreak])

    // useHotkeys('enter', ev => {
    //     handleFormSubmit()
    // })

    useEffect(() => {
        if (!textRef.current || !isBreak) {
            return
        }
        textRef.current.addEventListener('mousedown', event => {
            // 判断 Alt 键是否按下
            if (isMac && event.altKey && event.button === 0) {
                event.stopPropagation()
                handleRemoveBreakKey(breakKey)
                return
            }
            if (event.altKey && event.button === 0) {
                event.stopPropagation()
                // 0 是鼠标左键
                handleRemoveBreakKey(breakKey)
            }
        })
    }, [breakKey, handleRemoveBreakKey, isBreak, isMac, name])

    return (
        <Popover width={216} opened={open} onChange={setOpen} disabled={!isBreak} position='bottom-start'>
            <Popover.Target>
                <SCxPoint>
                    <Text ref={textRef} color={breakColor}>
                        {label}
                    </Text>
                </SCxPoint>
            </Popover.Target>
            <Popover.Dropdown>
                <SCxMenu>
                    <SCxMenuItem onClick={handleReset}>
                        <Flex alignItems="center" gap={8}>
                            <IconFont type="Undo" size={16} fill="var(--color-gray-400)" />
                            <Text color="var(--color-black)">重置</Text>
                        </Flex>
                        <Flex alignItems="center" gap={4}>
                            <Text color="var(--color-gray-500)">{keyBoardKey}</Text>
                            <Text color="var(--color-gray-500)">+</Text>
                            <Text color="var(--color-gray-500)">左键</Text>
                        </Flex>
                    </SCxMenuItem>
                </SCxMenu>
            </Popover.Dropdown>
        </Popover>
    )
}
