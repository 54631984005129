import { Divider, Modal, Select, tinyButtons } from '@byecode/ui'
import type { DragEndEvent, DragMoveEvent, DragStartEvent, Modifier } from '@dnd-kit/core'
import { closestCenter, DndContext, DragOverlay, MeasuringStrategy, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { getBlockIcon, getBlockName } from '@lighthouse/block'
import type { BlockAbstract, BlockRuntimeState, DataSourceAbstract, PageType, ViewBlockAbstract } from '@lighthouse/core'
import { BlockType, PAGE_TYPE } from '@lighthouse/core'
import type { ApplicationPreviewEnum, CustomViewVisibleData } from '@lighthouse/shared'
import {
    findBlockById,
    getBlockChildren,
    getCurrentBlockChildren,
    getFieldBlockWithDsId,
    isContainerBlock,
    isCustomViewBlock,
    isFormContainerBlock,
    scroll2FlowNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import produce, { original } from 'immer'
import { clone, find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { openPageStackAtom } from '@/atoms/page/action'
import { blocksAtom, lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { PageFieldBlocksProvider } from '@/contexts/PageContext'
import { useNodeToolbarActions } from '@/hooks/layoutEngine/useNodeToolbarActions'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useBlockActions } from '@/hooks/useBlock'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageList } from '@/hooks/usePage'
import * as srv from '@/services'
import { NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { Item, SortableItem } from './SortableItem'
import type { FlattedNode } from './types'

const Root = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const ListWrapper = styled.div`
    flex: 1;
    padding: 4px 12px 32px;
    overflow: auto;
`

const List = styled.ul`
    list-style: none;
    display: block;
    ${tinyButtons}
    overflow: hidden;
    border-radius: 5px;
    background-color: var(--color-gray-100);
`

const PAGE_ICON_MAP: Record<PageType, string> = {
    [PAGE_TYPE.creator]: 'LayerFormPage',
    [PAGE_TYPE.default]: 'PageBroadContent',
    [PAGE_TYPE.document]: 'LayerDetailPage',
    [PAGE_TYPE.edit]: 'LayerEditPage'
}

function getCustomViewData(block: ViewBlockAbstract, dataSourceList: DataSourceAbstract[]) {
    const dataSource = find(ds => ds.id === block.config.pointer, dataSourceList)
    if (!dataSource) {
        return
    }
    return {
        name: block.title,
        dataSource
    }
}

type FlattenParams = {
    blockRuntimeState?: BlockRuntimeState
    indent: number
    parentId?: string
    collapsedIds: string[]
    dataSourceList: DataSourceAbstract[]
    previewType: ApplicationPreviewEnum
    customViewData?: CustomViewVisibleData
}
function flattenBlocks(blocks: BlockAbstract[], option: FlattenParams): FlattedNode[] {
    const { blockRuntimeState, indent, parentId, collapsedIds, dataSourceList, previewType, customViewData } = option
    return blocks.reduce<FlattedNode[]>((total, current) => {
        const collapsed = collapsedIds.includes(current.id)

        const isContainerBlock = current.type === BlockType.container
        const isFormContainerBlock = current.type === BlockType.formContainer
        const isCustomViewBlock = current.type === BlockType.view && current.config.viewType === 'custom'
        const collapsible = isContainerBlock || isFormContainerBlock || isCustomViewBlock

        const currentCustomViewData: CustomViewVisibleData | undefined = isCustomViewBlock
            ? getCustomViewData(current, dataSourceList)
            : undefined

        const children = getCurrentBlockChildren(current, blockRuntimeState)
        const visibleMode = current.config.breakPoint?.visibility?.visible
        return [
            ...total,
            {
                id: current.id,
                name: current.title || getBlockName(current),
                icon: getBlockIcon(current, previewType),
                indent,
                parentId,
                collapsible,
                collapsed,
                block: current,
                children,
                customViewData,
                description: isFormContainerBlock ? dataSourceList.find(item => item.id === current.config.pointer)?.name : undefined,
                visibleMode
            },
            ...(collapsed
                ? []
                : flattenBlocks(children || [], {
                      blockRuntimeState,
                      indent: indent + 1,
                      parentId: current.id,
                      collapsedIds,
                      dataSourceList,
                      previewType,
                      customViewData: currentCustomViewData || customViewData
                  }))
        ]
    }, [])
}

function buildPageBlocksTree(nodes: FlattedNode[], blockRuntimeState: BlockRuntimeState | undefined): BlockAbstract[] {
    const tree: BlockAbstract[] = []
    const map: Record<string, BlockAbstract> = {}

    nodes.forEach(node => {
        const block = clone(node.block)
        if (!block) {
            return
        }

        if (isContainerBlock(block)) {
            const views = block.children
            views.find(view => view.id === blockRuntimeState?.container?.[block.id]?.currentView)?.children.splice(0)
            map[block.id] = {
                ...block,
                children: views
            }

            return
        }

        if (isFormContainerBlock(block) || isCustomViewBlock(block)) {
            map[block.id] = {
                ...block,
                children: []
            }
            return
        }

        map[block.id] = block
    })

    nodes.forEach(node => {
        if (!node.parentId) {
            tree.push(map[node.id])
            return
        }

        if (node.parentId && map[node.parentId]) {
            const data = map[node.id]

            const parent = map[node.parentId]
            if (isContainerBlock(parent)) {
                const view = parent.children.find(item => item.id === blockRuntimeState?.container?.[parent.id]?.currentView)
                if (!view) {
                    return
                }
                view.children.push(data)
                return
            }

            if (isFormContainerBlock(parent) || isCustomViewBlock(parent)) {
                parent.children?.push(data)
            }
        }
    })

    return tree
}

function getParentId(indent: number, overIndex: number, items: FlattedNode[], prevItem?: FlattedNode) {
    if (indent === 1 || !prevItem) {
        return
    }
    if (indent === prevItem.indent) {
        return prevItem.parentId
    }
    if (indent > prevItem.indent) {
        return prevItem.id
    }
    return items
        .slice(0, overIndex)
        .reverse()
        .find(item => item.indent === indent)?.parentId
}

/** 检查是否包含禁止的block */
function checkIncludeNotAllowedBlock(block: BlockAbstract) {
    if (block.type === BlockType.view || block.type === BlockType.chart) {
        return true
    }

    const children = getBlockChildren(block)
    if (children) {
        for (const child of children) {
            const res = checkIncludeNotAllowedBlock(child)
            if (res) {
                return true
            }
        }
    }

    return false
}

/** 检查是否是自定义视图后代 */
function checkIsCustomViewChildren(flattedPageNodes: FlattedNode[], parentId: string | undefined): boolean {
    if (!parentId) {
        return false
    }
    const parent = flattedPageNodes.find(item => item.id === parentId)
    if (!parent || !parent.block) {
        return false
    }

    if (isCustomViewBlock(parent.block)) {
        return true
    }

    if (parent.parentId) {
        return checkIsCustomViewChildren(flattedPageNodes, parent.parentId)
    }

    return false
}

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always
    }
}
const adjustTranslate: Modifier = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 25
    }
}

export const PageLayers: React.FC = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const allPageList = usePageList()
    const selectIdRef = useRef('')
    const [pageId, stackId, rootPageId, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.stackId || '', s?.rootPageId || '', s?.blockRuntimeState] as const, [])
    )
    const [pageType, pageName, pageDsId] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.type || PAGE_TYPE.default, s?.name || '', s?.dsId || ''] as const, [])
    )

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const previewType = usePreviewType()
    const options = useMemo(() => {
        return allPageList.map(item => ({
            label: item.name,
            value: item.id
        }))
    }, [allPageList])

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const { run: setPageBlocks } = useAtomAction(blocksAtom)
    const { asideType, selectedNodes } = useAtomData(
        pageStackAtomFamily({ stackId, rootPageId }),
        useCallback(s => s?.state ?? { asideType: AsideType.PAGE }, [])
    )
    const selectedNode = selectedNodes && selectedNodes.length === 1 ? selectedNodes[0] : undefined
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const [collapsedIds, setCollapsedIds] = useState<string[]>([])
    const [activeId, setActiveId] = useState<string>()

    const fieldBlocksWithDsId = useMemo(
        () => getFieldBlockWithDsId({ blocks: pageBlocks, blockRuntimeState, pageDsId }),
        [blockRuntimeState, pageBlocks, pageDsId]
    )

    useEffect(() => {
        if (selectedNode && selectIdRef.current !== selectedNode) {
            selectIdRef.current = selectedNode
            document.querySelector(`li[data-layer-id="${selectedNode}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
    }, [selectedNode])

    const flattedPageNodes = useMemo(() => {
        const collapsed = collapsedIds.includes(pageId)
        return collapsed
            ? []
            : flattenBlocks(pageBlocks, {
                  blockRuntimeState,
                  indent: 1,
                  collapsedIds: activeId ? [...collapsedIds, activeId] : collapsedIds,
                  previewType,
                  dataSourceList
              })
    }, [activeId, blockRuntimeState, collapsedIds, dataSourceList, pageBlocks, pageId, previewType])

    const items = useMemo(() => flattedPageNodes.map(item => item.id), [flattedPageNodes])

    const activeFlattedNode = useMemo(() => flattedPageNodes.find(item => item.id === activeId), [activeId, flattedPageNodes])

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 4 }
        })
    )
    const [overInfo, setOverInfo] = useState<{ indent: number; parentId?: string } | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    stack.state.asideType = AsideType.BLOCK
                    stack.state.selectedNodes = [e.active.id] as string[]
                }
            })
        }
    }

    const onDragMove = ({ delta, active, over }: DragMoveEvent) => {
        if (over) {
            const activeIndex = items.indexOf(active.id as string)
            const overIndex = items.indexOf(over.id as string)
            const activeItem = flattedPageNodes[activeIndex]
            const newItems = arrayMove(flattedPageNodes, activeIndex, overIndex)
            const prevItem = newItems[overIndex - 1]
            const nextItem = newItems[overIndex + 1]
            const currentIndent = activeItem.indent + Math.round(delta.x / 20)

            const max = prevItem ? (prevItem.collapsible ? prevItem.indent + 1 : prevItem.indent) : 1
            const min = nextItem ? nextItem.indent : 1
            const indent = Math.max(min, Math.min(max, currentIndent))
            const parentId = getParentId(indent, overIndex, newItems, prevItem)

            if (activeIndex === overIndex && indent === activeItem.indent) {
                setOverInfo(null)

                return
            }

            if (
                activeItem.block &&
                checkIncludeNotAllowedBlock(activeItem.block) &&
                checkIsCustomViewChildren(flattedPageNodes, parentId)
            ) {
                setOverInfo(null)
                return
            }
            setOverInfo({ indent, parentId })
        } else {
            setOverInfo(null)
        }
    }

    const onDragEnd = ({ over, active }: DragEndEvent) => {
        reset()

        if (over && overInfo) {
            const { indent, parentId } = overInfo
            const flattenNodes = flattenBlocks(pageBlocks, {
                blockRuntimeState,
                indent: 1,
                collapsedIds: [],
                previewType,
                dataSourceList
            })
            const activeIndex = flattenNodes.findIndex(item => item.id === active.id)
            const overIndex = flattenNodes.findIndex(item => item.id === over.id)

            const sortedNodes = arrayMove(
                produce(flattenNodes, draft => {
                    draft[activeIndex].parentId = parentId
                    draft[activeIndex].indent = indent
                }),
                activeIndex,
                overIndex
            )
            const newNodes = buildPageBlocksTree(sortedNodes, blockRuntimeState)
            let originBlocks: BlockAbstract[] | undefined

            setPageBlocks(draft => {
                const nodes = draft[pageId]
                originBlocks = original(nodes)
                if (!nodes) {
                    return
                }
                draft[pageId] = newNodes

                // scroll2FlowNode(active.id as string)
            })

            srv.updateBlock({ pageId, allBlocks: newNodes })

            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: { prev: originBlocks, next: newNodes }
            })
        }
    }

    const onDragCancel = () => {
        reset()
    }

    const reset = () => {
        setActiveId(undefined)
        setOverInfo(null)
    }

    const actions = useNodeToolbarActions({ pageId, rootPageId, stackId })

    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    // 是否是选中的节点后代
    const isSelectedChildren = (id: string) => {
        if (asideType !== AsideType.BLOCK || !selectedNode) {
            return false
        }

        const selectedBlock = findBlockById(selectedNode, pageBlocks)
        if (!selectedBlock) {
            return false
        }

        const children = getBlockChildren(selectedBlock)
        if (!children) {
            return false
        }

        const isLeaf = (children: BlockAbstract[]): boolean => {
            for (const node of children) {
                if (node.id === id) {
                    return true
                }

                const nestChildren = getBlockChildren(node)
                if (nestChildren && isLeaf(nestChildren)) {
                    return true
                }
            }

            return false
        }

        return isLeaf(children)
    }

    return (
        <PageFieldBlocksProvider value={fieldBlocksWithDsId}>
            <Root>
                <Select m={12} options={options} value={pageId} onChange={v => openPageStack(stackFactory({ appId, pageId: v }))} />

                <Divider my={8} />

                <ListWrapper>
                    <List data-ignore-click-away>
                        <Item
                            data={{
                                id: 'page',
                                indent: 0,
                                name: pageName,
                                icon: PAGE_ICON_MAP[pageType],
                                description: dataSourceList.find(item => item.id === pageDsId)?.name || '',
                                collapsed: collapsedIds.includes(pageId),
                                collapsible: true
                            }}
                            // disabledVisibleIcon
                            onCollapseChange={v => {
                                setCollapsedIds(s => {
                                    if (v) {
                                        return [...s, pageId]
                                    }
                                    return s.filter(id => id !== pageId)
                                })
                            }}
                            isSelected={asideType === AsideType.PAGE}
                            onClick={() => {
                                setPageStack(draft => {
                                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                                    if (stack) {
                                        stack.state.asideType = AsideType.PAGE
                                        stack.state.selectedNodes = undefined
                                    }
                                })
                            }}
                        />
                        <DndContext
                            sensors={sensors}
                            measuring={measuring}
                            collisionDetection={closestCenter}
                            onDragStart={onDragStart}
                            onDragMove={onDragMove}
                            onDragEnd={onDragEnd}
                            onDragCancel={onDragCancel}
                        >
                            <SortableContext disabled={disabledWithVersion} items={items} strategy={verticalListSortingStrategy}>
                                {flattedPageNodes.map(item => (
                                    <SortableItem
                                        key={item.id}
                                        disabled={disabledWithVersion}
                                        isSelected={asideType === AsideType.BLOCK && item.id === selectedNode}
                                        isSelectedChildren={isSelectedChildren(item.id)}
                                        data={{ ...item, indent: activeId === item.id && overInfo ? overInfo.indent : item.indent }}
                                        onNameChange={v =>
                                            item.block &&
                                            onUpdateBlock(
                                                produce(item.block, draft => void (draft.title = v)),
                                                item.block
                                            )
                                        }
                                        onCollapseChange={v => {
                                            setCollapsedIds(s => {
                                                if (v) {
                                                    return [...s, item.id]
                                                }
                                                return s.filter(id => id !== item.id)
                                            })
                                        }}
                                        onDeleteItem={async id => {
                                            if (item.collapsible) {
                                                const isConfirm = await Modal.confirm({
                                                    title: '确认删除',
                                                    content: '删除后，该节点之后的其他节点也将一并删除，且不可恢复，请谨慎操作！'
                                                })
                                                if (!isConfirm) {
                                                    return
                                                }
                                            }
                                            actions.onRemove([id])
                                        }}
                                        // onDuplicationItem={actions.onClone}
                                        onClick={() => {
                                            selectIdRef.current = item.id
                                            setPageStack(draft => {
                                                const stack = equalPageStack({ rootPageId, stackId })(draft)
                                                if (stack) {
                                                    stack.state.asideType = AsideType.BLOCK
                                                    stack.state.selectedNodes = [item.id]
                                                }
                                                scroll2FlowNode(item.id)
                                            })
                                        }}
                                    />
                                ))}

                                {createPortal(
                                    <DragOverlay modifiers={[adjustTranslate]} style={{ opacity: 0.8 }}>
                                        {activeFlattedNode && <Item data={activeFlattedNode} isOverlay />}
                                    </DragOverlay>,
                                    document.body
                                )}
                            </SortableContext>
                        </DndContext>
                    </List>
                </ListWrapper>
            </Root>
        </PageFieldBlocksProvider>
    )
}
