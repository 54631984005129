import type { Sorter, VariableADTvalue } from '@lighthouse/core'
import {
    type FilterCommonCondition,
    type FilterConditions,
    type FilterFormType,
    type FilterGroupType,
    type JoinSetting,
    VariableType
} from '@lighthouse/core'
import type { JSONContent } from '@tiptap/core'

import { USER_DATASOURCE } from '../constants'
import type { FieldFindUse, FindUseADTObject, FlowNode } from '../types'
import { FindUseType } from '../types'
import { getUpstreamRealDsId } from './flow'

type GetIsFindUsePayload = {
    findUseObject?: FindUseADTObject
    type: FindUseType
    dsId?: string
    fieldId?: string
}

export const getIsFindUse = (payload: GetIsFindUsePayload) => {
    const { findUseObject, type, dsId, fieldId } = payload
    if (!findUseObject || findUseObject.type !== type || !dsId) {
        return false
    }
    if (findUseObject.type === FindUseType.DATASHEET) {
        return findUseObject.dataSheet.dsId === dsId
    }
    return findUseObject.field.dsId === dsId && findUseObject.field.fieldId === fieldId
}

type GetIsFindUseInJoinSettingPayload = {
    findUseObject?: FindUseADTObject
    type: FindUseType
    data: JoinSetting
}

// 在连接表设置中查找使用
export const getIsFindUseInJoinSetting = (payload: GetIsFindUseInJoinSettingPayload) => {
    const { findUseObject, type, data } = payload
    if (!findUseObject || findUseObject.type !== type) {
        return false
    }
    if (findUseObject.type === FindUseType.DATASHEET) {
        return findUseObject.dataSheet.dsId === data.joinedDsId
    }
    const { dsId, fieldId } = findUseObject.field
    return data.joinConditions?.some(item => {
        return (
            (data.joinedDsId === dsId && item.leftFieldId === fieldId) ||
            (item.right?.type === VariableType.VARIABLE && item.right?.fieldVariable?.dsId === dsId && item.right?.fieldVariable.fieldId === fieldId)
        )
    })
}

type getIsFindUseInVariablePayload = {
    findUseObject?: FindUseADTObject
    variable?: VariableADTvalue
    currentDsId?: string
    allParentNodes?: FlowNode[]
}

export const getIsFindUseInVariable = (payload: getIsFindUseInVariablePayload): boolean => {
    const { currentDsId, variable, findUseObject, allParentNodes } = payload
    if (!variable || !findUseObject || findUseObject.type === FindUseType.DATASHEET) {
        return false
    }
    const { dsId, fieldId } = findUseObject.field
    switch (variable.type) {
        case VariableType.FIELD_ID: {
            return dsId === currentDsId && fieldId === variable.fieldIdVariable?.fieldId
        }
        case VariableType.USER: {
            return dsId === USER_DATASOURCE && fieldId === variable.userVariable?.fieldId
        }
        case VariableType.INPUT: {
            return false
        }
        case VariableType.VARIABLE: {
            return dsId === variable.fieldVariable?.dsId && fieldId === variable.fieldVariable.fieldId
        }

        case VariableType.PAGE: {
            return dsId === variable.pageVariable?.dsId && fieldId === variable.pageVariable.fieldId
        }

        case VariableType.VIEW: {
            return dsId === currentDsId && fieldId === variable.viewVariable?.fieldId
        }

        case VariableType.UPSTREAM: {
            if (!allParentNodes || !variable.upstreamVariable?.nodeId) {
                return false
            }
            const upstreamDsId = getUpstreamRealDsId(variable.upstreamVariable?.nodeId, allParentNodes)
            return dsId === upstreamDsId && fieldId === variable.upstreamVariable?.fieldId
        }

        default: {
            return false
        }
    }
}

type GetIsFindUseInConditionsPayload = {
    findUseObject: FieldFindUse
    conditions: FilterConditions[]
    currentDsId?: string
    allParentNodes?: FlowNode[]
}

const getIsFindUseInConditions = (payload: GetIsFindUseInConditionsPayload): boolean => {
    const { findUseObject, conditions, currentDsId, allParentNodes } = payload
    return conditions.some(condition => {
        const childrenConditions = (condition as FilterGroupType)?.conditions
        const childrenWhere = (condition as FilterGroupType)?.where
        if (childrenConditions && childrenWhere) {
            return getIsFindUseInConditions({ findUseObject, conditions: childrenConditions, currentDsId, allParentNodes })
        }
        const { idVariable, paramList } = condition as FilterCommonCondition

        const isIdVariableFindUse = getIsFindUseInVariable({
            findUseObject,
            variable: idVariable,
            currentDsId,
            allParentNodes
        })

        const isParamList =
            !!paramList &&
            paramList.some(param => {
                return getIsFindUseInVariable({
                    findUseObject,
                    variable: param,
                    currentDsId,
                    allParentNodes
                })
            })

        return isIdVariableFindUse || isParamList
    })
}

type GetIsFindUseInFilterPayload = {
    findUseObject?: FindUseADTObject
    data?: FilterFormType
    currentDsId?: string // 当前使用filter的dsId
    allParentNodes?: FlowNode[]
}
// 在筛选器中查找使用
export const getIsFindUseInFilter = (payload: GetIsFindUseInFilterPayload) => {
    const { findUseObject, data, currentDsId, allParentNodes } = payload
    if (!findUseObject || !data || findUseObject.type === FindUseType.DATASHEET) {
        return false
    }
    const conditions = data.expression?.conditions
    if (!conditions) {
        return false
    }
    return getIsFindUseInConditions({
        findUseObject,
        conditions,
        currentDsId,
        allParentNodes
    })
}

type GetIsFindUseInSortPayload = {
    findUseObject?: FindUseADTObject
    data?: Sorter[]
    currentDsId?: string // 当前使用filter的dsId
}

// 在排序中查找使用
export const getIsFindUseInSort = (payload: GetIsFindUseInSortPayload) => {
    const { findUseObject, data, currentDsId } = payload
    if (!findUseObject || !data || findUseObject.type === FindUseType.DATASHEET) {
        return false
    }
    const { dsId, fieldId } = findUseObject.field
    return data.some(sort => {
        return sort.fieldId === fieldId && currentDsId === dsId
    })
}

type GetIsFindUseInRichTextPayload = {
    doc?: JSONContent | null
    findUseObject?: FindUseADTObject
    currentDsId?: string
    allParentNodes?: FlowNode[]
}
export const getIsFindUseInRichText = (payload: GetIsFindUseInRichTextPayload): boolean => {
    const { doc, findUseObject, currentDsId, allParentNodes } = payload
    if (!findUseObject || findUseObject.type === FindUseType.DATASHEET || !doc?.content) {
        return false
    }
    return doc.content.some(c => {
        if (c.content) {
            return getIsFindUseInRichText({
                doc: c,
                findUseObject,
                currentDsId,
                allParentNodes
            })
        }
        if (c.type === 'variable' && c?.attrs?.value) {
            const variable = c.attrs.value as VariableADTvalue
            return getIsFindUseInVariable({
                findUseObject,
                variable,
                currentDsId,
                allParentNodes
            })
        }
        return false
    })
}
