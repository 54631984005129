import {
    BlockThumbImageMap,
    BlockTypeIconMap
} from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type { BlockTreeItem } from '../type'

// export const appNavBlock: BlockTreeItem = {
//     name: '应用导航',
//     icon: BlockTypeIconMap.appNav,
//     describeImage: BlockThumbImageMap['appNav'],
//     color: '#2E90FA',
//     backGroundColor: 'rgba(46, 144, 250, 0.10)',
//     type: BlockType.appNav
// }


export const blockFilter: BlockTreeItem = {
    name: '筛选控制器',
    icon: BlockTypeIconMap.filter,
    describeImage: BlockThumbImageMap['filter'],
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)',
    type: BlockType.filter
}

export const blockTabs: BlockTreeItem = {
    name: '标签栏',
    icon: BlockTypeIconMap.tabs,
    describeImage: BlockThumbImageMap['tabs'],
    color: '#5551FF',
    backGroundColor: 'rgba(85, 81, 255, 0.10)',
    type: BlockType.tabs
}
