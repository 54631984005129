import { Group, SegmentedControl } from '@byecode/ui'
import type { ContainerBlockConfig } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { InheritLabel } from '@/components/InheritLabel'

import { OVERFLOW_OPTIONS } from '../constants'
import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'
import { MinMaxRectSizeAdder } from './MinMaxRectSizeAdder'
import { RectMinMaxSizeInput } from './RectMinMaxSizeInput'
import { RectSizeInput } from './RectSizeInput'

export interface SizeSettingSwitch {
    hideHeight?: boolean
    hideMinMaxRectSizeAdder?: boolean
    hideMaxWidth?: boolean
    hideMinWidth?: boolean
    hideMaxHeight?: boolean
    hideMinHeight?: boolean
    hideOverflow?: boolean

    disableWidthAuto?: boolean
    disableHeightAuto?: boolean

    disableWidth?: boolean
    disableMinWidth?: boolean
    disableMaxWidth?: boolean
    disableHeight?: boolean
    disableMinHeight?: boolean
    disableMaxHeight?: boolean
    disableOverflow?: boolean
}

export interface SizeSettingProps extends SizeSettingSwitch {}

export const SizeSetting: React.FC<SizeSettingProps> = ({
    hideHeight,
    hideMinMaxRectSizeAdder,
    hideMaxWidth,
    hideMinWidth,
    hideMaxHeight,
    hideMinHeight,
    hideOverflow,
    disableWidthAuto,
    disableHeightAuto,
    disableWidth,
    disableMinWidth,
    disableMaxWidth,
    disableHeight,
    disableMinHeight,
    disableMaxHeight,
    disableOverflow
}) => {
    const { setValue, control } = useFormContext<ContainerBlockConfig>()
    const { handleSetBreakKey } = useSetExtendsKeys()
    const [minWidth, maxWidth, minHeight, maxHeight] = useWatch({
        control,
        name: ['breakPoint.size.minWidth', 'breakPoint.size.maxWidth', 'breakPoint.size.minHeight', 'breakPoint.size.maxHeight']
    })

    return (
        <Group
            label="尺寸"
            extra={
                !hideMinMaxRectSizeAdder && (
                    <MinMaxRectSizeAdder hideMinWidth={hideMinWidth} hideMaxHeight={hideMaxHeight} hideMinHeight={hideMinHeight} />
                )
            }
        >
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <InheritLabel label="宽度" name="breakPoint.size.width" />
                <div style={{ display: 'flex' }}>
                    <Controller
                        control={control}
                        name="breakPoint.size.width"
                        render={({ field }) => (
                            <RectSizeInput
                                type="width"
                                disableAutoOption={disableWidthAuto}
                                disable={disableWidth}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.size.width')
                                }}
                            />
                        )}
                    />
                </div>
            </ListItem4ByecodeUi>
            {!hideMinWidth && minWidth && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="最小宽度" name="breakPoint.size.minWidth" />
                    <div style={{ display: 'flex' }}>
                        <Controller
                            control={control}
                            name="breakPoint.size.minWidth"
                            render={({ field }) => (
                                <RectMinMaxSizeInput
                                    type="minWidth"
                                    disable={disableMinWidth}
                                    value={field.value}
                                    onChange={val => {
                                        field.onChange(val)
                                        handleSetBreakKey('breakPoint.size.minWidth')
                                    }}
                                    onRemove={() => {
                                        field.onChange(undefined)
                                        handleSetBreakKey('breakPoint.size.minWidth')
                                    }}
                                />
                            )}
                        />
                    </div>
                </ListItem4ByecodeUi>
            )}
            {!hideMaxWidth && maxWidth && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="最大宽度" name="breakPoint.size.maxWidth" />
                    <div style={{ display: 'flex' }}>
                        <Controller
                            control={control}
                            name="breakPoint.size.maxWidth"
                            render={({ field }) => (
                                <RectMinMaxSizeInput
                                    type="maxWidth"
                                    value={field.value}
                                    disable={disableMaxWidth}
                                    onChange={val => {
                                        field.onChange(val)
                                        handleSetBreakKey('breakPoint.size.maxWidth')
                                    }}
                                    onRemove={() => {
                                        field.onChange(undefined)
                                        handleSetBreakKey('breakPoint.size.maxWidth')
                                    }}
                                />
                            )}
                        />
                    </div>
                </ListItem4ByecodeUi>
            )}
            {!hideHeight && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="高度" name="breakPoint.size.height" />
                    <Controller
                        control={control}
                        name="breakPoint.size.height"
                        render={({ field }) => (
                            <RectSizeInput
                                type="height"
                                disable={disableHeight}
                                disableAutoOption={disableHeightAuto}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.size.height')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}
            {!hideMinHeight && minHeight && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="最小高度" name="breakPoint.size.minHeight" />
                    <div style={{ display: 'flex' }}>
                        <Controller
                            control={control}
                            name="breakPoint.size.minHeight"
                            render={({ field }) => (
                                <RectMinMaxSizeInput
                                    type="minHeight"
                                    value={field.value}
                                    disable={disableMinHeight}
                                    onChange={val => {
                                        field.onChange(val)
                                        handleSetBreakKey('breakPoint.size.minHeight')
                                    }}
                                    onRemove={() => {
                                        field.onChange(undefined)
                                        handleSetBreakKey('breakPoint.size.minHeight')
                                    }}
                                />
                            )}
                        />
                    </div>
                </ListItem4ByecodeUi>
            )}
            {!hideMaxHeight && maxHeight && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="最大高度" name="breakPoint.size.maxHeight" />
                    <div style={{ display: 'flex' }}>
                        <Controller
                            control={control}
                            name="breakPoint.size.maxHeight"
                            render={({ field }) => (
                                <RectMinMaxSizeInput
                                    type="maxHeight"
                                    value={field.value}
                                    disable={disableMaxHeight}
                                    onChange={val => {
                                        field.onChange(val)
                                        handleSetBreakKey('breakPoint.size.maxHeight')
                                    }}
                                    onRemove={() => {
                                        field.onChange(undefined)
                                        handleSetBreakKey('breakPoint.size.maxHeight')
                                    }}
                                />
                            )}
                        />
                    </div>
                </ListItem4ByecodeUi>
            )}
            {!hideOverflow && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="当内容超出" name="breakPoint.size.overflow" />
                    <Controller
                        control={control}
                        name="breakPoint.size.overflow"
                        render={({ field }) => (
                            <SegmentedControl
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.size.overflow')
                                }}
                                disabled={disableOverflow}
                                fullWidth
                                data={OVERFLOW_OPTIONS}
                                style={{ width: 180 }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}
        </Group>
    )
}
