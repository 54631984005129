import { Button, Divider, Group, IconFont } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { Field } from '@lighthouse/core'
import { type CollapseBlockAbstract } from '@lighthouse/core'
import {
    DATE_SYSTEM,
    generateJson,
    getPageDataOptions,
    getSystemOption,
    getUserDatasourceOptions,
    useAtomData,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { SortableConfigure } from './SortableConfigure'

const filterField = (field: Field) => !['file', 'video', 'notes'].includes(field.type)
const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v

export const CollapseSetting = ({ blockId }: { blockId: string }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const { control } = useFormContext<CollapseBlockAbstract['config']>()
    const designProps = getBlockAndPageDesignLimit('collapse')
    const { fields, remove, move, append } = useFieldArray({ control, name: 'list', keyName: 'key' })

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { distance: 8 }
        })
    )

    const [activeId, setActiveId] = useState<string | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = fields.findIndex(item => item.id === over.id)
            const activeIndex = fields.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                move(activeIndex, overIndex)
            }
        }
    }

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const {
        prev,
        curr: { variableSourceType, datasource },
        dataSourceList
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])
    const systemOption = useMemo(() => getSystemOption(DATE_SYSTEM), [])
    const userOption = useMemo(() => {
        const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: filterField
        })
    }, [dataSourceList])

    const { customViewOption } = useVariableCustomViewOption()

    const items = useMemo(() => fields.map(item => item.id), [fields])

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SizeSetting {...designProps.size} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="面板配置">
                        <div style={{ overflowY: 'auto', margin: '8px 0' }}>
                            <DndContext
                                sensors={sensors}
                                modifiers={[restrictToVerticalAxis, restrictToWindowEdges, restrictToFirstScrollableAncestor]}
                                onDragStart={onDragStart}
                                onDragEnd={onDragEnd}
                                onDragCancel={() => setActiveId(null)}
                            >
                                <SortableContext disabled={disabledWithVersion} items={items} strategy={verticalListSortingStrategy}>
                                    {fields.map((item, index) => (
                                        <SortableConfigure
                                            key={item.id}
                                            disabled={disabledWithVersion}
                                            index={index}
                                            id={item.id}
                                            dsId={datasource?.id}
                                            options={options}
                                            userOption={userOption}
                                            systemOption={systemOption}
                                            viewOption={customViewOption}
                                            onRemove={remove}
                                        />
                                    ))}
                                </SortableContext>
                            </DndContext>
                        </div>
                        <Button
                            style={{ gap: 6, padding: '0 12px' }}
                            radius={32}
                            onClick={() => {
                                append({
                                    id: nanoid(),
                                    title: generateJson('标题'),
                                    subTitle: generateJson('副标题'),
                                    introduction: generateJson('请输入描述')
                                })
                            }}
                            icon={<IconFont size={16} fill="var(--color-gray-500)" type="Add" />}
                        >
                            添加
                        </Button>
                    </Group>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
