import type { TextBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { generateJson, TEXT_FONT_NORMAL } from '@lighthouse/shared'

/**
 * 生成文本block的配置
 *
 * @returns {TextBlockConfig}
 */
export const generateTextBlock = (): TextBlockConfig => {
    return {
        content: { value: generateJson('这是一段文本') },
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'visible'
            },
            design: {
                color: { type: BACKGROUND_TYPE.color, color: '#101828' },
                // shadow: {
                //     blur: 4,
                //     color: "#00000040",
                //     diffusion: 4,
                //     x: 0,
                //     y: 4
                // }
            },
            font: {
                advance: '',
                fontFamily: TEXT_FONT_NORMAL,
                fontSize: 1,
                lineHeight: 1.5,
                letterSpacing: 0,
                fontWeight: 400
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
