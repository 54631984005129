import { Toast } from '@byecode/ui'
import { Box } from '@byecode/ui/components/Box'
import { Button } from '@byecode/ui/components/Button'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Input } from '@byecode/ui/components/Input'
import { Modal } from '@byecode/ui/components/Modal'
import { Radio } from '@byecode/ui/components/Radio'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { collectBlockAndChildren } from '@lighthouse/block'
import type { BlockAbstract, ContainerBlockAbstract, ContainerBlockConfig, ContainerView } from '@lighthouse/core'
import { findBlockById, useAtomAction, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { current } from 'immer'
import { useSetAtom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { syncBlocksAtom } from '@/atoms/application/state'
import { createBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, lastPageOfStackAtom, pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { copyBlock } from '@/hooks/layoutEngine/utils'
import * as srv from '@/services'

interface Props {
    containerId: string
    syncHostContainerId: string
}

export const ViewListSettings = ({ syncHostContainerId, containerId }: Props) => {
    const { control } = useFormContext<ContainerBlockConfig>()
    const { fields, append, insert, move, remove } = useFieldArray({ control, name: 'viewList', keyName: 'key' })

    const [activeId, setActiveId] = useState<string>()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { distance: 8 }
        })
    )
    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = fields.findIndex(item => item.id === over.id)
            const activeIndex = fields.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                move(activeIndex, overIndex)
            }
        }
        setActiveId(undefined)
    }

    const { pageId = '', stackId = '', rootPageId = '' } = useAtomData(lastPageOfStackAtom) ?? {}

    const logicId = useAtomData(
        pageBlocksAtom(pageId),
        useCallback(
            s => {
                const block = findBlockById(containerId, s)
                // s?.find(item => item.id === containerId)
                return block?.id
            },
            [containerId]
        )
    )
    const componentRuntimeState = useAtomData(
        pageStackAtom,
        useCallback(
            draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack && logicId) {
                    return stack.blockRuntimeState.container?.[logicId]
                }
            },
            [logicId, rootPageId, stackId]
        )
    )

    const { run: setPageBlocks } = useAtomAction(blocksAtom)
    const syncBlocks = useAtomData(syncBlocksAtom)
    const setSyncBlocks = useSetAtom(syncBlocksAtom)

    const countRef = useRef(fields.length)

    const onCreate = () => {
        const id = nanoid()
        const key = (++countRef.current).toString().padStart(2, '0')
        append({ id, name: `面板 ${key}` })

        // 如果是同步组件，往同步组件里设置
        const isSyncBlock = findBlockById(syncHostContainerId, syncBlocks)
        if (isSyncBlock) {
            setSyncBlocks(draft => {
                const block = findBlockById(syncHostContainerId, draft)
                if (!block) {
                    return
                }

                ;(block as ContainerBlockAbstract).children.push({ id, children: [] })
            })
        } else {
            setPageBlocks(draft => {
                const draftPageBlocks = draft[pageId]
                if (!draftPageBlocks) {
                    return
                }

                const block = findBlockById(containerId, draftPageBlocks)
                if (!block) {
                    return
                }

                ;(block as ContainerBlockAbstract).children.push({ id, children: [] })
                srv.updateBlock({ pageId, allBlocks: current(draftPageBlocks) })
            })
        }
    }

    const onDelete = (index: number) => {
        if (fields.length === 1) {
            Toast.warning('不可删除，至少需要1个面板')
            return
        }

        const deleteId = fields[index].id

        const isSyncBlock = findBlockById(syncHostContainerId, syncBlocks)
        if (isSyncBlock) {
            setSyncBlocks(draft => {
                const block = findBlockById<ContainerBlockAbstract>(containerId, draft)
                if (!block) {
                    return
                }

                const viewIndex = block.children.findIndex(item => item.id === deleteId)
                if (viewIndex === -1) {
                    return
                }

                block.children.splice(viewIndex, 1)
            })
        } else {
            setPageBlocks(draft => {
                const draftPageBlocks = draft[pageId]
                if (!draftPageBlocks) {
                    return
                }

                const block = findBlockById<ContainerBlockAbstract>(containerId, draftPageBlocks)
                if (!block) {
                    return
                }

                const viewIndex = block.children.findIndex(item => item.id === deleteId)
                if (viewIndex === -1) {
                    return
                }

                block.children.splice(viewIndex, 1)
                srv.updateBlock({ pageId, allBlocks: current(draftPageBlocks) })
            })
        }

        remove(index)

        if (deleteId === componentRuntimeState?.currentView) {
            const firstField = fields.find(item => item.id !== deleteId)
            handleViewChange(firstField?.id || '')
        }
    }

    const { run: createBlock } = useAtomAction(createBlockAtom)

    const onDuplicate = useAtomCallback(async (get, set, index: number) => {
        const newId = nanoid()
        insert(index + 1, { id: newId, name: `${fields[index].name}-副本` })

        let finalBlocks: BlockAbstract[] = []
        let newBlocks: BlockAbstract[] = []
        let addedBlocksIds: string[] = []

        setPageBlocks(draft => {
            const pageBlocks = draft[pageId]
            if (!pageBlocks) {
                return
            }

            const block = findBlockById<ContainerBlockAbstract>(containerId, pageBlocks)
            if (!block) {
                return
            }

            const beCopyPanel = block.children[index]
            newBlocks = beCopyPanel.children.map(copyBlock)

            addedBlocksIds = newBlocks.reduce<string[]>(
                (total, curr) => [...total, ...collectBlockAndChildren(curr).map(item => item.id)],
                []
            )
            set(blockCreatingListAtom, s => [...s, ...addedBlocksIds])

            const newPanel = { id: newId, children: newBlocks }

            block.children.splice(index, 0, newPanel)

            finalBlocks = current(pageBlocks)
        })

        await createBlock({ rootPageId, pageId, stackId, createdBlocks: newBlocks, blocks: finalBlocks, autoSelect: false })
        set(blockCreatingListAtom, s => s.filter(id => !addedBlocksIds.includes(id)))
    })

    const handleViewChange = useAtomCallback<void, string[]>((_, set, view) => {
        set(pageStackAtom, draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack && logicId) {
                stack.blockRuntimeState.container = {
                    ...stack.blockRuntimeState.container,
                    [logicId]: {
                        ...stack.blockRuntimeState.container?.[logicId],
                        currentView: view
                    }
                }
            }
        })
    })

    return (
        <Box py={8}>
            <div style={{ overflow: 'auto' }}>
                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(undefined)}
                >
                    <SortableContext items={fields.map(item => item.id)} strategy={verticalListSortingStrategy}>
                        <Radio.Group
                            name="test"
                            value={componentRuntimeState?.currentView}
                            onChange={handleViewChange}
                            style={{ flexDirection: 'column' }}
                        >
                            {fields.map((item, index) => (
                                <SortableItem
                                    key={item.id}
                                    disabled={fields.length <= 1}
                                    index={index}
                                    data={item}
                                    onDelete={async i => {
                                        const isConfirm = await Modal.confirm({
                                            title: '确认删除',
                                            content: '删除的面板内容不可恢复'
                                        })
                                        if (!isConfirm) {
                                            return
                                        }
                                        onDelete(i)
                                    }}
                                    onDuplicate={onDuplicate}
                                />
                            ))}
                        </Radio.Group>
                    </SortableContext>
                </DndContext>
            </div>
            <Button radius={100} icon={<IconFont type="Add" />} onClick={onCreate} style={{ marginTop: 12 }}>
                添加
            </Button>
        </Box>
    )
}

const ActionButton = styled(Button)`
    all: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 4px;
    color: var(--color-gray-400);
    &:disabled {
        color: var(--color-gray-300);
    }
    &:not(:disabled):hover {
        cursor: pointer;
        background-color: #26415a0f;
    }
`

interface SortableItemProps {
    index: number
    data: ContainerView
    onDelete: (index: number) => void
    onDuplicate: (index: number) => void
    disabled?: boolean
}
const SortableItem = ({ index, data, onDelete, onDuplicate, disabled }: SortableItemProps) => {
    const { setNodeRef, setActivatorNodeRef, listeners, attributes, transform, transition } = useSortable({
        id: data.id,
        attributes: { role: 'li' }
    })

    const { register } = useFormContext<ContainerBlockConfig>()

    const style: React.CSSProperties = {
        flex: 1,
        transform: CSS.Translate.toString(transform),
        transition
    }

    return (
        <Flex alignItems="center" gap="6px" ref={setNodeRef} style={style}>
            <ActionButton {...listeners} {...attributes} ref={setActivatorNodeRef}>
                <IconFont type="DotsSix" />
            </ActionButton>
            <Radio size="xs" value={data.id} />
            <Input {...register(`viewList.${index}.name`)} size="sm" styles={{ wrapper: { flex: 1 } }} />
            <ActionButton onClick={() => onDuplicate(index)}>
                <IconFont type="Duplicate" />
            </ActionButton>
            <ActionButton disabled={disabled} onClick={() => !disabled && onDelete(index)}>
                <IconFont type="Trash" />
            </ActionButton>
        </Flex>
    )
}
