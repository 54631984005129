import { useAtomCallback } from 'jotai/utils'
import { useRef } from 'react'
import { useUpdateEffect } from 'react-use'

import { containerDesignStateAtom, lastPageOfStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'

export const useSetGapHighlight = (disabled?: boolean) => {
    const isLeaveWhenDisabledRef = useRef(true)

    const onMouseEnterGap = useAtomCallback((get, set) => {
        if (disabled) {
            isLeaveWhenDisabledRef.current = false
            return
        }

        const pageStack = get(lastPageOfStackAtom)
        if (!pageStack) {
            return
        }

        const id = pageStack.state.asideType === AsideType.PAGE ? pageStack.pageId : pageStack.state.selectedNodes?.[0]
        if (!id) {
            return
        }

        set(containerDesignStateAtom, draft => {
            Reflect.set(draft, id, { ...draft[id], gap: true })
        })
    })
    const onMouseLeaveGap = useAtomCallback((get, set) => {
        if (disabled) {
            isLeaveWhenDisabledRef.current = true
            return
        }

        const pageStack = get(lastPageOfStackAtom)
        if (!pageStack) {
            return
        }

        const id = pageStack.state.asideType === AsideType.PAGE ? pageStack.pageId : pageStack.state.selectedNodes?.[0]
        if (!id) {
            return
        }

        set(containerDesignStateAtom, draft => {
            Reflect.set(draft, id, { ...draft[id], gap: false })
        })
    })

    useUpdateEffect(() => {
        if (disabled) {
            return
        }

        if (isLeaveWhenDisabledRef.current) {
            onMouseLeaveGap()
        } else {
            onMouseEnterGap()
        }
    }, [disabled])

    return {
        onMouseEnterGap,
        onMouseLeaveGap
    }
}
