import type { Option } from '@byecode/ui'
import { Divider, IconFont, Select, Tooltip } from '@byecode/ui'
import { FilterMode } from '@lighthouse/core'
import type { FlowNode, NodeTypes } from '@lighthouse/shared'
import {
    CollapseBox,
    FlowSelectSourceType,
    flowSelectSourceTypeMap,
    getUpstreamRealDsId,
    multiRecordNodeType,
    nodeTypeIconMap,
    nodeTypeOptions,
    SelectDataSource,
    singleRecordNodeType
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { promptSwitchDataSource } from '@/containers/FlowDetail/utils/promptSwitchDataSource'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { TriggerFilter } from '../../../TriggerFilter'
import { TriggerSorter } from '../../../TriggerSorter'
import * as SC from '../../styles'

const SCxDescription = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 12px;
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

interface FindRecordActionConfigureProps {
    allParentNodes: FlowNode[]
}

export const FindRecordActionConfigure: React.FC<FindRecordActionConfigureProps> = ({ allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { control } = useFormContext()

    const selectType = useWatch({ control, name: 'config.selectType' })

    const allSingleParentNodes = useMemo(
        () => allParentNodes.filter(item => singleRecordNodeType.has(item.data.nodeType)),
        [allParentNodes]
    )

    const nodeOptions = useMemo(
        () =>
            allParentNodes.reduce<Option[]>((nodes, item) => {
                if (multiRecordNodeType.has(item.data.nodeType)) {
                    nodes.push({
                        label: item.data.name,
                        value: item.id,
                        icon: nodeTypeIconMap[item.data.nodeType]
                    })
                }
                return nodes
            }, []),
        [allParentNodes]
    )

    const dataSourceId: string = useWatch({
        control,
        name: 'config.dataSourceId'
    })

    const nodeId: string = useWatch({
        control,
        name: 'config.nodeId'
    })

    // const multiRecordNode = useMemo(() => allParentNodes.filter(item => item.id === nodeId), [allParentNodes, nodeId])

    // const fieldMode = useMemo(() => (selectType === FlowSelectSourceType.DATASOURCE ? FilterMode.NORMAL : FilterMode.CUSTOM), [selectType])

    const realDataSourceId = useMemo(() => {
        if (selectType === FlowSelectSourceType.DATASOURCE) {
            return dataSourceId
        }
        return getUpstreamRealDsId(nodeId, allParentNodes)
    }, [allParentNodes, dataSourceId, nodeId, selectType])

    const isShowSetting = dataSourceId || nodeId

    return (
        <>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="nodeType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        disabled
                                        value={field.value}
                                        options={nodeTypeOptions}
                                        onChange={val => field.onChange?.(val as NodeTypes)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>查询的数据来自</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.selectType"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            disabledPortal
                                            value={field.value}
                                            // disabled={!!field.value}
                                            placeholder="请选择"
                                            onChange={val => {
                                                field.onChange(val)
                                            }}
                                            options={flowSelectSourceTypeMap}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {selectType === 'DATASOURCE' && (
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel required>选择数据表</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="config.dataSourceId"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <SelectDataSource
                                                // disabled={!!field.value}
                                                value={field.value}
                                                searchable
                                                withinPortal
                                                placeholder="请选择数据表"
                                                onChange={async dsId => {
                                                    const isConfirmed = field.value === undefined ? true : await promptSwitchDataSource()
                                                    if (isConfirmed) {
                                                        field.onChange(dsId)
                                                    }
                                                }}
                                                dataSourceList={dataSourceList}
                                            />
                                        )
                                    }}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                    )}
                    {selectType === 'UPSTREAM' && (
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel required>选择上游节点</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="config.nodeId"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                disabledPortal
                                                disabled={!!field.value}
                                                value={field.value}
                                                searchable
                                                placeholder="选择上游节点"
                                                onChange={val => {
                                                    field.onChange(val)
                                                }}
                                                dropdownProps={{
                                                    extra: (
                                                        <SCxDescription>
                                                            可选哪些节点类型？
                                                            <Tooltip title="查询多条记录、创建多条记录">
                                                                <IconFont size={16} type="Question" />
                                                            </Tooltip>
                                                        </SCxDescription>
                                                    )
                                                }}
                                                options={nodeOptions}
                                            />
                                        )
                                    }}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                    )}
                </SC.Content>
            </CollapseBox>
            {isShowSetting && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="筛选条件">
                        <TriggerFilter
                            prefix="config"
                            fieldMode={FilterMode.CUSTOM}
                            sourceFieldFlowNode={allSingleParentNodes}
                            dataSourceId={realDataSourceId}
                            paramsMode={FilterMode.CUSTOM}
                            description="设置筛选条件，获得满足条件的数据。如果未设置筛选条件，则获得所有来自对象的数据；最多获取 500 条数据"
                        />
                    </CollapseBox>
                </>
            )}
            {isShowSetting && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="排序规则">
                        <TriggerSorter
                            prefix="config"
                            dataSourceId={realDataSourceId}
                            description="当查找到多个记录时，将按照以下排序规则获得第一条记录。如果未设置规则，返回最近更新的一条记录"
                        />
                    </CollapseBox>
                </>
            )}
        </>
    )
}
