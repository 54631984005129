import { Breadcrumbs, Divider, IconFont } from '@byecode/ui'
import type { SubFormBlockConfig } from '@lighthouse/core'
import { type FieldInputConfigProtocol } from '@lighthouse/core'
import { FieldInputNameMap, getFieldOptions, getPrimaryDataSourceEnableFieldIds } from '@lighthouse/shared'
import React, { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'

import type { BlockSettingsSegmentedEnum } from '../../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../../constants'
import { BlockConfigureSegmentedControl } from '../BlockConfigureSegmentedControl'
import { Data } from './Data'
import { Style } from './Style'
import type { FieldInputSettingProps } from './types'

const SCxContainer = styled.div``
const SCxItem = styled.div``

export const FieldInputSetting: React.FunctionComponent<FieldInputSettingProps> = ({
    mode,
    dataSourceList,
    prefix = '',
    pointer,
    style,
    onBack
}) => {
    const { watch } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const inputType = watch(`${prefix}inputType`)
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit('field')
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const dataSource = useDataSource(appId, envId, pointer)

    const fieldOptions = useMemo(() => getFieldOptions(dataSource), [dataSource])

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList || [])
    }, [dataSource, dataSourceList])

    const filterFieldOptions = useMemo(
        () =>
            fieldOptions.filter(item => {
                return primaryDataSourceFieldIds?.has(item.value)
            }),
        [fieldOptions, primaryDataSourceFieldIds]
    )

    return (
        <SCxContainer style={style}>
            {mode === 'subForm' && (
                <>
                    <Breadcrumbs items={[{ icon: <IconFont type="ArrowLeft" />, label: FieldInputNameMap[inputType], onClick: onBack }]} />
                    <Divider />
                </>
            )}
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {/* 数据 */}
            <SCxItem key={BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN}>
                {mode !== 'subForm' && (
                    <>
                        <SizeSetting {...designProps.size} />
                        <Divider />
                    </>
                )}
                <Style prefix={prefix} pointer={pointer} mode={mode} />
            </SCxItem>

            {/* 设计 */}
            <SCxItem key={BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE}>
                <Data pointer={pointer} prefix={prefix} mode={mode} fieldOptions={filterFieldOptions} />
                <Divider />
                {mode !== 'subForm' && <VisibilitySetting />}
            </SCxItem>
        </SCxContainer>
    )
}
