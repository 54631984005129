import { BreadcrumbBlock } from '@lighthouse/block'
import { type BreadcrumbBlockAbstract } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'

import { openPageStackAtom } from '@/atoms/page/action'
import { pageStackBreadcrumbAtom } from '@/atoms/page/state'
import { usePreviewType } from '@/hooks/useApplication'
import { usePageList } from '@/hooks/usePage'

interface BreadcrumbBlockControllerProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: BreadcrumbBlockAbstract
}

const BreadcrumbBlockController: React.FC<BreadcrumbBlockControllerProps> = ({ blockData, ...rest }) => {
    const stackList = useAtomData(pageStackBreadcrumbAtom)
    const { run: openPageStack } = useAtomAction(openPageStackAtom)

    const pageList = usePageList()
    const previewType = usePreviewType()

    const options = useMemo(() => {
        return stackList.map((item, index) => {
            const page = find(page => page.id === item.pageId, pageList)
            return { label: page?.name ?? '', value: item.stackId, jumpIndex: index }
        })
    }, [stackList, pageList])

    const handleLink = useCallback(
        (id: string) => {
            const stack = find(item => item.stackId === id, stackList)
            if (stack) {
                openPageStack(stack)
            }
        },
        [stackList, openPageStack]
    )

    return <BreadcrumbBlock blockData={blockData} onLink={handleLink} options={options} previewType={previewType} {...rest} />
}

export default BreadcrumbBlockController
