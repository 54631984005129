import { IconFont, Input, Popover, Text } from '@byecode/ui'
import type { ContainerBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { FilledInput, FillPickerPopoverDropdown, ListItem4ByecodeUi } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { InheritLabel } from '@/components/InheritLabel'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { combinePath } from './utils'

interface TextBorderProps {
    prefixPath?: string
    onSetBreakKey?: (name: string) => void
}

export const TextBorder: React.FC<TextBorderProps> = ({ prefixPath, onSetBreakKey }) => {
    const { control, setValue } = useFormContext<ContainerBlockConfig>()

    const textBorderName = combinePath(prefixPath, 'breakPoint.design.textBorder')

    const colorSystemMethods = useColorSystemAction()
    const [textBorder] = useWatch({
        control,
        name: [textBorderName]
    })

    const hasBorderValue = useMemo(() => {
        if (!textBorder) {
            return false
        }

        if (textBorder.type === BACKGROUND_TYPE.color) {
            return !!textBorder.color
        }

        if (textBorder.type === BACKGROUND_TYPE.gradient) {
            return !!textBorder.gradient?.stops
        }
    }, [textBorder])

    const [draftBorderWidth, setDraftBorderWidth] = useState(textBorder?.all?.toString() || '')

    const handleConfirmChangeBorderWidth = useCallback(
        (value: string) => {
            const number = Number(value)
            const min = 0
            const max = 100
            if (Number.isNaN(number) || min > number || number > max) {
                setDraftBorderWidth(textBorder?.all?.toString() || '')
                return
            }

            setDraftBorderWidth(number.toString())
            setValue(`${textBorderName}.all`, number)
            onSetBreakKey?.(textBorderName)
        },
        [onSetBreakKey, setValue, textBorder?.all, textBorderName]
    )

    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <InheritLabel label="描边" name='breakPoint.design.textBorder' />
                <Controller
                    control={control}
                    name={textBorderName}
                    render={({ field }) => {
                        const value = field.value && {
                            type: field.value.type,
                            color: field.value.color,
                            gradient: field.value.gradient
                        }
                        return (
                            <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                                <Popover.Target>
                                    <FilledInput
                                        style={{ width: 180 }}
                                        value={value}
                                        onClear={() => {
                                            field.onChange({ ...field.value, type: BACKGROUND_TYPE.color, color: '' })
                                            onSetBreakKey?.(textBorderName)
                                        }}
                                    />
                                </Popover.Target>
                                {/* <PopoverDropdownWithClose title="描边">
                            <FillPicker
                                value={
                                    field.value
                                        ? {
                                              type: field.value.type,
                                              color: field.value.color,
                                              gradient: field.value.gradient
                                          }
                                        : undefined
                                }
                                onChange={v => {
                                    const initBorderWidth = 1
                                    field.onChange({ all: initBorderWidth, ...field.value, ...v })
                                    setDraftBorderWidth(initBorderWidth.toString())
                                }}
                            />
                        </PopoverDropdownWithClose> */}
                                <FillPickerPopoverDropdown
                                    title="描边"
                                    value={
                                        field.value
                                            ? {
                                                  type: field.value.type,
                                                  color: field.value.color,
                                                  gradient: field.value.gradient
                                              }
                                            : undefined
                                    }
                                    onChange={v => {
                                        const initBorderWidth = 1
                                        setDraftBorderWidth(initBorderWidth.toString())
                                        const val = { all: initBorderWidth, ...field.value, ...v }
                                        if(isDeepEqual(val, field.value)){
                                            return
                                        }
                                        field.onChange(val)
                                        onSetBreakKey?.(textBorderName)
                                    }}
                                    enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                    {...colorSystemMethods}
                                />
                            </Popover>
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            {hasBorderValue && (
                <ListItem4ByecodeUi justifyContent="flex-end" alignItems="center">
                    <Input
                        value={draftBorderWidth}
                        onChange={e => {
                            setDraftBorderWidth(e.target.value)
                        }}
                        onBlur={e => handleConfirmChangeBorderWidth(e.currentTarget.value)}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleConfirmChangeBorderWidth(e.currentTarget.value)
                            }
                        }}
                        prefix={<IconFont type="LineSize" />}
                        placeholder=""
                        styles={{ wrapper: { width: 180 } }}
                    />
                </ListItem4ByecodeUi>
            )}
        </>
    )
}
