import { Button, Loading, Modal, ModalConfirm, Toast } from '@byecode/ui'
import type { BaseFlowNode, DrawMode, FlowEdge, FlowNode, FlowNodeButtonClickPayload, FlowPageMode, Workflow } from '@lighthouse/shared'
import { FindUseLocationType, useAtomAction, useAtomData } from '@lighthouse/shared'
import { Pagination } from '@mantine/core'
import { lightFormat } from 'date-fns'
import equal from 'fast-deep-equal'
import { clone, find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useBlocker, useNavigate } from 'react-router-dom'
import { useBeforeUnload } from 'react-use'
import { ReactFlowProvider, useReactFlow } from 'reactflow'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { setFindUseAtom } from '@/atoms/application/action'
import { findUseAtom } from '@/atoms/application/state'
import { fetchWorkflowAtom } from '@/atoms/workflow/action'
import { workflowAtomFamily } from '@/atoms/workflow/state'
import { FlowProvider } from '@/contexts/FlowContext'
import * as srv from '@/services'
import type { RunStatus } from '@/services/types'
import { useFlowLogsWithPagination } from '@/shared/reusable'

import { StatusTable } from '../FlowList/components/StatusTable'
import { FlowEditor } from './components/FlowEditor'
import { PageHeader } from './components/PageHeader'
import { FlowConfigure } from './FlowConfigure'
import { FlowSetting } from './FlowSetting'
import { addNewConditionNodeWithEdges } from './utils/addNode'

interface FlowDetailState {
    opened: boolean
    mode: DrawMode
    node: FlowNode | null
    edge: FlowNodeButtonClickPayload | null
}

const SCxFlowDetailWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #fff;
`

const SCxFlowDetailContent = styled.div`
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

const SCxFlowSettingWrapper = styled.div`
    position: absolute;
    top: 55px;
    right: 0px;
    height: 100%;
    background-color: var(--color-white);
    z-index: 1;
`

const SCxFlowRunLogWrapper = styled.div`
    margin: 24px;
    height: calc(100% - 160px);
`

export interface FlowDetailProps {
    id: string
    showBack?: boolean
    showLogTab?: boolean
    showClose?: boolean
    closeAfterDelete?: boolean
    onClose?: () => void
}

export interface FlowDetailContentProps {
    id: string
    workflow: Workflow
    status?: RunStatus
    showClose?: boolean
    isChanged: boolean
    onUpdateWorkflow: (val: Partial<Workflow>) => void
}

export const FlowDetailContent: React.FC<FlowDetailContentProps> = ({ id, workflow, showClose, isChanged, onUpdateWorkflow }) => {
    const { setNodes, setEdges, getNodes, getEdges } = useReactFlow()
    const findUseData = useAtomData(findUseAtom)
    const { run: setFindUse } = useAtomAction(setFindUseAtom)
    const { type, content, enabled, errorMap } = workflow
    // const [isChanged, setIsChanged] = useState(false)

    const handleNodesOrEdgesChange = useCallback(
        (nodes: FlowNode[], edges: FlowEdge[], refresh = true) => {
            // await srv.updateFlow({ id, nodes, edges })
            // if (refresh) {
            //     mutate()
            //     return
            // }
            // 更新工作流状态
            workflow && onUpdateWorkflow({ content: { nodes, edges }, changed: true })
        },
        [onUpdateWorkflow, workflow]
    )

    const handleChangeNodeConfig = useCallback(
        (nodeId: string, data: BaseFlowNode['data']) => {
            const nodes = getNodes() as FlowNode[]
            const edges = getEdges() as FlowEdge[]
            if (!nodes || !edges) {
                return
            }
            if (workflow && content) {
                const newNodes = nodes.map(item => {
                    if (item.id === nodeId) {
                        return {
                            ...item,
                            data
                        }
                    }
                    return item
                })
                setNodes(newNodes)
                // await srv.updateFlow({ id, nodes: newNodes, edges })
                // 当工作流启动后,第一次修改节点配置,需要重新拉取工作流状态
                /* workflow.enabled && !workflow.changed &&  */
                onUpdateWorkflow({ content: { ...content, nodes: newNodes }, changed: true })
            }
        },
        [content, getEdges, getNodes, onUpdateWorkflow, setNodes, workflow]
    )

    const [state, setState] = useImmer<FlowDetailState>({
        opened: true,
        mode: 'nodeCreator',
        node: null,
        edge: null
    })
    const { opened, mode, node: currentNode, edge: currentEdge } = state

    const isSettingShown = currentNode || currentEdge

    const handleClose = useCallback(() => {
        setState(draft => {
            draft.opened = false
        })
    }, [setState])

    const handleNodeClick = useCallback(
        (node: FlowNode) => {
            setState(draft => {
                draft.mode = 'nodeConfigurator'
                draft.opened = true
                draft.node = node
            })
        },
        [setState]
    )

    useEffect(() => {
        if (!findUseData) {
            return
        }
        const { location } = findUseData
        if (location.type === FindUseLocationType.WORKFLOW && location.workflowId === workflow.id) {
            const node = find(item => item.id === location.nodeId, content?.nodes || [])
            if (node) {
                handleNodeClick(node)
            }
            setFindUse(undefined)
        }
    }, [content?.nodes, findUseData, handleNodeClick, setFindUse, workflow.id])

    // Block navigating elsewhere when data has been entered into the input
    useBeforeUnload(!showClose && isChanged, '你修改了工作流设计但没有保存，是否需要保存工作流设计并继续？')

    // const handleDropDraft = useCallback(async () => {
    //     try {
    //         const res = await srv.dropFlowDraft(id)
    //         if (res.success) {
    //             Toast.success('已放弃更改')
    //             mutate()
    //             return
    //         }
    //     } catch {
    //         Toast.error('操作失败，请稍后重试')
    //     }
    // }, [id, mutate])

    // const handleSaveDraftAsRelease = useCallback(async () => {
    //     try {
    //         const res = await srv.saveFlowDraftAsRelease(id)
    //         mutate()
    //         if (res.success) {
    //             Toast.success('更新成功')
    //             return
    //         }
    //     } catch {
    //         Toast.error('更新失败，请稍后重试')
    //     }
    // }, [id, mutate])
    // const handleUpdateAndRelease = useCallback(async () => {
    //     if (enabled) {
    //         try {
    //             const updated = await srv.updateAndEnableWorkflow({ id, ...content })
    //             if (updated) {
    //                 Toast.success('更新成功')
    //                 onSetIsChanged(false)
    //                 return true
    //             }
    //         } catch {
    //             Toast.error('更新失败，请稍后重试')
    //         }
    //         return false
    //     }

    //     const updated = await srv.updateFlow({ id, ...content })
    //     if (updated) {
    //         Toast.success('更新成功')
    //         onSetIsChanged(false)
    //     }
    //     return updated
    // }, [content, enabled, id, onSetIsChanged])

    const handleSelectChange = useCallback(
        (selected: boolean) => {
            if (!selected) {
                setState(draft => {
                    draft.node = null
                    draft.edge = null
                })
            }
        },
        [setState]
    )

    const handleEdgeButtonClick = useCallback(
        (params: FlowNodeButtonClickPayload) => {
            const {
                edge: { source, target },
                operator
            } = params
            // 如果当前操作为添加条件，则直接添加，不需要弹出右侧节点配置面板
            if (operator === 'addCondition') {
                const nodes = getNodes() as FlowNode[]
                const edges = getEdges() as FlowEdge[]

                const { nodes: newNodes, edges: newEdges } = addNewConditionNodeWithEdges(nodes, edges, source, target)

                handleNodesOrEdgesChange(newNodes, newEdges)

                setNodes(newNodes)
                setEdges(newEdges)
                return
            }

            setState(draft => {
                draft.mode = 'nodeCreator'
                draft.opened = true
                draft.edge = clone(params)
            })
        },
        [getEdges, getNodes, handleNodesOrEdgesChange, setEdges, setNodes, setState]
    )

    return workflow ? (
        <FlowProvider
            id={workflow.id}
            type={type}
            errorMap={errorMap}
            onChange={handleNodesOrEdgesChange}
            onEdgeButtonClick={handleEdgeButtonClick}
        >
            <FlowEditor
                type={type}
                nodes={content?.nodes || []}
                edges={content?.edges || []}
                onChange={handleNodesOrEdgesChange}
                onSelectChange={handleSelectChange}
                onNodeClick={handleNodeClick}
            />
            {isSettingShown && (
                <SCxFlowSettingWrapper>
                    <FlowSetting
                        type={type}
                        opened={opened}
                        mode={mode}
                        edges={content?.edges || []}
                        node={currentNode}
                        edge={currentEdge}
                        onClose={handleClose}
                        onChangeNodeConfig={handleChangeNodeConfig}
                        onNodesOrEdgesChange={(nodes, edges, node) => {
                            const newNotSelectedEdges = edges.map(e => ({ ...e, selected: false }))
                            handleNodesOrEdgesChange(nodes, newNotSelectedEdges, false)
                            if (node) {
                                setState(draft => {
                                    draft.mode = 'nodeConfigurator'
                                    draft.opened = true
                                    draft.node = node
                                    draft.edge = null
                                })
                            }
                        }}
                    />
                </SCxFlowSettingWrapper>
            )}
        </FlowProvider>
    ) : (
        <Loading outlined />
    )
}

interface FlowRunLogProps {
    id: string
    workflow: Workflow
    status?: RunStatus
    showClose?: boolean
}

export const FlowRunLog: React.FC<FlowRunLogProps> = ({ id, workflow, status }) => {
    const { workflows, totalPage, setCurrentPage } = useFlowLogsWithPagination({ workflowId: id, state: status })

    return workflow ? (
        <SCxFlowDetailContent>
            <SCxFlowRunLogWrapper>
                <StatusTable showNameField data={workflows} />
                <Pagination style={{ marginTop: 16 }} size="md" total={totalPage} onChange={page => setCurrentPage(page)} />
            </SCxFlowRunLogWrapper>
        </SCxFlowDetailContent>
    ) : null
}

export const FlowDetailContainer: React.FC<{
    id: string
    showBack: boolean
    showLogTab: boolean
    showClose: boolean
    closeAfterDelete?: boolean
    onClose?: () => void
}> = ({ id, showBack, showClose, showLogTab, closeAfterDelete, onClose }) => {
    const navigate = useNavigate()
    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) => !showClose && isChanged && currentLocation.pathname !== nextLocation.pathname
    )
    const [isSaveAndLeaveLoading, setIsSaveAndLeaveLoading] = useState(false)
    const [tab, setTab] = useState<FlowPageMode>('flow')
    const [status, setStatus] = useState<RunStatus>('all')
    const [isChanged, setIsChanged] = useState(false)
    const { run: fetchWorkflow, loading: isLoading } = useAtomAction(fetchWorkflowAtom)
    const { run: updateWorkflow } = useAtomAction(workflowAtomFamily(id))
    const [workflow, setWorkFlow] = useImmer<Workflow | undefined>(undefined)

    const handleNameChange = useCallback(
        async (name: string) => {
            const oldName = workflow?.name
            updateWorkflow(draft => {
                if (!draft) {
                    return
                }
                draft.name = name
            })
            setWorkFlow(draft => {
                if (!draft) {
                    return
                }
                draft.name = name
            })
            const isUpdate = await srv.updateFlow({ id, name })
            if (!isUpdate && oldName) {
                updateWorkflow(draft => {
                    if (!draft) {
                        return
                    }
                    draft.name = oldName
                })
                setWorkFlow(draft => {
                    if (!draft) {
                        return
                    }
                    draft.name = oldName
                })
            }
        },
        [id, setWorkFlow, updateWorkflow, workflow?.name]
    )

    const handleFetchWorkflow = useCallback(async () => {
        const data = await fetchWorkflow(id)
        setWorkFlow(data)
    }, [fetchWorkflow, id, setWorkFlow])

    useEffect(() => {
        handleFetchWorkflow()
    }, [handleFetchWorkflow])


    const handleBack = useCallback(() => {
        navigate({ pathname: '../flow' })
    }, [navigate])

    const handleFlowEnabledChange = useCallback(
        async (enabled: boolean) => {
            if (!workflow) {
                return
            }
            if (enabled) {
                try {
                    const { content, ...restWorkflow } = workflow
                    const res = await srv.updateAndEnableWorkflow({ ...restWorkflow, ...content })

                    if (res) {
                        Toast.success('启用成功')
                        setIsChanged(false)
                        // fetchWorkflow(id)
                        setWorkFlow(draft => {
                            if (!draft) {
                                return
                            }
                            draft.enabled = enabled
                        })
                        updateWorkflow(draft => {
                            if (!draft) {
                                return
                            }
                            draft.enabled = enabled
                        })
                    }
                } catch {
                    Toast.error('无法启用工作流，请检查配置后重试')
                }
                return
            }
            try {
                const res = await srv.enableFlow(id, enabled)

                if (res.success) {
                    Toast.success(`${enabled ? '启用' : '禁用'}成功`)
                    setIsChanged(false)
                    setWorkFlow(draft => {
                        if (!draft) {
                            return
                        }
                        draft.enabled = enabled
                    })
                    updateWorkflow(draft => {
                        if (!draft) {
                            return
                        }
                        draft.enabled = enabled
                    })
                }
            } catch {
                Toast.error(enabled ? '无法启用工作流，请检查配置后重试' : '禁用失败，请稍后重试')
            }
        },
        [id, setWorkFlow, updateWorkflow, workflow]
    )

    const handleUpdateWorkflow = useCallback(
        (val: Partial<Workflow>) => {
            if (!workflow) {
                return
            }
            const newWorkFlow = {
                ...workflow,
                ...val
            }
            setIsChanged(true)
            setWorkFlow(newWorkFlow)
        },
        [setWorkFlow, workflow]
    )

    const handleUpdateAndRelease = useCallback(async () => {
        if (!workflow) {
            return
        }
        if (workflow.enabled) {
            try {
                const updated = await srv.updateAndEnableWorkflow({ id, runType: workflow.runType, ...workflow.content })
                if (updated) {
                    updateWorkflow({ id, runType: workflow.runType, ...workflow.content })
                    Toast.success('更新成功')
                    setIsChanged(false)
                    return true
                }
            } catch {
                Toast.error('更新失败，请稍后重试')
            }
            return false
        }

        const updated = await srv.updateFlow({ id, runType: workflow.runType, ...workflow.content })
        if (updated) {
            updateWorkflow({ id, runType: workflow.runType, ...workflow.content })
            Toast.success('更新成功')
            setIsChanged(false)
        }
        return updated
    }, [id, updateWorkflow, workflow])

    const handleDelete = useCallback(async () => {
        const isDeleted = await Modal.confirm({
            title: '删除工作流',
            content: `确认删除“${workflow?.name ?? '未命名'}”工作流？删除后不可恢复，请谨慎操作`
        })

        if (!isDeleted) {
            return
        }

        await srv.deleteFlow(id)

        Toast.success('删除成功')

        if (closeAfterDelete) {
            onClose?.()
            return
        }
        navigate({ pathname: '../flow' })
    }, [closeAfterDelete, id, navigate, onClose, workflow?.name])

    const handleSaveAndLeave = useCallback(async () => {
        setIsSaveAndLeaveLoading(true)
        const isSuccess = await handleUpdateAndRelease()
        setIsSaveAndLeaveLoading(false)
        isSuccess ? blocker?.proceed?.() : blocker?.reset?.()
    }, [blocker, handleUpdateAndRelease])

    // useEffect(() => {
    //     return () => {
    //         mutate(undefined, { revalidate: false })
    //     }
    // }, [mutate])

    const descriptions = useMemo(() => {
        if (!workflow) {
            return []
        }
        const { updatedTime, updatedBy, createdTime, createdBy } = workflow
        return [
            { id: '1', label: '最后编辑', value: lightFormat(updatedTime, 'yyyy/MM/dd HH:mm') },
            { id: '2', label: '编辑人', value: updatedBy },
            { id: '3', label: '创建时间', value: lightFormat(createdTime, 'yyyy/MM/dd HH:mm') },
            { id: '4', label: '创建人', value: createdBy }
        ]
    }, [workflow])

    const content = useMemo(() => {
        if (!workflow) {
            return
        }

        switch (tab) {
            case 'runLog': {
                return <FlowRunLog id={id} showClose={showClose} workflow={workflow} status={status} />
            }
            case 'configure': {
                return <FlowConfigure workflow={workflow} onSetIsChanged={setIsChanged} onUpdateWorkflow={handleUpdateWorkflow} />
            }
            default: {
                return (
                    <FlowDetailContent
                        id={id}
                        showClose={showClose}
                        workflow={workflow}
                        onUpdateWorkflow={handleUpdateWorkflow}
                        isChanged={isChanged}
                        // onSetIsChanged={setIsChanged}
                    />
                )
            }
        }
    }, [handleUpdateWorkflow, id, isChanged, showClose, status, tab, workflow])

    if (!workflow || isLoading) {
        return <Loading />
    }

    const { type, enabled } = workflow

    return (
        <SCxFlowDetailContent>
            <PageHeader
                type={type}
                showBack={showBack}
                showClose={showClose}
                showLogTab={showLogTab}
                name={workflow.name}
                tab={tab}
                descriptions={descriptions}
                flowEnabled={enabled}
                flowUpdated={isChanged}
                onNameChange={handleNameChange}
                // onNameUpdate={handleNameUpdate}
                onBack={handleBack}
                onClose={onClose}
                onDelete={() => {
                    setIsChanged(false)
                    handleDelete?.()
                }}
                onFlowEnableChange={handleFlowEnabledChange}
                onUpdateAndRelease={handleUpdateAndRelease}
                onTabChange={setTab}
                onLogStatusFilterChange={setStatus}
            />
            {content}
            {blocker.state === 'blocked' && tab !== 'runLog' && (
                <ModalConfirm
                    title="工作流设计有修改，是否保存？"
                    content="你修改了工作流设计但没有保存，是否需要保存工作流设计并继续？"
                    footerExtra={
                        <Button type="text" size="sm" onClick={blocker.reset}>
                            返回设计
                        </Button>
                    }
                    onReject={blocker.proceed}
                    onResolve={handleSaveAndLeave}
                    okStatus="primary"
                    okText="保存并继续"
                    okProps={{ loading: isSaveAndLeaveLoading }}
                    cancelText="不保存"
                />
            )}
        </SCxFlowDetailContent>
    )
}

export const FlowDetail: React.FC<FlowDetailProps> = ({
    id,
    showBack = true,
    showClose = false,
    showLogTab = true,
    closeAfterDelete = true,
    onClose
}) => {
    return (
        <ReactFlowProvider>
            <SCxFlowDetailWrapper>
                <FlowDetailContainer
                    id={id}
                    showBack={showBack}
                    showLogTab={showLogTab}
                    showClose={showClose}
                    closeAfterDelete={closeAfterDelete}
                    onClose={onClose}
                />
            </SCxFlowDetailWrapper>
        </ReactFlowProvider>
    )
}
