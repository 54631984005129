import type { CollisionArea } from '../types'
import type { LayoutInteractionInstance, LayoutInteractionPlugin } from './LayoutInteractionEngine'
import {
    DOM_DATA_LEVELS_NAME,
    DOM_DATA_NODE_NAME,
    DOM_DATA_PARENT_NAME,
    DOM_DATA_REAL_PARENT_ID,
    DOM_DATA_VIRTUAL,
    findNodeById,
    findTriggerDom,
    getIndicatorRect
} from './utils'

const DRAG_MIN_DISTANCE = 5
const INDICATOR_SIZE = 6

interface DragEngineConstructorParams {
    onDragEnd: (activeId: string | null, over: CollisionArea | null) => void

    collisionAreaDetection: (activeId: string | null, over: CollisionArea | undefined) => CollisionArea | null
}
export class DragPlugin implements LayoutInteractionPlugin {
    name = 'DRAG_PLUGIN'

    #onDragEnd: DragEngineConstructorParams['onDragEnd']

    #collisionAreaDetection: DragEngineConstructorParams['collisionAreaDetection']

    /** 内存中的碰撞区域 */
    #collisionArea: CollisionArea[] = []

    #overCollisionArea: CollisionArea | null = null

    /** 是否在拖拽节点 */
    #isDragging = false

    #activeId: string | null = null

    /** 被抓起的dom */
    #dragNode: HTMLElement | null = null

    /** 克隆的抓起的dom */
    #dragOverlay: HTMLElement | null = null

    /** 指示器 */
    #indicator: HTMLElement | null = null

    #coreInstance: LayoutInteractionInstance | null = null

    constructor(props: DragEngineConstructorParams) {
        this.#onDragEnd = props.onDragEnd
        this.#collisionAreaDetection = props.collisionAreaDetection
    }

    init(instance: LayoutInteractionInstance) {
        this.#coreInstance = instance

        document.addEventListener('keydown', this.#escKeydownHandler)
    }

    destroy() {
        document.removeEventListener('keydown', this.#escKeydownHandler)
    }

    #escKeydownHandler = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            this.cancelDrag()
        }
    }

    handleMousedown = () => {
        const triggerDom = findTriggerDom(this.#coreInstance?.tappedDom, this.#coreInstance?.root)
        this.#activeId = triggerDom ? triggerDom.getAttribute(DOM_DATA_NODE_NAME) : null
    }

    handleMouseMove: LayoutInteractionPlugin['handleMouseMove'] = e => {
        if (!this.#coreInstance || this.#coreInstance.root === this.#coreInstance.tappedDom) {
            return
        }

        if (this.#isDragging) {
            this.#dragMoveHandler(e)
        }

        if (this.#activeId && this.#coreInstance.selectedIds.includes(this.#activeId)) {
            return
        }

        const isDragAction =
            Math.abs(this.#coreInstance.delta.x) >= DRAG_MIN_DISTANCE || Math.abs(this.#coreInstance.delta.y) >= DRAG_MIN_DISTANCE
        if (isDragAction && !this.#isDragging) {
            this.#dragStartHandler(e)
        }
    }

    handleMouseUp = () => {
        if (this.#isDragging) {
            this.#dragEndHandler()
        }

        this.#activeId = null
    }

    #dragStartHandler = (e: MouseEvent, dragNode?: HTMLElement) => {
        e.stopPropagation()

        document.documentElement.style.setProperty('cursor', 'grabbing')
        document.documentElement.style.setProperty('pointer-events', 'none', 'important')

        const target = e.target as HTMLElement

        this.#isDragging = true

        this.#dragNode = dragNode || findTriggerDom(target, this.#coreInstance?.root) || target

        this.#coreInstance?.collectNodes()

        this.#generateCollisionArea()
    }

    #dragMoveHandler = (e: MouseEvent) => {
        if (!this.#isDragging || !this.#coreInstance) {
            return
        }

        window.getSelection()?.removeAllRanges()
        this.#updateDragOverlay()

        const overCollisionArea = this.#collisionArea.filter(item => {
            return e.clientX >= item.x && e.clientX <= item.x + item.width && e.clientY >= item.y && e.clientY <= item.y + item.height
        })

        overCollisionArea.sort((a, b) => {
            if (a.position === 'in' && a.level === b.level) {
                return -1
            }
            if (b.position === 'in' && a.level === b.level) {
                return 1
            }

            if (a.level === b.level) {
                return a.width * a.height - b.width * b.height
            }

            return b.level - a.level
        })

        const highestWeightArea = this.#collisionAreaDetection(this.#activeId, overCollisionArea[0])

        this.#overCollisionArea = highestWeightArea
        this.#updateIndicator()
    }

    #dragEndHandler = () => {
        if (!this.#isDragging) {
            return
        }

        this.#onDragEnd(this.#activeId, this.#overCollisionArea)

        this.cancelDrag()
    }

    #generateCollisionArea() {
        if (!this.#coreInstance) {
            return
        }
        const rootRect = this.#coreInstance.root.getBoundingClientRect()
        const nodes = this.#coreInstance.root.querySelectorAll<HTMLElement>(`[${DOM_DATA_NODE_NAME}]`)

        // 创建碰撞检测区域
        this.#collisionArea.push({
            level: 0,
            id: 'root',
            parentId: 'root',
            position: 'in',
            width: rootRect.width,
            height: rootRect.height,
            x: rootRect.x,
            y: rootRect.y
        })
        const queue = [...nodes]
        while (queue.length > 0) {
            const elm = queue.shift() as HTMLElement

            const id = elm.getAttribute(DOM_DATA_NODE_NAME)
            const levelStr = elm.getAttribute(DOM_DATA_LEVELS_NAME)
            const parentId = elm.getAttribute(DOM_DATA_PARENT_NAME)
            // const isDisabled = elm.getAttribute(DOM_DATA_DISABLED)
            const isVirtual = elm.getAttribute(DOM_DATA_VIRTUAL)
            const realParentId = elm.getAttribute(DOM_DATA_REAL_PARENT_ID) || undefined
            if (!id || !levelStr || !parentId) {
                continue
            }

            const level = Number(levelStr)

            const nodeRect = this.#coreInstance.nodesMap.get(id)
            const parentRect = this.#coreInstance.nodesMap.get(parentId)
            if (!nodeRect || !parentRect) {
                continue
            }

            const parentNode = findNodeById(realParentId || parentId)(this.#coreInstance.data)
            if (!parentNode) {
                continue
            }

            const { children = [], data } = parentNode

            // 自定义视图下的虚拟容器
            // if (isDisabled) {
            //     continue
            // }
            if (isVirtual) {
                this.#collisionArea.push({
                    isVirtual: true,
                    level,
                    id,
                    parentId,
                    position: 'in',
                    width: nodeRect.width,
                    height: nodeRect.height,
                    x: nodeRect.x,
                    y: nodeRect.y
                })
                continue
            }

            const nodeIndex = children.findIndex(item => item.id === id)
            const node = children[nodeIndex]
            const isContainerNode = node.type === 'container'
            const isFirstNode = nodeIndex === 0
            const isLastNode = nodeIndex === children.length - 1

            const { layout } = data ?? {}

            const parentIsVertical = layout?.align?.direction === 'vertical'

            if (isContainerNode && (!node.children || node.children.length === 0)) {
                this.#collisionArea.push({
                    level,
                    id,
                    parentId,
                    realParentId,
                    position: 'in',
                    width: nodeRect.width,
                    height: nodeRect.height,
                    x: nodeRect.x,
                    y: nodeRect.y
                })
            }

            if (isFirstNode) {
                this.#collisionArea.push({
                    level,
                    id,
                    parentId,
                    realParentId,
                    position: 'before',
                    width: parentIsVertical ? parentRect.width : nodeRect.x - parentRect.x + nodeRect.width / 2,
                    height: parentIsVertical ? nodeRect.y - parentRect.y + nodeRect.height / 2 : parentRect.height,
                    x: parentRect.x,
                    y: parentRect.y
                })
            }

            if (isLastNode) {
                this.#collisionArea.push({
                    level,
                    id,
                    parentId,
                    realParentId,
                    position: 'after',
                    width: parentIsVertical ? parentRect.width : parentRect.right - nodeRect.right + nodeRect.width / 2,
                    height: parentIsVertical ? parentRect.bottom - nodeRect.bottom + nodeRect.height / 2 : parentRect.height,
                    x: parentIsVertical ? parentRect.x : nodeRect.x + nodeRect.width / 2,
                    y: parentIsVertical ? nodeRect.top + nodeRect.height / 2 : parentRect.y
                })

                continue
            }

            const nextNode = this.#coreInstance.nodesMap.get(children[nodeIndex + 1].id)
            if (nextNode) {
                this.#collisionArea.push({
                    level,
                    id,
                    parentId,
                    realParentId,
                    position: 'after',
                    width: parentIsVertical ? parentRect.width : nodeRect.width / 2 + (nextNode.left - nodeRect.right) + nextNode.width / 2,
                    height: parentIsVertical
                        ? nodeRect.height / 2 + (nextNode.top - nodeRect.bottom) + nextNode.height / 2
                        : parentRect.height,
                    x: parentIsVertical ? parentRect.x : nodeRect.x + nodeRect.width / 2,
                    y: parentIsVertical ? nodeRect.top + nodeRect.height / 2 : parentRect.y
                })
            }
        }
    }

    #updateDragOverlay() {
        if (!this.#coreInstance) {
            return
        }
        if (!this.#dragNode) {
            return
        }

        if (!this.#dragOverlay) {
            const el = this.#dragNode.cloneNode(true) as HTMLElement
            const rect = this.#dragNode.getBoundingClientRect()
            el.style.pointerEvents = 'none'
            el.style.boxSizing = 'border-box'
            el.style.opacity = '0.6'
            el.style.position = 'absolute'
            el.style.left = `${rect.left}px`
            el.style.top = `${rect.top}px`
            el.style.width = `${rect.width}px`
            el.style.height = `${rect.height}px`
            el.style.willChange = 'transform'
            el.style.boxShadow = 'var(--box-shadow)'
            this.#dragOverlay = el
            document.body.append(el)
        }

        this.#dragOverlay.style.transform = `translate3d(${this.#coreInstance.delta.x}px, ${this.#coreInstance.delta.y}px, 0)`
    }

    #updateIndicator() {
        if (!this.#coreInstance) {
            return
        }

        if (!this.#overCollisionArea) {
            this.#clearIndicator()
            return
        }

        if (!this.#indicator) {
            const el = document.createElement('div')
            el.style.position = 'absolute'
            el.style.left = '0'
            el.style.top = '0'
            el.style.willChange = 'transform'
            el.style.backgroundColor = 'var(--color-main)'
            el.style.pointerEvents = 'none'
            el.style.zIndex = '1'
            this.#indicator = el

            this.#coreInstance.root.append(el)
        }

        const nodeRect = this.#coreInstance.nodesMap.get(this.#overCollisionArea.id)
        if (!nodeRect) {
            return
        }

        const parentRect = this.#coreInstance.nodesMap.get(this.#overCollisionArea.parentId)
        if (!parentRect) {
            return
        }

        const node = findNodeById(this.#overCollisionArea.isVirtual ? this.#overCollisionArea.parentId : this.#overCollisionArea.id)(
            this.#coreInstance.data
        )
        if (!node) {
            return
        }
        const parentNode = findNodeById(this.#overCollisionArea.realParentId || this.#overCollisionArea.parentId)(this.#coreInstance.data)
        if (!parentNode) {
            return
        }

        const { children = [], data: parentData } = parentNode
        const nodeIndex = children.findIndex(item => item.id === this.#overCollisionArea?.id)
        const nextNode = children[nodeIndex + 1]
        const nextNodeRect = nextNode && this.#coreInstance.nodesMap.get(nextNode.id)

        const rect = getIndicatorRect({
            collisionArea: this.#overCollisionArea,
            layout: node.data?.layout,
            parentLayout: parentData?.layout,
            rootRect: this.#coreInstance.root.getBoundingClientRect(),
            nodeRect,
            nextNodeRect,
            parentRect,
            indicatorSize: INDICATOR_SIZE
        })
        if (rect) {
            this.#indicator.style.transform = `translate3d(${rect.left / this.#coreInstance.scale}px, ${
                rect.top / this.#coreInstance.scale
            }px, 0)`
            this.#indicator.style.width = `${rect.width / this.#coreInstance.scale}px`
            this.#indicator.style.height = `${rect.height / this.#coreInstance.scale}px`
        }
    }

    #clearCollisionArea() {
        this.#collisionArea = []
        this.#overCollisionArea = null
    }

    #clearDragOverlay() {
        document.documentElement.style.removeProperty('cursor')
        document.documentElement.style.removeProperty('pointer-events')

        this.#dragOverlay?.remove()
        this.#dragOverlay = null
    }

    #clearIndicator() {
        this.#indicator?.remove()
        this.#indicator = null
    }

    startDrag(e: MouseEvent, activeId?: string, dragNode?: HTMLElement) {
        if (!this.#coreInstance) {
            return
        }

        this.#coreInstance.isActive = true
        if (dragNode) {
            this.#coreInstance.tappedDom = dragNode
        }

        this.#coreInstance.initialCoordinate = {
            x: e.clientX,
            y: e.clientY
        }

        if (activeId) {
            this.#activeId = activeId
        }

        this.#dragStartHandler(e, dragNode)
    }

    cancelDrag() {
        if (this.#coreInstance) {
            this.#coreInstance.isActive = false
            this.#coreInstance.initialCoordinate = { x: 0, y: 0 }
            this.#coreInstance.delta = { x: 0, y: 0 }
        }
        this.#activeId = null
        this.#dragNode = null
        this.#isDragging = false

        this.#clearCollisionArea()
        this.#clearIndicator()
        this.#clearDragOverlay()
    }
}
