import { Button, Flex, IconFont, Loading, SegmentedControl, Text } from '@byecode/ui'
import { ApplicationStruct, ApplicationType } from '@lighthouse/core'
import { ApplicationContainer, AppThemeContextProvider, DEFAULT_LANG } from '@lighthouse/shared'
import { BreakPointSize, useBreakpoint } from '@lighthouse/tools'
import { find } from 'rambda'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import * as srv from '@/services'

import { CopyPrivateTemplate } from './CopyPrivateTemplate'

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: var(--color-gray-100);
    display: flex;
    flex-direction: column;
`

const SCxHeader = styled.div`
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    height: 60px;
    background-color: var(--color-gray-50);
`

const SCxLogoWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    overflow: hidden;
    flex: 1;
`

const SCxLogo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
`

const SCxTitleWrapper = styled.div`
    flex: 1;
    overflow: hidden;
`

const SCxHeaderExtra = styled(Flex)`
    display: flex;
    align-items: center;
    gap: 8px;
`

const SCxContent = styled.div`
    /* width: 100%; */
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 84px);
`

const SCxIframe = styled.iframe`
    width: 100%;
    flex: 1;
    background-color: var(--color-white);
`

const tabs = [
    { value: 'mobile', icon: <IconFont type="DeviceMobile" size={20} /> },
    { value: 'desktop', icon: <IconFont type="Desktop" size={20} /> }
]

export const PrivateTemplate: React.FC = () => {
    const navigate = useNavigate()
    const { applicationId: pathAppId = '' } = useParams()
    const local = useLocation()
    const { ref, breakPoint } = useBreakpoint()

    const isMobile = useMemo(() => breakPoint === BreakPointSize.sm || breakPoint === BreakPointSize.xs, [breakPoint])

    const [open, setOpen] = useState(false)
    const { value: data, loading } = useAsyncRetry(() => srv.getAppOfOutside(pathAppId), [])

    useLayoutEffect(() => {
        const helpElement = document.querySelector('#byecode-help') as HTMLDivElement
        if (helpElement) {
            helpElement.style.display = 'none'

            return () => {
                helpElement.style.display = 'inline-flex'
            }
        }
    }, [])

    // const isLogin = useMemo(() => !!user, [user])

    const themeColor = useMemo(() => {
        if (!data || !data?.config.theme.primaryPointer) {
            return
        }
        return find(item => item.id === data.config.theme.primaryPointer, data.config.theme.palettes || [])
    }, [data])

    const appPrivateTemplate = data?.type === ApplicationType.website ? data?.config.privateTemplate : undefined

    const device = useMemo(() => {
        if(!appPrivateTemplate){
            return
        }
        if (!appPrivateTemplate?.device || appPrivateTemplate?.device === 'all') {
            return appPrivateTemplate?.initDevice || 'desktop'
        }
        return appPrivateTemplate?.device || 'desktop'
    }, [appPrivateTemplate])
    const [tab, setTab] = useState<string | undefined>()

    useEffect(() => {
        setTab(device)
    }, [device])

    const templateUrl = useMemo(() => {
        if (!data || data.type === ApplicationType.wxApplet) {
            navigate('/template/404')
            return
        }
        return `https://${data.config.domain.domainPrefix}-template.${data.mainDomain}`
    }, [data, navigate])

    const handleChangeLanguage = useCallback(() => {
        return undefined
    }, [])

    const frameContent = useMemo(() => {
        return <SCxIframe src={templateUrl} sandbox="allow-forms allow-scripts allow-same-origin allow-popups" title={data?.name} />
    }, [data?.name, templateUrl])

    const handleOpen = useCallback(async () => {
        const isLogged = await srv.hasLoggedIn()
        if (!isLogged) {
            navigate({ pathname: `/account/login`, search: `redirect=${local.pathname}` })
            return
        }
        setOpen(true)
    }, [local.pathname, navigate])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const content = useMemo(() => {
        if (tab === 'mobile') {
            return (
                <AppThemeContextProvider theme={data?.config?.theme}>
                    <ApplicationContainer
                        previewMode="simulationMobile"
                        application={ApplicationStruct()}
                        language={DEFAULT_LANG}
                        disabledNavBar
                        onChangeLanguage={handleChangeLanguage}
                    >
                        {frameContent}
                    </ApplicationContainer>
                </AppThemeContextProvider>
            )
        }
        return frameContent
    }, [data?.config?.theme, frameContent, handleChangeLanguage, tab])

    const body = useMemo(() => {
        if (loading) {
            return <Loading />
        }
        return (
            <>
                <SCxHeader>
                    <SCxLogoWrapper>
                        <SCxLogo style={{ backgroundColor: themeColor?.value }}>
                            {data?.icon && <IconFont type={data?.icon} size={24} fill="var(--color-white)" />}
                        </SCxLogo>
                        <SCxTitleWrapper>
                            <Text size={14} color="var(--color-black)" weight={600}>
                                {data?.name}
                            </Text>
                            <Text size={12} color="var(--color-gray-500)">
                                分享者 {data?.createdBy}
                            </Text>
                        </SCxTitleWrapper>
                    </SCxLogoWrapper>
                    <SCxHeaderExtra justifyContent="space-between" style={isMobile ? {} : { width: 'calc(50% + 100px)' }}>
                        {!appPrivateTemplate?.device || appPrivateTemplate.device === 'all' ? (
                            <SegmentedControl
                                size="lg"
                                fullWidth
                                value={tab}
                                style={{ width: isMobile ? 70 : 200 }}
                                data={tabs}
                                onChange={setTab}
                            />
                        ) : (
                            <div />
                        )}
                        <Button
                            type="primary"
                            size="lg"
                            onClick={handleOpen}
                            icon={<IconFont type="CopyObject" fill="var(--color-white" size={16} />}
                        >
                            {isMobile ? '复制' : '复制应用'}
                        </Button>
                    </SCxHeaderExtra>
                </SCxHeader>
                <SCxContent>{content}</SCxContent>
                {open && data && (
                    <CopyPrivateTemplate
                        open={open}
                        appId={data.id}
                        appName={data.name}
                        appIcon={data.icon}
                        createdBy={data.createdBy}
                        appColor={themeColor?.value}
                        onClose={handleClose}
                    />
                )}
            </>
        )
    }, [appPrivateTemplate?.device, content, data, handleClose, handleOpen, isMobile, loading, open, tab, themeColor?.value])

    return <SCxContainer ref={ref}>{body}</SCxContainer>
}
