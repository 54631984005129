import { ByecodeUIConfigProvider, Loading } from '@byecode/ui'
import { ApplicationType } from '@lighthouse/core'
import {
    APPLICATION_VERSION_PROD,
    ApplicationPreviewEnum,
    FillPickerProvider,
    getDeviceType,
    UploadManage,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import React, { useCallback, useMemo, useRef } from 'react'
import { Outlet, useMatch, useParams } from 'react-router-dom'
import { useAsyncRetry, useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import {
    fetchAppEnvListAtom,
    fetchApplicationAtom,
    fetchApplicationListAtom,
    fetchSyncComponentsAtom,
    setLanguageAtom
} from '@/atoms/application/action'
import { developerRole } from '@/atoms/application/constants'
import { applicationSettingAtom, appListAtom, currentAppVersionIdAtom, currentEnvIdAtom, logoAnimationEndAtom } from '@/atoms/application/state'
import {
    fetchDataSourceListAtom,
    fetchSystemDataSourceAtom
} from '@/atoms/dataSource/action'
import { fetchPageListAtom } from '@/atoms/page/action'
import { ApplicationContainer } from '@/containers/Application'
import { FindUse } from '@/containers/FindUse'
import { MobileSupport } from '@/containers/MobileSupport'
import type { FindUseConfigure } from '@/contexts/PageContext'
import { FindUseProvider } from '@/contexts/PageContext'
import { useLanguage } from '@/hooks/useApplication'
import http from '@/http'
import Page from '@/views/Byecode/Application/[id]/Page'

const SCxApplicationContent = styled.div`
    flex: 1;
    height: 100%;
    overflow: hidden;
`

const Application: React.FC = () => {
    const [state, setState] = useImmer<FindUseConfigure>({ open: false })
    const { applicationId: pathAppId = '' } = useParams()
    const { run: fetchApplication } = useAtomAction(fetchApplicationAtom)
    const { run: fetchSystemDataSource } = useAtomAction(fetchSystemDataSourceAtom)
    const { run: fetchPageList } = useAtomAction(fetchPageListAtom)
    const { run: fetchAppEnvList } = useAtomAction(fetchAppEnvListAtom)
    const { run: fetchDataSourceList } = useAtomAction(fetchDataSourceListAtom)
    const { run: fetchApplicationList } = useAtomAction(fetchApplicationListAtom)
    const { run: fetchSyncComponents } = useAtomAction(fetchSyncComponentsAtom)
    const { run: setDefaultEnd } = useAtomAction(logoAnimationEndAtom)
    const { run: setLanguage } = useAtomAction(setLanguageAtom)
    const { open, config } = state
    const result = getDeviceType()
    const isMobile = result === ApplicationPreviewEnum.mobile

    const appVersionId = useAtomData(currentAppVersionIdAtom)
    const appEnvId = useAtomData(currentEnvIdAtom)
    const prefVersionIdRef = useRef(appVersionId)
    const prevEnvIdRef = useRef(appEnvId)

    const getApplicationData = useCallback(async () => {
        const app = await fetchApplication()
        if (app.type === ApplicationType.website) {
            setLanguage(app.config.language.list?.[0].lang)
        }
        prefVersionIdRef.current = app.version.id
        prevEnvIdRef.current = app.version.envId
        await Promise.all([
            fetchAppEnvList(),
            fetchSystemDataSource(),
            fetchPageList(),
            fetchDataSourceList({ appId: pathAppId, envId: app.version.envId })
        ])
        fetchApplicationList(app.spaceId)
        return app
    }, [fetchAppEnvList, fetchApplication, fetchApplicationList, fetchDataSourceList, fetchPageList, fetchSystemDataSource, pathAppId, setLanguage])

    const { loading, value, retry, error } = useAsyncRetry(async () => {
        setDefaultEnd(false)
        http.defaults.headers.common['appUserId'] = developerRole.userId
        http.defaults.headers.common['lang'] = ''
        await getApplicationData()

        return pathAppId
    }, [pathAppId])

    useUpdateEffect(() => {
        if (!appVersionId || error) {
            return
        }
        // if (!prefVersionIdRef.current) {
        //     prefVersionIdRef.current = appVersionId
        //     prevEnvIdRef.current = appEnvId
        //     return
        // }

        if (appVersionId !== prefVersionIdRef.current) {
            retry()
        }
    }, [appVersionId, retry])

    useUpdateEffect(() => {
        if (!appEnvId) {
            return
        }
        if (!prefVersionIdRef.current) {
            prevEnvIdRef.current = appEnvId
            return
        }

        if (appEnvId !== prevEnvIdRef.current) {
            retry()
        }
    }, [appEnvId, retry])

    const isLayoutPath = useMatch('/:appId/page')

    const handleChangeFindUse = useCallback(
        (val: FindUseConfigure) => {
            setState(val)
        },
        [setState]
    )

    const locale = useLanguage()
    const palettes = useAtomData(
        applicationSettingAtom,
        useCallback(s => {
            return { palettes: s?.theme.palettes ?? [] }
        }, [])
    )

    const loadingDescription = useMemo(() => {
        if (prefVersionIdRef.current !== appVersionId) {
            return `正在切换${appVersionId === APPLICATION_VERSION_PROD ? '正式' : '草稿'}版本`
        }
        if (prevEnvIdRef.current !== appEnvId) {
            return '正在切换环境'
        }
        return undefined
    }, [appEnvId, appVersionId])

    return useMemo(() => {
        if (isMobile) {
            return <MobileSupport />
        }

        // 初次加载时显示全局loading
        if (!value && loading) {
            return <Loading />
        }
        if (!value) {
            return null
        }

        return (
            <ByecodeUIConfigProvider locale={locale}>
                <FillPickerProvider value={palettes}>
                    <ApplicationContainer id={value}>
                        <FindUseProvider value={{ open, config, onChange: handleChangeFindUse }}>
                            <SCxApplicationContent>
                                {/* 更新应用时显示局部loading */}
                                {prefVersionIdRef.current === appVersionId && prevEnvIdRef.current === appEnvId ? (
                                    <div style={{ display: isLayoutPath ? undefined : 'none', width: '100%', height: '100%' }}>
                                        {/* 不离开应用的话，页面会一直保持渲染状态 */}
                                        <Page />
                                    </div>
                                ) : (
                                    <Loading shape="indicator" description={loadingDescription} />
                                )}

                                <Outlet context={value} />
                            </SCxApplicationContent>
                            {open && <FindUse />}
                        </FindUseProvider>
                        <UploadManage />
                    </ApplicationContainer>
                </FillPickerProvider>
            </ByecodeUIConfigProvider>
        )
    }, [
        appEnvId,
        appVersionId,
        config,
        handleChangeFindUse,
        isLayoutPath,
        isMobile,
        loading,
        loadingDescription,
        locale,
        open,
        palettes,
        value
    ])
}

export default Application
