import type { Option } from '@byecode/ui'
import { IconFont, pointer, Select } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import {
    FieldTypeTag,
    getFieldIcon,
    getIsMatchInnerTypeByOperator,
    getJoinOption,
    innerTypeJoinOperatorMap,
    JoinDataSourceDisableFieldType,
    VariableSelect
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
`

const SCxRemove = styled.div`
    ${pointer}
    padding: 2px;
    color: var(--color-gray-500);
    &:hover {
        color: var(--color-gray-900);
    }
`

const SelectStyle = {
    flex: 1,
    flexShrink: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
}

export interface MatchConditionProps {
    index: number
    primaryDsId: string
    prefixName: string
    joinedDs: DataSourceAbstract
    useDsIds: string[][]
    dataSourceList: DataSourceAbstract[]
    onRemove?: () => void
}

export const MatchCondition: React.FC<MatchConditionProps> = ({
    index,
    primaryDsId,
    prefixName,
    useDsIds,
    joinedDs,
    dataSourceList,
    onRemove
}) => {
    const { control, setValue } = useFormContext()

    const leftFieldId = useWatch({
        control,
        name: `${prefixName}.leftFieldId`
    })

    const operator = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    const leftField = useMemo(() => {
        const { schema } = joinedDs
        return schema?.[leftFieldId]
    }, [joinedDs, leftFieldId])

    const operatorOptions = useMemo(() => {
        if (!leftField) {
            return []
        }
        const { innerType } = leftField
        if (!innerType) {
            return []
        }
        return innerTypeJoinOperatorMap[innerType]
    }, [leftField])

    const useJoinDsId = useMemo(() => {
        const ids = useDsIds.filter((_, i) => index > i).flat()
        return [primaryDsId, ...ids]
    }, [index, primaryDsId, useDsIds])

    const rightDataSourceOptions = useMemo(
        () =>
            useJoinDsId.reduce<DataSourceAbstract[]>((acc, cur) => {
                const dataSource = dataSourceList.find(item => item.id === cur)
                if (!dataSource) {
                    return acc
                }
                acc.push(dataSource)
                return acc
            }, []),
        [dataSourceList, useJoinDsId]
    )

    const leftFieldOptions = useMemo(() => {
        const { schema } = joinedDs
        return Object.entries(schema).reduce<Option[]>((prev, [_, item]) => {
            if (!item?.innerType || JoinDataSourceDisableFieldType.includes(item.type)) {
                return prev
            }
            prev.push({
                label: item.name,
                value: item.id,
                icon: getFieldIcon(item.id, item.type, item.innerType),
                extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
            })
            return prev
        }, [])
    }, [joinedDs])

    const rightOptions = useMemo(
        () =>
            getJoinOption({
                primaryDsId,
                dataSourceList: rightDataSourceOptions,
                validateField: f => getIsMatchInnerTypeByOperator(leftField?.innerType, operator, f.innerType)
            }),
        [leftField?.innerType, operator, primaryDsId, rightDataSourceOptions]
    )

    return (
        <SCxContainer>
            <Controller
                control={control}
                name={`${prefixName}.leftFieldId`}
                render={({ field }) => (
                    <Select
                        styles={{
                            root: SelectStyle
                        }}
                        searchable
                        dropdownWidth={282}
                        position="bottom-start"
                        value={field.value}
                        options={leftFieldOptions}
                        onChange={val => {
                            field.onChange(val)
                            // setValue(`${prefixName}.rightFieldId`, '')
                            setValue(`${prefixName}.right`, undefined)
                            setValue(`${prefixName}.operator`, '=')
                        }}
                    />
                )}
            />
            <Controller
                control={control}
                name={`${prefixName}.operator`}
                render={({ field }) => (
                    <Select
                        styles={{
                            root: {
                                flexShrink: 0,
                                width: '100px'
                            }
                        }}
                        disabled={!leftField}
                        value={field.value}
                        options={operatorOptions}
                        onChange={val => {
                            field.onChange(val)
                        }}
                    />
                )}
            />
            {!['isEmpty', 'isNotEmpty'].includes(operator) && (
                <Controller
                    control={control}
                    name={`${prefixName}.right`}
                    render={({ field }) => (
                        <VariableSelect
                            disabled={!leftField}
                            withinPortal
                            field={leftField}
                            options={rightOptions}
                            value={field.value}
                            style={SelectStyle as React.CSSProperties}
                            width="100%"
                            onChange={field.onChange}
                        />
                    )}
                />
            )}
            <SCxRemove onClick={onRemove}>
                <IconFont type="Close" size={16} />
            </SCxRemove>
        </SCxContainer>
    )
}
