import { Divider, Empty, Flex, Input, Popover } from '@byecode/ui'
import { PAGE_TYPE } from '@lighthouse/core'
import { PageIconTypeMap, useAtomAction, useAtomData } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { find, max } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'

import { updateAppAtom } from '@/atoms/application/action'
import { CURRENT_PAGE, HOME_PAGE, RELATIVE_PAGE } from '@/atoms/application/constants'
import { appAtom, sharePageAtom } from '@/atoms/application/state'
import { homePageIdAtom, lastPageOfStackAtom, pageListAtom } from '@/atoms/page/state'
import { useApplication } from '@/hooks/useApplication'
import { usePageList } from '@/hooks/usePage'

import * as CM from '../../styles'
import { SharedHeader } from './Header'
import * as SC from './styles'

interface SharedContainerProps {
    title: string
    subTitle: string
    isUpdateSubName?: boolean
    children: React.ReactNode
    onClose?: () => void
    onBack: () => void
}

const prefixLabelMap: Record<string, string> = { CURRENT_PAGE: '当前页面', HOME_PAGE: '主页' }

export const SharedContainer: React.FunctionComponent<SharedContainerProps> = ({
    title,
    subTitle,
    isUpdateSubName,
    children,
    onClose,
    onBack
}) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [keyword, setKeyword] = useState('')
    const [opened, { toggle, close }] = useDisclosure(false)
    const app = useAtomData(
        appAtom,
        useCallback(({ subName, id }) => ({ subName, id }), [])
    )
    const [newName, setNewName] = useState(app.subName)

    const sharePage = useAtomData(sharePageAtom)
    const { run: updateApplication } = useAtomAction(updateAppAtom)
    const { run: setSharePage } = useAtomAction(sharePageAtom)

    const pageList = usePageList()
    const { height: windowHeight } = useWindowSize()

    const currentPageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId, [])
    )
    const homePage = useAtomData(homePageIdAtom)
    const currentPage = useAtomData(
        pageListAtom,
        useCallback(list => find(p => p.id === currentPageId, list), [currentPageId])
    )

    const maxHeight = useMemo(() => {
        if (!containerRef.current || !containerRef?.current) {
            return windowHeight - 130
        }
        const containRect = containerRef.current.getBoundingClientRect()
        const bottom = windowHeight - containRect.y
        return max(50, bottom - 30)
    }, [windowHeight])

    const options = useMemo(
        () =>
            pageList
                .map(item => ({
                    icon: <CM.Icon type={PageIconTypeMap[item.type]} />,
                    label: item.name,
                    value: item.id,
                    type: item.type
                }))
                .sort(item => (item.type === PAGE_TYPE.default ? -1 : 1))
                .filter(item => item.label.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())),
        [keyword, pageList]
    )

    const option = useMemo(() => {
        if (sharePage === CURRENT_PAGE) {
            return {
                label: currentPage?.name,
                value: currentPage?.id,
                icon: <CM.Icon color="var(--color-theme-7)" type="MapPointIconLibrary" />
            }
        }
        if (sharePage === HOME_PAGE) {
            return { label: homePage?.name, value: homePage?.id, icon: <CM.Icon color="var(--color-theme-7)" type="Home" /> }
        }
        return find(p => p.value === sharePage, options)
    }, [currentPage?.id, currentPage?.name, homePage?.id, homePage?.name, options, sharePage])

    const prefixLabel = prefixLabelMap[sharePage]

    const handleChange = useCallback(
        (v: string) => {
            setSharePage(v)
            close()
        },
        [close, setSharePage]
    )

    const handleKeyDown = useCallback((ev: React.KeyboardEvent) => {
        if (ev.key === 'Enter') {
            inputRef.current?.blur()
        }
    }, [])

    useEffect(() => {
        const isExitPage = pageList.some(p => p.id === sharePage)
        if (!isExitPage && !RELATIVE_PAGE.includes(sharePage)) {
            setSharePage(CURRENT_PAGE)
        }
    }, [pageList, setSharePage, sharePage])

    return (
        <SC.Container>
            <SharedHeader onBack={onBack} onClose={onClose} title={title} />
            <SC.ContentScrollArea style={{ maxHeight }} ref={containerRef}>
                <SC.Describe>进入应用访问</SC.Describe>
                <Popover opened={opened} withinPortal onChange={toggle}>
                    <Popover.Target>
                        <SC.SelectPagePreview>
                            <Flex alignItems="center">
                                <Flex gap="4px">
                                    {option?.icon}
                                    {prefixLabel && <Text color="var(--color-black)">{prefixLabel}:</Text>}
                                </Flex>
                                <Input placeholder="当前页面名称" value={option?.label} style={{ pointerEvents: 'none' }} />
                            </Flex>
                            <CM.Icon type="ArrowDownSmall" />
                        </SC.SelectPagePreview>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <Input
                            value={keyword}
                            placeholder="搜索"
                            prefix={<CM.Icon type="Search" color="var(--color-gray-400)" />}
                            onChange={e => setKeyword(e.target.value)}
                            style={{ margin: '4px 12px 8px 12px', cursor: 'pointer' }}
                        />
                        <SC.PageSelectItem
                            value={CURRENT_PAGE}
                            icon={<CM.Icon color="var(--color-theme-7)" type="MapPointIconLibrary" />}
                            describe={
                                <Text style={{ paddingLeft: 24, lineHeight: '20px' }} size={12} color="var(--color-gray-400)">
                                    {currentPage?.name}
                                </Text>
                            }
                            style={{ height: 56 }}
                            label="当前页面"
                            extra={sharePage === CURRENT_PAGE && <CM.Icon type="Tick" color="var(--color-purple-500)" />}
                            onClick={() => handleChange(CURRENT_PAGE)}
                        />
                        <SC.PageSelectItem
                            value={HOME_PAGE}
                            icon={<CM.Icon color="var(--color-theme-7)" type="Home" />}
                            style={{ height: 56 }}
                            describe={
                                <Text style={{ paddingLeft: 24, lineHeight: '20px' }} size={12} color="var(--color-gray-400)">
                                    {homePage?.name}
                                </Text>
                            }
                            label="主页"
                            extra={sharePage === HOME_PAGE && <CM.Icon type="Tick" color="var(--color-purple-500)" />}
                            onClick={() => handleChange(HOME_PAGE)}
                        />
                        <Divider color="var(--color-gray-200)" style={{ margin: 8 }} />

                        <Text color="var(--color-gray-500)" size={12} style={{ lineHeight: '32px', padding: '0 16px' }}>
                            具体页面
                        </Text>
                        <SC.ScrollArea>
                            {options.length === 0 && <Empty icon="Nodata-8i554976" style={{ height: 86 }} description="没有数据" />}
                            {options.map(option => {
                                return (
                                    <SC.PageSelectItem
                                        {...option}
                                        key={option.value}
                                        extra={option.value === sharePage && <CM.Icon type="Tick" color="var(--color-purple-500)" />}
                                        onClick={() => handleChange(option.value)}
                                    />
                                )
                            })}
                        </SC.ScrollArea>
                    </Popover.Dropdown>
                </Popover>
                {isUpdateSubName && (
                    <>
                        <SC.Describe>副标题</SC.Describe>
                        <Input
                            ref={inputRef}
                            value={newName}
                            maxLength={16}
                            onChange={e => setNewName(e.target.value)}
                            onBlur={e => updateApplication({ subName: newName, id: app.id })}
                            onKeyDown={handleKeyDown}
                            size="lg"
                            style={{ marginBottom: 8 }}
                        />
                    </>
                )}

                <SC.Describe>{subTitle}</SC.Describe>
                {children}
            </SC.ContentScrollArea>
        </SC.Container>
    )
}
