import { Select } from '@byecode/ui'
import type { BlockAbstract, ContainerBlockAbstract } from '@lighthouse/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import * as SC from '../../styles'

const viewControlActionTypeOptions = [
    { label: '刷新', value: 'refresh' },
    { label: '切换分页', value: 'paginateTo' },
    { label: '横向滚动', value: 'horizontalScrollTo' }
]

const viewHorizontalScrollDirectionOptions = [
    { label: '向左', value: 'left' },
    { label: '向右', value: 'right' }
]

const viewControlActionParamsOptions = [
    { label: '上一页', value: 'prev', actionType: 'paginateTo' },
    { label: '下一页', value: 'next', actionType: 'paginateTo' },
    { label: '1', value: '1', actionType: 'horizontalScrollTo' },
    { label: '2', value: '2', actionType: 'horizontalScrollTo' },
    { label: '3', value: '3', actionType: 'horizontalScrollTo' },
    { label: '4', value: '4', actionType: 'horizontalScrollTo' },
    { label: '5', value: '5', actionType: 'horizontalScrollTo' },
    { label: '滚动到底', value: 'end', actionType: 'horizontalScrollTo' }
]

const containerControlActionTypeOptions = [
    { label: '切换面板', value: 'switchPanel' },
    { label: '展开容器', value: 'open' },
    { label: '收起容器', value: 'close' }
]

export interface ControlActionSelectorProps {
    controlElement: BlockAbstract
    prefixName: string
}

export interface ComponentItemProps {}

export const ControlActionSelector: React.FC<ControlActionSelectorProps> = ({ controlElement, prefixName }) => {
    const { control } = useFormContext()
    const controlActionType = useWatch({ control, name: `${prefixName}.controlAction.type` })

    const containerControlActionParamsOptions = useMemo(() => {
        const containerBlock = controlElement as ContainerBlockAbstract
        if (!containerBlock?.config || containerBlock?.type !== 'container') {
            return []
        }
        return containerBlock.config.viewList.map(view => ({
            label: view.name,
            value: view.id,
            actionType: 'switchPanel'
        }))
    }, [controlElement])

    /**
     * 根据控件类型返回不同的执行行为类型选项
     */
    const controlActionTypeOptions = useMemo(() => {
        if (controlElement?.type === 'container') {
            return containerControlActionTypeOptions
        }

        /** */
        if (controlElement?.type === 'view') {
            const options: typeof viewControlActionTypeOptions = [{ label: '刷新', value: 'refresh' }]
            // 如果是看板或者日历视图，且方向为垂直，则不显示切换分页
            if (
                !(
                    ['kanban', 'calendar'].includes(controlElement?.config?.viewType) ||
                    (['custom', 'gallery'].includes(controlElement?.config?.viewType) &&
                        controlElement?.config?.breakPoint?.viewLayout?.direction === 'horizontal')
                )
            ) {
                options.push({ label: '切换分页', value: 'paginateTo' })
            }
            if (
                ['custom', 'gallery'].includes(controlElement?.config?.viewType) &&
                controlElement?.config?.breakPoint?.viewLayout?.direction === 'horizontal'
            ) {
                options.push({ label: '横向滚动', value: 'horizontalScrollTo' })
            }
            return options
        }

        return []
    }, [controlElement])

    const canHorizontalScroll = useMemo(() => {
        return (
            controlElement?.type === 'view' &&
            (controlElement?.config?.viewType === 'custom' || controlElement?.config?.viewType === 'gallery')
        )
    }, [controlElement?.config, controlElement?.type])

    const paramsSelectorLabel = useMemo(() => {
        if (controlActionType === 'paginateTo') {
            return '切换到'
        }

        if (controlActionType === 'horizontalScrollTo') {
            return '滚动几个卡片'
        }

        if (controlActionType === 'switchPanel') {
            return '切换到面板'
        }

        return ''
    }, [controlActionType])

    const paramsSelectorName = useMemo(() => {
        if (controlActionType === 'paginateTo') {
            return 'paginateToType'
        }

        if (controlActionType === 'horizontalScrollTo') {
            return 'horizontalScrollTo'
        }

        if (controlActionType === 'switchPanel') {
            return 'switchPanelId'
        }
    }, [controlActionType])

    /**
     * 根据控件类型返回不同的执行行为参数选项
     */
    const controlActionParamsOptions = useMemo(() => {
        if (controlElement?.type === 'container') {
            return containerControlActionParamsOptions.filter(option => option.actionType === controlActionType)
        }

        return viewControlActionParamsOptions.filter(option => option.actionType === controlActionType)
    }, [containerControlActionParamsOptions, controlActionType, controlElement?.type])

    return (
        <>
            <SC.FormItem>
                <SC.FormItemLabelWrapper>
                    <SC.FormItemLabel required>执行行为</SC.FormItemLabel>
                </SC.FormItemLabelWrapper>
                <SC.FormItemContent>
                    <Controller
                        name={`${prefixName}.controlAction.type`}
                        control={control}
                        render={({ field }) => <Select options={controlActionTypeOptions} {...field} />}
                    />
                </SC.FormItemContent>
            </SC.FormItem>

            {canHorizontalScroll && (
                <SC.FormItem>
                    <SC.FormItemLabelWrapper>
                        <SC.FormItemLabel>滚动方向</SC.FormItemLabel>
                    </SC.FormItemLabelWrapper>
                    <SC.FormItemContent>
                        <Controller
                            name={`${prefixName}.controlAction.params.horizontalScrollDirection`}
                            control={control}
                            render={({ field }) => <Select options={viewHorizontalScrollDirectionOptions} {...field} />}
                        />
                    </SC.FormItemContent>
                </SC.FormItem>
            )}
            {paramsSelectorLabel && (
                <SC.FormItem>
                    <SC.FormItemLabelWrapper>
                        <SC.FormItemLabel>{paramsSelectorLabel}</SC.FormItemLabel>
                    </SC.FormItemLabelWrapper>
                    <SC.FormItemContent>
                        <Controller
                            name={`${prefixName}.controlAction.params.${paramsSelectorName}`}
                            control={control}
                            render={({ field }) => <Select options={controlActionParamsOptions} {...field} />}
                        />
                    </SC.FormItemContent>
                </SC.FormItem>
            )}
        </>
    )
}
