import { Tooltip } from '@byecode/ui'
import { BlockThumbImageMap } from '@lighthouse/block'
import type { ViewBreakPointProtocol } from '@lighthouse/core'
import { CUSTOM_VIEW_LAYOUT, ViewBreakPointConfigProtocol } from '@lighthouse/core'
import { Image } from '@mantine/core'
import type { AnyArray } from 'immer/dist/internal'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useSetExtendsKeys } from '@/components/DesignSetting/hooks/useMouseEventDistance'
import { InheritLabel } from '@/components/InheritLabel'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

interface StyleSwitcherProps {}

interface StyleSwitcherGridProps {
    options: AnyArray
    value?: string
    onChange?: (value: string) => void
}

interface StyleSwitcherItemProps {
    image: string
    label?: string
    color: string
    background: string
    checked?: boolean
    onCheck?: () => void
    disabled?: boolean
}

const SCxContainer = styled.div`
    padding: 12px 16px;
`

const SCxHeader = styled.div`
    font-weight: 600;
    padding: 6px 0;
`

const SCxStyleSwitchers = styled.div`
    display: grid;
    grid-template-columns: 72px 72px 72px;
    grid-row-gap: 8px;
    gap: 10px;
    padding: 8px 0;
`

const SCxStyleSwitcherItem = styled.div``

// const SCxStyleWrapper = styled.div<Pick<React.CSSProperties, 'backgroundColor'>>`
//     position: absolute;
//     inset: 0;
//     z-index: 1;
//     background-color: ${({ backgroundColor }) => backgroundColor};
//     border-radius: 16px;
// `

const SCxBlock = styled.div<Pick<React.CSSProperties, 'width' | 'height' | 'background'>>`
    position: relative;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
    border-radius: 16px;
    cursor: pointer;
    background-color: ${({ background }) => background || 'var(--color-gray-50)'};
    img {
        -webkit-user-drag: none;
    }

    &:hover {
        background-color: ${({ background }) => !background && 'var(--color-gray-100)'};
    }
`

const SCxText = styled.div<{ color?: string }>`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 2;
    bottom: 8px;
    color: ${({ color }) => color ?? 'var(--color-gray-500)'};
    font-weight: 400;
    font-size: 12px;
`

const OPTIONS = [
    {
        label: '九宫格',
        value: CUSTOM_VIEW_LAYOUT.grid,
        image: BlockThumbImageMap.grid,
        color: 'var(--color-main)',
        background: 'var(--color-purple-100)'
    },
    {
        label: '瀑布流',
        value: CUSTOM_VIEW_LAYOUT.waterfall,
        image: BlockThumbImageMap.waterfall,
        color: 'var(--color-main)',
        background: 'var(--color-purple-100)'
    }

    // {
    //     label: '列表',
    //     value: CUSTOM_VIEW_LAYOUT.list,
    //     image: BlockThumbImageMap.gallery,
    //     color: 'var(--color-main)',
    //     background: 'var(--color-purple-100)'
    // }
]

const StyleSwitcherItem: React.FC<StyleSwitcherItemProps> = ({ disabled, image, label, color, background, checked, onCheck }) => {
    const hoverColor = checked ? color : 'var(--color-gray-500)'
    const activeBackground = checked ? background : undefined
    return (
        <Tooltip title={label} disabled={disabled}>
            <SCxBlock
                width={72}
                height={78}
                background={activeBackground}
                style={{ cursor: disabled ? 'not-allowed' : undefined }}
                onClick={disabled ? undefined : onCheck}
            >
                {/* <SCxStyleWrapper backgroundColor={hoverBackground} /> */}
                <Image fit="cover" style={{ zIndex: 2, position: 'relative' }} radius={16} src={image} />
                <SCxText color={hoverColor}>{label}</SCxText>
            </SCxBlock>
        </Tooltip>
    )
}

const StyleSwitcherGrid: React.FC<StyleSwitcherGridProps> = ({ value, options, onChange }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    return (
        <SCxStyleSwitchers>
            {options.map(option => (
                <SCxStyleSwitcherItem key={option.value}>
                    <StyleSwitcherItem
                        disabled={disabledWithVersion}
                        checked={option.value === value}
                        {...option}
                        onCheck={() => onChange?.(option.value)}
                    />
                </SCxStyleSwitcherItem>
            ))}
        </SCxStyleSwitchers>
    )
}

export const StyleSwitcher: React.FC<StyleSwitcherProps> = () => {
    const { control } = useFormContext<ViewBreakPointProtocol>()
    const { handleSetBreakKey } = useSetExtendsKeys()
    return (
        <SCxContainer>
            <InheritLabel label="风格" name="breakPoint.viewLayout.layout" />
            <Controller
                control={control}
                name="breakPoint.viewLayout.layout"
                render={({ field }) => (
                    <StyleSwitcherGrid
                        options={OPTIONS}
                        value={field.value}
                        onChange={val => {
                            field.onChange(val)
                            handleSetBreakKey('breakPoint.viewLayout.layout')
                        }}
                    />
                )}
            />
        </SCxContainer>
    )
}
