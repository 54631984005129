import type { ViewType } from '@lighthouse/core'
import React, { createContext, useContext, useMemo } from 'react'

export type BlockSettingContextValue = {
    blockId: string
}

export const BlockSettingContext = createContext<BlockSettingContextValue>({
    blockId: '',
})

export const BlockSettingProvider = ({
    blockId,
    children
}: {
    blockId: string
    children: React.ReactNode
}) => {
    const ctxValue = useMemo(() => ({ blockId }), [blockId, ])
    return <BlockSettingContext.Provider value={ctxValue}>{children}</BlockSettingContext.Provider>
}

export const useBlockSettingContext = () => useContext(BlockSettingContext)

BlockSettingContext.displayName = 'blockSettingContext'

