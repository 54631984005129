import { Anchor, BaseModal, Button, Flex, Group, IconFont, Image, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { TagIcon } from '@lighthouse/shared'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { guideTaskList } from './constants'

interface IGuideTaskProps {
    onClose: () => void
    onChangTask: (id: string) => void
}

const SCxContainer = styled.div`
    padding: 20px;
`

const SCxContent = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const SCxCollapse = styled(Group)`
    border: 1px solid var(--color-gray-200);
    box-shadow: 0px 0px 6px 0px #3838381f;
    border-radius: 10px;
`

const SCxCloseIcon = styled(TagIcon)`
    position: absolute;
    right: 12px;
    top: 12px;
`

export const TaskList: React.FunctionComponent<IGuideTaskProps> = ({ onChangTask, onClose }) => {
    const [openIndex, setOpenIndex] = useState(0)
    return (
        <SCxContainer>
            <SCxCloseIcon
                iconSize={20}
                iconColor="var(--color-black)"
                icon="Close"
                size={28}
                radius={100}
                hoverBackground="transparent"
                onClick={onClose}
                enableHover
            />
            <Text color="var(--color-black)" size={20} lineHeight="28px" style={{ marginTop: 12 }}>
                欢迎来到ByeCode
            </Text>
            <Text color="var(--color-gray-400)" size={14} lineHeight="22px" style={{ marginTop: 9 }}>
                使用ByeCode来搭建你的第一个应用吧
            </Text>
            <SCxContent>
                {guideTaskList.map((task, index) => {
                    return (
                        <SCxCollapse
                            label={task.title}
                            styles={{
                                root: {
                                    padding: '16px!important',
                                    borderColor: openIndex === index ? 'var(--color-main)!important' : 'var(--color-gray-200)'
                                },
                                wrapper: {
                                    height: '22px!important',
                                    padding: '0px!important'
                                },
                                label: {
                                    fontWeight: '400!important'
                                },
                                collapse: {
                                    padding: '0px!important',
                                    marginTop: openIndex === index ? 12 : 0
                                }
                            }}
                            key={task.id}
                            iconColor="var(--color-gray-400)"
                            opened={openIndex === index}
                            onCollapseChange={v => setOpenIndex(v ? index : -1)}
                        >
                            <Flex direction="column" gap={12}>
                                <Image src={task.image} height={140} radius={8} />
                                <Text
                                    algin="center"
                                    color="var(--color-main)"
                                    size={14}
                                    lineHeight="22px"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => onChangTask(task.id)}
                                >
                                    开始搭建
                                </Text>
                            </Flex>
                        </SCxCollapse>
                    )
                })}
            </SCxContent>
        </SCxContainer>
    )
}
