import { RecordOpenType } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'

import type { PageMetaData, PageStackTree } from './types'
import { AsideType } from './types'

export function addNode(parentId: string, data: PageMetaData) {
    function recursion(id: string, tree?: PageStackTree[]) {
        if (!tree) {
            return
        }

        for (const stack of tree) {
            if (stack.stackId === id) {
                if (!stack.children) {
                    stack.children = []
                }
                stack.children.push(data)
                break
            }

            recursion(id, stack.children)
        }
    }

    return (tree: PageStackTree[]) => {
        recursion(parentId, tree)
    }
}

export function removeNode(id: string) {
    function recursion(tree?: PageStackTree[]) {
        if (!tree) {
            return
        }

        for (let i = 0; i < tree.length; i++) {
            if (tree[i].stackId === id) {
                tree.splice(i, 1)
                break
            }

            recursion(tree[i].children)
        }
    }

    return (tree: PageStackTree[]) => {
        recursion(tree)
    }
}

export function getNode(id: string) {
    function recursion(tree?: PageStackTree[]): PageMetaData | undefined {
        if (!tree) {
            return
        }

        for (const stack of tree) {
            if (stack.stackId === id) {
                return stack
            }

            const res = recursion(stack.children)
            if (res) {
                return res
            }
        }
    }

    return (tree: PageStackTree) => {
        if (tree.stackId === id) {
            return tree
        }
        return recursion(tree.children)
    }
}

type StackFactory = Partial<PageStackTree> & {
    appId: string
    pageId: string
}
export function stackFactory({
    appId,
    pageId,
    stackId = nanoid(),
    rootPageId = pageId,
    stackDisplayType = RecordOpenType.page,
    state,
    ...rest
}: StackFactory): PageStackTree {
    return {
        appId,
        stackId,
        pageId,
        rootPageId,
        stackDisplayType,
        state: {
            asideType: AsideType.PAGE,
            ...state
        },
        blockRuntimeState: {},
        ...rest
    }
}
