import { type BlockAbstract, type PageAbstract, RecordOpenType } from '@lighthouse/core'
import type { ApplicationPreviewEnum } from '@lighthouse/shared'
import {
    findBlockById,
    getBlockChildren,
    getBlockWithMergedBreakPoint,
    getPageWithMergedBreakPoint,
    hasChildrenBlock,
    isContainerBlock
} from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import { type Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { omit } from 'rambda'

import type { DragNode } from '@/constants/Block/type'

import { languageAtom, previewTypeAtom } from '../application/state'
import { applyDraftPayload } from '../utils/applyDraftPayload'
import { equalPageStack } from '../utils/equalPageStack'
import type { ContainerDesignState } from './types'
import { type PageMetaData, type PageStackTree } from './types'
import { getNode, stackFactory } from './utils'

export const pageListAtom = atomWithImmer<PageAbstract[]>([])

export const defaultPageListAtom = atom(get => {
    const list = get(pageListAtom)
    const language = get(languageAtom)
    return list.filter(page => page.type === 'default' && page.language === language)
})

export const recordPageListAtom = atom(get => {
    const list = get(pageListAtom)
    const language = get(languageAtom)
    return list.filter(page => page.type === 'document' && page.language === language)
})

export const recordEditPageListAtom = atom(get => {
    const list = get(pageListAtom)
    const language = get(languageAtom)
    return list.filter(page => page.type === 'edit' && page.language === language)
})

export const formPageListAtom = atom(get => {
    const list = get(pageListAtom)
    const language = get(languageAtom)
    return list.filter(page => page.type === 'creator' && page.language === language)
})

export const homePageIdAtom = atom(get => {
    const list = get(defaultPageListAtom)
    const language = get(languageAtom)
    return list.find(page => page.isHome && page.language === language)
})

export const pageAtomFamily = atomFamily((pageID: string) =>
    atom(
        get => {
            const previewType = get(previewTypeAtom)
            const page = get(pageListAtom)?.find(item => item.id === pageID)
            if (!page) {
                return
            }
            return getPageWithMergedBreakPoint(previewType, page)
        },
        (_, set, payload: PageAbstract | null | ((draft: Draft<PageAbstract | null>) => void)) =>
            set(pageListAtom, draft => {
                const page = draft?.find(item => item.id === pageID) ?? null
                applyDraftPayload(page, payload)
            })
    )
)

export const blocksAtom = atomWithImmer<Partial<Record<string, BlockAbstract[]>>>({})

const transformOriginBlock = (blocks: BlockAbstract[], previewType: ApplicationPreviewEnum): BlockAbstract[] => {
    return blocks.map(block => {
        const newBlock = getBlockWithMergedBreakPoint(previewType, block)
        if (hasChildrenBlock(newBlock)) {
            if (isContainerBlock(newBlock) && newBlock.children) {
                return {
                    ...newBlock,
                    children: newBlock.children.map(item => ({
                        ...item,
                        children: transformOriginBlock(item.children, previewType)
                    }))
                }
            }
            if (newBlock.children && newBlock.children.length > 0) {
                return {
                    ...newBlock,
                    children: transformOriginBlock(newBlock.children as BlockAbstract[], previewType)
                } as BlockAbstract
            }
        }
        return newBlock
    })
}

export const pageBlocksAtom = atomFamily((pageId: string) => {
    return atom(get => {
        const previewType = get(previewTypeAtom)
        const originBlocks = get(blocksAtom)[pageId]
        if (!originBlocks) {
            return []
        }
        return transformOriginBlock(originBlocks, previewType)
    })
}, equal)

export const pageStackAtom = atomWithImmer<PageStackTree[]>([])
export const pageStackIndexHistoriesAtom = atomWithImmer<[number, string][]>([])

/** 弹窗层的pageStack */
export const pageStackLayersAtom = atom(get => {
    const tree = get(pageStackAtom)
    const indexes = get(pageStackIndexHistoriesAtom)
    const indexHistory = indexes[indexes.length - 1]

    const [rootIndex, stackId] = indexHistory
    const rootStack = tree[rootIndex]

    if (!rootStack.children) {
        return []
    }

    function reverseRecursion(children: PageStackTree[]): PageMetaData[] {
        for (const stack of children) {
            // 找到子节点，直接返回
            if (stack.stackId === stackId) {
                return [stack]
            }

            if (stack.children) {
                const res = reverseRecursion(stack.children)
                // 有值说明找到子节点了，直接一路往上返回
                if (res.length > 0) {
                    // 如果当前栈不是页面形式的，也加入到弹窗列表中
                    if (stack.stackDisplayType !== RecordOpenType.page) {
                        return [omit('children', stack), ...res]
                    }
                    return res
                }
            }
        }

        return []
    }

    return reverseRecursion(rootStack.children)
})

/** 面包屑的pageStack */
export const pageStackBreadcrumbAtom = atom(get => {
    const tree = get(pageStackAtom)
    const indexes = get(pageStackIndexHistoriesAtom)
    const indexHistory = indexes[indexes.length - 1]

    const [rootIndex, stackId] = indexHistory
    const rootStack = tree[rootIndex]
    if (!rootStack) {
        return []
    }
    const { children, ...root } = rootStack

    if (!children) {
        return [root]
    }

    function reverseRecursion(children: PageStackTree[]): PageMetaData[] {
        for (const stack of children) {
            // 找到子节点，直接返回
            if (stack.stackId === stackId) {
                return [stack]
            }

            if (stack.children) {
                const res = reverseRecursion(stack.children)
                // 有值说明找到子节点了，直接一路往上返回
                if (res.length > 0) {
                    const { children, ...s } = stack
                    return [s, ...res]
                }
            }
        }

        return []
    }

    return [root, ...reverseRecursion(children)]
})

export const pageStackAtomFamily = atomFamily(
    ({ stackId, rootPageId }: { stackId: string; rootPageId: string }) =>
        atom(get => {
            const stackTree = get(pageStackAtom)
            return equalPageStack({ rootPageId, stackId })(stackTree)
        }),
    equal
)

export const blockRuntimeStateAtom = atomFamily((params: { stackId: string; rootPageId: string }) =>
    atom(get => get(pageStackAtomFamily(params))?.blockRuntimeState)
)

export const firstPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stackTree = get(pageStackAtom)
    const indexes = get(pageStackIndexHistoriesAtom)
    const [rootIndex] = indexes[indexes.length - 1]

    const root = stackTree[rootIndex]
    if (!root) {
        return
    }
    const { children, ...stack } = root

    return stack
})

/** 最后的pageStack */
export const lastPageOfStackAtom = atom<PageMetaData | undefined>(get => {
    const stackTree = get(pageStackAtom)
    const indexes = get(pageStackIndexHistoriesAtom)
    if (indexes.length === 0) {
        return
    }
    const [rootIndex, stackId] = indexes[indexes.length - 1]
    const root = stackTree[rootIndex]
    if (!root) {
        return
    }
    return getNode(stackId)(root)
})

/** 最后的页面形式的pageStack */
export const lastPageTypeOfStackAtom = atom(get => {
    const stackTree = get(pageStackAtom)
    const indexes = get(pageStackIndexHistoriesAtom)
    const current = indexes[indexes.length - 1]
    if (!current) {
        return
    }
    const [rootIndex, stackId] = current
    const rootStack = stackTree[rootIndex]

    if (!rootStack) {
        return
    }

    let recentPageTypeParentStack: PageMetaData | undefined
    function getPageTypeStackId(tree: PageStackTree): boolean {
        if (!tree.children) {
            return false
        }

        for (const node of tree.children) {
            if (node.stackId === stackId) {
                if (node.stackDisplayType === RecordOpenType.page && !recentPageTypeParentStack) {
                    recentPageTypeParentStack = omit('children', node)
                }
                return true
            }

            const res = getPageTypeStackId(node)
            if (res) {
                if (node.stackDisplayType === RecordOpenType.page && !recentPageTypeParentStack) {
                    recentPageTypeParentStack = omit('children', node)
                }
                return res
            }
        }

        return false
    }

    getPageTypeStackId(rootStack)

    return recentPageTypeParentStack ?? omit<PageStackTree, PageMetaData>('children', rootStack)
})

/** 初始页面栈信息 */
export const initPageStackAtom = atom(null, (get, set) => {
    const pageList = get(defaultPageListAtom)
    const page = pageList[0]
    if (!page) {
        return
    }

    const pageStack = stackFactory({ appId: page.appId, pageId: page.id })
    set(pageStackAtom, [pageStack])
    set(pageStackIndexHistoriesAtom, [[0, pageStack.stackId]])
})

export const selectedBlockAtomFamily = atomFamily(
    ({ rootPageId, pageId, stackId }: { rootPageId: string; pageId: string; stackId: string }) => {
        return atom(get => {
            const blocksMap = get(blocksAtom)
            const blocks = blocksMap[pageId]
            const ids = get(pageStackAtomFamily({ stackId, rootPageId }))?.state?.selectedNodes

            if (!ids || ids.length !== 1 || !blocks) {
                return null
            }

            return findBlockById(ids[0], blocks) ?? null
        })
    },
    equal
)

/** 外部添加拖拽时的node */
export const outsideDraggingNode = atomWithImmer<DragNode | null>(null)

/** 容器设计配置的交互状态 pageId/containerId -> ContainerDesignState */
export const containerDesignStateAtom = atomWithImmer<Record<string, ContainerDesignState | undefined>>({})

/** block高亮的状态 */
export const blockHighlightAtom = atomWithImmer<string[]>([])


/** 创建block时loading */
export const createNodeLoadingAtom = atom(false)

/**
 * block创建时的列表, 用于前端创建某些视图block，
 * 后端的创建接口还未完成时，前端的视图已经渲染并发出获取视图请求报错的问题
 */
export const blockCreatingListAtom = atom<string[]>([])

/**
 * dataDrawer进行增删改后, 需要重新获取选中的视图，图表block数据
 */
export const blockUpdateListAtom = atom<string[]>([])
