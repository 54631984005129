import type { FileBlockConfig } from '@lighthouse/core'

export const generateFileBlock = (): FileBlockConfig => {
    return {
        previewMode: 'preview',
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 300,
                    unit: 'px'
                },
                overflow: 'visible'
            },
            layout: {}
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
