import type { GroupProps } from '@byecode/ui'
import { Divider, Flex, Group, Input } from '@byecode/ui'
import type { ContainerBreakPointConfigProtocol, PageAbstract, PageShareConfig, PartialExcludesIdAndName } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import {
    ApplicationPreviewEnum,
    getPageWithMergedBreakPoint,
    ListItem4ByecodeUi,
    SelectDataSource,
    useAtomAction,
    useSharedConfigDisabledWithVersion
} from '@lighthouse/shared'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useUpdateEffect } from 'react-use'
import { debounce } from 'throttle-debounce'

import { updateLocalPageAtom, updateRemotePageAtom } from '@/atoms/page/action'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { PAGE_CONFIG_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { BlockConfigureSegmentedControl } from '../BlockSettings/Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../BlockSettings/constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../BlockSettings/constants'
import { AutoLayoutSetting, getBlockAndPageDesignLimit, SizeSetting, StyleSetting } from '../DesignSetting'
import { PageShare } from './Share'
import { PageVisibility } from './Visibility'

interface PageSettingProps {
    pageData: PageAbstract
    mode?: GroupProps['mode']
}

const PageSetting: React.FC<PageSettingProps> = ({ pageData, mode }) => {
    const previewType = usePreviewType()
    const pageContent = useMemo(() => getPageWithMergedBreakPoint(previewType, pageData), [pageData, previewType])
    const { id, type, shareConfig, breakPoint, breakPoints, name, dsId } = pageContent
    const [activeTab, setActiveTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const { run: updateLocalPage } = useAtomAction(updateLocalPageAtom)
    const { run: updateRemotePage } = useAtomAction(updateRemotePageAtom)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const disabledWithVersion = useSharedConfigDisabledWithVersion()

    const debounceUpdateRemotePage = useMemo(
        () =>
            debounce(
                500,
                async (origin: PageAbstract, current: PageAbstract) => {
                    pageUndoRedoController.add({ action: PAGE_CONFIG_UPDATE_ACTION, payload: { prev: origin, next: current } })
                    await updateRemotePage(current)
                },
                { atBegin: true }
            ),
        [updateRemotePage]
    )

    const updatePage = useCallback(
        (current: PageAbstract) => {
            updateLocalPage(current)
            debounceUpdateRemotePage(pageData, current)
        },
        [debounceUpdateRemotePage, pageData, updateLocalPage]
    )

    // 获取数据源
    const dataSourceList = useDataSourceList(appId, envId)

    const selectDtaSourceList = useMemo(() => {
        return dataSourceList.filter(cur => {
            return !(cur.type === DataSourceType.aggregateDataSource)
        })
    }, [dataSourceList])

    const designProps = getBlockAndPageDesignLimit('page')

    const methods = useForm({
        values: { shareConfig, breakPoint, name, dsId }
    })

    useUpdateEffect(() => {
        methods.reset({ shareConfig, breakPoint, name, dsId })
    }, [previewType])

    useEffect(() => {
        const subscribe = methods.watch((values, info) => {
            const { shareConfig, breakPoint, ...rest } = values
            const validShareConfig = shareConfig as PageShareConfig
            const validBreakPoint = clone(breakPoint) as ContainerBreakPointConfigProtocol
            const page = clone({ ...pageData, ...rest, id, shareConfig: validShareConfig, breakPoint: validBreakPoint, breakPoints })
            if (previewType !== ApplicationPreviewEnum.desktop && info.name?.includes('breakPoint')) {
                page.breakPoint = pageData.breakPoint
                const index = findIndex(item => item?.id === previewType, page.breakPoints)
                if (index >= 0) {
                    page.breakPoints[index] = {
                        ...validBreakPoint,
                        id: page.breakPoints[index]?.id,
                        name: page.breakPoints[index]?.name
                    } as PartialExcludesIdAndName<ContainerBreakPointConfigProtocol>
                }
            }
            updatePage(page)
        })

        return () => {
            subscribe.unsubscribe()
        }
    }, [updatePage, id, methods, breakPoints, previewType, pageData.breakPoint, pageData])

    return (
        <FormProvider {...methods}>
            <Flex grow>
                <BlockConfigureSegmentedControl value={activeTab} onChange={setActiveTab} />
            </Flex>
            {activeTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <AutoLayoutSetting {...designProps.layout} />
                    <Divider />
                    <StyleSetting {...designProps.design} />
                </>
            )}
            {activeTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="基础">
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>数据表</div>
                            <Controller
                                control={methods.control}
                                name="dsId"
                                render={({ field }) => (
                                    <SelectDataSource
                                        withinPortal
                                        size="md"
                                        disabled={disabledWithVersion}
                                        position="bottom-end"
                                        dataSourceList={selectDtaSourceList}
                                        {...field}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>标题</div>
                            <Input
                                maxLength={30}
                                {...methods.register('name', {
                                    onChange: v => {
                                        if (!v) {
                                            return '未命名页面'
                                        }
                                    }
                                })}
                                styles={{
                                    wrapper: { width: 180 }
                                }}
                            />
                        </ListItem4ByecodeUi>
                    </Group>

                    <Divider color="var(--color-gray-200)" />

                    <PageShare />

                    <Divider color="var(--color-gray-200)" />

                    <PageVisibility pageId={id} mode={mode} addMemberWithinPortal />

                    {/* <Divider color="var(--color-gray-200)" /> */}

                    {/* <SeoSearchEngine pageId={id} /> */}
                </>
            )}
        </FormProvider>
    )
}
export default PageSetting
