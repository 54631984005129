import { Box, Divider, Group } from '@byecode/ui'
import type { ButtonBlockAbstract, PageAbstract } from '@lighthouse/core'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import FormSegmentedControl from '../Common/FormSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM, POSITION_ALIGN_CONTROL } from '../constants'
import { ButtonSettingList } from './ButtonSettingList'
import { BUTTON_RADIUS_OPTIONS, BUTTON_SHAPE_OPTIONS, BUTTON_SIZE_OPTIONS } from './constant'

// import { getSelectData } from './help'

interface ButtonSettingsProps {
    allPages: PageAbstract[]
    hideConfigs?: {
        fillWay?: boolean
    }
}

const SCxContainer = styled.div`
    /* padding: 0 16px; */
    height: 100%;
`

const SCxScrollContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
`

export const ButtonSettings: React.FC<ButtonSettingsProps> = ({ allPages, hideConfigs }) => {
    const { control, watch } = useFormContext<ButtonBlockAbstract['config']>()
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const [shape] = watch(['shape'])
    const designProps = getBlockAndPageDesignLimit('buttonGroup')
    return (
        <Box style={{ height: '100%' }}>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />
            <SCxContainer key={BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN}>
                <SizeSetting {...designProps.size} />
                <Divider />
                <Group label="按钮样式">
                    <Controller
                        name="shape"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="风格"
                                size="md"
                                compact
                                data={BUTTON_SHAPE_OPTIONS}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />

                    {shape === 'rectangle' && (
                        <Controller
                            name="radius"
                            control={control}
                            render={({ field }) => (
                                <FormSegmentedControl
                                    label="圆角"
                                    size="md"
                                    compact
                                    data={BUTTON_RADIUS_OPTIONS}
                                    value={field.value}
                                    onChange={v => field.onChange(v)}
                                />
                            )}
                        />
                    )}

                    <Controller
                        name="size"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="尺寸"
                                size="md"
                                compact
                                data={BUTTON_SIZE_OPTIONS}
                                value={field.value}
                                onChange={v => field.onChange(v)}
                            />
                        )}
                    />

                    <Controller
                        name="align"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="对齐"
                                size="md"
                                compact
                                data={POSITION_ALIGN_CONTROL}
                                value={field.value}
                                onChange={v => field.onChange(v)}
                            />
                        )}
                    />
                </Group>
            </SCxContainer>
            <SCxContainer key={BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE}>
                <Group label="按钮组">
                    <SCxScrollContainer>
                        <ButtonSettingList allPages={allPages} hideConfigs={hideConfigs} listKeyName="group" />
                    </SCxScrollContainer>
                </Group>

                <Divider />

                <VisibilitySetting />
            </SCxContainer>
        </Box>
    )
}
