import { FilterMode } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import {
    getAppointField,
    getViewColumns,
    notDataSettingInnerTypes,
    useAtomData,
    USER_DATASOURCE,
    VariableSourceType,
    ViewFieldFilterConfigure} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller } from 'react-hook-form'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { ListItemMenu } from '../../ListItemMenu'
import type { BaseFieldInputSetting } from '../types'

interface PersonConfigProps extends Pick<BaseFieldInputSetting, 'prefix'> {}

const PersonConfig: React.FunctionComponent<PersonConfigProps> = ({ prefix }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const disabledWithVersion = useIsDisabledWithVersion()
    const userDataSource = useDataSource(appId, envId, USER_DATASOURCE)

    const dataSourceList = useDataSourceList(appId, envId)

    const { viewOptions: userViewOptions, schema: userSchema = {} } = userDataSource ?? {}

    const [stackId, pageId, rootPageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || '', s?.rootPageId || ''], [])
    )

    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })

    const userColumns = getViewColumns({
        tableProps: userViewOptions?.tableProps ?? [],
        schema: userSchema
    })

    const userPrimaryField = useMemo(() => {
        if (!userDataSource) {
            return userColumns[0]?.fieldId
        }
        const field = getAppointField(userDataSource, 'ID')
        return field?.id || userColumns[0]?.fieldId
    }, [userColumns, userDataSource])

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    return (
        <Controller
            name={`${prefix}person.filter`}
            render={({ field: { onChange, value } }) => {
                const filter = value
                const filtersLength = filter?.expression?.conditions?.length || 0
                return (
                    <div style={{ padding: '0 8px' }}>
                        <ListItemMenu
                            width="auto"
                            label="数据筛选"
                            compact
                            value={Boolean(filtersLength) && `已设${String(filtersLength)}个条件`}
                            // icon={<IconFont type="Filter" color="var(--color-gray-400)" size={16} />}
                            popupContent={
                                <ViewFieldFilterConfigure
                                    dataSource={userDataSource}
                                    dataSourceList={dataSourceList}
                                    // sourceType={variableSourceType}
                                    // fieldBlocksWithDsId={fieldBlocksWithDsId}
                                    // sourceDataSource={defaultValueDataSourceList}
                                    disabled={disabledWithVersion}
                                    sources={sources}
                                    columns={userColumns}
                                    paramsMode={FilterMode.CUSTOM}
                                    primaryField={userPrimaryField}
                                    fieldMode={FilterMode.CUSTOM}
                                    filter={filter}
                                    noSettingInnerType={notDataSettingInnerTypes}
                                    onFilter={onChange}
                                />
                            }
                        />
                    </div>
                )
            }}
        />
    )
}

export default PersonConfig
