import type { BlockAbstract, PageAbstract } from '@lighthouse/core'
import type { MakeADT } from 'ts-adt/MakeADT'

import { UndoRedoController } from '../core'

export const BLOCK_NODE_CREATE_ACTION = 'BLOCK_NODE_CREATE_ACTION'
export type BlockNodeCreateAction = 'BLOCK_NODE_CREATE_ACTION'
export const BLOCK_NODE_REMOVE_ACTION = 'BLOCK_NODE_REMOVE_ACTION'
export type BlockNodeRemoveAction = 'BLOCK_NODE_REMOVE_ACTION'
export const BLOCK_UPDATE_ACTION = 'BLOCK_UPDATE_ACTION'
export type BlockUpdateAction = 'BLOCK_UPDATE_ACTION'
export const NODE_UPDATE_ACTION = 'NODE_UPDATE_ACTION'
export type NodeUpdateAction = 'NODE_UPDATE_ACTION'

export const PAGE_CONFIG_UPDATE_ACTION = 'PAGE_SETTINGS_UPDATE_ACTION'
export type PageConfigUpdateAction = 'PAGE_SETTINGS_UPDATE_ACTION'

export type UndoRedoAction = BlockNodeCreateAction | BlockNodeRemoveAction | BlockUpdateAction | NodeUpdateAction | PageConfigUpdateAction

export type PageUndoRedoPayload = MakeADT<
    'action',
    {
        [BLOCK_NODE_CREATE_ACTION]: {
            payload: {
                createdBlocks: BlockAbstract[]
                prev: undefined | BlockAbstract[]
                next: undefined | BlockAbstract[]
            }
        }
        [BLOCK_NODE_REMOVE_ACTION]: {
            payload: {
                removedBlocks: BlockAbstract[]
                prev: undefined | BlockAbstract[]
                next: undefined | BlockAbstract[]
            }
        }
        [BLOCK_UPDATE_ACTION]: {
            payload: { prev: BlockAbstract; next: BlockAbstract }
        }
        [NODE_UPDATE_ACTION]: {
            payload: { prev: undefined | BlockAbstract[]; next: undefined | BlockAbstract[] }
        }

        [PAGE_CONFIG_UPDATE_ACTION]: {
            payload: {
                prev: PageAbstract
                next: PageAbstract
            }
        }
    }
>

export const pageUndoRedoController = new UndoRedoController<PageUndoRedoPayload>()
