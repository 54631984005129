import { Divider, Flex, Radio, Text } from '@byecode/ui'
import type { Workflow } from '@lighthouse/shared'
import { WorkflowRunType } from '@lighthouse/shared'
import { useDebounce } from '@lighthouse/tools'
import React, { useCallback, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import * as SC from './styles'

interface FlowConfigureProps {
    workflow: Workflow
    onSetIsChanged: (val: boolean) => void
    onUpdateWorkflow: (val: Partial<Workflow>) => void
}

const options = [
    {
        label: '并行执行（默认）',
        value: WorkflowRunType.PARALLEL,
        description: '同时执行多个任务，执行速度快，适合大多数流程使用。执行速度快，适合大多数流程使用。'
    },
    {
        label: '顺序执行',
        value: WorkflowRunType.SEQUENTIAL,
        description: '节点一个接一个地按顺序执行。每个节点必须等到前一个节点完成之后才能开始，任务之间没有重叠。'
    },
    {
        label: '严格串行执行',
        value: WorkflowRunType.STRICT_SEQUENTIAL,
        description: '与顺序执行类似，区别在于有更高的严格程度，且没有被其他任务打断的可能性。'
    }
]

export const FlowConfigure: React.FC<FlowConfigureProps> = ({ workflow, onSetIsChanged, onUpdateWorkflow }) => {
    const [runType, setRunType] = useState<WorkflowRunType>(workflow.runType)

    const handleChange = useCallback((val: string) => {
        const type = val as WorkflowRunType
        setRunType(type)
    }, [])

    const result = useDebounce(runType, 200)

    const handleReload = useCallback(() => {
        // await srv.updateFlow({ id: workflow.id, runType: result })
        onSetIsChanged(true)
        onUpdateWorkflow({ runType: result, changed: true })
    }, [onSetIsChanged, onUpdateWorkflow, result])

    useUpdateEffect(() => {
        handleReload()
    }, [result])

    return (
        <SC.Container>
            <Flex direction="column" gap={16}>
                <Text weight={600} size={20} color="var(--color-black)">
                    运行方式
                </Text>
                <Text size={14} color="var(--color-gray-400)">
                    设置流程的运行方式，仅支持新增记录触发，自定义动作触发的流程。
                </Text>
            </Flex>
            <Divider my={16} />
            <SC.Group name="workWay" value={runType} onChange={handleChange}>
                {options.map(option => (
                    <Flex key={option.value} alignItems="flex-start" gap={8} onClick={() => handleChange(option.value)}>
                        <Radio size="xs" value={option.value} style={{ paddingTop: '7px' }} onClick={e => e.stopPropagation()} />
                        <Flex direction="column">
                            <Text size={14} lineHeight="32px" weight={600} color="var(--color-black)">
                                {option.label}
                            </Text>
                            <Text size={14} lineHeight="30px" color="var(--color-gray-400)">
                                {option.description}
                            </Text>
                        </Flex>
                    </Flex>
                ))}
            </SC.Group>
        </SC.Container>
    )
}
