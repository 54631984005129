import { Empty, flex, tinyButtons, Toast } from '@byecode/ui'
import type { ErrorCode } from '@lighthouse/shared'
import { type StatusFile, errorCodeMap } from '@lighthouse/shared'
import { getRandomIcon, mergeRefs, useBreakpoint } from '@lighthouse/tools'
import { Grid } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import Uploady from '@rpldy/uploady'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import { uploadInitApplication } from '@/utils/auth'

import { blankTemplateExcelId } from '../constant'
import * as CM from '../styles'
import TemplateItem from '../TemplateItem'
import type { Route, Template, TemplateKindList, TemplateList as TemplateListType } from '../types'
import { TemplateUploadyItem } from './TemplateUploadyItem'

interface TemplateListProps {
    searchWord: string
    pointIndex: number
    list: TemplateListType
    isScrollHandle: boolean

    onScrollHandle: (val: boolean) => void
    onChangePoint?: (index: number) => void
    onCreate: (value: Template) => void
    onLink: (value: Route) => void
    onChangeFileStatus?: (status: StatusFile | null, appId?: string | undefined) => void
}

const itemMarginBottom = 48

const SCXKindListContainer = styled.div``

const SCXContainer = styled.div`
    flex: 1;
    height: 100%;
    padding: 20px 30px;
    padding-bottom: 200px;
    overflow-y: auto;
    ${tinyButtons}

    ${SCXKindListContainer}:not(:first-child) {
        padding-top: ${itemMarginBottom}px;
    }
`

const SCXKindTitle = styled.h3`
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 28px;
    margin-left: -8px;
`
const SCXKindContent = styled(Grid)`
    margin-top: 16px;
    ${flex};
    flex-wrap: wrap;
    gap: 22px;
`

type TemplateKindItemProps = Pick<TemplateListProps, 'searchWord' | 'onCreate' | 'onLink'> &
    TemplateKindList & {
        onChangeSize: (size: { height: number; width: number }) => void
        onChangeFileStatus?: (status: StatusFile | null, appId?: string | undefined) => void
    }

const TemplateKindItem: React.FunctionComponent<TemplateKindItemProps> = ({
    categoryId,
    list,
    name,
    searchWord,
    onChangeSize,
    onLink,
    onCreate,
    onChangeFileStatus
}) => {
    const { ref, breakPoint, width: breakPointWidth } = useBreakpoint()
    const spaceId = useCurrentSpaceID()
    // @TODO 张琪 高度最好不要放在状态里
    const { ref: elementSizeRef, ...rect } = useElementSize()

    useEffect(() => {
        onChangeSize(rect ?? { width: 0, height: 0 })
    }, [onChangeSize, rect])

    const width = useMemo(() => {
        if (breakPointWidth > 1200) {
            return '23%'
        }
        switch (breakPoint) {
            case 'lg': {
                return '31%'
            }
            case 'md': {
                return '48%'
            }
            case 'sm': {
                return '100%'
            }
            case 'xs': {
                return '100%'
            }
            default: {
                return 0
            }
        }
    }, [breakPoint, breakPointWidth])

    return (
        <SCXKindListContainer key={categoryId} ref={mergeRefs([ref, elementSizeRef])}>
            {!searchWord && <SCXKindTitle>{name}</SCXKindTitle>}
            <SCXKindContent>
                {list.map(item => {
                    const isImportExcelToGenerate = item.id === blankTemplateExcelId

                    return isImportExcelToGenerate ? (
                        <div style={{ width }}>
                            <Uploady
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                                {...uploadInitApplication({
                                    spaceId,
                                    icon: getRandomIcon(),
                                    createMode: 3
                                })}
                                isSuccessfulCall={xhr => {
                                    const res = JSON.parse(xhr.response)
                                    // @合一，后续需优化  创建成功或创建失败暂无返回
                                    if (res.code !== '0000000') {
                                        Toast.error(errorCodeMap[res.code as ErrorCode])
                                        onChangeFileStatus?.({ status: 'error', percent: 100 })
                                        return false
                                    }
                                    onChangeFileStatus?.(
                                        {
                                            status: 'success',
                                            percent: 100
                                        },
                                        res.content
                                    )
                                    return res.success
                                }}
                            >
                                <TemplateUploadyItem item={item} onChangeFileStatus={onChangeFileStatus} />
                            </Uploady>
                        </div>
                    ) : (
                        <TemplateItem
                            onClick={() => (item.isBlankTemplate ? onCreate(item) : onLink({ id: item.id, name: item.name }))}
                            key={item.id}
                            data={item}
                            width={width}
                        />
                    )
                })}
            </SCXKindContent>
        </SCXKindListContainer>
    )
}

const TemplateList: React.FC<TemplateListProps> = ({
    searchWord,
    pointIndex,
    list: kindList,
    isScrollHandle,
    onChangePoint,
    onScrollHandle,
    onCreate,
    onChangeFileStatus,
    onLink
}) => {
    const [kindHeiList, setKindHeiList] = useImmer<number[]>([])
    const scrollableRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const scrollTop = kindHeiList.slice(0, pointIndex).reduce((preVal, height) => preVal + height, 0)
        if (scrollableRef.current && isScrollHandle) {
            scrollableRef.current.scrollTo({ top: scrollTop + 20 })
            onScrollHandle(false)
        }
    }, [isScrollHandle, kindHeiList, onScrollHandle, pointIndex])

    const isCategoryListEmpty = useMemo(() => {
        return kindList.length <= 0
    }, [kindList.length])

    const searchKindList = useMemo(() => {
        return searchWord.length > 0
        ? kindList
        .filter(kind => kind.categoryId !== 'create')
        .reduce<TemplateListType>(([preTemplate], { list, ...rest }) => {
            const newList = list.filter(template =>
                template?.name?.toLocaleLowerCase().includes(searchWord?.toLocaleLowerCase()?.trim())
            )
            const finallyList = [...(preTemplate?.list ?? []), ...newList].reduce<Template[]>((p, c) => {
                if (p.some(v => v.id === c.id)) {
                    return p
                }
                return [...p, c]
            }, [])
            return [
                {
                    ...preTemplate,
                    list: finallyList
                }
            ]
        }, [])
        : kindList
    }, [kindList, searchWord])

    const handleScroll = useCallback(
        (ev: React.UIEvent<HTMLDivElement>) => {
            if (isScrollHandle) {
                return
            }
            onScrollHandle(false)
            let preVal = 0
            for (const [index, height] of kindHeiList.entries()) {
                preVal += height
                if (ev.currentTarget.scrollTop <= preVal) {
                    onChangePoint?.(index)
                    return
                }
            }
        },
        [isScrollHandle, kindHeiList, onChangePoint, onScrollHandle]
    )

    const handleChangeSize = useCallback(
        (index: number, size: { height: number; width: number }) => {
            setKindHeiList(kindHeiList => {
                kindHeiList[index] = size.height + (index === 0 ? 0 : itemMarginBottom)
            })
        },
        [setKindHeiList]
    )

    return (
        <SCXContainer ref={scrollableRef} onScroll={handleScroll}>
            {isCategoryListEmpty && <Empty />}
            {searchWord && (
                <>
                    <CM.Text color="var(--color-theme-6)" size={20} style={{ fontWeight: 550 }}>
                        {searchWord}
                    </CM.Text>
                    <CM.Text size={20} style={{ fontWeight: 550 }}>
                        <span style={{ padding: ' 0 16px' }}>的搜索结果</span>
                        <span>{searchKindList[0]?.list?.length}</span>
                    </CM.Text>
                </>
            )}
            {searchKindList.map((item, index) => (
                <TemplateKindItem
                    key={item.categoryId}
                    {...item}
                    searchWord={searchWord}
                    onLink={onLink}
                    onCreate={onCreate}
                    onChangeSize={size => handleChangeSize(index, size)}
                    onChangeFileStatus={onChangeFileStatus}
                />
            ))}
        </SCXContainer>
    )
}

export default TemplateList
