import { IconFont, Input, itemBackgroundTransitionOnClick, Popover, Toast } from '@byecode/ui'
import type { RectSize, RectSizeUnit } from '@lighthouse/core'
import { findBlockById, findParentBlockById, getBlockChildren, useAtomData } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import { getDefaultStore } from 'jotai'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { usePreviewType } from '@/hooks/useApplication'
import { transform2Integer, transform2Number } from '@/utils/number'

import { SizeIcon } from './SizeIcon'
import { type SizeInputType, convertSize, getSizeBaseType } from './utils'

type SizeInputValue = {
    size?: RectSize
    unit?: RectSizeUnit
}

interface SizeInputProps {
    type: SizeInputType
    value?: SizeInputValue
    onChange: (value: SizeInputValue) => void
    disableAutoOption?: boolean
    disable?: boolean
}

type RectSizeOption = RectSize | RectSizeUnit

const SCxRectSizeInputWrapper = styled.div`
    display: flex;
    width: 180px;
`

const SCxInput = styled(Input)``

const SCxDropdownButton = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-100);
    border-left: 1px solid var(--color-gray-200);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;

    ${itemBackgroundTransitionOnClick}

    &:hover {
        background-color: var(--color-gray-200);
    }
`

const SCxListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const rectSizeValue: Record<string, 'auto' | 'fill'> = {
    适应内容: 'auto',
    撑满容器: 'fill'
}

export const RectSizeInput: React.FC<SizeInputProps> = ({ type, value, onChange, disableAutoOption, disable }) => {
    const previewType = usePreviewType()
    const store = getDefaultStore()
    const [moveOpen, setMoveOpen] = useState(false)
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const init = useMemo(() => value ?? { size: undefined, unit: undefined }, [value])
    const [v, setV] = useImmer<SizeInputValue>(init)

    const [pageId, blockId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.state.selectedNodes?.[0] || ''], [])
    )

    const lowerCaseType = type.toLowerCase()
    const inputIconType = useMemo(() => {
        if (lowerCaseType.includes('width')) {
            return 'Width'
        }
        if (lowerCaseType.includes('height')) {
            return 'Height'
        }
    }, [lowerCaseType])

    useUpdateEffect(() => {
        if (init?.size === v.size && init?.unit === v.unit) {
            return
        }
        setV(init)
    }, [value])

    const label = useMemo(() => {
        if (inputIconType === 'Width') {
            return '宽'
        }
        return '高'
    }, [inputIconType])

    const dropdownOptions = useMemo(() => {
        return [
            { label: `固定${label}度`, value: 'px' },
            { label: `百分比${label}`, value: '%' },
            { label: '相对视窗宽度', value: 'vw' },
            { label: '相对视窗高度', value: 'vh' },
            { label: '适应内容', value: 'auto' },
            { label: '撑满容器', value: 'fill' }
        ].filter(({ value }) => {
            if (disableAutoOption && value === 'auto') {
                return false
            }
            const t = getSizeBaseType(type)
            if (t === 'width') {
                return value !== 'vh'
            }
            if (t === 'height') {
                return value !== 'vw'
            }
            return true
        })
    }, [disableAutoOption, label, type])
    const isReadOnly = v?.size === 'auto' || v?.size === 'fill'

    const handleMoveChange = useCallback(
        (v: number) => {
            const num = transform2Integer(v)
            const val: SizeInputValue = {
                size: num,
                unit: 'px'
            }
            setV(val)
            if (isDeepEqual(value, val)) {
                return
            }
            onChange(val)
        },
        [onChange, setV, value]
    )

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const val = ev.target.value
            const size = val ? transform2Number(val) : 0
            setV(draft => {
                draft.size = size as RectSize
            })
        },
        [setV]
    )

    const handleItemClick = useCallback(
        (sizeOrUnit: RectSizeOption) => {
            const pageBlocks = store.get(pageBlocksAtom(pageId))
            const t = lowerCaseType === 'width' ? 'width' : 'height'
            const label = lowerCaseType === 'width' ? '宽' : '高'
            switch (sizeOrUnit) {
                case 'auto': {
                    const block = findBlockById(blockId, pageBlocks)
                    const val = { size: sizeOrUnit }
                    if (!block) {
                        setV(val)
                        if (isDeepEqual(value, val)) {
                            return
                        }
                        onChange(val)
                        return
                    }
                    const children = getBlockChildren(block)
                    if (children) {
                        const isHasFill = children.some(child => child.config.breakPoint.size[t].size === 'fill')
                        if (isHasFill) {
                            Toast.error(`当存在子组件${label}为撑满容器时，无法设置为适应内容`)
                            return
                        }
                    }
                    setV(val)
                    if (isDeepEqual(value, val)) {
                        return
                    }
                    onChange(val)

                    break
                }
                case 'fill': {
                    const parentBlock = findParentBlockById(blockId, pageBlocks)
                    const val = { size: sizeOrUnit }
                    if (!parentBlock) {
                        if (t === 'height') {
                            Toast.error(`当页面${label}为适应内容时，无法设置为撑满容器`)
                            return
                        }
                        setV(val)
                        if (isDeepEqual(value, val)) {
                            return
                        }
                        onChange(val)
                        return
                    }
                    const isHasAuto = parentBlock.config.breakPoint.size[t].size === 'auto'
                    if (isHasAuto) {
                        Toast.error(`当父组件${label}为适应内容时，无法设置为撑满容器`)
                        return
                    }
                    setV(val)
                    if (isDeepEqual(value, val)) {
                        return
                    }
                    onChange(val)

                    break
                }
                case 'px':
                case '%':
                case 'vw':
                case 'vh': {
                    const size = convertSize(blockId, type, sizeOrUnit, previewType) as RectSize
                    // onChange({ size, unit: sizeOrUnit })
                    const val = {
                        size,
                        unit: sizeOrUnit
                    }
                    setV(val)
                    if (isDeepEqual(value, val)) {
                        return
                    }
                    onChange(val)

                    break
                }
                default: {
                    break
                }

                // No default
            }
            setDropdownOpened(false)
        },
        [blockId, lowerCaseType, onChange, pageId, previewType, setV, store, type, value]
    )

    const handleBlur = useCallback(
        (ev: React.FocusEvent<HTMLInputElement>) => {
            const v = ev.target.value
            if (v === '适应内容' || v === '撑满容器') {
                const size = rectSizeValue[v]
                const val = { size }
                if (isDeepEqual(val, value)) {
                    return
                }
                onChange(val)
                return
            }
            const number = transform2Number(Number(v).toFixed(2))
            const validNumber = Number.isNaN(number) ? undefined : Number(number.toFixed(2))
            const val = { size: validNumber, unit: value?.unit }
            if (isDeepEqual(val, value)) {
                return
            }
            onChange(val)
        },
        [onChange, value]
    )

    const resizeStyle = useMemo(
        () =>
            moveOpen
                ? {
                      borderColor: 'var(--color-main)',
                      backgroundColor: 'var(--color-gray-200)'
                  }
                : {},
        [moveOpen]
    )

    return (
        <SCxRectSizeInputWrapper>
            <SCxInput
                focusOutLine={!isReadOnly}
                readOnly={isReadOnly}
                value={isReadOnly ? (v.size === 'auto' ? '适应内容' : '撑满容器') : v?.size}
                prefix={
                    inputIconType && (
                        <SizeIcon
                            disable={disable}
                            type={inputIconType}
                            blockStyleSize={{
                                blockId,
                                sizeInputType: type
                            }}
                            onChangeMove={setMoveOpen}
                            onChange={handleMoveChange}
                        />
                    )
                }
                suffix={value?.unit}
                radius={0}
                disabled={disable}
                type={isReadOnly ? 'text' : 'number'}
                onFocus={e => e.currentTarget.select()}
                styles={{
                    wrapper: {
                        borderTopRightRadius: '0!important',
                        borderBottomRightRadius: '0!important',
                        ...resizeStyle
                    },
                    suffix: { fontSize: 12 }
                }}
                onChange={handleChange}
                onKeyDownCapture={ev => {
                    if (ev.key === 'Enter') {
                        ev.currentTarget.blur()
                    }
                }}
                onBlur={handleBlur}
            />
            <Popover opened={dropdownOpened} width={180} position="bottom-end" disabled={disable} onChange={setDropdownOpened}>
                <Popover.Target>
                    <SCxDropdownButton>
                        <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" />
                    </SCxDropdownButton>
                </Popover.Target>
                <Popover.Dropdown>
                    {dropdownOptions.map(option => (
                        <SCxListItem key={option.value} onClick={() => handleItemClick(option.value as RectSizeOption)}>
                            {option.label}
                            {(value?.unit === option.value || value?.size === option.value) && (
                                <IconFont type="Tick" color="var(--color-main)" />
                            )}
                        </SCxListItem>
                    ))}
                </Popover.Dropdown>
            </Popover>
        </SCxRectSizeInputWrapper>
    )
}
