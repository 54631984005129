import { IconFont, Tooltip } from '@byecode/ui'
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react'
import { getBlockName } from '@lighthouse/block'
import { DIRECTION } from '@lighthouse/core'
import {
    DOM_DATA_NODE_NAME,
    findParentBlockById,
    getCurrentBlockChildren,
    hasChildrenBlock,
    PAGE_CONTAINER_HOST_ID,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { findParentByClassName, findParentById, mergeRefs, stopPropagation } from '@lighthouse/tools'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { createPortal, flushSync } from 'react-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import { dataDrawerStateAtom } from '@/atoms/application/state'
import { blocksAtom, pageAtomFamily, pageBlocksAtom, pageStackAtomFamily, selectedBlockAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useNodeToolbarActions } from '@/hooks/layoutEngine/useNodeToolbarActions'

function getBoundaryByClass(currentElement: null | HTMLElement, className: string) {
    // 如果在弹窗中
    const boundary = findParentByClassName(currentElement, 'byecode-Modal-modal')
    if (boundary) {
        return boundary
    }

    return findParentByClassName(currentElement, className)
}
function getBoundaryById(currentElement: null | HTMLElement, id: string) {
    // 如果在弹窗中
    const boundary = findParentByClassName(currentElement, 'byecode-Modal-modal')
    if (boundary) {
        return boundary
    }

    return findParentById(currentElement, id)
}

const Container = styled.div({
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    height: '28px',
    borderRadius: '4px',
    color: '#fff',
    background: 'var(--color-purple-900)',
    boxShadow: '0px 4px 12px 0px rgba(16, 24, 40, 0.10)',
    cursor: 'auto',
    zIndex: '200'
})

const Sep = styled.div({
    alignSelf: 'stretch',
    width: 1,
    margin: '6px 2px',
    background: 'rgba(255,255,255, 0.3)'
})

const IconButton = styled.div({
    touchAction: 'none',
    userSelect: 'none',
    padding: 2,
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    '&[data-enable-hover]': {
        color: '#E4E7EC'
    },
    '&[data-enable-hover]:hover': {
        borderRadius: '5px',
        color: '#fff',
        background: 'rgba(255, 255, 255, 0.25)'
    }
})

const Label = styled.span({
    marginLeft: 2,
    fontSize: 12,
    lineHeight: '20px'
})

interface BlockToolbarProps extends React.ComponentPropsWithoutRef<'div'> {
    selectedId: string
    onChangeSelectedId: (id: string[]) => void
    onDragBar?: (e: React.MouseEvent) => void
}

export const BlockToolbar = React.forwardRef<HTMLDivElement, BlockToolbarProps>((props, ref) => {
    const { selectedId, onChangeSelectedId, onDragBar, ...rest } = props

    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()

    const title = useAtomData(
        selectedBlockAtomFamily({ rootPageId, pageId, stackId }),
        useCallback(s => (s ? s.title || getBlockName(s) : '未知节点'), [])
    )

    const [reference, setReference] = useState(document.querySelector<HTMLElement>(`[${DOM_DATA_NODE_NAME}="${selectedId}"]`))

    useEffect(() => {
        let reference = document.querySelector(`[${DOM_DATA_NODE_NAME}="${selectedId}"]`)
        const observer = new MutationObserver(list => {
            list.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(addedNode => {
                        if (addedNode instanceof HTMLElement) {
                            const newReference = document.querySelector<HTMLElement>(`[${DOM_DATA_NODE_NAME}="${selectedId}"]`)
                            if (newReference && addedNode.contains(newReference)) {
                                reference = newReference
                                setReference(newReference)
                                // flushSync(() => {
                                // })
                            }
                        }
                    })

                    mutation.removedNodes.forEach(removedNode => {
                        if (removedNode instanceof HTMLElement && removedNode.contains(reference)) {
                            setReference(null)
                            // flushSync(() => {
                            // })
                        }
                    })
                }
            })
        })

        const target = document.querySelector(`#${PAGE_CONTAINER_HOST_ID}`)
        if (!target) {
            return
        }
        observer.observe(target, { childList: true, subtree: true })

        setTimeout(() => {
            flushSync(() => {
                setReference(document.querySelector<HTMLElement>(`[${DOM_DATA_NODE_NAME}="${selectedId}"]`))
            })
        }, 0);

        return () => {
            observer.disconnect()
        }
    }, [selectedId])

    const actions = useNodeToolbarActions({ stackId, rootPageId, pageId })
    const { run: onVisibleDataDrawer } = useAtomAction(dataDrawerStateAtom)

    const { floatingStyles, refs } = useFloating<HTMLDivElement>({
        strategy: 'fixed',
        placement: 'top-start',
        elements: { reference },
        middleware: [
            offset(4),
            shift({
                boundary: getBoundaryById(reference, PAGE_CONTAINER_HOST_ID) || undefined,
                crossAxis: true
            })
        ],
        whileElementsMounted: autoUpdate
    })

    const onSelectParent = useAtomCallback((get, set) => {
        const blocks = get(blocksAtom)[pageId] || []

        const parent = findParentBlockById(selectedId, blocks)
        const ids = parent ? [parent.id] : []
        onChangeSelectedId?.(ids)
    })

    useHotkeys('shift+enter', onSelectParent, [onSelectParent])

    const onSwapKeydown = useAtomCallback<void, Parameters<Parameters<typeof useHotkeys>[1]>>((get, set, _, hotkeysEvent) => {
        const blocks = get(pageBlocksAtom(pageId))
        const page = get(pageAtomFamily(pageId))
        const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

        let direction = page?.breakPoint.layout?.align?.direction

        const parent = findParentBlockById(selectedId, blocks)
        if (parent && hasChildrenBlock(parent)) {
            direction = parent.config.breakPoint.layout?.align?.direction
        }

        const children = parent ? getCurrentBlockChildren(parent, blockRuntimeState) || [] : blocks

        switch (hotkeysEvent.key) {
            case 'up': {
                if (direction === DIRECTION.horizontal) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedId)
                if (currentIndex === 0) {
                    return
                }
                const prev = children[currentIndex - 1].id
                actions.onSwap(selectedId, prev)
                break
            }
            case 'down': {
                if (direction === DIRECTION.horizontal) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedId)
                if (currentIndex === children.length - 1) {
                    return
                }
                const next = children[currentIndex + 1].id
                actions.onSwap(selectedId, next)
                break
            }
            case 'left': {
                if (!direction || direction === DIRECTION.vertical) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedId)
                if (currentIndex === 0) {
                    return
                }
                const prev = children[currentIndex - 1].id
                actions.onSwap(selectedId, prev)
                break
            }
            case 'right': {
                if (!direction || direction === DIRECTION.vertical) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedId)
                if (currentIndex === children.length - 1) {
                    return
                }
                const next = children[currentIndex + 1].id
                actions.onSwap(selectedId, next)
                break
            }

            default: {
                break
            }
        }
    })

    useHotkeys('up, down, left, right', onSwapKeydown, [onSwapKeydown])

    if (!reference) {
        return null
    }

    const defaultDom = (
        <Container ref={mergeRefs([ref, refs.setFloating])} style={floatingStyles} onMouseDown={stopPropagation} {...rest}>
            <Tooltip title="移动">
                <IconButton onMouseDownCapture={onDragBar} style={{ cursor: 'grab' }}>
                    <IconFont type="DotsSix" />
                    <Label>{title}</Label>
                </IconButton>
            </Tooltip>
            <Sep />

            <Tooltip title="选择上一级">
                <IconButton data-enable-hover onClickCapture={onSelectParent}>
                    <IconFont type="SelectHigher" />
                </IconButton>
            </Tooltip>

            <Tooltip title="组件绑定的数据">
                <IconButton data-enable-hover onClickCapture={() => onVisibleDataDrawer(true)}>
                    <IconFont fill="var(--color-white)" type="SearchData2" />
                </IconButton>
            </Tooltip>

            <Tooltip title="复制">
                <IconButton data-enable-hover onClickCapture={() => actions.onClone(selectedId)}>
                    <IconFont type="Duplicate" />
                </IconButton>
            </Tooltip>

            <Tooltip title="删除">
                <IconButton
                    data-enable-hover
                    onClickCapture={() => {
                        actions.onRemove([selectedId])
                    }}
                >
                    <IconFont type="Trash" />
                </IconButton>
            </Tooltip>
        </Container>
    )

    const target = getBoundaryByClass(reference, 'applicationContainer')

    if (target) {
        return createPortal(defaultDom, target)
    }

    return defaultDom
})
