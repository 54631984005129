import type { PageAbstract } from '@lighthouse/core'
import type { OpenRecordEditPageActionConfig } from '@lighthouse/shared'
import { type ActionFlowNode, OpenDetailPageSelectedRecordType, resolveFilter } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleOpenRecordEditPage = async (node: ActionFlowNode<OpenRecordEditPageActionConfig>, extraParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { actionEvents, clickTriggerNodeParams } = extraParams
    const pageList = extraParams.pageList as PageAbstract[]
    const { recordEditPageId, pageOpenType, dataSourceId, filter, selectedRecordType, upstreamRecordNodeId } =
        config as OpenRecordEditPageActionConfig
    const { openPage, refreshPage } = actionEvents

    const { record, currentEnvId, currentAppId } = clickTriggerNodeParams

    if (!recordEditPageId || !actionEvents) {
        return extraParams
    }
    const page = pageList.find(item => item.id === recordEditPageId)
    if (!page || !page.dsId){
        return
    }

    let resolvedRecord

    switch (selectedRecordType) {
        case OpenDetailPageSelectedRecordType.CURRENT_RECORD: {
            resolvedRecord = record
            if (page.dsId !== resolvedRecord.dsId) {
                return
            }
            break
        }

        case OpenDetailPageSelectedRecordType.MATCHED_RECORD: {
            if (page.dsId !== dataSourceId) {
                return
            }
            // if (filter?.expression?.conditions?.length) {
            const resolvedFilter = resolveFilter({ filter, extraParams })

            // if (resolvedFilter?.expression?.conditions?.length) {
            const data = await srv.getDs({
                appId: record?.appId || currentAppId,
                envId: record?.envId || currentEnvId,
                dsId: dataSourceId ?? '',
                pagination: {
                    currentPage: 1,
                    pageSize: 1
                },
                filter: resolvedFilter
            })
            if (data) {
                const { records } = data
                const resRecord = records[0]
                if (resRecord) {
                    resolvedRecord = resRecord
                }
            }
            // }
            // }
            break
        }
        case OpenDetailPageSelectedRecordType.UPSTREAM_RECORD: {
            if (upstreamRecordNodeId) {
                const upstreamRecord = extraParams[upstreamRecordNodeId]?.record
                if (upstreamRecord) {
                    resolvedRecord = upstreamRecord
                }
            }
            break
        }
        default: {
            break
        }
    }

    openPage({
        pageId: recordEditPageId,
        openType: pageOpenType,
        appId: resolvedRecord?.appId,
        dsId: resolvedRecord?.dsId,
        recordId: resolvedRecord?.id,
        onSaveCallback: () => {
            refreshPage()
        }
    })

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        success: true
    })
}
