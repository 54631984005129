import { Flex, RadioGroup } from "@byecode/ui";
import styled from "styled-components";

export const Container = styled.div`
    margin: 16px;
    border: 1px solid var(--color-gray-200);
    border-radius: 16px;
    padding: 20px 24px;

`

export const Group = styled(RadioGroup)`
    display: flex;
    flex-direction: column;
    gap: 8px;
`
