import type { QrBarcodeBlockConfig } from '@lighthouse/core'
import { QrBarcodeEnum } from '@lighthouse/core'

/**
 * 生成图片block的配置
 *
 * @returns {QrBarcodeBlockConfig}
 */
export const generateQrBarcodeBlock = (): QrBarcodeBlockConfig => {
    return {
        codeType: QrBarcodeEnum.QR_CODE,
        content: { value: undefined },
        barWidth: 1,
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'visible'
            },
            layout: {}
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
