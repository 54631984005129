import type { ApplicationAbstract, RectSizeUnit } from "@lighthouse/core"
import type { ApplicationPreviewEnum } from "@lighthouse/shared"

export type SizeInputType = 'width' | 'height' | 'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight' | 'gap'

export const getSizeBaseType = (type: SizeInputType) => {
    const lowerCaseType = type.toLowerCase()
    return lowerCaseType.includes('width') ? 'width' : 'height'
}


const getApplicationScale = (previewType: ApplicationPreviewEnum) => {
    if(previewType !== 'mobile'){
        return 1
    }
    const applicationWrapper = document.querySelector('.applicationWrapper')
    if(!applicationWrapper){
        return 1
    }

    const { height } = applicationWrapper.getBoundingClientRect()
    if (height < 876) {
        return height / 876
    }
    return 1
}


export const convertSize = (blockId: string, type: SizeInputType, toUnit: RectSizeUnit, previewType: ApplicationPreviewEnum) => {
    const node = document.querySelector(`[data-node-id="${blockId}"]`)
    const parentNode = node?.parentNode as HTMLElement
    const parentNodeRect = parentNode?.getBoundingClientRect()

    const nodeRect = node?.getBoundingClientRect()
    const scale = getApplicationScale(previewType)
    const t = getSizeBaseType(type)
    const nodeSize = nodeRect?.[t] ? nodeRect[t] / scale : undefined
    if (toUnit === 'px') {
        return nodeSize ? Math.floor(nodeSize) : 0
    }
    if (toUnit === '%') {
        const parentNodeSize = parentNodeRect?.[t] ? parentNodeRect[t] / scale : undefined
        return nodeSize && parentNodeSize ? Math.floor((nodeSize / parentNodeSize) * 100) : 0
    }
    if (toUnit === 'vw') {
        return nodeSize ? Math.floor((nodeSize / window.innerWidth) * 100) : 100
    }
    if (toUnit === 'vh') {
        return nodeSize ? Math.floor((nodeSize / window.innerHeight) * 100) : 100
    }
}
