import { Popover } from '@byecode/ui'
import { type ContainerBlockConfig, type Field, type VariableADTvalue, BACKGROUND_TYPE } from '@lighthouse/core'
import {
    FilledInput,
    FillPickerPopoverDropdown,
    getPageDataOptions,
    getUserDatasourceOptions,
    getViewOptions,
    ListItem4ByecodeUi,
    useAtomData,
    USER_DATASOURCE
} from '@lighthouse/shared'
import React, { useCallback, useId, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { InheritLabel } from '@/components/InheritLabel'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { uploadManagerInAppParams } from '@/utils/auth'

import { combinePath } from './utils'

const filterField = (field: Field) => field.type === 'file'
const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v

interface BorderFieldProps {
    prefixPath?: string
    customViewDsId?: string
    breakKeys?: string[]
    onSetBreakKey?: (name: string) => void
}

export const BackgroundField: React.FC<BorderFieldProps> = ({ prefixPath, customViewDsId, breakKeys, onSetBreakKey }) => {
    const { control } = useFormContext<ContainerBlockConfig>()

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const name = 'breakPoint.design.background'
    const BackgroundFieldName = combinePath(prefixPath, name)
    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { recordId: parentRecordId } = prev
    const { recordId, page, variableSourceType, datasource } = curr
    const renderLabel = useVariableValueRender(parentRecordId, recordId)

    const uploadId = useId()
    const uploadProps = useMemo(
        () => ({
            accept: 'image/*',
            multiple: false,
            uploadOptions: {
                info: { id: uploadId, label: page?.name ?? '', groupId: pageId },
                options: {
                    ...uploadManagerInAppParams()
                }
            }
        }),
        [page?.name, pageId, uploadId]
    )

    const fileOptions = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])

    const userImgOption = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: filterField
            }),
        [dataSourceList]
    )

    const { customViewOption: customViewParentOption } = useVariableCustomViewOption(filterField)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const customViewSelfDataSource = useAtomData(dataSourceAtomFamily({ appId, envId, dsId: customViewDsId || '' }))

    const customViewSelfOption = useMemo(
        () =>
            customViewSelfDataSource &&
            getViewOptions({ dataSource: customViewSelfDataSource, viewType: 'custom', validateFieldType: filterField }),
        [customViewSelfDataSource]
    )
    const customViewOption = customViewSelfOption ?? customViewParentOption

    const colorSystemMethods = useColorSystemAction()

    const getImageVariableUrl = useCallback((value: VariableADTvalue) => renderLabel(value, { useFileUrl: true }), [renderLabel])

    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
            <InheritLabel label="背景" name={name} />
            <Controller
                control={control}
                name={BackgroundFieldName}
                render={({ field }) => (
                    <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                        <Popover.Target>
                            <FilledInput
                                value={field.value}
                                getImageVariableUrl={getImageVariableUrl}
                                options={fileOptions}
                                userOption={userImgOption}
                                viewOption={customViewOption}
                                onClear={() => {
                                    field.onChange({ type: BACKGROUND_TYPE.color })
                                    onSetBreakKey?.(BackgroundFieldName)
                                }}
                                style={{ width: 180 }}
                            />
                        </Popover.Target>
                        {/* <PopoverDropdownWithClose title="背景">
                            <FillPicker
                                value={field.value}
                                onChange={field.onChange}
                                enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                                options={fileOptions}
                                uploadProps={uploadProps}
                                userOption={userImgOption}
                            />
                        </PopoverDropdownWithClose> */}
                        <FillPickerPopoverDropdown
                            title="背景"
                            value={field.value}
                            onChange={val => {
                                field.onChange(val)
                                onSetBreakKey?.(BackgroundFieldName)
                            }}
                            enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient, BACKGROUND_TYPE.image]}
                            options={fileOptions}
                            uploadProps={uploadProps}
                            userOption={userImgOption}
                            viewOption={customViewOption}
                            {...colorSystemMethods}
                        />
                    </Popover>
                )}
            />
        </ListItem4ByecodeUi>
    )
}
