import type { BlockAbstract, ChartBlockAbstract, DataSourceAbstract, ViewOptions } from '@lighthouse/core'
import { BlockType, ChartType } from '@lighthouse/core'
import { chartTypeLevel, findBlockById, getViewColumns, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useMemo } from 'react'
import { debounce } from 'throttle-debounce'

import { blocksAtom, lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { BlockSettings } from '@/components/BlockSettings'
import { generateCalendarConfig, generateGalleryConfig } from '@/constants/Block/generate/view'
import { getIndicatorOption } from '@/constants/Block/sharedStyle'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useBlockActions, useViewRecords } from '@/hooks/useBlock'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useDefaultPageList } from '@/hooks/usePage'
import * as srv from '@/services'
import { BLOCK_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import {
    cleanBarConfig,
    cleanCalendarConfig,
    cleanGalleryConfig,
    cleanIndicatorConfig,
    cleanKanbanConfig,
    cleanPieConfig,
    cleanStriationConfig
} from './constants'

const getInitChartBlockConfigurator = function (block: ChartBlockAbstract, dataSource: DataSourceAbstract): ChartBlockAbstract {
    const { schema, appId, id: dsId } = dataSource
    const textField = Object.values(schema).find(f => f.type === 'text' || (f.type === 'aggregation' && f.innerType === 'TEXT'))
    const textFieldId = textField?.id || 'ID'
    const numberField = Object.values(schema).find(f => f.type === 'number' || (f.type === 'aggregation' && f.innerType === 'NUMBER'))
    const numberFieldId = numberField?.id
    const calcType = numberField ? 'max' : 'count'
    switch (block.config.chartType) {
        case 'bar':
        case 'striation': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType,
                                  chartType: 'bar'
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'line': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType,
                                  chartType: 'line',
                                  showArea: false,
                                  showSymbol: true,
                                  lineType: 'smooth'
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'funnel':
        case 'pie': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'composite': {
            const secondNumberField = Object.values(schema).find(f => f.type === 'number' && f.id !== numberFieldId)
            const secondCalcType = secondNumberField ? 'sum' : calcType
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType,
                                  chartType: 'bar'
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    },
                    showSecondaryAxis: !!secondNumberField,
                    secondaryAxis: secondNumberField
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: secondNumberField?.id,
                                  calcType: secondCalcType,
                                  chartType: 'line',
                                  showArea: false,
                                  showSymbol: true,
                                  lineType: 'straight'
                              }
                          ]
                        : []
                }
            }
        }
        case 'indicator': {
            return {
                ...block,
                config: {
                    ...block.config,
                    ...getIndicatorOption(),
                    appId,
                    pointer: dsId,
                    fieldId: numberFieldId,
                    calcType
                }
            }
        }
        default: {
            return block
        }
    }
    // const dimension = dataSource.schema
}

export interface BlockSettingsControllerProps {
    loading?: boolean
    dataSourceID?: string
}

const BlockSettingsController = React.forwardRef<HTMLDivElement, BlockSettingsControllerProps>(
    ({ loading, dataSourceID: parentDsId }, ref) => {
        const appId = useCurrentAppID()
        const envId = useCurrentEnvId()
        const { pageId, stackId, selectedNode } = useAtomData(
            lastPageOfStackAtom,
            useCallback(
                s => ({
                    pageId: s?.pageId ?? '',
                    stackId: s?.stackId ?? '',
                    selectedNode: s?.state.selectedNodes && s.state.selectedNodes.length === 1 ? s.state.selectedNodes[0] : undefined
                }),
                []
            )
        )

        const selectedBlock = useAtomData(
            blocksAtom,
            useCallback(s => (selectedNode ? findBlockById(selectedNode, s[pageId] || []) : undefined), [pageId, selectedNode])
        )

        const { onUpdateBlock } = useBlockActions(pageId, stackId)

        const pageDsId = useAtomData(
            pageAtomFamily(pageId),
            useCallback(page => page?.dsId, [])
        )

        const pointer = useMemo(() => {
            if (!selectedBlock) {
                return ''
            }
            switch (selectedBlock.type) {
                case 'view': {
                    return selectedBlock.config.pointer || ''
                }
                case 'chart': {
                    if (selectedBlock.config.chartType === ChartType.indicator) {
                        return ''
                    }
                    return selectedBlock.config.pointer
                }
                case 'fieldGroup':
                case 'field': {
                    // if (selectModule.type === 'formModule') {
                    //     return selectModule?.config?.form.pointer
                    // }
                    return pageDsId ?? ''
                }
                default: {
                    return parentDsId || ''
                }
            }
        }, [selectedBlock, pageDsId, parentDsId])

        const dataSourceList = useDataSourceList(appId, envId)

        const dataSource = useDataSource(appId, envId, pointer)
        // const allDataSource = useDataSourcePool()

        const allPages = useDefaultPageList()
        const records = useViewRecords(selectedBlock?.id ?? '')

        const handleFetchAllViews = useCallback(() => srv.getAllViews(), [])

        const debounceAdd = useMemo(
            () =>
                debounce(
                    500,
                    (origin: BlockAbstract, current: BlockAbstract) => {
                        pageUndoRedoController.add({
                            action: BLOCK_UPDATE_ACTION,
                            payload: {
                                prev: origin,
                                next: current
                            }
                        })
                    },
                    { atBegin: true }
                ),
            []
        )

        const onChange = useAtomCallback<void, [BlockAbstract]>((get, set, value) => {
            if (!selectedBlock) {
                return
            }

            // if(isEqual(value.config.breakPoint, selectedBlock.config.breakPoint)){
            //     if(previewType !== ApplicationPreviewEnum.desktop){

            //         Object.assign(value.config, {
            //             viewFieldSettings,
            //             quickFilter: { mode: 'normal', rules: [] },
            //             groupByFieldId: undefined,
            //             groupConfig: undefined,
            //             linkFilterController: {
            //                 expression: {
            //                     where: 'AND',
            //                     conditions: []
            //                 }
            //             }
            //         })
            //     }

            // }

            if (value.type === BlockType.view && selectedBlock.type === BlockType.view) {
                // 切换视图数据源时初始化配置
                if (value.config.pointer !== selectedBlock.config.pointer) {
                    const newDatasource = dataSourceList.find(item => item.id === value.config.pointer)
                    if (!newDatasource) {
                        return
                    }

                    const { viewOptions, schema } = newDatasource

                    if (value.config.viewType === 'gallery') {
                        Object.assign(value.config, generateGalleryConfig(newDatasource))
                    }

                    if (value.config.viewType === 'calendar') {
                        Object.assign(value.config, generateCalendarConfig(newDatasource))
                    }

                    const viewFieldSettings = getViewColumns({
                        tableProps: viewOptions.tableProps,
                        disabledFieldName: true,
                        schema
                    })
                    Object.assign(value.config, {
                        viewFieldSettings,
                        quickFilter: { mode: 'normal', rules: [] },
                        groupByFieldId: undefined,
                        groupConfig: undefined,
                        linkFilterController: {
                            expression: {
                                where: 'AND',
                                conditions: []
                            }
                        }
                    })
                }

                // 切换视图类型时，清空其他类型视图配置
                if (value.config.viewType !== selectedBlock.config.viewType) {
                    switch (value.config.viewType) {
                        case 'advancedTable':
                        case 'list':
                        case 'table': {
                            Object.assign(value.config, {
                                ...cleanGalleryConfig,
                                ...cleanCalendarConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'calendar': {
                            Object.assign(value.config, {
                                ...cleanGalleryConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'gallery': {
                            Object.assign(value.config, {
                                ...cleanCalendarConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'kanban': {
                            Object.assign(value.config, {
                                ...cleanCalendarConfig,
                                ...cleanGalleryConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        default: {
                            break
                        }
                    }
                }
            }

            if (value.type === BlockType.chart && selectedBlock.type === BlockType.chart) {
                if (chartTypeLevel[value.config.chartType] !== chartTypeLevel[selectedBlock.config.chartType]) {
                    switch (value.config.chartType) {
                        case ChartType.bar:
                        case ChartType.line:
                        case ChartType.composite: {
                            Object.assign(value.config, cleanBarConfig)
                            break
                        }

                        case ChartType.striation: {
                            Object.assign(value.config, cleanStriationConfig)
                            break
                        }

                        case ChartType.pie:
                        case ChartType.funnel: {
                            Object.assign(value.config, cleanPieConfig)
                            break
                        }

                        case ChartType.indicator: {
                            Object.assign(value.config, cleanIndicatorConfig)
                            break
                        }

                        default: {
                            break
                        }
                    }
                    // Object.assign(value, getInitChartBlockConfigurator(value, newDatasource)
                    // )
                }

                const { config } = value
                const { config: selectConfig } = selectedBlock
                if (config.pointer !== selectConfig.pointer) {
                    const newDatasource = dataSourceList.find(item => item.id === config.pointer)
                    if (newDatasource) {
                        Object.assign(value, getInitChartBlockConfigurator(value, newDatasource))
                    }
                }
            }

            onUpdateBlock(value, selectedBlock)
            debounceAdd(selectedBlock, value)
        })

        return selectedBlock ? (
            <BlockSettings
                key={selectedBlock.id}
                ref={ref}
                appId={appId}
                loading={loading}
                value={selectedBlock}
                onChange={onChange}
                dataSource={dataSource}
                dataSourceList={dataSourceList}
                records={records}
                allDataSources={dataSourceList}
                allPages={allPages ?? []}
                // allViews={allViews}
                pointer={pointer}
                onFetchAllViews={handleFetchAllViews}
            />
        ) : null
    }
)

export default BlockSettingsController
