import type { ButtonBlockConfig, ButtonItem } from '@lighthouse/core'
import { ActionItemPattern, ALIGNMENT, BLOCK_SIZE, SHAPE } from '@lighthouse/core'
import { generateJson } from '@lighthouse/shared'
import { getRandomIcon, nanoid } from '@lighthouse/tools'

/**
 * 初始化单个按钮配置
 *
 * @param {?ActionItemPattern} [pattern]
 * @returns {ButtonItem}
 */
export const getInitButton = function (pattern?: ActionItemPattern): ButtonItem {
    return {
        id: nanoid(),

        iconPosition: 'left',

        showType: 'icon-name',

        fillWay: 'contain',

        pattern: pattern ?? ActionItemPattern.primary,

        backgroundColor: { type: 'color', color: '#12B76A' },

        color: { type: 'color', color: '#fff' },

        name: generateJson('按钮'),

        icon: getRandomIcon(),

        // 除了按钮组，其他地方暂时都还是用这个
        events: {
            handleEvent: 'none',

            triggerEvent: 'click',

            params: []
        },

        action:
            pattern === ActionItemPattern.primary
                ? {
                      type: 'notification',
                      trigger: 'click',
                      data: {
                          notification: {
                              notificationStatus: 'INFO',
                              notificationContent: {
                                  type: 'doc',
                                  content: [
                                      {
                                          type: 'paragraph',
                                          content: [
                                              {
                                                  type: 'text',
                                                  text: '请配置按钮点击后执行的动作'
                                              }
                                          ]
                                      }
                                  ]
                              }
                          }
                      }
                  }
                : {
                      type: 'none',
                      trigger: 'click',
                      data: {
                          none: {}
                      }
                  }
    }
}

/**
 * 生成按钮block的配置
 *
 * @returns {ButtonBlockConfig}
 */
export const generateButtonBlock = (): ButtonBlockConfig => {
    return {
        size: BLOCK_SIZE.md,

        shape: SHAPE.rectangle,

        radius: '8',

        align: ALIGNMENT.left,

        group: [getInitButton(ActionItemPattern.primary), getInitButton(ActionItemPattern.secondary)],

        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'visible'
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
