import { Divider, Flex, Group, Switch } from '@byecode/ui'
import type { ContainerBlockConfig, FormContainerBlockConfig } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import {
    DEFAULT_ACTION,
    Group4ByecodeUi,
    ListItem4ByecodeUi,
    SelectDataSource,
    useAtomData,
    useSharedConfigDisabledWithVersion
} from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { AutoLayoutSetting } from '@/components/DesignSetting/AutoLayoutSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { StyleSetting } from '@/components/DesignSetting/StyleSetting'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { ValidationRuleAdder } from '@/components/ValidationRuleAdder'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { WeChatCollector } from '@/components/WeChatCollector'
import { ActionAdderProvider } from '@/contexts/ActionAdderContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useNotAggregateDataSourceList } from '@/hooks/useDataSource'
import { useDefaultPageList } from '@/hooks/usePage'

import { ButtonConfigure } from '../ButtonGroup/ButtonConfigure'
import { BUTTON_RADIUS_OPTIONS, BUTTON_SIZE_OPTIONS } from '../ButtonGroup/constant'
import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import FormSegmentedControl from '../Common/FormSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM, POSITION_ALIGN_CONTROL } from '../constants'
import { ViewListSettings } from './ViewListSettings'

interface ContainerSettingProps {
    syncHostContainerId: string
    containerId: string
    type: Extract<BlockType, 'container' | 'formContainer' | 'tabsContainer'>
}

export const ContainerSetting: React.FC<ContainerSettingProps> = ({ syncHostContainerId, containerId, type }) => {
    const { watch, register, control } = useFormContext<ContainerBlockConfig | FormContainerBlockConfig>()
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit(type)
    const allPages = useDefaultPageList()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useNotAggregateDataSourceList(appId, envId)
    const pageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId || '', [])
    )
    const [pageDsId, pageType] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(p => [p?.dsId, p?.type], [])
    )
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const isFormContainer = type === BlockType.formContainer

    const [action, formPointer] = watch(['action', 'pointer'])

    const formModuleDsId = useMemo(() => formPointer || pageDsId || '', [formPointer, pageDsId])
    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <AutoLayoutSetting {...designProps.layout} />
                    <Divider />
                    <StyleSetting {...designProps.design} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    {!isFormContainer && (
                        <>
                            <Group
                                label={
                                    <PopoverQuestion
                                        type="panel"
                                        mainAxis={38}
                                        arrowOffset={20}
                                        renderTarget={node => (
                                            <Flex gap={4} alignItems="center">
                                                面板
                                                {node}
                                            </Flex>
                                        )}
                                    />
                                }
                            >
                                <ViewListSettings syncHostContainerId={syncHostContainerId} containerId={containerId} />
                            </Group>

                            <Divider />
                        </>
                    )}
                    {isFormContainer && (
                        <>
                            <Group4ByecodeUi label={isFormContainer ? '数据' : '容器配置'}>
                                {isFormContainer && (
                                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                                        <div>{isFormContainer ? '提交数据到' : '数据来源'}</div>
                                        <Controller
                                            name="pointer"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <SelectDataSource
                                                    withinPortal
                                                    placeholder="请选择数据表"
                                                    value={value}
                                                    disabled={disabledWithVersion}
                                                    onChange={onChange}
                                                    // 表单编辑页可以使用用户表
                                                    dataSourceList={dataSourceList}
                                                />
                                            )}
                                        />
                                    </ListItem4ByecodeUi>
                                )}
                            </Group4ByecodeUi>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="提交按钮">
                                <ButtonConfigure
                                    prefixName="submitButton"
                                    listKeyName=""
                                    style={{ padding: 0 }}
                                    hideConfigs={{ fillWay: true }}
                                    // themeColor="primaryColor"
                                />
                                <Controller
                                    name="submitButton.size"
                                    control={control}
                                    render={({ field }) => (
                                        <FormSegmentedControl
                                            label="尺寸"
                                            size="md"
                                            compact
                                            data={BUTTON_SIZE_OPTIONS}
                                            value={field.value}
                                            onChange={v => field.onChange(v)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="submitButton.radius"
                                    control={control}
                                    render={({ field }) => (
                                        <FormSegmentedControl
                                            label="圆角"
                                            size="md"
                                            compact
                                            data={BUTTON_RADIUS_OPTIONS}
                                            value={field.value}
                                            onChange={v => field.onChange(v)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="submitButton.align"
                                    control={control}
                                    render={({ field }) => (
                                        <FormSegmentedControl
                                            label="对齐"
                                            size="md"
                                            compact
                                            data={POSITION_ALIGN_CONTROL}
                                            value={field.value}
                                            onChange={v => field.onChange(v)}
                                        />
                                    )}
                                />
                            </Group>
                            <Divider />
                            <Group collapseProps={{ style: { padding: '0 8px' } }} label="表单提交后动作">
                                {action && (
                                    <ActionAdderProvider dsId={formModuleDsId} isForm>
                                        <ActionAdder
                                            title="表单提交后"
                                            allPages={allPages}
                                            events={{
                                                params: [],
                                                handleEvent: 'copy',
                                                triggerEvent: 'click'
                                            }}
                                            action={action}
                                            prefixName=""
                                            showLabel={false}
                                        />
                                    </ActionAdderProvider>
                                )}
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="自定义校验规则">
                                <ValidationRuleAdder />
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="自动采集">
                                <WeChatCollector />
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                        </>
                    )}
                    {!isFormContainer && (
                        <>
                            <Group label="动作" styles={{ collapse: { padding: '0 8px !important' } }}>
                                <ActionAdder
                                    showLabel={false}
                                    title="点击容器后"
                                    allPages={allPages}
                                    action={action ?? DEFAULT_ACTION}
                                    prefixName=""
                                />
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="其他" styles={{ collapse: { padding: '0 16px' } }}>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <div>展开容器</div>
                                    <Controller
                                        control={control}
                                        name="shown"
                                        render={({ field }) => (
                                            <Switch checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                        </>
                    )}
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
