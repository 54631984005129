import type { ActionFlowNode, SendEmailActionConfig } from '@lighthouse/shared'
import { generateVariableValue, getEmails } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleSendEmail = async (node: ActionFlowNode<SendEmailActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }

    const { senderAccountId, recipients, cc, title, content } = config

    const titleValue = generateVariableValue({ innerType: 'TEXT', jsonContent: title, extraParams: excParams })
    const contentValue = generateVariableValue({ innerType: 'TEXT', jsonContent: content, extraParams: excParams, isRichText: true })
    const recipientsValue = getEmails(recipients, excParams)
    const ccValue = getEmails(cc, excParams)

    await srv.activeSendEmailAction({
        senderAccountId,
        recipients: recipientsValue,
        cc: ccValue,
        title: Array.isArray(titleValue) ? '' : String(titleValue),
        content: Array.isArray(contentValue) ? '' : String(contentValue)
    })

    return {
        senderAccountId,
        title: titleValue,
        content: contentValue,
        recipients: recipientsValue,
        cc: ccValue
    }
}
