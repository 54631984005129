import { IconFont } from '@byecode/ui'
import type { VariableADTvalue } from '@lighthouse/core'
import { type AppUser, type DTSelectItem, type Field, VariableType } from '@lighthouse/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import type { Editor, NodeViewProps } from '@tiptap/react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'
import styled from 'styled-components'

import { useSharedConfigDisabledWithVersion } from '../../../../contexts'
import type { VariableTree } from '../../../'
import { type VariableOptions } from '../../../'
import { VariableTag } from '../../../Variable/VariableTag'
import { useNodeFieldEditor } from '../../EditorProvider'
// import type { VariablePopoverSchema } from '../../../VariablePicker'
// import { getSchemaOption, VariableType } from '../../../VariablePicker'

const SCxNodeWrapper = styled.span<{ background?: string; color?: string }>`
    font-size: var(--font-size-sm);

    border-radius: 4px;
    margin: 0 3px;
    color: ${({ color }) => color || 'var(--color-black)'};
    border: 1px solid var(--color-gray-200);
`

const SCxSelectNodeWrapper = styled.span<{ background?: string; color?: string }>`
    font-size: var(--font-size-sm);
    border-radius: 100px;
    margin: 0 3px;
    color: ${({ color }) => color || 'var(--color-black)'};
    border: 1px solid var(--color-gray-200);
`

const SCxNode = styled.span`
    margin: 2px 4px;
    display: inline-flex;
    /* height: 24px; */
    /* line-height: 24px; */
    align-items: center;
    justify-content: flex-start;
`

const SCxVariableNode = styled(NodeViewContent)`
    margin: 0 4px;
    /* vertical-align: middle; */
`

const SCxText = styled.span`
    font-size: var(--font-size-sm);
    margin: 0 4px;
    white-space: nowrap;
`

const SCxIcon = styled(IconFont)`
    font-size: 14px;
    margin-left: 4px;
`

const SCxPersonAvatar = styled.img`
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 22px;
    margin-right: 4px;
    display: block;
`

const SCxPersonTextAvatar = styled.div<{ background?: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    min-width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: var(--font-size-sm);
    margin-right: 4px;
    border-radius: 50%;
    color: var(--color-white);
    background-color: ${({ background }) => background || 'var(--color-gray-2)'};
`

const PersonName = styled.span`
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

export interface DataQuoteOptions {
    options: VariableOptions
    // nodeData?: VariableOptions
    // renderTooltip?: (node: ProseMirrorNode) => React.ReactNode
    personOptions?: AppUser[]
    userOption?: VariableTree
    formOption?: VariableTree
    viewOption?: VariableTree
    dataSourceOption?: VariableTree
    selectOptions?: DTSelectItem[]
    onNodeClick?: () => void
}

interface DataQuoteComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
    extension: { options: DataQuoteOptions }
    deleteNode: () => void
}

export interface DateQuoteOptions {
    field: Field
    onNodeClick?: () => void
}
interface DateQuoteComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
    extension: { options: DateQuoteOptions }
    deleteNode: () => void
}

const commonStyle: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center'
    // position: 'relative',
    // top: '-1px'
}

const isCursorInCustomNode = (node: NodeViewProps['node'], state: Editor['state']) => {
    const { selection } = state

    // @ts-expect-error - ProseMirror types are not up-to-date
    return node === selection?.node
}

export const VariableComponent: React.FC<DataQuoteComponentProps> = ({ children, node, extension, deleteNode }) => {
    const { options: extensionOptions } = extension
    const { options, userOption, viewOption, formOption, dataSourceOption } = extensionOptions
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const variableData = node.attrs.value as VariableADTvalue
    const editor = useNodeFieldEditor()
    const isSelected = editor?.state && isCursorInCustomNode(node, editor?.state)

    if (!variableData) {
        return null
    }

    if (
        [
            VariableType.FIELD_ID,
            VariableType.PAGE,
            VariableType.VIEW,
            VariableType.USER,
            VariableType.VARIABLE,
            VariableType.UPSTREAM,
            VariableType.ARG_VARIABLE,
            VariableType.SYSTEM,
            VariableType.PAGE_LINK,
            VariableType.FORM
        ].includes(variableData.type)
    ) {
        return (
            <NodeViewWrapper as="span" style={{ fontWeight: 'normal' }}>
                <SCxVariableNode as="span" contentEditable={false}>
                    <VariableTag
                        isSelected={isSelected}
                        withClose={!disabledWithVersion}
                        onClose={deleteNode}
                        dataSourceOption={dataSourceOption}
                        viewOption={viewOption}
                        formOption={formOption}
                        value={variableData}
                        options={options}
                        userDsOption={userOption}
                    />
                </SCxVariableNode>
            </NodeViewWrapper>
        )
    }
    return null
}
