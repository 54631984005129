import { Group, IconFont, SegmentedControl } from '@byecode/ui'
import type { SubFormBlockConfig } from '@lighthouse/core'
import { type FieldInputConfigProtocol, type TableColumn, type ViewField, DataSourceType, FilterMode } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import {
    getAppointField,
    getViewColumns,
    ListItemPaddingByecodeUi,
    notDataSettingInnerTypes,
    SelectMode,
    silentInputTypes,
    useAtomData,
    VariableSourceType,
    ViewFieldDisplayConfigure,
    ViewFieldFilterConfigure,
    ViewSortConfigure
} from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import { find, reduce } from 'rambda'
import React, { useCallback, useMemo, useRef } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { InputControl } from '../../InputControl'
import { ListItemMenu } from '../../ListItemMenu'
import { SwitchControl } from '../../SwitchControl'
import { CreateRecordV2 } from '../../UserOperate'
import { DIRECTION_OPTIONS, inputHeightOptions, relativeSelectFieldList, SHOW_MODE_OPTIONS, VIEW_DATASOURCE_OPTIONS } from '../constants'
import type { BaseFieldInputSetting } from '../types'

interface StyleProps extends BaseFieldInputSetting {}

const SCxRelationSelectContainer = styled.div`
    padding: 0 8px;
`

export const Style: React.FunctionComponent<StyleProps> = ({ mode, pointer, prefix }) => {
    const btnRef = useRef<HTMLDivElement>(null)

    const isNotParentSubForm = mode !== 'subForm'

    const { watch, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [inputType, fieldPointer = '', showTitle, relativePointer = '', relativeShowMode] = watch([
        `${prefix}inputType`,
        `${prefix}fieldPointer`,
        `${prefix}showTitle`,
        `${prefix}relativeSelect.relativePointer`,
        `${prefix}relativeSelect.showMode`
    ])

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, pointer)
    const relatedDataSource = useDataSource(appId, envId, relativePointer)

    const { schema: relatedSchema, viewOptions: relatedViewOptions } = relatedDataSource ?? { schema: {} }

    const { tableProps: relatedTableProps = [] } = relatedViewOptions ?? {}

    const columns = getViewColumns({
        tableProps: relatedTableProps,
        schema: relatedSchema
    })

    const primaryField = useMemo(() => {
        if (!dataSource) {
            return columns[0]?.fieldId
        }
        const field = getAppointField(dataSource, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSource])

    const { field: canCreateRecordField } = useController({ control, name: `${prefix}relativeSelect.canCreateRecord` })
    const { field: creatingConfigField } = useController({ control, name: `${prefix}relativeSelect.creatingConfig` })

    const showRelativeSetting = useMemo(() => {
        const field = dataSource?.schema?.[fieldPointer]
        return field && !relativeSelectFieldList.has(field.type) && inputType === 'relativeSelect'
    }, [dataSource?.schema, fieldPointer, inputType])

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''] as const, [])
    )

    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()

    return (
        <>
            <Group label="输入框样式">
                <Controller
                    name={`${prefix}showTitle`}
                    render={({ field: { value, onChange } }) => (
                        <SwitchControl
                            checked={value}
                            label="显示标题"
                            onChange={e => {
                                onChange?.(e.currentTarget.checked)
                            }}
                        />
                    )}
                />
                {showTitle && <Controller name={`${prefix}title`} render={({ field }) => <InputControl label="标题" {...field} />} />}
                {!silentInputTypes.has(inputType) && (
                    <Controller name={`${prefix}placeholder`} render={({ field }) => <InputControl label="占位符" {...field} />} />
                )}
                {inputType === 'text' && (
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>输入框高度</Text>
                        <div style={{ width: 180 }}>
                            <Controller
                                name={`${prefix}inputHeight`}
                                control={control}
                                render={({ field }) => (
                                    <SegmentedControl data={inputHeightOptions} fullWidth value={field.value} onChange={field.onChange} />
                                )}
                            />
                        </div>
                    </ListItemPaddingByecodeUi>
                )}
                {inputType === 'relativeSelect' && (
                    <>
                        {isNotParentSubForm && (
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>显示方式</Text>
                                <div style={{ width: 180 }}>
                                    <Controller
                                        name={`${prefix}relativeSelect.showMode`}
                                        control={control}
                                        render={({ field }) => (
                                            <SegmentedControl
                                                data={SHOW_MODE_OPTIONS}
                                                fullWidth
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                </div>
                            </ListItemPaddingByecodeUi>
                        )}
                        {relativeShowMode !== 'input' && (
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>排列方式</Text>
                                <div style={{ width: 180 }}>
                                    <Controller
                                        name={`${prefix}relativeSelect.direction`}
                                        control={control}
                                        render={({ field }) => (
                                            <SegmentedControl
                                                data={DIRECTION_OPTIONS}
                                                fullWidth
                                                value={field.value}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                </div>
                            </ListItemPaddingByecodeUi>
                        )}
                    </>
                )}
            </Group>
            {showRelativeSetting && (
                <>
                    {relatedDataSource && (
                        <>
                            <Divider color="var(--color-gray-200)" style={{ marginBottom: 12 }} />
                            {relativeShowMode === 'input' && (
                                <Group label="选择窗" style={{ paddingTop: 0 }}>
                                    <Controller
                                        name={`${prefix}relativeSelect.showType`}
                                        render={({ field: { value, onChange } }) => {
                                            return (
                                                <SelectMode
                                                    options={VIEW_DATASOURCE_OPTIONS}
                                                    itemWidth="100%"
                                                    styles={{
                                                        image: {
                                                            padding: '20px 0px 0px 0px',
                                                            borderRadius: '8px',
                                                            width: 158,
                                                            height: 88,
                                                            outline: 'var(--color-gray-200) solid 1px',
                                                            background: 'var(--color-white)'
                                                        },
                                                        root: {
                                                            width: '100%',
                                                            gap: '8px',
                                                            marginTop: 8
                                                        }
                                                    }}
                                                    value={value}
                                                    onChange={val => onChange(val)}
                                                />
                                            )
                                        }}
                                    />
                                </Group>
                            )}

                            <SCxRelationSelectContainer>
                                {relativeShowMode === 'input' && (
                                    <Controller
                                        name={`${prefix}relativeSelect.viewFieldSettings`}
                                        render={({ field: { onChange, value } }) => {
                                            const currentColumns = getViewColumns({
                                                tableProps: relatedTableProps,
                                                value,
                                                schema: relatedSchema
                                            })
                                            // const currentPrimaryField = currentColumns?.[0]?.fieldId ?? ''
                                            const visibleNum = currentColumns.filter(item => item.visible).length
                                            const isAllVisible = visibleNum === currentColumns.length
                                            return (
                                                <ListItemMenu
                                                    label="字段设置"
                                                    compact
                                                    value={isAllVisible ? '全部显示' : `显示${visibleNum}个字段`}
                                                    icon={<IconFont type="Setting" color="var(--color-gray-400)" size={16} />}
                                                    ref={btnRef}
                                                    popupContent={
                                                        <ViewFieldDisplayConfigure
                                                            columns={currentColumns}
                                                            onChange={val => {
                                                                const newValue = reduce<TableColumn, ViewField[]>(
                                                                    (preVal, curVal) => {
                                                                        const columnItem = find(
                                                                            item => item.fieldId === curVal.id,
                                                                            currentColumns
                                                                        )
                                                                        if (!columnItem) {
                                                                            return preVal
                                                                        }
                                                                        return [...preVal, { ...columnItem, visible: curVal.visible }]
                                                                    },
                                                                    [],
                                                                    val
                                                                )
                                                                onChange(newValue)
                                                            }}
                                                        />
                                                    }
                                                />
                                            )
                                        }}
                                    />
                                )}

                                <Controller
                                    name={`${prefix}relativeSelect.filter`}
                                    render={({ field: { onChange, value } }) => {
                                        const filter = value
                                        const filtersLength = filter?.expression?.conditions?.length || 0
                                        return (
                                            <ListItemMenu
                                                width="auto"
                                                label="筛选选项"
                                                compact
                                                value={Boolean(filtersLength) && `${String(filtersLength)} 条筛选项`}
                                                icon={<IconFont type="Filter" color="var(--color-gray-400)" size={16} />}
                                                popupContent={
                                                    <ViewFieldFilterConfigure
                                                        dataSource={relatedDataSource}
                                                        dataSourceList={dataSourceList}
                                                        // sourceType={variableSourceType}
                                                        fieldBlocksWithDsId={fieldBlocksWithDsId}
                                                        // sourceDataSource={defaultValueDataSourceList}
                                                        sources={sources}
                                                        columns={columns}
                                                        paramsMode={FilterMode.CUSTOM}
                                                        primaryField={primaryField}
                                                        fieldMode={FilterMode.CUSTOM}
                                                        filter={filter}
                                                        noSettingInnerType={notDataSettingInnerTypes}
                                                        onFilter={onChange}
                                                    />
                                                }
                                            />
                                        )
                                    }}
                                />

                                <Controller
                                    name={`${prefix}relativeSelect.disabledFilter`}
                                    control={control}
                                    render={({ field: { onChange, value } }) => {
                                        const filtersLength = value?.expression?.conditions?.length || 0
                                        return (
                                            <ListItemMenu
                                                width="auto"
                                                label="禁用选项"
                                                compact
                                                value={Boolean(filtersLength) && `${String(filtersLength)} 条筛选项`}
                                                icon={<IconFont type="Forbidden" color="var(--color-gray-400)" size={16} />}
                                                popupContent={
                                                    <ViewFieldFilterConfigure
                                                        dataSource={relatedDataSource}
                                                        dataSourceList={dataSourceList}
                                                        fieldBlocksWithDsId={fieldBlocksWithDsId}
                                                        sources={sources}
                                                        columns={columns}
                                                        paramsMode={FilterMode.CUSTOM}
                                                        primaryField={primaryField}
                                                        fieldMode={FilterMode.NORMAL}
                                                        filter={value}
                                                        noSettingInnerType={notDataSettingInnerTypes}
                                                        onFilter={onChange}
                                                    />
                                                }
                                            />
                                        )
                                    }}
                                />

                                <Controller
                                    name={`${prefix}relativeSelect.sorts`}
                                    render={({ field: { onChange, value } }) => (
                                        <ListItemMenu
                                            width="auto"
                                            label="排序数据"
                                            compact
                                            value={Boolean(value?.length) && `${String(value?.length)} 条排序`}
                                            icon={<IconFont type="ArrowsDownUp" color="var(--color-gray-400)" size={16} />}
                                            popupContent={
                                                <ViewSortConfigure
                                                    noSettingInnerType={notDataSettingInnerTypes}
                                                    columns={columns}
                                                    primaryField={primaryField}
                                                    sorters={value}
                                                    onChangeSorter={onChange}
                                                    isShowDsName={relatedDataSource?.type === DataSourceType.joinDataSource}
                                                />
                                            }
                                        />
                                    )}
                                />
                                <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />

                                {relativeShowMode === 'input' && (
                                    <CreateRecordV2
                                        value={{ canCreateRecord: canCreateRecordField.value, creatingConfig: creatingConfigField.value }}
                                        dataSource={relatedDataSource}
                                        onChange={val => {
                                            const { canCreateRecord, creatingConfig } = val
                                            canCreateRecordField.onChange(canCreateRecord)
                                            creatingConfigField.onChange(creatingConfig)
                                        }}
                                    />
                                )}
                            </SCxRelationSelectContainer>
                        </>
                    )}
                </>
            )}
        </>
    )
}
