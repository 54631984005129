import React, { useState } from 'react'

import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'

interface BreadcrumbSettingProps {}

export const BreadcrumbSetting: React.FC<BreadcrumbSettingProps> = () => {
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit('breadcrumb')
    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && <SizeSetting {...designProps.size} />}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && <VisibilitySetting />}
        </>
    )
}
