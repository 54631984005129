import { IconFont } from '@byecode/ui'
import { max } from 'rambda'
import React, { useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { usePreviewType } from '@/hooks/useApplication'

import { type SizeInputType, convertSize } from './utils'

interface BlockStyleSize {
    blockId: string
    sizeInputType: SizeInputType
}
interface SizeIconProps {
    blockStyleSize?: BlockStyleSize
    value?: number
    type: string
    disable?: boolean
    min?: number
    onChangeMove?: (val: boolean) => void
    onChange?: (val: number) => void
}

interface SizeIconState {
    open: boolean
    size?: number
    x0: number
    x: number
}

const SCxIcon = styled(IconFont)`
    pointer-events: auto;
    cursor: ew-resize;
`

export const SizeIcon: React.FC<SizeIconProps> = ({ blockStyleSize, value, type, disable, min=1, onChangeMove, onChange }) => {
    const previewType = usePreviewType()
    const [move, setMove] = useImmer<SizeIconState>({
        open: false,
        x: 0,
        size: 0,
        x0: 0
    })
    const { open, x0, size } = move
    const iconRef = useRef<HTMLElement>(null)

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            window.getSelection()?.removeAllRanges()
            const offset = event.clientX - x0
            const targetSize = size ?? 0
            const s = targetSize + offset
            const abs = Math.abs(s)
            setMove(draft => {
                draft.x = event.clientX
                if (s < min) {
                    draft.x0 -= abs
                }
            })
            onChange?.(max(min, s))
        },
        [min, onChange, setMove, size, x0]
    )

    const handleStartMove = useCallback(
        (event: MouseEvent) => {
            document.documentElement.style.setProperty('cursor', 'ew-resize', 'important') // 强制使用 ew-resize
            document.documentElement.style.setProperty('pointer-events', 'none', 'important') // 强制使用 ew-resize
            const size = blockStyleSize ? convertSize(blockStyleSize.blockId, blockStyleSize.sizeInputType, 'px', previewType) : value ?? 0
            setMove(draft => {
                draft.open = true
                draft.size = size
                draft.x0 = event.clientX
            })
            onChangeMove?.(true)
        },
        [blockStyleSize, onChangeMove, previewType, setMove, value]
    )

    const handleUp = useCallback(
        (event: MouseEvent) => {
            document.documentElement.style.removeProperty('cursor')
            document.documentElement.style.removeProperty('pointer-events')
            setMove(draft => {
                draft.open = false
            })
            onChangeMove?.(false)
        },
        [onChangeMove, setMove]
    )

    useEffect(() => {
        const iconElement = iconRef.current
        if (!iconElement || disable) {
            return
        }
        iconElement.addEventListener('mousedown', handleStartMove)
        return () => {
            iconElement.removeEventListener('mousedown', handleStartMove)
        }
    }, [disable, handleStartMove])

    useEffect(() => {
        const iconElement = iconRef.current
        if (!iconElement || !open || disable) {
            return
        }
        document.addEventListener('mouseup', handleUp)
        document.addEventListener('mousemove', handleMouseMove)
        return () => {
            document.removeEventListener('mouseup', handleUp)
            document.removeEventListener('mousemove', handleMouseMove)
        }
    }, [disable, handleMouseMove, handleStartMove, handleUp, open])

    return <SCxIcon type={type} ref={iconRef} />
}
