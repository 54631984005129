import { IconFont } from '@byecode/ui'
import type { BlockAbstract, FilterVisibleMode } from '@lighthouse/core'
import { filterVisibleLabelMap } from '@lighthouse/shared'
import React from 'react'

interface VisibleIconProps {
    mode?: FilterVisibleMode
}

export const VisibleIcon: React.FC<VisibleIconProps> = ({ mode }) => {
    const filterVisibleData = mode ? filterVisibleLabelMap[mode] : undefined

    if (!filterVisibleData?.icon) {
        return <></>
    }
    return (
        <>
            <IconFont size={16} fill="var(--color-gray-400" type={filterVisibleData.icon} />
        </>
    )
}
