import { Menu } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { ApplicationPublishInfo } from '@lighthouse/shared'
import { useAtomData } from '@lighthouse/shared'
import { Flex, Image, Text } from '@mantine/core'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { defaultPageListAtom } from '@/atoms/page/state'
import { usePageURI } from '@/hooks/usePageURI'

import { ListItem } from '../ListItem'
import { PublishShare } from '../PublishShare'
import { PublishSharedPreview } from '../PublishSharedPreview'
import { SendEmail } from '../SendEmail'
import { SharedContainer } from '../Share'
import type { Mode } from '../types'
import { UrlPreview } from '../UrlPreview'

interface SharedOtherPlatformsProps {
    data?: ApplicationPublishInfo
    isPreview?: boolean
    onBack?: () => void
    onClose?: () => void
}
type SecondaryMode = 'wx' | 'email' | 'default'

const SCxPopoverContainer = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;
`

export const SharedOtherPlatforms: React.FunctionComponent<SharedOtherPlatformsProps> = ({ data, isPreview, onBack, onClose }) => {
    const [mode, setMode] = useState<SecondaryMode>('default')

    const isExitPage = useAtomData(
        defaultPageListAtom,
        React.useCallback(s => s.length > 0, [])
    )

    const { shareUrl } = usePageURI({ isCurrentPage: false, isProdHost: !isPreview })

    return useMemo(() => {
        switch (mode) {
            case 'email': {
                return <SendEmail onBack={() => setMode('default')} onClose={onClose} />
            }
            case 'wx': {
                return <PublishShare shareUrl={shareUrl} onBack={() => setMode('default')} onClose={onClose} />
            }

            default: {
                return (
                    <SharedContainer title="分享到社交媒体" subTitle="分享到" onBack={() => onBack?.()} onClose={onClose}>
                        <Flex gap={12} direction="column">
                            <Menu trigger="hover" withArrow withinPortal width="auto" position="left-start" disabled={!isExitPage}>
                                <Menu.Target>
                                    <div>
                                        <ListItem
                                            tagIcon="WeChatLogoIconLibrary"
                                            tagColor="var(--color-green-500)"
                                            tagLabel="微信"
                                            iconColor="var(--color-white)"
                                            rightLabel={
                                                <Text size={12} color="var(--color-gray-400)">
                                                    扫码分享
                                                </Text>
                                            }
                                            onClick={() => setMode('wx')}
                                        />
                                    </div>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <PublishSharedPreview />
                                </Menu.Dropdown>
                            </Menu>
                            {/* <ListItem
                            tagIcon="WeiboLogoIconLibrary"
                            tagColor="var(--color-red-500)"
                            tagLabel="微博"
                            iconColor="var(--color-white)"
                            rightLabel={
                                <Text size={12} color="var(--color-gray-400)">
                                    打开微博分享URL
                                </Text>
                            }Bold Duotone / Security / QR Code
                            onClick={() =>
                                window.open(
                                    `http://service.weibo.com/share/share.php?url=${shareUrl}&title=${encodeURIComponent(
                                        '【#实用工具推荐#】推荐一款超好用的无代码平台——ByeCode！无需编程基础，让你轻松搭建自己的应用。无论是个人还是企业，都能通过这个平台快速实现业务需求。快来体验一下吧！'
                                    )}`
                                )
                            }
                        /> */}

                            <Menu trigger="hover" withArrow withinPortal width={360} position="left-start">
                                <Menu.Target>
                                    <div>
                                        <ListItem
                                            tagIcon="LetterIconLibrary"
                                            tagColor="#54C5EB"
                                            tagLabel="邮箱"
                                            iconColor="var(--color-white)"
                                            rightLabel={
                                                <Text size={12} color="var(--color-gray-400)">
                                                    打开邮件分享URL
                                                </Text>
                                            }
                                            onClick={() => setMode('email')}
                                        />
                                    </div>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <SCxPopoverContainer>
                                        <Text size={14}>邮件预览</Text>
                                        <Image src={getAssetUrl('common', 'email_preview.jpg')} width="100%" />
                                    </SCxPopoverContainer>
                                </Menu.Dropdown>
                            </Menu>
                        </Flex>
                        {/* <UrlPreview /> */}
                    </SharedContainer>
                )
            }
        }
    }, [isExitPage, mode, onBack, onClose, shareUrl])
}
