import { Flex, IconFont, Input, singleTextEllipsis } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { getBlockName } from '@lighthouse/block'
import { FilterVisibleModeEnum } from '@lighthouse/core'
import type { CustomViewVisibleData } from '@lighthouse/shared'
import React, { forwardRef, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import type { FlattedNode } from './types'
import { VisibleIcon } from './useVisibleIcon'

const Container = styled.li<{
    indent: number
    isIndicator?: boolean
    isSorting?: boolean
    isSelected?: boolean
    isSelectedChildren?: boolean
}>`
    list-style: none;
    cursor: pointer;
    ${({ indent }) =>
        css`
            padding-left: ${indent * 20}px;
        `}
    transition: all 0.15s;

    ${({ isSorting, isSelected, isSelectedChildren }) => [
        isSelected &&
            !isSorting &&
            css`
                color: var(--color-white);
                background-color: var(--color-main);
            `,
        isSelectedChildren &&
            css`
                background-color: var(--color-gray-100);
            `,
        !isSorting &&
            !isSelected &&
            css`
                &:hover {
                    background: linear-gradient(0deg, rgba(38, 65, 90, 0.06) 0%, rgba(38, 65, 90, 0.06) 100%), #eceff4;
                }
            `
    ]}
`

const FlexActions = styled(Flex)`
    display: none;
`

const FlexVisible = styled(Flex)`
    display: flex;
    gap: 6px;
`

const Main = styled.div<{ isIndicator?: boolean }>`
    padding: 6px;
    position: relative;
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: space-between;

    ${({ isIndicator }) =>
        isIndicator &&
        css`
            padding: 0;
            height: 2px;
            margin-left: 20px;
            background: var(--color-blue-500);
        `}
`

const Label = styled.div`
    font-size: 12px;
    line-height: 20px;
    ${singleTextEllipsis}
`
const Description = styled.div`
    font-size: 10px;
    line-height: 20px;
    color: var(--color-white);
    opacity: 0.7;
    ${singleTextEllipsis}
`

const IconButton = styled.button<{ isSelected?: boolean }>`
    all: unset;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
    border-radius: 3px;
    color: ${({ isSelected }) => (isSelected ? 'var(--color-white)' : 'var(--color-gray-400)')};
    &:hover {
        color: ${({ isSelected }) => (isSelected ? 'var(--color-gray-100)' : 'var(--color-gray-900)')};

        background: rgba(50, 75, 99, 0.12);
    }
`

interface ItemProps extends React.ComponentPropsWithoutRef<'li'> {
    data: FlattedNode
    customViewData?: CustomViewVisibleData
    disabled?: boolean
    onNameChange?: (v: string) => void
    onCollapseChange?: (v: boolean) => void
    onDeleteItem?: (id: string) => void

    isSelected?: boolean
    isSelectedChildren?: boolean
    isOverlay?: boolean
    isDragging?: boolean
    isSorting?: boolean
}
export const Item = forwardRef<HTMLLIElement, ItemProps>(
    (
        {
            data,
            customViewData,
            disabled,
            onNameChange,
            onCollapseChange,
            onDeleteItem,
            isSelected,
            isSelectedChildren,
            isOverlay,
            isDragging,
            isSorting,
            ...rest
        },
        ref
    ) => {
        const inputRef = useRef<HTMLInputElement>(null)
        const [isEdit, setIsEdit] = useState(false)
        const [name, setName] = useState('')

        // const visibleIcon = useVisibleIcon(data.block, { customViewData })

        return (
            <Container
                ref={ref}
                {...rest}
                indent={data.indent}
                isSorting={isSorting}
                isSelected={isSelected}
                isSelectedChildren={isSelectedChildren}
                isIndicator={isDragging && !isOverlay}
            >
                <Main isIndicator={isDragging && !isOverlay}>
                    {isDragging ? null : (
                        <>
                            <Flex
                                gap={4}
                                alignItems="center"
                                style={{ position: 'relative', paddingLeft: 20, flex: 1, overflow: 'hidden' }}
                            >
                                {data.collapsible && (
                                    <IconButton
                                        style={{ position: 'absolute', left: 0 }}
                                        isSelected={isSelected}
                                        onClick={() => onCollapseChange?.(!data.collapsed)}
                                    >
                                        <IconFont size={12} type="CaretDown" rotate={data.collapsed ? -90 : 0} />
                                    </IconButton>
                                )}
                                <IconFont size={16} type={data.icon} fill={isSelected ? 'var(--color-white)' : 'var(--color-gray-400)'} />

                                {isEdit && data.block ? (
                                    <Input
                                        ref={inputRef}
                                        size="xs"
                                        styles={{ input: { fontSize: 12 } }}
                                        value={name}
                                        onChange={e => {
                                            const { value } = e.target
                                            setName(value)
                                        }}
                                        onBlur={e => {
                                            const { value } = e.currentTarget
                                            const final = data.block ? value || getBlockName(data.block) : value
                                            onNameChange?.(final)

                                            setIsEdit(false)
                                        }}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                const { value } = e.currentTarget
                                                const final = data.block ? value || getBlockName(data.block) : value
                                                onNameChange?.(final)

                                                setIsEdit(false)
                                            }
                                        }}
                                    />
                                ) : (
                                    <Flex
                                        gap={6}
                                        alignItems="center"
                                        style={{ flex: 1, overflow: 'hidden' }}
                                        onDoubleClick={() => {
                                            if (!data.block || disabled) {
                                                return
                                            }
                                            setIsEdit(true)
                                            setName(data.name)
                                            requestAnimationFrame(() => {
                                                inputRef.current?.select()
                                            })
                                        }}
                                    >
                                        <Label>{data.name}</Label>
                                        {data.description && <Description>{data.description}</Description>}
                                    </Flex>
                                )}
                            </Flex>

                            {/* {!disabled && !isEdit && !isSorting && onDeleteItem && (
                                <FlexActions gap={4} alignItems="center">
                                    <IconButton isSelected={isSelected} onClick={() => data.block && onDuplicationItem?.(data.block.id)}>
                                        <IconFont size={14} type="Duplicate" />
                                    </IconButton>
                                    <IconButton isSelected={isSelected} onClick={() => data.block && onDeleteItem?.(data.block.id)}>
                                        <IconFont size={14} type="Trash" />
                                    </IconButton>
                                </FlexActions>
                            )} */}
                            <FlexVisible>
                                <VisibleIcon mode={data.visibleMode} />
                            </FlexVisible>
                            {/* {data.visibleMode && data.visibleMode !== FilterVisibleModeEnum.VISIBLE && (
                                <FlexVisible>
                                    <VisibleIcon block={data.block} />
                                </FlexVisible>
                            )} */}
                        </>
                    )}
                </Main>
            </Container>
        )
    }
)

interface SortableItemProps extends Omit<ItemProps, 'hideActions'> {}
export const SortableItem = (props: SortableItemProps) => {
    const { data, disabled } = props
    const { setNodeRef, attributes, listeners, transform, transition, isDragging, isSorting } = useSortable({
        id: data.block?.id || '',
        disabled,
        attributes: { role: 'li', roleDescription: 'page layer' }
    })

    const style: React.CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition,
        zIndex: isDragging ? 1 : 0,
        cursor: isSorting ? 'grabbing' : undefined
    }

    return <Item ref={setNodeRef} data-layer-id={data.id} {...listeners} {...attributes} style={style} isDragging={isDragging} isSorting={isSorting} {...props} />
}
