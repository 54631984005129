import { Button, Flex, IconFont } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ModuleType } from '@/components/ApplicationSetting/type'
import { usePageURI } from '@/hooks/usePageURI'

import { StyledUrl } from '../StyledUrl'

interface UrlPreviewProps {
    hideSettingsButton?: boolean
    isProdHost?: boolean
    onLink?: () => void
}

export const UrlPreview: React.FunctionComponent<UrlPreviewProps> = ({ hideSettingsButton, isProdHost, onLink }) => {
    const { shareUrl } = usePageURI({ isCurrentPage: false, isProdHost })
    const navigate = useNavigate()

    return (
        <Flex gap={8}>
            <StyledUrl shareUrl={shareUrl} style={{ flex: 1 }} />
            {hideSettingsButton ? null : (
                <Link
                    to="./settings"
                    onClick={() => {
                        navigate('./settings')
                        onLink?.()
                    }}
                >
                    <Button
                        style={{ whiteSpace: 'nowrap' }}
                        size="lg"
                        icon={<IconFont type="Earth" size={16} fill="var(--color-gray-400)" />}
                    >
                        域名设置
                    </Button>
                </Link>
            )}
        </Flex>
    )
}
