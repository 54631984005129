import { Divider, Flex, Group, IconFont, Text } from '@byecode/ui'
import type { FieldGroupBlockConfig } from '@lighthouse/core'
import { findParentFormBlock, getTableIcon, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { blocksAtom,lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { useVariableCustomDataSource } from '@/hooks/useVariableCustomViewOption'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { ViewFieldSetting } from '../View/ViewField/ViewFieldSetting'

interface FieldGroupSettingProps {
    pointer: string
    id: string
}

export const FieldGroupSetting: React.FunctionComponent<FieldGroupSettingProps> = ({ pointer: pageDsId, id: blockId }) => {
    const { control } = useFormContext<FieldGroupBlockConfig>()
    const [pageId, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.blockRuntimeState] as const, [])
    )
    const designProps = getBlockAndPageDesignLimit('fieldGroup')
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const blocks = useAtomData(
        pageBlocksAtom(pageId)
    )

    const parentForm = useMemo(() => findParentFormBlock({ id: blockId, blocks, blockRuntimeState }), [blockId, blocks, blockRuntimeState])

    const { customViewDataSource } = useVariableCustomDataSource()

    const pointer = useMemo(() => {
        return customViewDataSource?.id || parentForm?.config.pointer || pageDsId
    }, [customViewDataSource, pageDsId, parentForm?.config.pointer])

    const dataSource = useDataSource(appId, envId, pointer)

    const tipLabel = useMemo(() => {
        if (!pointer) {
            return (
                <>
                    <Text size={12} color="var(--color-red-500)">
                        无法设置字段，因为所在页面未指定数据表
                    </Text>
                    <IconFont color="var(--color-red-500)" style={{ marginLeft: 6 }} type="Warning" />
                </>
            )
        }
        if (dataSource) {
            return (
                <>
                    <Text size={12} color="var(--color-gray-500)">
                        字段来自
                    </Text>
                    <IconFont color="var(--color-gray-500)" style={{ margin: '0 4px 0 8px' }} type={getTableIcon(dataSource)} />
                    <Text size={12} color="var(--color-gray-500)">
                        {dataSource.name}
                    </Text>
                </>
            )
        }
        return null
    }, [dataSource, pointer])

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SizeSetting {...designProps.size} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="字段设置">
                        {dataSource && (
                            <Controller
                                control={control}
                                name="viewFields"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <ViewFieldSetting
                                            value={value}
                                            type={dataSource.type}
                                            schema={dataSource?.schema}
                                            viewType="table"
                                            tableProps={dataSource.viewOptions.tableProps}
                                            onChange={val => {
                                                onChange(val)
                                            }}
                                        />
                                    )
                                }}
                            />
                        )}
                        <Flex justifyContent="flex-end"> {tipLabel}</Flex>
                    </Group>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
