import { Button, Tag } from '@byecode/ui'
import { type AppUser, type DataSourceAbstract } from '@lighthouse/core'
import { type FlowNode, type NodeFieldContent, getFieldIcon, placeholderFromFieldType } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { useDataSourceList } from '@/hooks/useDataSource'

import { getDefaultFiledValue } from '../../utils/setNodeRecordContent'
import { AddFieldButton } from './AddFieldButton'
import { FieldItem, SCxHeader, SCxIcon, SCxLabel, SCxText } from './FieldItem'

interface SettingFieldValueProps {
    appId: string
    envId: string
    prefixName?: string
    dataSource: DataSourceAbstract
    allParentNodes?: FlowNode[]
    fieldsName?: string
    disablePageLink?: boolean
    disableFormOption?: boolean
    disableViewOption?: boolean
    disablePageOption?: boolean
}

export const SettingFieldValue: React.FC<SettingFieldValueProps> = ({
    appId,
    envId,
    prefixName = 'config',
    dataSource,
    allParentNodes,
    fieldsName = 'fields',
    disablePageLink,
    disableFormOption,
    disableViewOption,
    disablePageOption
}) => {
    const { schema } = dataSource
    const { control } = useFormContext()
    const dataSourceList = useDataSourceList(appId, envId)
    const { fields, append, remove } = useFieldArray({
        control,
        name: `${prefixName}.${fieldsName}`,
    })

    const handleItemClick = useCallback(
        (id: string) => {
            const field = schema[id]
            const defaultValue = getDefaultFiledValue(field.type)
            append({
                fieldId: field.id,
                value: defaultValue
            })
        },
        [append, schema]
    )

    const handleItemDelete = useCallback(
        (index: number) => {
            remove(index)
        },
        [remove]
    )

    const fieldIds = useMemo(() => fields.map(item => (item as unknown as NodeFieldContent).fieldId), [fields])
    // SYSTEM_FIELD
    return (
        <>
            {fields.map((item, index) => {
                const nodeField = item as unknown as NodeFieldContent
                const field = schema[nodeField.fieldId]

                if (!field) {
                    return (
                        <SCxHeader key={nodeField.fieldId}>
                            <SCxLabel>
                                <Tag background="var(--color-red-100)" color="var(--color-red-500)" radius={8}>
                                    找不到字段
                                </Tag>
                            </SCxLabel>
                            <Button
                                type="text"
                                onClick={() => handleItemDelete(index)}
                                icon={<SCxIcon type="Trash" color="var(--color-gray-400)" />}
                            />
                        </SCxHeader>
                    )
                }

                const placeholder = placeholderFromFieldType[field.type]
                return (
                    <FieldItem
                        autoHeight
                        appId={appId}
                        dataSourceList={dataSourceList}
                        key={nodeField.fieldId}
                        placeholder={placeholder}
                        prefixName={`${prefixName}.${fieldsName}[${index}].value`}
                        allParentNodes={allParentNodes}
                        field={field}
                        disablePageLink={disablePageLink}
                        disableFormOption={disableFormOption}
                        disableViewOption={disableViewOption}
                        disablePageOption={disablePageOption}
                        onItemDelete={() => handleItemDelete(index)}
                    />
                )
            })}
            <AddFieldButton dataSource={dataSource} dataSourceList={dataSourceList} fieldIds={fieldIds} onItemClick={handleItemClick} />
        </>
    )
}
