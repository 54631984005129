import { Select } from '@byecode/ui'
import { type DataSourceAbstract, DataSourceType, FilterMode } from '@lighthouse/core'
import type { FlowNode, NodeTypes } from '@lighthouse/shared'
import { CollapseBox, getIsAllowedDataSourceAsFlowOption, nodeTypeOptions, SelectDataSource } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import useSwr from 'swr'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'

import { TriggerFilter } from '../../../TriggerFilter'
import * as SC from '../../styles'

interface MatchConditionTriggerConfigureProps {
    allParentNodes: FlowNode[]
}

export const MatchConditionTriggerConfigure: React.FC<MatchConditionTriggerConfigureProps> = ({ allParentNodes }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { data: listDs = [] } = useSwr(`/fetch/listDs/${appId}`, () => srv.listDs(appId, envId), {
        revalidateOnFocus: false
    })
    const { control } = useFormContext()
    const options = useMemo(
        () =>
            listDs.reduce<DataSourceAbstract[]>((listDs, item) => {
                if (getIsAllowedDataSourceAsFlowOption(item) && !item.sync && item.type !== DataSourceType.joinDataSource) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [listDs]
    )

    const dataSourceId: string = useWatch({
        control,
        name: 'config.dataSourceId'
    })

    return (
        <>
            <SC.Container>
                <CollapseBox label="触发器配置" collapseAble={false}>
                    <SC.Content>
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel>类型</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel required>数据表</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="config.dataSourceId"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => {
                                        return (
                                            <SelectDataSource
                                                {...field}
                                                searchable
                                                withinPortal
                                                placeholder="请选择数据表"
                                                value={dataSourceId}
                                                dataSourceList={options}
                                            />
                                        )
                                    }}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                    </SC.Content>
                </CollapseBox>
                {dataSourceId && (
                    <CollapseBox label="设置触发条件" required collapseAble={false}>
                        <TriggerFilter
                            dataSourceId={dataSourceId}
                            fieldMode={FilterMode.CUSTOM}
                            paramsMode={FilterMode.CUSTOM}
                            prefix="config"
                            sourceFieldFlowNode={allParentNodes}
                        />
                    </CollapseBox>
                )}
            </SC.Container>
        </>
    )
}
