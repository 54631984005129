import type { MenuProps, Option } from '@byecode/ui'
import { Empty, IconFont, Image, Input, Menu, Text } from '@byecode/ui'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { LANGUAGE_LIST } from '../../constants/language'

interface SelectLanguageProps extends Omit<MenuProps, 'children'> {
    options: Option[]
    targetComponent: React.ReactNode
    rightSection?: React.ReactNode
    empty?: React.ReactNode
    searchable?: boolean
    extra?: React.ReactNode
    onClick?: (v: string) => void
}

const SCxSearchWrapper = styled.div`
    padding: 4px 12px 0;
    margin-bottom: 8px;
`

export const SelectLanguage: React.FunctionComponent<SelectLanguageProps> = ({
    options,
    targetComponent,
    rightSection,
    empty,
    searchable,
    extra,
    width = 250,
    onClick,
    ...rest
}) => {
    const [searchLabel, setSearchLabel] = useState('')

    const filterOptions = useMemo(() => {
        if (!options) {
            return []
        }

        return options?.filter(item => {
            return (typeof item.label === 'string' ? item.label.toLocaleLowerCase() : item.value).includes(searchLabel.toLocaleLowerCase())
        })
    }, [options, searchLabel])

    return (
        <Menu width={width} closeOnItemClick {...rest}>
            <Menu.Target>{targetComponent}</Menu.Target>
            <Menu.Dropdown>
                {searchable && (
                    <SCxSearchWrapper>
                        <Input
                            onChange={e => setSearchLabel(e.target.value)}
                            prefix={<IconFont size={16} type="Search" />}
                            placeholder="搜索"
                        />
                    </SCxSearchWrapper>
                )}
                {filterOptions.length > 0 ? (
                    <>
                        {filterOptions.map(option => {
                            const icon = LANGUAGE_LIST.find(lang => lang.lang === option.value)?.flag
                            return (
                                <Menu.Item
                                    key={option.value}
                                    rightSection={option.extra ?? <Text color="var(--color-gray-400)">{option.describe}</Text>}
                                    icon={<Image height={16} src={icon} fit="cover" />}
                                    onClick={() => onClick?.(option.value)}
                                    style={{ lineHeight: '32px', padding: '0px 16px', height: 32 }}
                                >
                                    <Text>{option.label}</Text>
                                </Menu.Item>
                            )
                        })}
                        {extra}
                    </>
                ) : (
                    empty ?? <Empty style={{ height: 100 }} description="没有数据" />
                )}
            </Menu.Dropdown>
        </Menu>
    )
}
