import { IconFont, SegmentedControl, Tooltip } from '@byecode/ui'
import type { ContainerBlockConfig, Direction, FlexAlign } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'
import { Switch } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'


type AlignGridItemType = `${FlexAlign}:${FlexAlign}`

const SCxAlignmentSetting = styled.div`
    width: 180px;
    display: flex;
    align-items: center;
`

const SCxDirectionAndStretch = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    width: 100px;
    margin-right: 8px;
`

const SCxAlignGridWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 72px;
    height: 72px;
    display: flex;
    padding: 6px;
    border-radius: 4px;
    background-color: var(--color-gray-100);
`

const SCxAlignGridItemIndicator = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    /* padding: 2px; */
`

const SCxAlignGridItemIndicatorBar = styled.div`
    background-color: var(--color-main);
    border-radius: 4px;
`

const SCxAlignGridItem = styled.div<{ selected: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: var(--color-gray-400);
    }

    ${({ selected }) =>
        selected &&
        css`
            &:after {
                display: none;
            }
        `}
`

export const DIRECTION_OPTIONS = [
    {
        icon: <Tooltip title='水平布局' offset={12}><IconFont type="ArrowRight" /></Tooltip>,
        value: DIRECTION.horizontal
    },
    {
        icon: <Tooltip title='垂直布局' offset={12}><IconFont type="ArrowDown" /></Tooltip>,
        value: DIRECTION.vertical
    }
]

export const IS_DISTRIBUTED_ALIGNMENT_OPTIONS = [
    {
        label: <IconFont type="DistributeHorizontally" />,
        value: 'false'
    },
    {
        label: '分布对齐',
        value: 'true'
    }
]

// 生成一个 3x3 的对齐网格数组
const ALIGN_GRID: AlignGridItemType[][] = [
    ['flex-start:flex-start', 'flex-start:center', 'flex-start:flex-end'],
    ['center:flex-start', 'center:center', 'center:flex-end'],
    ['flex-end:flex-start', 'flex-end:center', 'flex-end:flex-end']
]

const Indicator: React.FC<{ align: AlignGridItemType; direction?: Direction; stretch?: boolean }> = ({ align, direction, stretch }) => {
    const [alignX, alignY] = align.split(':')

    if (direction === DIRECTION.horizontal) {
        const commonStyle = {
            width: 5,
            height: '100%'
        }
        switch (alignY) {
            case 'flex-start':
            case 'flex-end': {
                return (
                    <SCxAlignGridItemIndicator style={{ flexDirection: 'row', alignItems: alignY }}>
                        <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, height: stretch ? 0 : '60%' }} />
                        <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, height: stretch ? '100%' : '90%' }} />
                        <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, height: stretch ? 0 : '40%' }} />
                    </SCxAlignGridItemIndicator>
                )
            }
            case 'center': {
                return (
                    <SCxAlignGridItemIndicator style={{ flexDirection: 'row', alignItems: alignY }}>
                        <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, height: stretch ? 0 : '50%' }} />
                        <SCxAlignGridItemIndicatorBar style={commonStyle} />
                        <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, height: stretch ? 0 : '50%' }} />
                    </SCxAlignGridItemIndicator>
                )
            }
            default: {
                return null
            }
        }
    }

    const commonStyle = {
        height: 5,
        width: '100%'
    }
    switch (alignX) {
        case 'flex-start':
        case 'flex-end': {
            return (
                <SCxAlignGridItemIndicator style={{ flexDirection: 'column', alignItems: alignX }}>
                    <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, width: stretch ? 0 : '60%' }} />
                    <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, width: stretch ? '100%' : '90%' }} />
                    <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, width: stretch ? 0 : '40%' }} />
                </SCxAlignGridItemIndicator>
            )
        }
        case 'center': {
            return (
                <SCxAlignGridItemIndicator style={{ flexDirection: 'column', alignItems: alignX }}>
                    <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, width: stretch ? 0 : '50%' }} />
                    <SCxAlignGridItemIndicatorBar style={commonStyle} />
                    <SCxAlignGridItemIndicatorBar style={{ ...commonStyle, width: stretch ? 0 : '50%' }} />
                </SCxAlignGridItemIndicator>
            )
        }
        default: {
            return null
        }
    }
}

interface AlignmentSettingProps {
    disableDistributedAlignment?: boolean
    onSetBreakKey: (name: string) => void
}

export const AlignmentSetting: React.FC<AlignmentSettingProps> = ({ disableDistributedAlignment, onSetBreakKey }) => {
    const { control, setValue } = useFormContext<ContainerBlockConfig>()
    // const { handleSetBreakKey } = useSetExtendsKeys()
    const [alignX, alignY, direction, isDistributedAlignment] = useWatch({
        control,
        name: [
            'breakPoint.layout.align.alignX',
            'breakPoint.layout.align.alignY',
            'breakPoint.layout.align.direction',
            'breakPoint.layout.align.isDistributedAlignment'
        ]
    })

    const isHorizontal = direction === DIRECTION.horizontal

    const handleAlignGridItemClick = (value: AlignGridItemType) => {
        const [alignX, alignY] = value.split(':')
        setValue('breakPoint.layout.align', {
            direction,
            isDistributedAlignment,
            alignX: alignX as FlexAlign,
            alignY: alignY as FlexAlign,
        })
        onSetBreakKey('layout.align')
    }

    return (
        <SCxAlignmentSetting>
            <SCxDirectionAndStretch>
                <Controller
                    name="breakPoint.layout.align.direction"
                    control={control}
                    render={({ field }) => (
                        <SegmentedControl
                            value={field.value}
                            onChange={val => {
                                field.onChange(val)
                                onSetBreakKey('layout.align')
                            }}
                            fullWidth
                            data={DIRECTION_OPTIONS}
                        />
                    )}
                />
                <Controller
                    name="breakPoint.layout.align.isDistributedAlignment"
                    control={control}
                    render={({ field }) => (
                        <Switch
                            disabled={disableDistributedAlignment}
                            color="blue"
                            radius={6}
                            styles={{
                                body: {
                                    width: '100%'
                                },
                                track: {
                                    width: '100%',
                                    fontSize: 12,
                                    border: 'none',
                                    backgroundColor: 'var(--color-gray-100)',
                                    height: 32
                                },
                                trackLabel: { flex: 1, minWidth: 48, fontWeight: 'normal' },
                                thumb: {
                                    borderRadius: 6,
                                    border: 'none',
                                    height: 28,
                                    width: 28,
                                    transform: `translateX(${field.value ? -6 : 0}px)`
                                }
                            }}
                            size="lg"
                            thumbIcon={
                                <IconFont
                                    style={{ color: 'var(--color-gray-400)' }}
                                    type={isHorizontal ? 'AlignHorizontaAutoSpacing' : 'AlignVerticalAutoSpacing'}
                                    size={18}
                                />
                            }
                            onLabel="分布对齐"
                            offLabel="分布对齐"
                            checked={field.value}
                            onChange={val => {
                                field.onChange(val)
                                onSetBreakKey('layout.align')
                            }}
                        />
                    )}
                />
            </SCxDirectionAndStretch>
            <SCxAlignGridWrapper>
                {ALIGN_GRID.map(row => (
                    <div key={row[0]}>
                        {row.map(col => {
                            const [colAlignX, colAlignY] = col.split(':')
                            const selected =
                                col === `${alignX}:${alignY}` || isDistributedAlignment
                                    ? direction === DIRECTION.vertical
                                        ? colAlignX === alignX
                                        : colAlignY === alignY
                                    : false
                            return (
                                <SCxAlignGridItem key={col} selected={selected} onClick={() => handleAlignGridItemClick(col)}>
                                    {selected && <Indicator align={col} direction={direction} stretch={isDistributedAlignment} />}
                                </SCxAlignGridItem>
                            )
                        })}
                    </div>
                ))}
            </SCxAlignGridWrapper>
        </SCxAlignmentSetting>
    )
}
