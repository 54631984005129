import { Flex, IconFont, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { getFileSizeAccordanceBaseUnit, getFileSizeWithUnit } from '@lighthouse/tools'
import { lightFormat } from 'date-fns'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useLocalStorage } from 'react-use'
import styled, { css } from 'styled-components'

import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import { useSpaceQuota } from '@/shared/reusable'

import type { QuotaPromptData } from '../QuotaPromptModal'
import { QuotaPromptModal } from '../QuotaPromptModal'
import { openSpaceGrade } from '../SpaceGrade'

const Button = styled.button<{ styleTheme?: 'dark' | 'light' }>`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;

    flex: 1;
    border-radius: 8px;
    padding: 5px;
    line-height: 22px;
    font-size: 14px;
    text-align: center;

    &:hover {
        opacity: 0.8;
    }

    ${({ styleTheme }) => {
        if (styleTheme === 'dark') {
            return css`
                background-color: var(--color-gray-100);
            `
        }

        return css`
            color: var(--color-white);
            background-color: #3670ff;
        `
    }}
`

/** 配额达到限制的每日弹窗 */
export const QuotaDayWarningModal = () => {
    const { data } = useSpaceQuota()

    const currentSpaceId = useCurrentSpaceID()
    const isCurrent = data?.spaceId === currentSpaceId
    const navigate = useNavigate()

    const [quotaLimitWarningCache, setQuotaLimitWarningCache] = useLocalStorage<Record<string, string>>(`QUOTA_LIMIT_WARNING`)

    const open = quotaLimitWarningCache ? lightFormat(new Date(), 'yyyy-MM-dd') !== quotaLimitWarningCache[currentSpaceId] : true

    const reachLimitQuota = useMemo(() => {
        if (!data || !isCurrent) {
            return []
        }

        const list: QuotaPromptData[] = []

        if (data.apiInvoke.usage >= data.apiInvoke.quota && data.apiInvoke.quota > 0) {
            list.push({
                key: 'apiInvoke',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: 'API 调用次数',
                value: `${data.apiInvoke.quota}`
            })
        }
        if (data.fileStorage.usage >= data.fileStorage.quota && data.fileStorage.quota > 0) {
            list.push({
                key: 'fileStorage',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '附件储存',
                value: getFileSizeAccordanceBaseUnit(data.fileStorage.quota, 'MB')
            })
        }
        if (data.numberOfCu.usage >= data.numberOfCu.quota && data.numberOfCu.quota > 0) {
            list.push({
                key: 'numberOfCu',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '算力（cu）',
                value: `${data.numberOfCu.quota} cu`
            })
        }
        if (data.numberOfDomain.usage >= data.numberOfDomain.quota && data.numberOfDomain.quota > 0) {
            list.push({
                key: 'numberOfDomain',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '绑定自有域名',
                value: data.numberOfDomain.quota
            })
        }
        if (data.numberOfPublishedApps.usage >= data.numberOfPublishedApps.quota && data.numberOfPublishedApps.quota > 0) {
            list.push({
                key: 'numberOfPublishedApps',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '可发布应用数量',
                value: data.numberOfPublishedApps.quota
            })
        }
        if (data.numberOfRecords.usage >= data.numberOfRecords.quota && data.numberOfRecords.quota > 0) {
            list.push({
                key: 'numberOfRecords',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '数据表总记录数',
                value: data.numberOfRecords.quota
            })
        }
        if (data.numberOfUsers.usage >= data.numberOfUsers.quota && data.numberOfUsers.quota > 0) {
            list.push({
                key: 'numberOfUsers',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '登录用户数量',
                value: data.numberOfUsers.quota
            })
        }
        if (data.numberOfUv.usage >= data.numberOfUv.quota && data.numberOfUv.quota > 0) {
            list.push({
                key: 'numberOfUv',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '访客数量',
                value: data.numberOfUv.quota
            })
        }
        if (data.sms.usage >= data.sms.quota && data.sms.quota > 0) {
            list.push({
                key: 'sms',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: 'ByeCode验证码短信',
                value: data.sms.quota
            })
        }
        if (data.traffic.usage >= data.traffic.quota && data.traffic.quota > 0) {
            list.push({
                key: 'traffic',
                icon: <IconFont type="WarningCircle" size={20} fill="var(--color-yellow-500)" />,
                name: '访问流量',
                value: `${getFileSizeAccordanceBaseUnit(data.traffic.quota, 'MB')}`
            })
        }

        // 如果只有一个，并且该条是可发布应用数量，则不显示
        if (list.length === 1 && list[0].key === 'numberOfPublishedApps') {
            return []
        }

        return list
    }, [isCurrent, data])

    const handleClose = useCallback(() => {
        // 函数类型有的state有问题 https://github.com/streamich/react-use/blob/master/src/useLocalStorage.ts#L82C1-L83C1
        setQuotaLimitWarningCache({ ...quotaLimitWarningCache, [currentSpaceId]: lightFormat(new Date(), 'yyyy-MM-dd') })
    }, [quotaLimitWarningCache, setQuotaLimitWarningCache, currentSpaceId])

    if (reachLimitQuota.length === 0) {
        return null
    }

    return (
        <QuotaPromptModal
            open={open}
            onClose={handleClose}
            data={reachLimitQuota}
            leftPanelProps={{
                style: { background: `url(${getAssetUrl('common', 'quota_limit_warning.png')}) center center / 100% 100%` },
                children: (
                    <Flex direction="column" gap={6}>
                        <Text weight={600} size={24} lineHeight="33.6px" color="#F79009">
                            配额达到限制
                        </Text>
                        <Text size={12} lineHeight="20px" color="var(--color-gray-700)">
                            请升级版本获取更多的配额
                        </Text>
                    </Flex>
                )
            }}
            rightTitle="以下配额已达到限制:"
            rightActions={
                <Flex gap={12} justifyContent="flex-end">
                    <Button
                        styleTheme="dark"
                        onClick={() => {
                            navigate('/workbench/quota')
                            handleClose()
                        }}
                    >
                        查看配额
                    </Button>
                    <Button
                        onClick={() => {
                            openSpaceGrade('version_upgrade')
                            handleClose()
                        }}
                    >
                        立即升级
                    </Button>
                </Flex>
            }
        />
    )
}
