import { IconFont } from '@byecode/ui/components/IconFont'
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react'
import { ContainerBlock } from '@lighthouse/block'
import { type ContainerBlockAbstract, NavigationShowMode } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import {
    actionTypeInfos,
    checkActionEvent,
    DEFAULT_ACTION,
    PAGE_CONTAINER_HOST_ID,
    useActionRunningLoadings,
    useAppContainerContext,
    useAtomData,
    useRegisterBlockListener
} from '@lighthouse/shared'
import { findParentByClassName, findParentById, stopPropagation } from '@lighthouse/tools'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'

const ActionSection = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    padding: 2px 8px;
    color: var(--color-purple-700);
    font-size: 12px;
    line-height: 20px;
    background-color: #5551ff33;
    &:hover {
        background-color: #5551ff4d;
    }
    &[disabled] {
        cursor: not-allowed;
        opacity: 0.8;
    }
`

function getBoundary(currentElement: null | HTMLElement, id: string) {
    // 如果在弹窗中
    const boundary = findParentByClassName(currentElement, 'byecode-Modal-modal')
    if (boundary) {
        return boundary
    }

    return findParentById(currentElement, id)
}

interface ContainerControllerProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: ContainerBlockAbstract
    node: FlowLayoutNode
    disabled?: boolean
    onBlockChange?: (values: ContainerBlockAbstract, origin: ContainerBlockAbstract) => Promise<void> | void
}

const ContainerController = ({ blockData, onBlockChange, ...props }: ContainerControllerProps) => {
    const { config, id } = blockData
    const { action = DEFAULT_ACTION, shown } = config
    const { handleActionTrigger } = useActionTrigger()
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()

    const handleViewChange = useAtomCallback<void, string[]>((_, set, view) => {
        set(pageStackAtom, draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.blockRuntimeState.container = {
                    ...stack.blockRuntimeState.container,
                    [id]: {
                        ...stack.blockRuntimeState.container?.[id],
                        currentView: view
                    }
                }
            }
        })
    })

    useRegisterBlockListener(id, 'container', {
        switchPanel: payload => {
            payload && handleViewChange(payload.switchPanelId)
        },
        open: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: true } }
            onBlockChange?.(newBlockData, blockData)
        },
        close: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: false } }
            onBlockChange?.(newBlockData, blockData)
        }
    })

    const onClickAction = (ev: React.MouseEvent) => {
        if (!checkActionEvent(ev)) {
            return
        }

        if (action.type === 'none') {
            return
        }

        ev.stopPropagation()

        // eslint-disable-next-line no-return-await
        handleActionTriggerWithLoading({ type: 'click', action, id, trigger: async () => await handleActionTrigger(action) })
    }

    return <ContainerBlock onClick={onClickAction} shown={shown} {...props} />
}

export default ContainerController
