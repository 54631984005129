import type { DividerBlockConfig } from '@lighthouse/core'

export const generateDividerBlock = (): DividerBlockConfig => {
    return {
        visible: true,
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 20,
                    unit: 'px'
                },
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
