import {
    AdvancedTableBlock,
    CalendarBlock,
    CustomViewBlock,
    GalleryBlock,
    KanbanBoardBlock,
    ListBlock,
    TableBlock
} from '@lighthouse/block'
import type {
    AiFieldStatus,
    BlockAbstract,
    ButtonAction,
    DataSourceAbstract,
    FieldADTValue,
    KanbanColumnsSort,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SelectedMode,
    TableColumns,
    TableColumnWidth,
    ViewBlockAbstract
} from '@lighthouse/core'
import { RecordOpenType } from '@lighthouse/core'
import type {VisibleDomProps } from '@lighthouse/shared';
import { type ApplicationPreviewEnum, type FlowLayoutNode, findBlockById, getFieldBlockWithDsId, useAppContainerContext, useApplicationContext, useAtomData } from '@lighthouse/shared'
import type { BreakPointSize } from '@lighthouse/tools'
import produce from 'immer'
import { useAtomCallback } from 'jotai/utils'
import type { atomWithImmer } from 'jotai-immer'
import React, { useCallback, useMemo } from 'react'

import { appRoleAtom } from '@/atoms/application/state'
import { pageBlocksAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useCurrentAppID } from '@/hooks/useApplication';
import { useDataSourceList } from '@/hooks/useDataSource';
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'
import { uploadManagerInAppParams } from '@/utils/auth'

interface ViewBlockRenderProps {
    envId: string
    // common
    dataSource: DataSourceAbstract
    blockData: ViewBlockAbstract
    // 视图
    dataSourceList: DataSourceAbstract[]
    records?: RecordLikeProtocol[]
    previewType: ApplicationPreviewEnum
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    selectedRecords: string[]
    blockWidth: number
    breakPoint: BreakPointSize
    tablePropsCache: TableColumns
    tableColumnCache?: TableColumnWidth
    cachedKanbanSort?: KanbanColumnsSort
    // 视图
    onSelectedRecords: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordAdd: (initialRecordValue?: Record<string, string | number>) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    // 高级视图
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    onCellUpdate?: (recordId: string, fieldValue: FieldADTValue) => Promise<boolean>
    // 日历视图
    onUpdateRecord: (recordId: string, content: RecordLikeProtocol['content']) => Promise<RecordLikeProtocol>

    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>

    onSelectModeChange?: (mode?: SelectedMode) => void

    onTableColumnWidthChange: (val: TableColumnWidth) => void

    onChangeCachedKanbanSort: (val: KanbanColumnsSort | undefined) => void

    pageTarget?: string
    // 自定义视图
    node: FlowLayoutNode
}

export const ViewBlockRender: React.FC<ViewBlockRenderProps> = props => {
    const { envId, blockData, dataSource } = props
    const appId = useCurrentAppID()
    const { config, id } = blockData
    const { viewType } = config
    const userId = useAtomData(appRoleAtom)
    const { pageId } = useCurrentPageContext()
    const { scale } = useAppContainerContext()
    const stackId = useCurrentStackIdContext()
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()
    const dataSourceList = useDataSourceList(appId, envId)
    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const { curr, prev } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const uploadData = useMemo(
        () => ({
            uploadOptions: {
                info: { id: '', label: dataSource.name, groupId: dataSource.id },
                options: uploadManagerInAppParams()
            },
            richTextUploadOptions: uploadManagerInAppParams(),
            videoUploadOptions: {
                info: { id: '', label: dataSource.name, groupId: dataSource.id },
                options: uploadManagerInAppParams()
            }
        }),
        [dataSource.id, dataSource.name]
    )

    const { rootPageId } = useRootPageContext()
    const [selectedNodes, displayType] = useAtomData(
        pageStackAtomFamily({ stackId, rootPageId }),
        useCallback(s => [s?.state.selectedNodes, s?.stackDisplayType ?? RecordOpenType.page] as const, [])
    )


    const getIsVisible = useVisibilityFilter({ prev, curr })

    const fieldBlocksWithDsId = useMemo(
        () => getFieldBlockWithDsId({ blocks: pageBlocks, pageDsId: curr?.page?.dsId }),
        [curr?.page?.dsId, pageBlocks]
    )


    const rec = useCallback(
        (nodes: FlowLayoutNode[], currentPageBlocks: BlockAbstract[], record: RecordLikeProtocol): FlowLayoutNode[] => {
            return nodes.reduce<FlowLayoutNode[]>((total, current) => {
                const block = findBlockById(current.id, currentPageBlocks)
                // block?.id === 'container-W066472f27p2'
                if (!block) {
                    return total
                }
                if (!block.config.breakPoint?.visibility) {
                    if (current.type === 'container' && current.children) {
                        return [...total, { ...current, children: rec(current.children, currentPageBlocks, record) }]
                    }
                    return [...total, current]
                }
                const visible = getIsVisible({
                    visibility: block.config.breakPoint?.visibility,
                    viewRecord: {
                        record,
                        datasource: dataSource
                    },
                    userId
                })

                if (!visible) {
                    return total
                }

                if (current.type === 'container' && current.children) {
                    return [...total, { ...current, children: rec(current.children, currentPageBlocks, record) }]
                }

                return [...total, current]
            }, [])
        },
        [dataSource, getIsVisible, userId]
    )

    const visibleParams: VisibleDomProps = useMemo(
        () => ({
            currentPage: curr,
            prevPage: prev,
            dataSourceList,
            personOptions,
            roleOptions,
            departmentOptions,
            fieldBlocksWithDsId
        }),
        [curr, dataSourceList, departmentOptions, fieldBlocksWithDsId, personOptions, prev, roleOptions]
    )

    const handleFilterNode = useAtomCallback((get, _, node: FlowLayoutNode, record: RecordLikeProtocol) => {
        if (node.type === 'container' && node.children) {
            const currentPageBlocks = get(pageBlocksAtom(pageId))
            return produce(node, draft => {
                if (draft.children) {
                    draft.children = rec(draft.children, currentPageBlocks, record)
                }
            })
        }
        return node
    })

    return useMemo(() => {
        switch (viewType) {
            case 'table': {
                return <TableBlock {...props} />
            }
            case 'advancedTable': {
                return <AdvancedTableBlock {...props} {...uploadData} />
            }
            case 'list': {
                return <ListBlock {...props} />
            }
            case 'gallery': {
                return <GalleryBlock scale={scale} {...props} />
            }
            case 'kanban': {
                return <KanbanBoardBlock {...props} />
            }
            case 'calendar': {
                return <CalendarBlock {...props} />
            }
            case 'custom': {
                return <CustomViewBlock scale={scale} userId={userId} visibleParams={visibleParams} onFilterNode={handleFilterNode} {...props} />
            }
            default: {
                return null
            }
        }
    }, [handleFilterNode, props, scale, uploadData, userId, viewType, visibleParams])
}
