import { collectBlockAndChildren } from '@lighthouse/block'
import type { BlockAbstract } from '@lighthouse/core'
import { findBlockById, findParentBlockById, getCurrentBlockChildren, useAtomAction } from '@lighthouse/shared'
import { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'

import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, pageStackAtomFamily } from '@/atoms/page/state'
import * as srv from '@/services'
import {
    BLOCK_NODE_CREATE_ACTION,
    BLOCK_NODE_REMOVE_ACTION,
    NODE_UPDATE_ACTION,
    pageUndoRedoController
} from '@/utils/undoRedo/page/controller'

import { copyBlock, removeBlockById } from './utils'

interface Params {
    pageId: string
    rootPageId: string
    stackId: string
}

/**
 * 一些操作nodes的方法
 * @returns actions functions
 */
export const useNodeToolbarActions = ({ stackId, rootPageId, pageId }: Params) => {
    const { run: setBlocks } = useAtomAction(blocksAtom)
    const { run: createBlock } = useAtomAction(createBlockAtom)
    const { run: removeBlock } = useAtomAction(removeBlockAtom)

    // 克隆block
    const onClone = useAtomCallback<void, [string]>(async (get, set, id) => {
        const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

        let originBlocks: BlockAbstract[] | undefined
        let finalBlocks: BlockAbstract[] = []
        let newBlock: BlockAbstract | undefined

        let addedBlocksIds: string[] = []

        setBlocks(draft => {
            const pageBlocks = draft[pageId]
            originBlocks = original(pageBlocks)
            if (!pageBlocks) {
                return
            }

            const copiedBlock = findBlockById(id, current(pageBlocks))
            if (!copiedBlock) {
                return
            }

            const parentBlock = findParentBlockById(id, pageBlocks)

            const children = parentBlock ? getCurrentBlockChildren(parentBlock, blockRuntimeState) : pageBlocks
            if (!children) {
                return
            }

            const index = children.findIndex(item => item.id === copiedBlock.id)
            if (index === -1) {
                return
            }

            // 如果复制的容器，则递归复制容器内的子元素
            newBlock = copyBlock(copiedBlock)

            addedBlocksIds = collectBlockAndChildren(newBlock).map(item => item.id)
            set(blockCreatingListAtom, s => [...s, ...addedBlocksIds])

            children.splice(index + 1, 0, newBlock)

            finalBlocks = current(pageBlocks)
        })

        if (!newBlock) {
            return
        }

        await createBlock({ rootPageId, pageId, stackId, blocks: finalBlocks, createdBlocks: [newBlock] })
        set(blockCreatingListAtom, s => s.filter(id => !addedBlocksIds.includes(id)))

        pageUndoRedoController.add({
            action: BLOCK_NODE_CREATE_ACTION,
            payload: {
                createdBlocks: [newBlock],
                prev: originBlocks,
                next: finalBlocks
            }
        })
    })

    // 删除block
    const onRemove = useAtomCallback<void, [string[]]>((get, set, ids: string[]) => {
        let originBlocks: BlockAbstract[] | undefined
        let finalBlocks: BlockAbstract[] = []
        const removedBlocks: BlockAbstract[] = []

        set(blocksAtom, draft => {
            const currentBlocks = draft[pageId]
            originBlocks = original(currentBlocks)
            if (!currentBlocks) {
                return
            }

            ids.forEach(id => {
                const removed = removeBlockById(id)(currentBlocks)
                if (removed) {
                    removedBlocks.push(...removed.map(current))
                }
            })

            finalBlocks = current(currentBlocks)
        })

        if (removedBlocks.length === 0) {
            return
        }

        pageUndoRedoController.add({
            action: BLOCK_NODE_REMOVE_ACTION,
            payload: {
                removedBlocks,
                prev: originBlocks,
                next: finalBlocks
            }
        })

        removeBlock({ rootPageId, pageId, stackId, blocks: finalBlocks, removedBlocks })
    })

    // 交换顺序
    const onSwap = useAtomCallback<void, [string, string]>((get, set, from: string, to: string) => {
        if (from === to) {
            return
        }

        const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

        function recursionSwap(blocks: BlockAbstract[]) {
            let fromIndex: number | undefined
            let toIndex: number | undefined
            for (const [index, node] of blocks.entries()) {
                if (node.id === from) {
                    fromIndex = index
                }

                if (node.id === to) {
                    toIndex = index
                }
                if (fromIndex !== undefined && toIndex !== undefined && fromIndex !== toIndex) {
                    ;[blocks[fromIndex], blocks[toIndex]] = [blocks[toIndex], blocks[fromIndex]]
                    return true
                }

                const children = getCurrentBlockChildren(node, blockRuntimeState)
                if (children) {
                    const isSwapped = recursionSwap(children)
                    if (isSwapped) {
                        return true
                    }
                }
            }

            return false
        }

        let originNodes: BlockAbstract[] | undefined
        let finalNodes: BlockAbstract[] = []
        setBlocks(draft => {
            const pageBlocks = draft[pageId]
            originNodes = original(pageBlocks)
            if (!pageBlocks) {
                return
            }

            recursionSwap(pageBlocks)
            finalNodes = current(pageBlocks)
        })

        srv.updateBlock({
            allBlocks: finalNodes,
            pageId
        })

        pageUndoRedoController.add({
            action: NODE_UPDATE_ACTION,
            payload: {
                prev: originNodes,
                next: finalNodes
            }
        })
    })

    return {
        onClone,
        onRemove,
        onSwap
    }
}
