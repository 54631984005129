import { Box, SegmentedControl } from '@byecode/ui'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { SMS_TYPE } from '@lighthouse/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { TagIcon } from '@/components/TagIcon'

import { LoginBlock } from '../../SettingAuthentication/LoginBlock'
import { SelfSms } from './SelfSms'
import { SystemSms } from './SystemSms'

const SmsOptions = [
    {
        label: '由 ByeCode 发送',
        value: SMS_TYPE.SYSTEM
    },
    {
        label: '选择自有短信',
        value: SMS_TYPE.SELF
    }
]
interface LoginSmsProps {
    prefix?: string
    style?: React.CSSProperties
}

export const SmsConfig: React.FC<LoginSmsProps> = ({ prefix = '', style }) => {
    const { control, watch } = useFormContext()

    const smsType = watch(`${prefix}.smsType`)

    return (
        <Box style={{ flex: 1, ...style }}>
            <Controller
                control={control}
                name={`${prefix}.smsType`}
                render={({ field }) => (
                    <SegmentedControl
                        data={SmsOptions}
                        value={field.value}
                        onChange={field.onChange}
                        fullWidth
                        style={{ marginBottom: 12 }}
                    />
                )}
            />
            {smsType === SMS_TYPE.SELF ? <SelfSms prefix={prefix} /> : <SystemSms />}
        </Box>
    )
}
