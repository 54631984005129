import { IconFont } from '@byecode/ui'
import { Button } from '@byecode/ui/components/Button'
import type { AppUser, DataSourceAbstract, Field, RecordLikeProtocol } from '@lighthouse/core'
import type { FlowNode, VariableSource } from '@lighthouse/shared'
import {
    getDefaultValueOptionsByInnerType,
    getFieldIcon,
    getFormDatasourceOptions,
    getUserDatasourceOptions,
    getViewOptions,
    matchWritableBetweenField,
    NodeFieldPreview,
    pureTextFieldTypes,
    useAtomData,
    USER_DATASOURCE,
    VariableSourceType
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

const SCxFieldItemWrapper = styled.div`
    margin-bottom: 12px;
`

export const SCxHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const SCxNodeFieldPreviewerWrapper = styled.div`
    position: relative;
    flex: 1;
    margin-top: 2px;
    cursor: pointer;
`

export const SCxLabel = styled.div`
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    overflow: hidden;
`

export const SCxIcon = styled(IconFont)``

export const SCxText = styled.div`
    flex: 1;
    margin-left: 6px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
`

interface FieldItemProps {
    appId: string
    dataSourceList: DataSourceAbstract[]
    prefixName: string
    field: Field
    allRecords?: RecordLikeProtocol[]
    allParentNodes?: FlowNode[]
    placeholder?: string
    autoHeight?: boolean
    disablePageLink?: boolean
    disableFormOption?: boolean
    disableViewOption?: boolean
    disablePageOption?: boolean
    onItemDelete?: () => void
}

// const AddVariableButton = React.forwardRef<HTMLDivElement>(({ ...props }, ref) => {
//     return (
//         <SCxAddVariableButton {...props} ref={ref}>
//             <SCxIcon type="Add" fill="var(--color-white)" size={16} />
//         </SCxAddVariableButton>
//     )
// })

export const FieldItem: React.FC<FieldItemProps> = ({
    appId,
    dataSourceList,
    prefixName,
    field,
    allParentNodes,
    placeholder,
    autoHeight = false,
    disablePageLink,
    disableFormOption,
    disableViewOption,
    disablePageOption,
    onItemDelete
}) => {
    const { control, watch } = useFormContext()

    const { dsId, isForm, viewType } = useActionAdderDepParams()
    const { type } = useFlow()
    const disabledWithVersion = useIsDisabledWithVersion()

    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const { customViewOption } = useVariableCustomViewOption(validatedField => matchWritableBetweenField(field, validatedField.type))

    const dataSource = useMemo(() => dataSourceList.find(ds => ds.id === dsId), [dataSourceList, dsId])

    const formOption = useMemo(
        () => (!disableFormOption && isForm && !isInFlow ? getFormDatasourceOptions({ dataSource, validateFieldType: validatedField => validatedField.innerType === field.innerType }) : undefined),
        [dataSource, disableFormOption, field.innerType, isForm, isInFlow]
    )

    const viewOption = useMemo(() => {
        if (isInFlow || disableViewOption) {
            return
        }
        return (
            customViewOption ||
            getViewOptions({
                dataSource,
                viewType,
                validateFieldType: validatedField => validatedField.innerType === field.innerType
            })
        )
    }, [isInFlow, disableViewOption, customViewOption, dataSource, viewType, field.innerType])

    const userOption = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: validatedField => validatedField.innerType === field.innerType
            }),
        [dataSourceList, field]
    )

    const enablePageLinkOption = !disablePageLink && type !== 'approval' && type !== 'automation' && pureTextFieldTypes.has(field.type)

    const options = useMemo(() => {
        // if (!dataSource) {
        //     return
        // }

        return getDefaultValueOptionsByInnerType({
            sources: [
                isInFlow && {
                    sourceType: VariableSourceType.parentNode,
                    parentNodes: allParentNodes,
                    dataSourceList
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.parentPage,
                    dataSource: prev.datasource,
                    page: prev.page
                },
                isNotAutomation && !disablePageOption && {
                    sourceType: VariableSourceType.page,
                    dataSource: curr.datasource,
                    page: curr.page
                }
            ].filter(Boolean) as VariableSource[],
            validateInnerType: innerType => field.innerType === innerType
        })
    }, [isInFlow, allParentNodes, dataSourceList, isNotAutomation, prev.datasource, prev.page, disablePageOption, curr.datasource, curr.page, field.innerType])

    return (
        <SCxFieldItemWrapper>
            <SCxHeader>
                <SCxLabel>
                    <SCxIcon type={getFieldIcon(field.id, field.type, field.innerType)} color="var(--color-gray-400)" />
                    <SCxText>{field.name}</SCxText>
                </SCxLabel>
                <Button type="text" onClick={onItemDelete} icon={<SCxIcon type="Trash" color="var(--color-gray-400)" />} />
            </SCxHeader>
            <SCxNodeFieldPreviewerWrapper style={{ height: autoHeight ? 'auto' : 36 }}>
                <Controller
                    name={prefixName}
                    control={control}
                    render={({ field: ctlField }) => {
                        return (
                            <NodeFieldPreview
                                disabled={disabledWithVersion}
                                autoHeight={autoHeight}
                                onChange={ctlField.onChange}
                                value={ctlField.value}
                                placeholder={placeholder}
                                field={field}
                                enablePageLink={enablePageLinkOption}
                                formOption={formOption}
                                userOption={userOption}
                                options={options}
                                viewOption={viewOption}
                            />
                        )
                    }}
                />
            </SCxNodeFieldPreviewerWrapper>
        </SCxFieldItemWrapper>
    )
}
