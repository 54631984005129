import { Checkbox, Flex, IconFont, Input, SegmentedControl, Select, Slider, Text, Tooltip } from '@byecode/ui'
import type { ApplicationSettingNavbar } from '@lighthouse/core'
import { ApplicationSettingThemeNavBarMode, NavigationShowMode } from '@lighthouse/core'
import { ListItem4ByecodeUi, useAtomAction, useAtomData } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { clone } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useLatest, useUpdateEffect } from 'react-use'
import { debounce } from 'throttle-debounce'

import { fetchAppSettingAtom, updateAppVersionConfigAtom } from '@/atoms/application/action'
import { applicationThemeColorAtom, websiteApplicationSettingAtom } from '@/atoms/application/state'
import { AppStyle } from '@/components/AppCreator/AppSet/AppStyle'
import { SwitchControl } from '@/components/BlockSettings/Common/SwitchControl'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { MAX_WIDTH_OPTIONS } from '@/constants/common'
import { useLanguage } from '@/hooks/useApplication'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { transform2Integer } from '@/utils/number'

import SettingBlock from '../SettingBlock'
import * as CM from '../styles'
import NavBarButton from './NavBarButton'
import NavBarLink from './NavBarLink'
import NavBarLogo from './NavBarLogo'
import NavBarMode from './NavBarMode'
import * as SC from './styles'

interface SettingNavbarProps {}

const VERTICAL_FLEX_LAYOUT_OPTIONS = [
    {
        value: 'flex-start',
        icon: (
            <Tooltip title="居左">
                <IconFont size={16} type="AlignLeft" />
            </Tooltip>
        )
    },
    {
        value: 'center',
        icon: (
            <Tooltip title="居中">
                <IconFont size={16} type="AlignCenter" />
            </Tooltip>
        )
    },
    {
        value: 'flex-end',
        icon: (
            <Tooltip title="居右">
                <IconFont size={16} type="AlignRight" />
            </Tooltip>
        )
    }
]

const SettingNavbar: React.FC<SettingNavbarProps> = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const navbar = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.navbar, [])
    )
    const navbarRef = useLatest(navbar)
    const disabledRef = useRef(false)
    const { run: updateAppVersionConfig } = useAtomAction(updateAppVersionConfigAtom)
    const { run: fetchAppSetting } = useAtomAction(fetchAppSettingAtom)

    const language = useLanguage()
    const activeColor = useAtomData(applicationThemeColorAtom)

    const methods = useForm<ApplicationSettingNavbar>({
        mode: 'onChange',
        defaultValues: navbar
    })

    const { watch, control, setValue, reset } = methods

    const [isNavbarShow, showMode, backgroundOverlay, isSticky] = watch(['isShow', 'showMode', 'backgroundOverlay', 'isSticky'])

    const handleSubmit = useMemo(
        () =>
            debounce(500, async (value: ApplicationSettingNavbar) => {
                if (disabledRef.current) {
                    disabledRef.current = false
                    return
                }
                const isSuccess = await updateAppVersionConfig({
                    config: {
                        navbar: value
                    }
                })
            }),
        [updateAppVersionConfig]
    )

    useEffect(() => {
        const { unsubscribe } = watch((value, { name, type }) => {
            handleSubmit(clone(value) as ApplicationSettingNavbar)
        })
        return unsubscribe
    }, [handleSubmit, watch])

    useUpdateEffect(() => {
        disabledRef.current = true
        reset(navbarRef.current)
    }, [language])

    return (
        <SC.Container>
            <SC.BlockContainer>
                {/* <Controller name="isShow" control={control} render={({ field }) => <NavBarShow {...field} />} /> */}
                <Controller
                    name="showMode"
                    control={control}
                    render={({ field }) => <NavBarMode disabled={disabledWithVersion} {...field} />}
                />
                <SettingBlock label="导航栏风格">
                    <Controller
                        name="themeMode"
                        control={control}
                        render={({ field }) => {
                            return (
                                <SC.Row>
                                    <SC.Card
                                        width={72}
                                        onClick={() => !disabledWithVersion && field.onChange(ApplicationSettingThemeNavBarMode.custom)}
                                    >
                                        <AppStyle
                                            navigationShowMode={showMode}
                                            navBarColor={activeColor}
                                            active={field.value === ApplicationSettingThemeNavBarMode.custom}
                                        />
                                        <SC.CardText active={field.value === ApplicationSettingThemeNavBarMode.custom}>主题色</SC.CardText>
                                    </SC.Card>
                                    <SC.Card
                                        width={72}
                                        onClick={() => !disabledWithVersion && field.onChange(ApplicationSettingThemeNavBarMode.dark)}
                                    >
                                        <AppStyle
                                            navigationShowMode={showMode}
                                            navBarColor="var(--color-black)"
                                            active={field.value === ApplicationSettingThemeNavBarMode.dark}
                                        />
                                        <SC.CardText active={field.value === ApplicationSettingThemeNavBarMode.dark}>深色</SC.CardText>
                                    </SC.Card>
                                    <SC.Card
                                        width={72}
                                        onClick={() => !disabledWithVersion && field.onChange(ApplicationSettingThemeNavBarMode.light)}
                                    >
                                        <AppStyle
                                            navigationShowMode={showMode}
                                            iconColor={activeColor}
                                            navBarColor="var(--color-white)"
                                            contentColor="var(--color-gray-100)"
                                            boxShadow="-1px -1px 0.7px 0px #8A8CB924"
                                            active={field.value === ApplicationSettingThemeNavBarMode.light}
                                        />
                                        <SC.CardText active={field.value === ApplicationSettingThemeNavBarMode.light}>浅色</SC.CardText>
                                    </SC.Card>
                                    <SC.Card
                                        width={72}
                                        onClick={() =>
                                            !disabledWithVersion && field.onChange(ApplicationSettingThemeNavBarMode.transparent)
                                        }
                                    >
                                        <AppStyle
                                            navigationShowMode={showMode}
                                            iconColor={activeColor}
                                            navBarColor="transparent"
                                            contentColor="transparent"
                                            boxShadow="-1px -1px 0.7px 0px #8A8CB924"
                                            contentStyle={{
                                                background: 'linear-gradient(137.8deg, #E4F2FF 4.58%, #FCEFFF 40.23%, #E6EFFB 75.88%)'
                                            }}
                                            active={field.value === ApplicationSettingThemeNavBarMode.transparent}
                                        />
                                        <SC.CardText active={field.value === ApplicationSettingThemeNavBarMode.transparent}>
                                            透明
                                        </SC.CardText>
                                    </SC.Card>
                                </SC.Row>
                            )
                        }}
                    />
                </SettingBlock>
                <SettingBlock label="导航栏外框">
                    {showMode === NavigationShowMode.horizontal && (
                        <>
                            {' '}
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>左右边距</div>
                                <Controller
                                    control={control}
                                    name="leftAndRightPadding"
                                    render={({ field }) => (
                                        <Slider
                                            min={0}
                                            max={7}
                                            step={0.125}
                                            {...field}
                                            style={{ width: 180 }}
                                            inputProps={{
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>上边距</div>
                                <Controller
                                    control={control}
                                    name="topPadding"
                                    render={({ field }) => (
                                        <Slider
                                            min={0}
                                            max={7}
                                            step={0.125}
                                            {...field}
                                            style={{ width: 180 }}
                                            inputProps={{
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                        </>
                    )}
                    {showMode === NavigationShowMode.verticalWide && (
                        <>
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>左边距</div>
                                <Controller
                                    control={control}
                                    name="leftPadding"
                                    render={({ field }) => (
                                        <Slider
                                            min={0}
                                            max={7}
                                            step={0.125}
                                            {...field}
                                            style={{ width: 180 }}
                                            inputProps={{
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>上下边距</div>
                                <Controller
                                    control={control}
                                    name="topAndDownPadding"
                                    render={({ field }) => (
                                        <Slider
                                            min={0}
                                            max={7}
                                            step={0.125}
                                            {...field}
                                            style={{ width: 180 }}
                                            inputProps={{
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                        </>
                    )}
                </SettingBlock>
                <SettingBlock label="导航栏样式">
                    {showMode === NavigationShowMode.horizontal && (
                        <>
                            <Controller
                                name="isSticky"
                                control={control}
                                render={({ field }) => (
                                    <SwitchControl label="吸顶" checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                                )}
                            />
                            {isSticky && (
                                <>
                                    <Controller
                                        name="backgroundOverlay"
                                        control={control}
                                        render={({ field }) => (
                                            <SwitchControl
                                                label="叠加背景"
                                                checked={field.value}
                                                onChange={e => field.onChange(e.target.checked)}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="backgroundBlur"
                                        control={control}
                                        render={({ field }) => (
                                            <SwitchControl
                                                label="背景模糊"
                                                checked={field.value}
                                                onChange={e => field.onChange(e.target.checked)}
                                            />
                                        )}
                                    />
                                </>
                            )}
                        </>
                    )}

                    {showMode === NavigationShowMode.horizontal && (
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>对齐</Text>
                            <Controller
                                control={control}
                                name="align"
                                render={({ field }) => (
                                    <SegmentedControl {...field} fullWidth data={VERTICAL_FLEX_LAYOUT_OPTIONS} style={{ width: 180 }} />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    )}
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <div>圆角</div>
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Controller
                                control={control}
                                name="radius.0"
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<CM.Icon type="RadiusLeftTop" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="radius.1"
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<CM.Icon type="RadiusRightTop" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="flex-end">
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Controller
                                control={control}
                                name="radius.3"
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<CM.Icon type="RadiusLeftBottom" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="radius.2"
                                defaultValue={0}
                                render={({ field }) => (
                                    <Input
                                        value={field.value}
                                        onChange={e => field.onChange(transform2Integer(e.target.value))}
                                        placeholder=""
                                        prefix={<CM.Icon type="RadiusRightBottom" />}
                                        onFocus={e => {
                                            e.target.select()
                                        }}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>

                    {showMode === NavigationShowMode.horizontal && (
                        <>
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>高度</div>
                                <Controller
                                    control={control}
                                    name="height"
                                    render={({ field }) => (
                                        <Slider
                                            min={3}
                                            max={10}
                                            step={0.125}
                                            {...field}
                                            style={{ width: 180 }}
                                            inputProps={{
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                        </>
                    )}
                    {showMode === NavigationShowMode.verticalWide && (
                        <>
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>宽度</div>
                                <Controller
                                    control={control}
                                    name="width"
                                    render={({ field }) => (
                                        <Slider
                                            min={10}
                                            max={20}
                                            step={0.125}
                                            {...field}
                                            style={{ width: 180 }}
                                            inputProps={{
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                        </>
                    )}
                </SettingBlock>
                {showMode === NavigationShowMode.horizontal && (
                    <SettingBlock label="导航栏内容">
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>最大宽度</div>
                            <Controller
                                control={control}
                                name="maxWidth"
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        // prefix={<IconFont type="MaxWidth" />}
                                        options={MAX_WIDTH_OPTIONS}
                                        styles={{ root: { width: 180 } }}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <div>横向边距</div>
                            <Controller
                                control={control}
                                name="backgroundPadding"
                                render={({ field }) => (
                                    <Slider
                                        min={0}
                                        max={12}
                                        step={0.125}
                                        {...field}
                                        style={{ width: 180 }}
                                        inputProps={{
                                            onFocus: e => {
                                                e.target.select()
                                            }
                                        }}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </SettingBlock>
                )}
                <Controller name="logo" control={control} render={({ field }) => <NavBarLogo {...field} />} />
                <SettingBlock label="导航链接">
                    <PopoverQuestion
                        type="tabBar"
                        mainAxis={38}
                        renderTarget={node => (
                            <Checkbox
                                checked={isNavbarShow}
                                color="var(--color-main)"
                                label={
                                    <Flex alignItems="center" gap={4}>
                                        在 H5 中显示为底部标签栏{node}
                                    </Flex>
                                }
                                onChange={e => setValue('isShow', Boolean(e.target.checked))}
                            />
                        )}
                    />

                    <Controller
                        name="linkList"
                        control={control}
                        render={({ field }) => <NavBarLink disabled={disabledWithVersion} {...field} />}
                    />
                </SettingBlock>
                <Divider />
                <SettingBlock label="导航按钮">
                    <Controller
                        name="buttonList"
                        control={control}
                        render={({ field }) => <NavBarButton disabled={disabledWithVersion} {...field} />}
                    />
                </SettingBlock>
            </SC.BlockContainer>
        </SC.Container>
    )
}

export default SettingNavbar
