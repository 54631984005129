import type { BlockAbstract } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { useAtomCallback } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import { debounce } from 'throttle-debounce'

import { viewRecordListAtomFamily } from '@/atoms/blockRecordsDict/state'
import { updateLocalBlockAtom, updateRemoteBlockAtom } from '@/atoms/page/action'
import { hasBlockNegativeUpdate } from '@/atoms/utils/block'
import { useRootPageContext } from '@/contexts/PageContext'

import { useCurrentAppID } from './useApplication'

export const useBlockActions = (pageId: string, stackId: string) => {
    const appId = useCurrentAppID()

    const { run: updateRemoteBlock } = useAtomAction(updateRemoteBlockAtom)
    const { run: updateLocalBlock } = useAtomAction(updateLocalBlockAtom)
    const { rootPageId } = useRootPageContext()

    /** 更新远程block */
    const onUpdateRemoteBlock = useMemo(
        () =>
            debounce(500, async (block: BlockAbstract, origin: BlockAbstract) => {
                await updateRemoteBlock({ appId, block, origin, pageId, rootPageId, stackId })
            }),
        [updateRemoteBlock, appId, pageId, rootPageId, stackId]
    )

    /** 更新block */
    const onUpdateBlock = useAtomCallback(
        useCallback(
            (get, set, block: BlockAbstract, origin: BlockAbstract) => {
                if (!hasBlockNegativeUpdate(appId, block, origin)) {
                    updateLocalBlock({ block, pageId })
                }
                onUpdateRemoteBlock(block, origin)
            },
            [appId, onUpdateRemoteBlock, pageId, updateLocalBlock]
        )
    )

    return useMemo(
        () => ({
            onUpdateBlock
        }),
        [onUpdateBlock]
    )
}

export const useViewRecords = (blockId: string) => {
    return useAtomData(viewRecordListAtomFamily(blockId))
}
