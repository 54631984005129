import type { ApplicationAbstract, PageType } from '@lighthouse/core'
import { PAGE_TYPE } from '@lighthouse/core'
import type { AppLoginMode } from '@lighthouse/shared'
import {
    AccountPage as AccountPageContainer,
    ApplicationContainer,
    ApplicationPreviewEnum,
    AppThemeContextProvider,
    getMainTableRecordId,
    PAGE_CONTAINER_HOST_ID,
    useAtomAction,
    useAtomData,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { mergeDeepRight } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { setLanguageAtom } from '@/atoms/application/action'
import { AppSystemPageAtom } from '@/atoms/application/state'
import { openPageStackAtom } from '@/atoms/page/action'
import { homePageIdAtom } from '@/atoms/page/state'
import { stackFactory } from '@/atoms/page/utils'
import { PageTool } from '@/components/PageTool'
import { PageToolMobile } from '@/components/PageTool/mobile'
import { useApplication, useCurrentEnvId, useLanguage, usePreviewType } from '@/hooks/useApplication'
import { useDataSource, useDataSourceRoles } from '@/hooks/useDataSource'
import { useToggleLang } from '@/hooks/useToggleLang'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

interface RegisterProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
`

const SCxContent = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    background-color: var(--color-gray-100);
`

const SCxWrapper = styled.div<{ isMobile?: boolean }>`
    margin: ${({ isMobile }) => (isMobile ? '46px 24px 38px 24px' : '16px 24px 56px 24px')};
    display: flex;
    flex-direction: column;
    height: ${({ isMobile }) => (isMobile ? 'calc(100% - 44px)' : 'calc(100% - 32px)')};
    border-radius: 12px;
    background-color: ${({ isMobile }) => (isMobile ? 'transparent' : 'var(--color-white)')};
    box-shadow: ${({ isMobile }) => (isMobile ? 'none' : '0px 4px 12px 0px rgba(0, 0, 0, 0.12)')};

    #page-scroll-container {
        display: flex;
        align-items: center;
    }
`

const AccountPage: React.FC<RegisterProps> = props => {
    // const appElement = document.querySelector('#dataViewer')
    const { run: openPageStack } = useAtomAction(openPageStackAtom)

    const envId = useCurrentEnvId()
    const app = useApplication()
    const {
        id: appId,
        config: {
            theme,
            navbar,
            authentication: { login },
            authentication
        }
    } = app
    const dataSource = useDataSource(appId, envId, USER_DATASOURCE)
    const language = useLanguage()
    const previewType = usePreviewType()
    const isMobile = previewType === ApplicationPreviewEnum.mobile
    const homePage = useAtomData(homePageIdAtom)
    // 切换语言
    const { handleToggleLang } = useToggleLang()
    const roles = useDataSourceRoles()
    const { data: otherPlatformList } = useOtherPlatformList()

    const wxOfficialAccount = otherPlatformList?.find(v => v.type === 'WECHAT_OFFICIAL_ACCOUNT')
    const isWxOfficialAccount = !!wxOfficialAccount

    const { run: setLanguage } = useAtomAction(setLanguageAtom)
    const { run: setAppSystemPage } = useAtomAction(AppSystemPageAtom)
    const [mode, setMode] = useState<AppLoginMode>('home')

    const loginApp = useMemo(() => mergeDeepRight<ApplicationAbstract>(app, { config: { navbar: { isShow: false } } }), [app])

    const accountDefaultMode = useMemo(() => {
        const loginWayMap: Record<
            Exclude<AppLoginMode, 'bindMobile' | 'perfect' | 'register' | 'home' | 'bindEmail' | 'bindWechat'>,
            boolean | undefined
        > = {
            weChatLogin: login?.weChat.isOpened,
            emailLogin: login?.email.isOpened,
            mobileLogin: login?.phone.isOpened
        }
        const loginWayList = Object.entries(loginWayMap).filter(([name, isOpened]) => isOpened)
        const loginWay = loginWayList[0]
        if (loginWayList.length === 1 && loginWay[0] !== 'weChatLogin') {
            return loginWay[0] as AppLoginMode
        }
        return 'home'
    }, [login])

    const fetchDataSourceFirstRecord = useCallback(
        async ({ dsId }: { dsId: string }) => {
            const res = await srv.getDs({
                appId,
                envId,
                dsId,
                pagination: { currentPage: 1, pageSize: 100 }
            })

            return res?.records?.[0]
        },
        [appId, envId]
    )

    const getPageStackExtraInfo = useCallback(
        async (pageType: PageType, dsId: string) => {
            const firstRecord = await fetchDataSourceFirstRecord({ dsId })
            const firstRecordId = getMainTableRecordId(firstRecord?.id)
            return pageType === PAGE_TYPE.default ? {} : { stackId: nanoid(), dsId, recordId: firstRecordId }
        },
        [fetchDataSourceFirstRecord]
    )

    const handleHomeBack = useCallback(async () => {
        if (!homePage) {
            return
        }
        const { id: pageId, dsId, appId, type } = homePage
        const pageStackExtraInfo = dsId ? await getPageStackExtraInfo(type, dsId) : {}
        setAppSystemPage(null)
        openPageStack(stackFactory({ pageId, appId, ...pageStackExtraInfo }))
    }, [getPageStackExtraInfo, homePage, openPageStack, setAppSystemPage])

    useEffect(() => {
        if (accountDefaultMode) {
            setMode(accountDefaultMode)
        }
    }, [accountDefaultMode])

    return (
        <AppThemeContextProvider theme={theme}>
            <SCxContainer>
                <SCxContent>
                    {isMobile && <PageToolMobile />}
                    <SCxWrapper isMobile={isMobile}>
                        <ApplicationContainer
                            id={PAGE_CONTAINER_HOST_ID}
                            application={loginApp}
                            previewMode={isMobile ? 'simulationMobile' : 'simulationWebsite'}
                            language={language}
                            onChangeLanguage={handleToggleLang}
                            topNavbar={!isMobile && <PageTool style={{ borderRadius: '12px 12px 0 0' }} />}
                            styles={{
                                desktopHeader: {
                                    display: 'none'
                                },
                                mobileHeader: {
                                    display: 'none'
                                },
                                wrapper: {
                                    background: isMobile ? '#fff' : undefined
                                }
                            }}
                        >
                            <AccountPageContainer
                                mode={mode}
                                dataSource={dataSource}
                                disableEvent
                                authentication={authentication}
                                language={language}
                                roles={roles}
                                navbar={navbar}
                                isWxOfficialAccount={isWxOfficialAccount}
                                style={{ padding: '0 32px' }}
                                onChangeMode={setMode}
                                onHomeBack={handleHomeBack}
                                onChangeLanguage={v => {
                                    setLanguage(v)
                                    accountDefaultMode && setMode(accountDefaultMode)
                                }}
                            />
                        </ApplicationContainer>
                    </SCxWrapper>
                    {/* <DomainFiling data={appSetting.domain} isMobile={previewType === ApplicationPreviewEnum.mobile} /> */}
                </SCxContent>
            </SCxContainer>
            {/* {appElement && createPortal(<PageFooter />, appElement)} */}
        </AppThemeContextProvider>
    )
}

export default AccountPage
