import { backgroundTransitionOnClick, IconFont, pointer, singleTextEllipsis, Tooltip } from '@byecode/ui'
import { AppUserStatus } from '@lighthouse/core'
import {
    getColorById,
    getImageFullUrlInApplication,
    PersonSelect,
    TooltipText,
    useApplicationContext,
    useAtomAction,
    useAtomData,
    UserAvatar
} from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import { clone, find } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { setAppRoleAtom } from '@/atoms/application/action'
import { commonRoleIds, developerRole, visitorRole } from '@/atoms/application/constants'
import { appRoleAtom } from '@/atoms/application/state'
import { useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceRoles, useDataSourceUsers } from '@/hooks/useDataSource'

import * as SC from './styles'

interface SelectRoleProps {}

const SCxIconWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    align-items: center;
    height: 22px;
`

const SCxLeftFill = styled.div`
    display: flex;
    align-items: center;

    > * {
        margin-right: 4px;
    }
`

const SCxUserContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    /* max-width: calc(100% - 30% - 356px); */
    overflow: hidden;
    gap: 4px;
    white-space: nowrap;

    /* background-color: #fff; */
`
const SCxRoleContainer = styled.div`
    display: inline-flex;
    padding: 4px;
    height: 32px;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    ${backgroundTransitionOnClick}
`
const SCxTargetContainer = styled.span`
    ${singleTextEllipsis}
    line-height: 22px;
`

const SCxDropDownItem = styled.div`
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${backgroundTransitionOnClick}
    ${pointer}
`

export const SelectRole: React.FunctionComponent<SelectRoleProps> = () => {
    const userId = useAtomData(appRoleAtom)
    const envId = useCurrentEnvId()
    const roles = useDataSourceRoles()
    const users = useDataSourceUsers()
    const { run: setAppRole } = useAtomAction(setAppRoleAtom)
    const { appId } = useApplicationContext()
    /** 获取当前用户记录 */
    const currentOptions = useMemo(() => clone(users).filter(item => item.status?.[0] !== AppUserStatus.DEPART), [users])

    const user = useMemo(() => {
        if (userId === developerRole.userId) {
            return developerRole
        }
        if (userId === visitorRole.userId) {
            return visitorRole
        }
        return find(item => item.userId === userId, currentOptions)
    }, [userId, currentOptions])

    const renderAvatar = useMemo(() => {
        if (user?.userId === developerRole.userId) {
            return <SC.RoundIcon type="Wrench" size={16} />
        }
        if (user?.userId === visitorRole.userId) {
            return <SC.RoundIcon type="User" size={16} />
        }
        return (
            <UserAvatar
                id={user?.userId ?? ''}
                avatar={getImageFullUrlInApplication(appId, user?.avatar)}
                style={{ flexShrink: 0 }}
                color="var(--color-white)"
                background={getColorById(user?.uniqueUserId ?? '')}
                displayHover
                name={user?.username ?? '未命名用户'}
                size="middle"
            />
        )
    }, [appId, user?.avatar, user?.uniqueUserId, user?.userId, user?.username])

    const renderValue = useMemo(() => {
        return (
            <>
                <div style={{ marginRight: 8 }}>{renderAvatar}</div>
                <TooltipText
                    title={user?.username ?? '未命名用户'}
                    render={ref => {
                        return <SCxTargetContainer ref={ref}>{user?.username ?? '未命名用户'}</SCxTargetContainer>
                    }}
                />
                <SCxIconWrapper>
                    <IconFont type="ArrowDownSmall" size={16} color="var(--color-gray-400)" />
                </SCxIconWrapper>
            </>
        )
    }, [renderAvatar, user?.username])

    useEffect(() => {
        if (!user) {
            setAppRole(developerRole.userId)
        }
    }, [setAppRole, user])

    const renderPrefixExtra = useCallback(
        (onClose?: () => void) => {
            return (
                <>
                    <SCxDropDownItem
                        onClick={() => {
                            onClose?.()
                            setAppRole(developerRole.userId)
                        }}
                    >
                        <SCxLeftFill>
                            <SC.RoundIcon type="Wrench" size={16} />
                            <Text>{developerRole.username}</Text>
                        </SCxLeftFill>
                        <Tooltip title="应用管理者不受权限约束" disablePortal>
                            <SC.Icon type="Question" color="var(--color-gray-400)" size={18} />
                        </Tooltip>
                    </SCxDropDownItem>
                    <SCxDropDownItem
                        onClick={() => {
                            onClose?.()
                            setAppRole(visitorRole.userId)
                        }}
                    >
                        <SCxLeftFill>
                            <SC.RoundIcon type="User" size={16} />
                            <Text>{visitorRole.username}</Text>
                        </SCxLeftFill>
                        <Tooltip title="游客是未登录的用户" disablePortal>
                            <SC.Icon type="Question" color="var(--color-gray-400)" size={18} />
                        </Tooltip>
                    </SCxDropDownItem>
                    <Divider style={{ margin: '8px 0' }} color="var(--color-gray-200)" />
                </>
            )
        },
        [setAppRole]
    )

    return (
        <SCxUserContainer>
            <SCxRoleContainer>
                <PersonSelect
                    valueComponent={renderValue}
                    extraComponent={({ onClose }) => renderPrefixExtra(onClose)}
                    options={currentOptions}
                    rightProperty="roleName"
                    value={[user?.userId ?? '']}
                    withinPortal
                    isMultiple={false}
                    enableCheckBox={false}
                    placeholder="成员名称、角色、部门"
                    styles={{
                        container: {
                            // width: 'calc(100% - 16px)',
                            borderStyle: 'none',
                            overflow: 'hidden'
                        },
                        value: {
                            padding: '0 4px'
                        }
                    }}
                    minWidth={300}
                    roleOptions={roles}
                    onChange={val => {
                        const roleId = val[0]
                        setAppRole(roleId)
                    }}
                />
            </SCxRoleContainer>
            <Text color="var(--color-gray-400)">的视角</Text>
        </SCxUserContainer>
    )
}
