import type { SelectProps } from '@byecode/ui'
import { Group, IconFont, Input, SegmentedControl, Select, Slider, Text } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { TextProtocol } from '@lighthouse/core'
import { ListItem4ByecodeUi, TEXT_FONT_NORMAL } from '@lighthouse/shared'
import { useUncontrolled } from '@lighthouse/tools'
import React, { useState } from 'react'
import styled from 'styled-components'

import { InheritLabel } from '../InheritLabel'

const Container = styled.div``
const CollapseButton = styled.button`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;
    display: flex;
    align-items: center;
    background-color: rgba(38, 65, 90, 0.06);
    padding: 6px;
    border-radius: 50%;
    &:hover {
        background-color: var(--color-gray-200);
    }
`

export interface TextControllerProps {
    defaultValue?: TextProtocol
    value?: TextProtocol
    onChange?: (value: TextProtocol) => void
    onChangeEnd?: (value: TextProtocol) => void

    collapsible?: boolean

    fontFamilyInputProps?: SelectProps

    hideEllipsis?: boolean

    notForm?: boolean
}

const CN_FONTS = [
    'BaoTuXiaoBaiTi-2',
    'FangZhengKaiTiJianTi-1',
    'FangZhengShuSongJianTi-1',
    'GaoDuanHeiXiuDing151105-1',
    'GenShinGothic-Regular-2',
    'HeTianYanZhongWanP-3',
    'LianMengQiYiLuShuaiZhengRuiHeiTi-2',
    'Muyao-Softbrush-2',
    'PangMenZhengDaoBiaoTiTiMianFeiBan-2',
    'PangMenZhengDaoCuShuTi-2',
    'PangMenZhengDaoQingSongTi-2',
    'QianTuXiaoTuTi-2',
    'RuiZiZhenYanTiMianFeiShangYong-2',
    'ShouShuTi-2',
    'Slidefu-Regular-2',
    'XinYeNianTi-2',
    'YanShiChunFengKai-2',
    'YanShiQiuHongKai-2',
    'YanShiXiaXingKai-2',
    'YanShiYouRanXiaoKai-2',
    'YangRenDongZhuShiTi-2',
    'YaoSuiXinShouXieTi-2',
    'YouSheBiaoTiHei-2',
    'ZhanKuKuHei-1',
    'ZhanKuQingKeHuangYouTi-2',
    'ZhanKuWenYiTi-2',
    'ZhanKuXiaoLOGOTi-2',
    'ZhuoJianGanLanJianTi-Regular-2'
] as const

const EN_FONTS = [
    'Arial',
    'ABeeZee',
    'Abel',
    'AbhayaLibre',
    'AbrilFatface',
    'Acme',
    'Actor',
    'Adamina',
    'AdventPro',
    'AguafinaScript',
    'BadScript',
    'Bahiana',
    'Baloo',
    'BalooBhai',
    'BalooBhaijaan',
    'BalooBhaina',
    'BalooChettan',
    'Cabin',
    'CabinCondensed',
    'CabinSketch',
    'CaesarDressing',
    'Cagliostro',
    'Cairo',
    'Calligraffitti',
    'Cambay',
    'Cambo',
    'Cantarell',
    'CantataOne',
    'Capriola',
    'DIN-Alternate-Bold',
    'Damion',
    'DancingScript',
    'Dangrek',
    'DavidLibre',
    'Dawning-of-a-NewDay',
    'DaysOne',
    'Dekko',
    'Delius',
    'DeliusSwashCaps',
    'DeliusUnicase',
    'DellaRespira',
    'Devonshire',
    'Dhurjati',

    'JosefinSlab-VariableFont_wght',
    'NotoSans-VariableFont',
    'Playball-Regular',
    'PlayfairDisplay',
    'Poppins-SemiBold',
    'Roboto-Medium',
    'TiltNeon',
    'Times-New-Roman'
] as const

const FONTS_NAME_MAP: Record<typeof CN_FONTS[number] | typeof EN_FONTS[number], string> = {
    Arial: 'Arial',
    ABeeZee: 'ABeeZee',
    Abel: 'Abel',
    AbhayaLibre: 'Abhaya Libre',
    AbrilFatface: 'Abril Fatface',
    Acme: 'Acme',
    Actor: 'Actor',
    Adamina: 'Adamina',
    AdventPro: 'Advent Pro',
    AguafinaScript: 'Aguafina Script',
    'BaoTuXiaoBaiTi-2': '包图小白体',
    BadScript: 'Bad Script',
    Bahiana: 'Bahiana',
    Baloo: 'Baloo',
    BalooBhai: 'Baloo Bhai',
    BalooBhaijaan: 'Baloo Bhaijaan',
    BalooBhaina: 'Baloo Bhaina',
    BalooChettan: 'Baloo Chettan',
    Cabin: 'Cabin',
    CabinCondensed: 'Cabin Condensed',
    CabinSketch: 'Cabin Sketch',
    CaesarDressing: 'Caesar Dressing',
    Cagliostro: 'Cagliostro',
    Cairo: 'Cairo',
    Calligraffitti: 'Calligraffitti',
    Cambay: 'Cambay',
    Cambo: 'Cambo',
    Cantarell: 'Cantarell',
    CantataOne: 'Cantata One',
    Capriola: 'Capriola',

    'DIN-Alternate-Bold': 'DIN Alternate',
    Damion: 'Damion',
    DancingScript: 'Dancing Script',
    Dangrek: 'Dangrek',
    DavidLibre: 'David Libre',
    'Dawning-of-a-NewDay': 'Dawning of a New Day',
    DaysOne: 'Days One',
    Dekko: 'Dekko',
    Delius: 'Delius',
    DeliusSwashCaps: 'Delius Swash Caps',
    DeliusUnicase: 'Delius Unicase',
    DellaRespira: 'Della Respira',
    Devonshire: 'Devonshire',
    Dhurjati: 'Dhurjati',

    'FangZhengKaiTiJianTi-1': '方正楷体简体',
    'FangZhengShuSongJianTi-1': '方正书宋简体',
    'GaoDuanHeiXiuDing151105-1': '站酷高端黑',
    'GenShinGothic-Regular-2': '思源真黑',
    'HeTianYanZhongWanP-3': '和田研字体',
    'JosefinSlab-VariableFont_wght': 'Josefin Slab',
    'LianMengQiYiLuShuaiZhengRuiHeiTi-2': '联盟起艺卢帅正锐黑体',
    'Muyao-Softbrush-2': '沐瑶软笔手写体',
    'NotoSans-VariableFont': 'Noto-Sans',
    'PangMenZhengDaoBiaoTiTiMianFeiBan-2': '庞门正道标题体',
    'PangMenZhengDaoCuShuTi-2': '庞门正道粗书体',
    'PangMenZhengDaoQingSongTi-2': '庞门正道轻松体',
    'Playball-Regular': 'Playball',
    PlayfairDisplay: 'Playfair Display',
    'Poppins-SemiBold': 'Poppins',
    'QianTuXiaoTuTi-2': '千图小兔体',
    'Roboto-Medium': 'Roboto',
    'RuiZiZhenYanTiMianFeiShangYong-2': '锐字真言体',
    'ShouShuTi-2': '手书体',
    'Slidefu-Regular-2': '演示佛系体',
    TiltNeon: 'Tilt Neon',
    'Times-New-Roman': 'Times New Roman',
    'XinYeNianTi-2': '新叶念体',
    'YanShiChunFengKai-2': '演示春风楷',
    'YanShiQiuHongKai-2': '演示秋鸿楷',
    'YanShiXiaXingKai-2': '演示夏行楷',
    'YanShiYouRanXiaoKai-2': '演示悠然小楷',
    'YangRenDongZhuShiTi-2': '杨任东竹石体',
    'YaoSuiXinShouXieTi-2': '沐瑶随心手写体',
    'YouSheBiaoTiHei-2': '优设标题黑',
    'ZhanKuKuHei-1': '站酷酷黑',
    'ZhanKuQingKeHuangYouTi-2': '站酷庆科黄油体',
    'ZhanKuWenYiTi-2': '站酷文艺体',
    'ZhanKuXiaoLOGOTi-2': '站酷小微LOGO体',
    'ZhuoJianGanLanJianTi-Regular-2': '卓键橄榄简体'
}
const FONT_FAMILY_OPTIONS = [
    {
        label: '默认',
        icon: <span>默认</span>,
        value: TEXT_FONT_NORMAL
    },
    {
        label: '中文',
        value: 'cn',
        children: CN_FONTS.map(font => {
            const icon = getAssetUrl('font', `${font}.svg`)
            return {
                label: FONTS_NAME_MAP[font],
                value: font,
                icon: <img width="100%" height="14" style={{ verticalAlign: 'middle' }} src={icon} alt={FONTS_NAME_MAP[font]} />
            }
        })
    },
    {
        label: '英文',
        value: 'en',
        children: EN_FONTS.map(font => {
            const icon = getAssetUrl('font', `${font}.svg`)
            return {
                label: FONTS_NAME_MAP[font],
                value: font,
                icon: <img width="100%" height="14" style={{ verticalAlign: 'middle' }} src={icon} alt={FONTS_NAME_MAP[font]} />
            }
        })
    }
]

const FONT_STYLE_OPTIONS = [
    {
        label: '常规体',
        value: 'normal'
    },
    {
        label: '斜体',
        value: 'italic'
    }
]

export const TextController = ({
    defaultValue,
    value,
    onChange,
    onChangeEnd,
    collapsible,
    fontFamilyInputProps,
    hideEllipsis,
    notForm
}: TextControllerProps) => {
    const [_value, _onChange] = useUncontrolled({ value, onChange, defaultValue, finalValue: {} })
    const { fontFamily, fontStyle, fontWeight, fontSize, lineHeight, letterSpacing, lineClamp } = _value

    const [draftLineClamp, setDraftLineClamp] = useState(lineClamp ? lineClamp.toString() : '')

    const handleLineClampSave = (v: number | undefined) => {
        setDraftLineClamp(v ? v.toString() : '')
        _onChange?.({ ..._value, lineClamp: v })
        onChangeEnd?.({ ..._value, lineClamp: v })
    }

    const dom = (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {notForm ? <Text>大小</Text> : <InheritLabel label="大小" name="breakPoint.font.fontSize" />}
                <Slider
                    value={fontSize}
                    onChange={v => _onChange?.({ ..._value, fontSize: v })}
                    onChangeEnd={v => onChangeEnd?.({ ..._value, fontSize: v })}
                    min={0.5}
                    max={7}
                    step={0.125}
                    style={{ width: 180 }}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {notForm ? <Text>行间距</Text> : <InheritLabel label="行间距" name="breakPoint.font.lineHeight" />}
                <Slider
                    value={lineHeight}
                    onChange={v => _onChange?.({ ..._value, lineHeight: v })}
                    onChangeEnd={v => onChangeEnd?.({ ..._value, lineHeight: v })}
                    min={0.75}
                    max={2.5}
                    step={0.125}
                    style={{ width: 180 }}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {notForm ? <Text>字间距</Text> : <InheritLabel label="字间距" name="breakPoint.font.letterSpacing" />}
                <Slider
                    value={letterSpacing}
                    onChange={v => _onChange?.({ ..._value, letterSpacing: v })}
                    onChangeEnd={v => onChangeEnd?.({ ..._value, letterSpacing: v })}
                    min={-0.5}
                    max={1.5}
                    step={0.125}
                    style={{ width: 180 }}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
            {/* <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>超出隐藏</Text>
                <Switch
                    checked={ellipsis}
                    onChange={e => {
                        _onChange?.({ ..._value, ellipsis: e.target.checked })
                        onChangeEnd?.({ ..._value, ellipsis: e.target.checked })
                    }}
                />
            </ListItem4ByecodeUi> */}

            {!hideEllipsis && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    {notForm ? <Text>最多行数</Text> : <InheritLabel label="最多行数" name="breakPoint.font.lineClamp" />}
                    <Input
                        value={draftLineClamp}
                        onChange={e => setDraftLineClamp(e.target.value)}
                        onBlur={e => {
                            const { value } = e.currentTarget
                            if (!value) {
                                handleLineClampSave(undefined)
                                return
                            }
                            const number = Number(value)
                            handleLineClampSave(Number.isNaN(number) || number <= 0 ? lineClamp : number)
                        }}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                const { value } = e.currentTarget
                                if (!value) {
                                    handleLineClampSave(undefined)
                                    return
                                }
                                const number = Number(value)
                                handleLineClampSave(Number.isNaN(number) || number <= 0 ? lineClamp : number)
                            }
                        }}
                        onFocus={e => {
                            e.target.select()
                        }}
                        placeholder="不限制"
                        style={{ width: 180 }}
                    />
                </ListItem4ByecodeUi>
            )}
        </>
    )

    return (
        <Container>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {notForm ? <Text>字体</Text> : <InheritLabel label="字体" name="breakPoint.font.fontFamily" />}
                <Select
                    value={fontFamily}
                    onChange={v => {
                        _onChange?.({ ..._value, fontFamily: v })
                        onChangeEnd?.({ ..._value, fontFamily: v })
                    }}
                    searchable
                    options={FONT_FAMILY_OPTIONS}
                    prefix={false}
                    position="left-start"
                    styles={{ root: { width: 180 }, item: { padding: '9px 16px' }, label: { display: 'none' } }}
                    {...fontFamilyInputProps}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {notForm ? <Text>风格</Text> : <InheritLabel label="风格" name="breakPoint.font.fontStyle" />}
                <SegmentedControl
                    value={fontStyle}
                    onChange={v => {
                        _onChange?.({ ..._value, fontStyle: v })
                        onChangeEnd?.({ ..._value, fontStyle: v })
                    }}
                    fullWidth
                    data={FONT_STYLE_OPTIONS}
                    style={{ width: 180 }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {notForm ? <Text>字重</Text> : <InheritLabel label="字重" name="breakPoint.font.fontWeight" />}
                <Slider
                    defaultValue={400}
                    value={fontWeight}
                    onChange={v => _onChange?.({ ..._value, fontWeight: v })}
                    onChangeEnd={v => onChangeEnd?.({ ..._value, fontWeight: v })}
                    min={100}
                    max={900}
                    step={100}
                    style={{ width: 180 }}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>

            {collapsible ? (
                <Group
                    label="更多配置"
                    renderRightIcon={(open, onChange) => {
                        return (
                            <CollapseButton onClick={() => onChange(!open)}>
                                <IconFont
                                    type="ArrowDownSmall"
                                    size={16}
                                    color="var(--color-gray-400)"
                                    style={{ transform: open ? 'rotate(-180deg)' : undefined, transition: 'transform 0.2s' }}
                                />
                            </CollapseButton>
                        )
                    }}
                    styles={{
                        root: { margin: '0 -16px', padding: 0 },
                        label: { marginLeft: 'auto', fontWeight: 400 },
                        rightIcon: { marginLeft: 'unset' }
                    }}
                >
                    {dom}
                </Group>
            ) : (
                dom
            )}
        </Container>
    )
}
