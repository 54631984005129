import type { DataSourceAbstract, Field, TypeInstanceMap, VariableADTvalue } from '@lighthouse/core'
import type { FlowNode, NodeTypes } from '@lighthouse/shared'
import {
    defaultFormat,
    getDefaultDataSourceOptions,
    getSystemOption,
    getUpstreamNodeOptionByInnerType,
    getUserDatasourceOptionsByInnerType,
    innerTypeNameMap,
    innerTypeToFieldType,
    NodeFieldPreview,
    placeholderFromInnerType,
    USER_DATASOURCE
} from '@lighthouse/shared'
import type { JSONContent } from '@tiptap/core'
import React, { useMemo } from 'react'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

export type CurrentNodeFields = {
    name: string
    nodeType: NodeTypes
    fields: Field[]
}

interface SettingSubProcessParamProps {
    value?: JSONContent
    dataSource?: DataSourceAbstract
    innerType: TypeInstanceMap
    allParentNodes?: FlowNode[]
    parentNodes?: FlowNode[]
    dataSourceList: DataSourceAbstract[]
    onChange: (val: JSONContent) => void
}

export const SettingSubProcessParam: React.FC<SettingSubProcessParamProps> = ({
    value,
    dataSource,
    innerType: paramInnerType,
    allParentNodes,
    parentNodes,
    dataSourceList,
    onChange
}) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const virtualField: Field | undefined = useMemo(() => {
        if (paramInnerType === 'NULL') {
            return
        }
        const extraConfig =
            paramInnerType === 'DATE'
                ? {
                      date: {
                          format: defaultFormat
                      }
                  }
                : {}
        return {
            id: '',
            name: innerTypeNameMap[paramInnerType],
            type: innerTypeToFieldType[paramInnerType],
            dsId: '',
            innerType: paramInnerType,
            ...extraConfig
        } as Field
    }, [paramInnerType])

    const disableInput = paramInnerType === 'ARRAY'

    const userOption = useMemo(() => {
        const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
        return getUserDatasourceOptionsByInnerType({
            dataSource: userDataSource,
            validateFieldType: (innerType: TypeInstanceMap) => {
                return innerType === paramInnerType
            }
        })
    }, [dataSourceList, paramInnerType])

    // const systemOption = useMemo(
    //     () => (paramInnerType === 'DATE' ? getSystemOption(['TODAY', 'TOMORROW', 'YESTERDAY', 'NOW']) : undefined),
    //     [paramInnerType]
    // )

    const dataSourceOption = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getDefaultDataSourceOptions(dataSource, innerType => innerType === paramInnerType)
    }, [dataSource, paramInnerType])

    const variableOptions = useMemo(() => {
        if (!parentNodes || parentNodes.length === 0 || !allParentNodes) {
            return
        }
        return [
            getUpstreamNodeOptionByInnerType({
                dataSourceList,
                parentNodes,
                allParentNodes,
                validateInnerType: innerType => innerType === paramInnerType
            })
        ]
    }, [allParentNodes, dataSourceList, paramInnerType, parentNodes])

    const placeholder = placeholderFromInnerType[virtualField?.innerType || 'TEXT']
    // const variableOptions = useMemo(() => {
    //     return [parentVariable, currentVariable].filter(Boolean) as VariableOptions
    // }, [currentVariable, parentVariable])

    return (
        // <VariableSelect
        //     width="100%"
        //     disableInput={disableInput}
        //     dataSourceOption={dataSourceOption}
        //     field={virtualField}
        //     options={variableOptions}
        //     userOption={userOption}
        //     systemOption={systemOption}
        //     value={value}
        //     onChange={onChange}
        // />
        <NodeFieldPreview
            disabled={disabledWithVersion}
            autoHeight
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            field={virtualField}
            dataSourceOption={dataSourceOption}
            userOption={userOption}
            options={variableOptions}
        />
    )
}
