import { Divider, Flex, Group, Text, Tooltip } from '@byecode/ui'
import type { SubFormBlockConfig } from '@lighthouse/core'
import { DataSourceType } from '@lighthouse/core'
import {
    findParentFormBlock,
    Group4ByecodeUi,
    ListItem4ByecodeUi,
    SelectDataSource,
    useAtomData,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { filter, omit } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { blocksAtom,lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { padBlocksByFormContainer } from '@/constants/Block/generate/field'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { BlockSettingIcon } from '../styles'
import { RelativeListConfigure } from './RelativeListConfigure'
import { TableColumnConfigure } from './TableColumnConfigure'

interface SubFormProps {
    blockId: string
}

export const SubFormSetting: React.FunctionComponent<SubFormProps> = ({ blockId }) => {
    const designProps = getBlockAndPageDesignLimit('subForm')
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const { control, setValue } = useFormContext<SubFormBlockConfig>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const [pageId, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.blockRuntimeState] as const, [])
    )
    const blocks = useAtomData(
        pageBlocksAtom(pageId)
    )

    const parentFormPointer = useMemo(
        () => findParentFormBlock({ id: blockId, blocks, blockRuntimeState })?.config.pointer,
        [blockId, blocks, blockRuntimeState]
    )

    const subFormDataSourceList = useMemo(
        () =>
            filter(item => {
                return parentFormPointer !== item.id  && item.type !== DataSourceType.aggregateDataSource
            }, dataSourceList),
        [dataSourceList, parentFormPointer]
    )

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SizeSetting {...designProps.size} />
                </>
            )}
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group4ByecodeUi label="基础配置">
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <Text>提交数据到</Text>
                            <Controller
                                name="pointer"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                    <SelectDataSource
                                        withinPortal
                                        placeholder="请选择数据表"
                                        value={value}
                                        onChange={v => {
                                            onChange(v)
                                            const dataSource = dataSourceList.find(d => d.id === v)
                                            if (!dataSource) {
                                                return
                                            }
                                            const fieldBlocks = padBlocksByFormContainer(dataSource, dataSourceList)
                                                .map(fieldBlock => ({
                                                    id: nanoid(10),
                                                    width: 270,
                                                    config: omit(['visibilityDevice'], fieldBlock.config)
                                                }))
                                                .slice(0, 3)
                                            setValue('columns', fieldBlocks)
                                        }}
                                        // 表单编辑页可以使用用户表
                                        dataSourceList={subFormDataSourceList}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </Group4ByecodeUi>
                    <Divider />
                    <Group
                        label={
                            <Flex alignItems="center" gap={4}>
                                <Text>关联字段</Text>
                                <Tooltip
                                    title={
                                        <p style={{ width: 400, fontSize: 12, lineHeight: '22px', padding: '4px 16px' }}>
                                            <ul>
                                                <li>
                                                    提交表单时，会将新增主表字段内容写入到绑定的子表字段中，以实现主子表单的数据关联，详见
                                                </li>
                                                <li>主表字段和子表字段均可重复选择</li>
                                                <li>可选择字段类型限制：只可选择文字类型字段</li>
                                            </ul>
                                        </p>
                                    }
                                >
                                    <BlockSettingIcon color="var(--color-gray-400)" type="Question" />
                                </Tooltip>
                            </Flex>
                        }
                    >
                        <RelativeListConfigure blockId={blockId} />
                    </Group>
                    <Divider />
                    <Group label="表单列">
                        <TableColumnConfigure blockId={blockId} />
                    </Group>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
