import type {
    CalendarViewOptions,
    DataSourceAbstract,
    FieldType,
    GalleryViewOptions,
    GroupFieldValue,
    ViewBlockAbstract,
    ViewLayout,
    ViewType
} from '@lighthouse/core'
import {
    ALIGNMENT,
    ARRANGEMENT,
    BACKGROUND_TYPE,
    BLOCK_SIZE,
    BlockType,
    CUSTOM_VIEW_LAYOUT,
    DIRECTION,
    EDGE_MODE,
    FLEX_ALIGN,
    GALLERY_AVATAR_SHAPE,
    GALLERY_AVATAR_SIZE,
    POSITION
} from '@lighthouse/core'
import { getIsAppointField, NO_EDIT_SYSTEM_FIELD, USER_DATASOURCE, VIEW_BLOCK_NAME_MAP } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

/**
 * 生成画廊block的配置
 * @date 2024/4/19 - 22:14:08
 *
 * @param {(undefined | DataSourceAbstract)} dataSource
 * @returns {GalleryViewOptions}
 */
export const generateGalleryConfig = (dataSource: undefined | DataSourceAbstract): GalleryViewOptions => {
    if (!dataSource) {
        return {}
    }

    const fields = Object.values(dataSource.schema)

    // 用户表
    if (dataSource.id === USER_DATASOURCE) {
        const contentFields: GroupFieldValue[] = [
            {
                wrap: true,
                id: 'MOBILE'
            },
            {
                wrap: true,
                id: 'ROLE'
            }
        ]

        return {
            contentTags: {
                fields: contentFields,
                show: true,
                showLabel: false
            },
            footTags: {
                fields: [],
                show: false,
                showLabel: false
            },
            headAvatar: {
                align: ALIGNMENT.left,
                position: POSITION.top,
                shape: GALLERY_AVATAR_SHAPE.circle,
                show: false,
                size: GALLERY_AVATAR_SIZE.md
            },
            headCover: {
                field: 'AVATAR',
                objectFit: 'cover',
                ratio: '4:3',
                show: true
            },
            headTags: {
                fields: [],
                show: false,
                showLabel: false
            },
            headTitle: {
                field: 'USERNAME',
                show: true
            }
        }
    }

    // 普通表
    const coverField = fields.find(item => item.type === 'file')
    const textTypeSet = new Set<FieldType>(['text', 'phoneNumber', 'url', 'email'])
    const titleField = fields.find(item => textTypeSet.has(item.type))
    const contentFields = fields
        .reduce<GroupFieldValue[]>((total, current) => {
            if (current.id === 'ID') {
                return total
            }

            return [...total, { id: current.id, wrap: true }]
        }, [])
        .slice(0, 4)

    return {
        contentTags: {
            fields: contentFields,
            show: true,
            showLabel: false
        },
        footTags: {
            fields: [],
            show: false,
            showLabel: false
        },
        headAvatar: {
            align: ALIGNMENT.left,
            position: POSITION.top,
            shape: GALLERY_AVATAR_SHAPE.circle,
            show: false,
            size: GALLERY_AVATAR_SIZE.md
        },
        headCover: {
            field: coverField?.id,
            objectFit: 'cover',
            ratio: '4:3',
            show: true
        },
        headTags: {
            fields: [],
            show: false,
            showLabel: true
        },
        headTitle: {
            field: titleField?.id,
            show: true
        }
    }
}

/**
 * 生成日历block的配置
 *
 * @param {DataSourceAbstract} dataSource
 * @returns {CalendarViewOptions}
 */
export const generateCalendarConfig = (dataSource: undefined | DataSourceAbstract): CalendarViewOptions => {
    if (!dataSource) {
        return {}
    }

    const fields = Object.values(dataSource.schema)
    const title = fields.find(item => item.type === 'text')
    const startDate = fields.find(item => item.type === 'date' && !getIsAppointField(item.id, NO_EDIT_SYSTEM_FIELD))
    const endDate =
        startDate &&
        fields.find(item => item.type === 'date' && !getIsAppointField(item.id, NO_EDIT_SYSTEM_FIELD) && item.id !== startDate.id)

    return {
        scheduleTitleField: title?.id,
        scheduleStartDateField: startDate?.id,
        scheduleEndDateField: endDate?.id
    }
}

/**
 * 生成自定义视图block的配置
 *
 * @returns {ViewLayout}
 */
const generateCustomLayout: ViewLayout = {
    direction: 'vertical',
    layout: CUSTOM_VIEW_LAYOUT.grid,
    arrangement: ARRANGEMENT.auto,
    size: BLOCK_SIZE.md,
    gap: 8,
    viewPadding: [4, 4, 4, 4]
}

const generateGalleryLayout: ViewLayout = {
    arrangement: ARRANGEMENT.auto,
    direction: DIRECTION.vertical,
    size: BLOCK_SIZE.md,
}

type Params = {
    viewType: ViewType
    id: string
    appId?: string
    dataSource?: DataSourceAbstract
}
/**
 * 生成视图block
 *
 * @param {Params} param0
 * @param {ViewType} param0.viewType
 * @param {string} param0.id
 * @param {string} [param0.appId='']
 * @param {DataSourceAbstract} param0.dataSource
 * @returns {(undefined | ViewBlockAbstract)}
 */
export const generateViewBlock = ({ viewType, id, appId = '', dataSource }: Params): undefined | ViewBlockAbstract => {
    return {
        id,
        logicId: nanoid(),
        type: BlockType.view,
        title: VIEW_BLOCK_NAME_MAP[viewType],
        config: {
            pointer: '',
            showTitle: false,
            canSearch: false,
            canEdit: false,
            canDisplay: false,
            canViewRecord: true,
            required: false,
            canSort: false,
            canDeleteRecord: false,

            // 系统表需要禁用,先统一关闭、使用后端返回的值
            canCreateRecord: false,
            creatingConfig: { page: '', label: '添加', openType: 'modal' },
            viewingConfig: { page: '', openType: 'modal' },
            groupConfigure: {
                groupByFieldId: '',
                groupConfig: []
            },
            viewFieldSettings: [],
            canPaginate: true,
            actions: {
                toolbar: {
                    customized: false
                },
                recordOperator: {
                    customized: false
                },
                recordClicked: {
                    customized: false
                }
            },
            linkFilterController: {
                expression: {
                    where: 'AND',
                    conditions: []
                }
            },
            pagination: {
                pageSize: 10
            },
            quickFilter: { mode: 'normal', fieldIds: [] },
            canSetKanban: true,
            canSortKanban: true,
            viewType,
            appId,
            breakPoint:
                viewType === 'custom'
                    ? {
                          id: 'desktop',
                          name: '桌面端',
                          visibility: {
                              visible: 'VISIBLE'
                          },
                          size: {
                              width: {
                                  size: 'fill'
                              },
                              height: {
                                  size: 'auto'
                              },
                              overflow: 'auto'
                          },
                          viewLayout: generateCustomLayout,
                          layout: {
                              align: {
                                  direction: DIRECTION.vertical,
                                  alignX: FLEX_ALIGN['flex-start'],
                                  alignY: FLEX_ALIGN['flex-start']
                              },
                              gap: 8,
                              padding: [4, 4, 4, 4]
                          },
                          design: {
                              radius: [8, 8, 8, 8],
                              border: {
                                  type: BACKGROUND_TYPE.color,
                                  color: '#e4e7ec',
                                  mode: EDGE_MODE.each,
                                  each: [1, 1, 1, 1]
                              }
                          }
                      }
                    : {
                          id: 'desktop',
                          name: '桌面端',
                          visibility: {
                              visible: 'VISIBLE'
                          },
                          size: {
                              width: {
                                  size: 'fill'
                              },
                              height: {
                                  size: 'auto'
                              },
                              overflow: 'auto'
                          },
                          viewLayout: generateGalleryLayout,
                          layout: {},
                          design: {}
                      },

            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ],

            // ...generateGalleryConfig(dataSource),
            // ...generateCalendarConfig(dataSource),
        }
    }
}
