import { Flex, Tooltip } from '@byecode/ui'
import type { ValueVariable } from '@lighthouse/core'
import { type Field, type VariableADTvalue, VariableType } from '@lighthouse/core'
import React, { useId, useMemo } from 'react'

import { getRealField, isDateValue, isFileValue, isNumberValue, isTextValue, scientificNotationToString } from '../../../../utils'
import { CheckboxItemPreviewer } from '../../../FieldValuePreviewer/CheckboxValuePreviewer'
import { Placeholder } from '../../../PlaceHolder'
import { TagIcon } from '../../../TagIcon'
import { AggregationInnerTypeToFieldType, DEFAULT_FILTER_VALUE_VARIABLE } from '../../constant'
import DateInput from './DateInput'
import { FileInput } from './FileInput'
import { SelectInput } from './SelectInput'
import * as SC from './styles'
import { TextInput } from './TextInput'

interface VariablePreviewProps {
    value?: ValueVariable
    placeholder?: string
    field?: Field
    disabled?: boolean
    width?: string
    opened?: boolean
    onChange: (value: VariableADTvalue) => void
    onChangeOpen: () => void
}

export const VariablePreview: React.FunctionComponent<VariablePreviewProps> = props => {
    const { value: data, placeholder, field, disabled, width, opened, onChangeOpen, onChange } = props
    const id = useId()

    const realField = useMemo(() => (field ? getRealField(field) : field), [field])

    const fieldType = useMemo(() => {
        if (!realField) {
            return
        }
        if (realField?.type === 'aggregation' && realField.innerType) {
            return AggregationInnerTypeToFieldType[realField.innerType]
        }
        return realField.type
    }, [realField])

    return useMemo(() => {
        const variableValue = data?.valueVariable?.value

        switch (fieldType) {
            case 'textGeneration':
            case 'id':
            case 'text':
            case 'email':
            case 'url':
            case 'phoneNumber':
            case 'formula': {
                return (
                    <TextInput
                        data={isTextValue(variableValue) ? variableValue : ''}
                        placeholder={placeholder}
                        width={width}
                        disabled={disabled}
                        opened={opened}
                        onChange={e =>
                            onChange({
                                type: VariableType.VALUE,
                                valueVariable: {
                                    type: fieldType,
                                    value: e.target.value
                                }
                            })
                        }
                        onChangeOpen={onChangeOpen}
                    />
                )
            }
            case 'number': {
                return (
                    <TextInput
                        type="number"
                        defaultData={isNumberValue(variableValue) ? variableValue : ''}
                        placeholder={placeholder}
                        width={width}
                        disabled={disabled}
                        onBlur={e =>
                            onChange({
                                type: VariableType.VALUE,
                                valueVariable: {
                                    type: fieldType,
                                    value: scientificNotationToString(e.target.value)
                                }
                            })
                        }
                        onChangeOpen={onChangeOpen}
                    />
                )
            }
            case 'date': {
                const dateValue = variableValue && isDateValue(variableValue) ? variableValue : ''
                return field ? (
                    <DateInput field={field} data={dateValue} placeholder={placeholder} onChange={onChange} onChangeOpen={onChangeOpen} />
                ) : null
            }
            case 'checkbox': {
                return (
                    <SC.ItemContainer>
                        {variableValue === undefined ? (
                            <Placeholder style={{ color: 'var(--color-gray-400)' }} value={placeholder} />
                        ) : (
                            <Flex style={{ width: '100%', paddingRight: 2 }} justifyContent="space-between">
                                <CheckboxItemPreviewer
                                    id={id}
                                    data={Boolean(variableValue)}
                                    onChange={e =>
                                        onChange({
                                            type: VariableType.VALUE,
                                            valueVariable: { type: fieldType, value: e.currentTarget.checked }
                                        })
                                    }
                                    disabled={disabled}
                                    defaultBgcolor="transparent"
                                />
                                <SC.Icon
                                    type="CloseCircle"
                                    size={16}
                                    isAction
                                    onClick={e => {
                                        e.stopPropagation()
                                        !disabled && onChange(DEFAULT_FILTER_VALUE_VARIABLE)
                                    }}
                                />
                            </Flex>
                        )}

                        <Tooltip title="选择数据">
                            <TagIcon
                                icon="DotsThreeVertical"
                                size={24}
                                radius={5}
                                isActive={opened}
                                iconSize={16}
                                enableHover
                                hoverBackground="rgba(38, 65, 90, 0.06)"
                                onClick={() => onChangeOpen?.()}
                            />
                        </Tooltip>
                    </SC.ItemContainer>
                )
            }
            case 'video':
            case 'file': {
                const fileUrls = isFileValue(variableValue) ? variableValue : []
                const defaultPlaceholder = fieldType === 'file' ? '粘贴文件链接' : ' 粘贴视频链接'
                return (
                    <FileInput
                        disabled={disabled}
                        field={field}
                        data={fileUrls}
                        placeholder={placeholder ?? defaultPlaceholder}
                        opened={opened}
                        onChange={onChange}
                        onChangeOpen={onChangeOpen}
                    />
                )
            }

            default: {
                return <SelectInput field={field} data={data} placeholder={placeholder} onChange={onChange} onChangeOpen={onChangeOpen} />
            }
        }
    }, [data, disabled, field, fieldType, id, onChange, onChangeOpen, opened, placeholder, width])
}
