import { Popover } from '@byecode/ui'
import type { Veins } from '@lighthouse/core'
import { type ContainerBlockConfig, BACKGROUND_TYPE } from '@lighthouse/core'
import { FilledInput, ListItem4ByecodeUi, PopoverDropdownWithClose, VeinsController } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { InheritLabel } from '@/components/InheritLabel'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { combinePath } from './utils'

interface VeinsFieldProps {
    prefixPath?: string
    onSetBreakKey?: (name: string) => void
}

export const VeinsField: React.FC<VeinsFieldProps> = ({ prefixPath, onSetBreakKey }) => {
    const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()
    // const themeColor = useAtomData(applicationThemeColorAtom)
    const colorSystemMethods = useColorSystemAction()
    const veinsName = combinePath(prefixPath, 'breakPoint.design.veins')

    const getValue = useCallback((val: Veins | undefined) => {
        if (val?.color) {
            return val?.color
        }
        if (val?.value) {
            return 'transparent'
        }
    }, [])

    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
            <InheritLabel label="纹理" name="breakPoint.design.veins" />
            <Popover
                width={300}
                position="left-start"
                withinPortal
                autoUpdate={false}
                offsetOptions={168}
                // onOpen={() => {
                //     const veinsValue = getValues(`${veinsName}.value`)
                //     if (!veinsValue) {
                //         setValue(veinsName, {
                //             color: themeColor && chroma(themeColor).alpha(0.1).hex(),
                //             value: VEINS_OPTIONS[0].value,
                //             strokeWidth: 2,
                //             size: 50
                //         })
                //         onSetBreakKey?.('breakPoint.design.veins')
                //     }
                // }}
            >
                <Controller
                    control={control}
                    name={veinsName}
                    render={({ field }) => {
                        return (
                            <Popover.Target>
                                <FilledInput
                                    value={{ type: BACKGROUND_TYPE.color, color: getValue(field.value) }}
                                    onClear={() => {
                                        setValue(veinsName, {
                                            color: '',
                                            value: '',
                                            strokeWidth: 1,
                                            size: 1
                                        })
                                        onSetBreakKey?.(veinsName)
                                    }}
                                    style={{ width: 180 }}
                                />
                            </Popover.Target>
                        )
                    }}
                />
                <PopoverDropdownWithClose title="纹理">
                    <Controller
                        control={control}
                        name={veinsName}
                        render={({ field }) => (
                            <VeinsController
                                name={veinsName}
                                value={field.value}
                                onChange={val => {
                                    if (isDeepEqual(val, field.value)) {
                                        return
                                    }
                                    field.onChange(val)
                                    onSetBreakKey?.(veinsName)
                                }}
                                {...colorSystemMethods}
                            />
                        )}
                    />
                </PopoverDropdownWithClose>
            </Popover>
        </ListItem4ByecodeUi>
    )
}
