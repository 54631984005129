import type { Option } from '@byecode/ui'
import { Button, IconFont, Popover, SelectDropdown } from '@byecode/ui'
import type { DataSourceAbstract, FieldType, SchemaProtocol } from '@lighthouse/core'
import {
    FieldTypeTag,
    getFieldIcon,
    getIsAppointField,
    getPrimaryDataSourceEnableFieldIds,
    NO_EDIT_SYSTEM_FIELD
} from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const SCxButtonContent = styled(Button)``

const SCxIcon = styled(IconFont)`
    margin-right: 6px;
`

const SCxDescription = styled.div`
    padding: 10px 12px;
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`
const SCxContainer = styled.div`
    margin: 4px 0;
`

interface AddFieldButtonProps {
    dataSource?: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    fieldIds: string[]
    onItemClick?: (id: string) => void
    buttonText?: string
    isFilteredCannotCreatedField?: boolean
}

const disableAddFieldTypes = new Set<FieldType>(['file', 'video', 'formula', 'notes', 'textGeneration', 'selectGenerationByText'])

export const AddFieldButton: React.FC<AddFieldButtonProps> = ({
    isFilteredCannotCreatedField = true,
    dataSource,
    dataSourceList,
    fieldIds,
    buttonText = '添加',
    onItemClick
}) => {
    const [open, setOpen] = useState(false)
    const { schema } = dataSource || { schema: {} as SchemaProtocol['schema'] }
    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    const options = useMemo(() => {
        // filter(item => {
        //     return item.name.includes(searchVal) && !disableAddFieldTypes.has(item.type)
        // })
        // const filteredCannotCreatedField =
        return Object.values(schema).reduce<Option[]>((prev, cur) => {
            if (fieldIds.includes(cur.id) || cur.type === 'aggregation' || !primaryDataSourceFieldIds?.has(cur.id)) {
                return prev
            }

            if (
                isFilteredCannotCreatedField &&
                (getIsAppointField(cur.id, NO_EDIT_SYSTEM_FIELD) || disableAddFieldTypes.has(cur.type))
            ) {
                return prev
            }
            prev.push({
                label: cur.name,
                value: cur.id,
                icon: getFieldIcon(cur.id, cur.type, cur.innerType),
                extra: <FieldTypeTag type={cur.type} innerType={cur.innerType} />
            })
            return prev
        }, [])
    }, [fieldIds, isFilteredCannotCreatedField, primaryDataSourceFieldIds, schema])

    const handleItemClick = useCallback(
        (val: string) => {
            setOpen(false)
            requestAnimationFrame(() => {
                onItemClick?.(val)
            })
        },
        [onItemClick]
    )

    if (options.length === 0) {
        return null
    }

    return (
        <Popover withinPortal opened={open} onChange={setOpen}>
            <Popover.Target>
                <SCxContainer>
                    <SCxButtonContent radius={100} icon={<SCxIcon type="Add" fill="var(--color-gray-500)" size={16} />}>
                        {buttonText}
                    </SCxButtonContent>
                </SCxContainer>
            </Popover.Target>
            <Popover.Dropdown>
                <SelectDropdown
                    searchable
                    styles={{ selectDropdown: { maxHeight: 300 } }}
                    options={options}
                    onSelect={handleItemClick}
                    extra={<SCxDescription>已隐藏不支持的部分字段</SCxDescription>}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
