import type { ChartViewOptions, DataSourceAbstract } from '@lighthouse/core'
import { enableSortConfiguratorChartType } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { DataConfigurator } from './DataConfigurator'
import { DimensionConfigurator } from './DimensionConfigurator'
import { IndicatorConfigurator } from './IndicatorConfigurator'
import { OperatorConfigurator } from './OperatorConfigurator'

interface DataProps {
    dataSource: DataSourceAbstract
}

export const Data: React.FC<DataProps> = ({ dataSource }) => {
    const { control } = useFormContext<ChartViewOptions>()
    const { id: dsId, schema } = dataSource
    const chartType = useWatch({ control, name: 'chartType' })

    const enableSortConfigurator = enableSortConfiguratorChartType.has(chartType)

    return useMemo(() => {
        switch (chartType) {
            case 'composite':
            case 'bar':
            case 'line':
            case 'funnel':
            case 'striation':
            case 'pie': {
                return (
                    <>
                        <DimensionConfigurator dsId={dsId} schema={schema} />
                        <IndicatorConfigurator dsId={dsId} schema={schema} />
                        <DataConfigurator enableSortConfigurator={enableSortConfigurator} dataSource={dataSource} />
                        <OperatorConfigurator/>
                        {/* <Divider color="var(--color-gray-200)" /> */}
                        {/* <VisibilitySetting /> */}
                    </>
                )
            }
            // case 'indicator': {
            //     return (
            //         <>
            //             <IndicatorConfigurator schema={schema} />
            //             <DataConfigurator dataSource={dataSource} />
            //             <Divider color="var(--color-gray-200)" />
            //             <VisibilityFilter />
            //         </>
            //     )
            // }

            default: {
                return null
            }
        }
    }, [chartType, dataSource, dsId, enableSortConfigurator, schema])
}
