import { Input } from '@byecode/ui'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { transform2Integer, transform2Number } from '@/utils/number'

import { SizeIcon } from '../SizeSetting/SizeIcon'
import { useSetGapHighlight } from './useSetGapHighlight'

type GapSizeValue = number | undefined

interface GapSizeInputProps {
    value?: GapSizeValue
    onChange: (value: GapSizeValue) => void

    disableHighlight?: boolean
}

const SCxRectSizeInputWrapper = styled.div`
    display: flex;
    width: 180px;
`

export const GapSizeInput: React.FC<GapSizeInputProps> = ({ value, onChange, disableHighlight }) => {
    const [v, setV] = useState(value)
    const [moveOpen, setMoveOpen] = useState(false)
    const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value
        const num = val ? transform2Number(val) : undefined
        setV(num)
    }, [])

    useUpdateEffect(() => {
        setV(value)
    }, [value])

    const handleMoveChange = useCallback(
        (v: number) => {
            const num = transform2Integer(v)
            setV(num)
            if (num === value) {
                return
            }
            onChange(num)
        },
        [onChange, value]
    )

    const handleBlur = useCallback(
        (ev: React.FocusEvent<HTMLInputElement>) => {
            const v = ev.target.value
            const validNumber = transform2Number(Number(v).toFixed(2))
            setV(validNumber)
            if (validNumber === value) {
                return
            }
            onChange(validNumber)
        },
        [onChange, value]
    )

    const { onMouseEnterGap, onMouseLeaveGap } = useSetGapHighlight(disableHighlight || moveOpen)

    const resizeStyle = useMemo(
        () =>
            moveOpen
                ? {
                      borderColor: 'var(--color-main)',
                      backgroundColor: 'var(--color-gray-200)'
                  }
                : {},
        [moveOpen]
    )

    return (
        <SCxRectSizeInputWrapper>
            <Input
                value={v}
                prefix={<SizeIcon type="GapHorizontal" min={0} value={v} onChangeMove={setMoveOpen} onChange={handleMoveChange} />}
                styles={{
                    wrapper: resizeStyle
                }}
                radius={0}
                onFocus={e => e.currentTarget.select()}
                onChange={handleChange}
                type="number"
                onKeyDownCapture={ev => {
                    if (ev.key === 'Enter') {
                        ev.currentTarget.blur()
                    }
                }}
                onBlur={handleBlur}
                wrapperProps={{
                    onMouseEnter: onMouseEnterGap,
                    onMouseLeave: onMouseLeaveGap
                }}
            />
        </SCxRectSizeInputWrapper>
    )
}
