import type {
    AliyunMessageActionConfig,
    CallActionConfig,
    CopyActionConfig,
    CreateRecordActionConfig,
    CreateSingleRecordActionConfig,
    DownloadFileActionConfig,
    FindRecordActionConfig,
    FindSingleRecordActionConfig,
    IClickActionConfig,
    InitiateApproveActionConfig,
    LoopNodeActionConfig,
    NotificationActionConfig,
    OpenFormPageActionConfig,
    OpenLinkActionConfig,
    OpenPageActionConfig,
    OpenRecordEditPageActionConfig,
    OpenRecordPageActionConfig,
    SendEmailActionConfig,
    SendMsgDingTalkRobotConfig,
    SendMsgWxRootConfig,
    StationMessageActionConfig,
    StripeActionConfig,
    UpdateRecordActionConfig,
    WeChatPayActionConfig,
    WechatTemplateMsgConfig
} from '@lighthouse/shared'
import { FlowSelectSourceType, isEmptyRichTextValue, isIntValue, isIntVariableValue, OpenDetailPageSelectedRecordType } from '@lighthouse/shared'

import type { ActionValidator, NodeValidator } from '../types'

/**
 * create record action
 */
const createRecordActionValidator: NodeValidator<ActionValidator<CreateSingleRecordActionConfig>, 'CREATE_SINGLE_RECORD_ACTION'> = {
    nodeType: 'CREATE_SINGLE_RECORD_ACTION',
    validators: [
        node => {
            return !!node.data.config?.dataSourceId
        },
        node => {
            return !!node.data.config?.fields?.length
        }
    ]
}

// create multiRecord action
const createMultiRecordActionValidator: NodeValidator<ActionValidator<CreateRecordActionConfig>, 'CREATE_RECORD_ACTION'> = {
    nodeType: 'CREATE_RECORD_ACTION',
    validators: [
        node => {
            return !!node.data.config?.dataSourceId && !!node.data.config?.nodeId
        },
        node => {
            return !!node.data.config?.fields?.length
        }
    ]
}

// find record action
const findMultiRecordActionValidator: NodeValidator<ActionValidator<FindRecordActionConfig>, 'FIND_RECORD_ACTION'> = {
    nodeType: 'FIND_RECORD_ACTION',
    validators: [
        // dsId required
        node => {
            return !!node.data.config?.dataSourceId
        }
    ]
}

// find record action
const findRecordActionValidator: NodeValidator<ActionValidator<FindSingleRecordActionConfig>, 'FIND_SINGLE_RECORD_ACTION'> = {
    nodeType: 'FIND_SINGLE_RECORD_ACTION',
    validators: [
        // dsId required
        node => {
            return node.data.config?.selectType === FlowSelectSourceType.DATASOURCE
                ? !!node.data.config?.dataSourceId
                : !!node.data.config?.nodeId
        }
    ]
}

// update record action
const updateRecordActionValidator: NodeValidator<ActionValidator<UpdateRecordActionConfig>, 'UPDATE_RECORD_ACTION'> = {
    nodeType: 'UPDATE_RECORD_ACTION',
    validators: [
        // dsId required
        // node => {
        //     return !!node.data.config?.dataSourceId
        // },
        // 记录
        node => {
            return !!(node.data.config?.selectType === 'UPSTREAM' ? node.data.config.nodeId : '')
        }
    ]
}

// open page action
const openPageValidator: NodeValidator<ActionValidator<OpenPageActionConfig>, 'OPEN_PAGE'> = {
    nodeType: 'OPEN_PAGE',
    validators: [
        node => {
            return !!node.data.config?.openPageId
        }
    ]
}

// open link action
const openLinkValidator: NodeValidator<ActionValidator<OpenLinkActionConfig>, 'OPEN_LINK'> = {
    nodeType: 'OPEN_LINK',
    validators: [
        node => {
            return !!node.data.config?.link
        }
    ]
}

const notificationValidator: NodeValidator<ActionValidator<NotificationActionConfig>, 'NOTIFICATION'> = {
    nodeType: 'NOTIFICATION',
    validators: [
        node => {
            return !!node.data.config && !isEmptyRichTextValue(node.data.config.notificationContent)
        }
    ]
}

// copy action
const copyActionValidator: NodeValidator<ActionValidator<CopyActionConfig>, 'COPY'> = {
    nodeType: 'COPY',
    validators: [
        node => {
            return !!node.data.config && !isEmptyRichTextValue(node.data.config.copyText)
        }
    ]
}

// initialApproval action
const initialApprovalActionValidator: NodeValidator<ActionValidator<InitiateApproveActionConfig>, 'INITIATE_APPROVE_ACTION'> = {
    nodeType: 'INITIATE_APPROVE_ACTION',
    validators: [
        node => {
            return !!node.data.config?.approveObjectId && !!node.data.config?.approveFlowId
        }
    ]
}

// open recordPage action
const openRecordPageActionValidator: NodeValidator<ActionValidator<OpenRecordPageActionConfig>, 'OPEN_RECORD_PAGE'> = {
    nodeType: 'OPEN_RECORD_PAGE',
    validators: [
        node => {
            const nodeConfig = node.data.config
            switch (nodeConfig?.selectedRecordType) {
                case OpenDetailPageSelectedRecordType.MATCHED_RECORD: {
                    return !!nodeConfig?.dataSourceId && !!nodeConfig?.recordPageId
                }
                case OpenDetailPageSelectedRecordType.UPSTREAM_RECORD: {
                    return !!nodeConfig?.upstreamRecordNodeId && !!nodeConfig?.recordPageId
                }
                default: {
                    return !!nodeConfig?.recordPageId
                }
            }
        }
        // node => {
        //     return !!node.data.config?.filter?.expression?.conditions?.length
        // }
    ]
}

// open recordEditPage action
const openRecordEditPageActionValidator: NodeValidator<ActionValidator<OpenRecordEditPageActionConfig>, 'OPEN_RECORD_EDIT_PAGE'> = {
    nodeType: 'OPEN_RECORD_EDIT_PAGE',
    validators: [
        node => {
            const nodeConfig = node.data.config
            switch (nodeConfig?.selectedRecordType) {
                case OpenDetailPageSelectedRecordType.MATCHED_RECORD: {
                    return !!nodeConfig?.dataSourceId && !!nodeConfig?.recordEditPageId
                }
                case OpenDetailPageSelectedRecordType.UPSTREAM_RECORD: {
                    return !!nodeConfig?.upstreamRecordNodeId && !!nodeConfig?.recordEditPageId
                }
                default: {
                    return !!nodeConfig?.recordEditPageId
                }
            }
        }
    ]
}

// open formPage action
const openFormPageActionValidator: NodeValidator<ActionValidator<OpenFormPageActionConfig>, 'OPEN_FORM_PAGE'> = {
    nodeType: 'OPEN_FORM_PAGE',
    validators: [
        node => {
            return !!node.data.config?.dataSourceId && !!node.data.config?.formPageId
        }
    ]
}

// aliyunMessage action
const aliyunMessageActionValidator: NodeValidator<ActionValidator<AliyunMessageActionConfig>, 'ALIYUN_MESSAGE_ACTION'> = {
    nodeType: 'ALIYUN_MESSAGE_ACTION',
    validators: [
        node => {
            return !!node.data.config?.id && !!node.data.config?.signatureId && !!node.data.config?.templateId
        },
        node => {
            return !!node.data.config?.mobileList
        },
        node => {
            const { config } = node.data
            if (!config) {
                return false
            }
            const { values } = config
            return values?.length ? values.every(v => !isEmptyRichTextValue(v.value)) : true
        }
    ]
}

// aliyunMessage action
const wechatMessageActionValidator: NodeValidator<ActionValidator<WechatTemplateMsgConfig>, 'WECHAT_TEMPLATE_MSG_ACTION'> = {
    nodeType: 'WECHAT_TEMPLATE_MSG_ACTION',
    validators: [
        node => {
            return !!node.data.config?.personList && node.data.config.personList.length > 0
        },
        node => {
            return !!node.data.config?.templateId
        },
        node => {
            const { config } = node.data
            if (!config) {
                return false
            }
            const { url } = config
            return !isEmptyRichTextValue(url)
        },
        node => {
            const { config } = node.data
            if (!config) {
                return false
            }
            const { values } = config
            return values?.length ? values.every(v => !isEmptyRichTextValue(v.value)) : true
        }
    ]
}

// iclick action
const iClickContactActionValidator: NodeValidator<ActionValidator<IClickActionConfig>, 'I_CLICK_CONTACT_ACTION'> = {
    nodeType: 'I_CLICK_CONTACT_ACTION',
    validators: [
        node => {
            return (
                !!node.data.config?.iClickId &&
                !isEmptyRichTextValue(node.data.config?.name) &&
                !isEmptyRichTextValue(node.data.config?.mobile) &&
                !isEmptyRichTextValue(node.data.config?.email)
            )
        }
    ]
}

// dingtalkRobotAction
const dingtalkRobotActionValidator: NodeValidator<ActionValidator<SendMsgDingTalkRobotConfig>, 'DINGTALK_ROBOT_ACTION'> = {
    nodeType: 'DINGTALK_ROBOT_ACTION',
    validators: [
        node => {
            return !!node.data.config?.id && !!node.data.config?.msgtype
        },
        node => {
            return (
                !!node.data.config &&
                !!node.data.config?.text?.editorContent &&
                !isEmptyRichTextValue(node.data.config?.text?.editorContent)
            )
        }
    ]
}

// wxRobotAction
const wxRobotActionValidator: NodeValidator<ActionValidator<SendMsgWxRootConfig>, 'WX_ROBOT_ACTION'> = {
    nodeType: 'WX_ROBOT_ACTION',
    validators: [
        node => {
            return !!node.data.config?.id && !!node.data.config?.msgtype
        },
        node => {
            return !!node.data.config && !isEmptyRichTextValue(node.data.config?.text?.editorContent)
        }
    ]
}

// sendEmail action
const sendEmailActionValidator: NodeValidator<ActionValidator<SendEmailActionConfig>, 'SEND_EMAIL_ACTION'> = {
    nodeType: 'SEND_EMAIL_ACTION',
    validators: [
        node => {
            return !!node.data.config?.senderAccountType
        },
        node => {
            if (!node.data.config) {
                return false
            }
            const { recipients, content, title } = node.data.config
            return !!recipients?.length && !isEmptyRichTextValue(content) && !isEmptyRichTextValue(title)
        }
    ]
}

// loop action
const loopActionValidator: NodeValidator<ActionValidator<LoopNodeActionConfig>, 'LOOP_ACTION'> = {
    nodeType: 'LOOP_ACTION',
    validators: [
        node => {
            return !!node.data.config?.loopType
        },
        node => {
            if (!node.data.config) {

                return false
            }
            const { loopType, loopErrorHandler } = node.data.config

            if (loopType === 'BASE_ON_RECORDS') {
                const { recordsConfig } = node.data.config
                return !!loopErrorHandler && !!recordsConfig?.subProcessId
            }
            if (loopType === 'BASE_ON_TIMES') {
                const { timesConfig } = node.data.config
                return !!loopErrorHandler && !!timesConfig.subProcessId && isIntVariableValue(timesConfig.start) && isIntVariableValue(timesConfig.end) && isIntVariableValue(timesConfig.step)
            }
            return false
        }
    ]
}

// call action
const callActionValidator: NodeValidator<ActionValidator<CallActionConfig>, 'CALL'> = {
    nodeType: 'CALL',
    validators: [
        node => {
            return !!node.data.config?.phone
        }
    ]
}

// download file action
const downloadFileActionValidator: NodeValidator<ActionValidator<DownloadFileActionConfig>, 'DOWNLOAD_FILE'> = {
    nodeType: 'DOWNLOAD_FILE',
    validators: [
        node => {
            return !!node.data.config?.fileUrl
        }
    ]
}

// wechatpay action
const wechatPayActionValidator: NodeValidator<ActionValidator<WeChatPayActionConfig>, 'WECHAT_PAY'> = {
    nodeType: 'WECHAT_PAY',
    validators: [
        node => {
            if (!node.data.config) {
                return false
            }
            const { operationType, payConfig } = node.data.config
            return operationType === 'PAY'
                ? !!payConfig?.descFieldId && !!payConfig?.amountFieldId
                : false
        }
    ]
}

// stripe pay action
const stripePayActionValidator: NodeValidator<ActionValidator<StripeActionConfig>, 'STRIPE'> = {
    nodeType: 'STRIPE',
    validators: [
        node => {
            if (!node.data.config) {
                return false
            }
            const { operationType, payConfig } = node.data.config
            return !!payConfig?.currency && !!payConfig?.amountFieldId && !!payConfig.productNameFieldId && !!payConfig.nodeId
        }
    ]
}

// ----------------------------
// 自动化独有
// ----------------------------

// sendStationMessage action
const stationMessageActionValidator: NodeValidator<ActionValidator<StationMessageActionConfig>, 'STATION_MESSAGE_ACTION'> = {
    nodeType: 'STATION_MESSAGE_ACTION',
    validators: [
        node => {
            return (
                !!node.data.config &&
                !isEmptyRichTextValue(node.data.config.title) &&
                !isEmptyRichTextValue(node.data.config.content) &&
                !!node.data.config.personList?.length
            )
        }
    ]
}

export const actionValidators = [
    createRecordActionValidator,
    createMultiRecordActionValidator,
    findRecordActionValidator,
    findMultiRecordActionValidator,
    updateRecordActionValidator,
    openPageValidator,
    openLinkValidator,
    notificationValidator,
    copyActionValidator,
    initialApprovalActionValidator,
    openRecordPageActionValidator,
    openRecordEditPageActionValidator,
    openFormPageActionValidator,
    aliyunMessageActionValidator,
    wechatMessageActionValidator,
    iClickContactActionValidator,
    dingtalkRobotActionValidator,
    wxRobotActionValidator,
    sendEmailActionValidator,
    loopActionValidator,

    // 动作流独有
    callActionValidator,
    downloadFileActionValidator,
    wechatPayActionValidator,
    // 自动化独有
    stationMessageActionValidator,
    stripePayActionValidator
]
