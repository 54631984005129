import { Popover } from '@byecode/ui'
import { type ContainerBlockConfig, BACKGROUND_TYPE } from '@lighthouse/core'
import { FilledInput, ListItem4ByecodeUi, PopoverDropdownWithClose, ShadowController } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { InheritLabel } from '@/components/InheritLabel'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { combinePath } from './utils'

interface ShadowFieldProps {
    prefixPath?: string
    onSetBreakKey?: (name: string) => void
}

export const ShadowField: React.FC<ShadowFieldProps> = ({ prefixPath, onSetBreakKey }) => {
    const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()
    const colorSystemMethods = useColorSystemAction()
    const shadowName = combinePath(prefixPath, 'breakPoint.design.shadow')
    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
            <InheritLabel label="阴影" name='breakPoint.design.shadow' />
            <Popover
                width={300}
                position="left-start"
                withinPortal
                autoUpdate={false}
                offsetOptions={168}
                onOpen={() => {
                    const shadowValue = getValues(shadowName)
                    if (!shadowValue?.color) {
                        setValue(shadowName, {
                            color: '#00000040',
                            x: 0,
                            y: 4,
                            blur: 4,
                            diffusion: 4
                        })
                        onSetBreakKey?.(shadowName)
                    }
                }}
            >
                <Controller
                    control={control}
                    name={shadowName}
                    render={({ field }) => (
                        <Popover.Target>
                            <FilledInput
                                value={{ type: BACKGROUND_TYPE.color, color: field.value?.color }}
                                onClear={() => {
                                    setValue(shadowName, {
                                        color: '',
                                        x: 0,
                                        y: 0,
                                        blur: 0,
                                        diffusion: 0
                                    })
                                    onSetBreakKey?.(shadowName)
                                }}
                                style={{ width: 180 }}
                            />
                        </Popover.Target>
                    )}
                />

                <PopoverDropdownWithClose title="阴影">
                    <Controller
                        control={control}
                        name={shadowName}
                        render={({ field }) => (
                            <ShadowController
                                value={field.value}
                                onChange={val => {
                                    if(isDeepEqual(val, field.value)){
                                        return
                                    }
                                    field.onChange(val)
                                    onSetBreakKey?.(shadowName)
                                }}
                                {...colorSystemMethods}
                            />
                        )}
                    />
                </PopoverDropdownWithClose>
            </Popover>
        </ListItem4ByecodeUi>
    )
}
