import type { BlockAbstract, BlockRuntimeState } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import {
    type FlowLayoutNode,
    findBlockById,
    getBlockChildren,
    getCurrentBlockChildren,
    isCustomViewBlock,
    isFormContainerBlock
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

/**
 * 根据id和tree删除节点
 * @date 2024/1/8 - 13:42:38
 *
 * @export
 * @param {string} id
 * @returns {(tree: BlockAbstract[]) => BlockAbstract[]}
 */
export function removeBlockById(id: string) {
    const recursion = (tree: BlockAbstract[]): undefined | BlockAbstract[] => {
        for (let i = 0; i < tree.length; i++) {
            const block = tree[i]
            if (block.id === id) {
                return tree.splice(i, 1)
            }

            if (block.type === BlockType.container) {
                for (const view of block.children) {
                    const res = recursion(view.children)
                    if (res) {
                        return res
                    }
                }
            }

            const children = getBlockChildren(block)
            if (children) {
                const res = recursion(children)
                if (res) {
                    return res
                }
            }
        }
    }
    return recursion
}

type IsLeafOptions = {
    activeId: string
    overId: string
    blocks: BlockAbstract[]
    blockRuntimeState?: BlockRuntimeState
}
/**
 * over的是否是active的子元素
 * @date 2024/1/16 - 19:09:12
 *
 */
export function isLeaf({ activeId, overId, blocks, blockRuntimeState }: IsLeafOptions) {
    const activeNode = findBlockById(activeId, blocks)
    if (!activeNode) {
        return false
    }

    const children = getCurrentBlockChildren(activeNode, blockRuntimeState)
    if (!children || children.length === 0) {
        return
    }

    const recursion = (tree: BlockAbstract[]): boolean => {
        for (const node of tree) {
            if (node.id === overId) {
                return true
            }

            const children = getCurrentBlockChildren(node, blockRuntimeState)
            if (children) {
                const res = recursion(children)
                if (res) {
                    return res
                }
            }
        }

        return false
    }

    return recursion(children)
}

/**
 * over的是否是active本身或者后代，禁止自己插入到自己或者自己的后代
 * @date 2024/3/26 - 15:01:24
 *
 * @export
 * @param {string} activeId
 * @param {string} overId
 * @returns {(tree: {}, parentIsActiveId?: boolean) => boolean}
 */
export function isActiveInOver(activeId: string, overId: string) {
    function findOver(id: string, tree: BlockAbstract[]): boolean {
        for (const node of tree) {
            if (node.id === overId) {
                return true
            }

            const children = getBlockChildren(node)
            if (children) {
                const res = findOver(id, children)
                if (res) {
                    return res
                }
            }
        }

        return false
    }

    const recursion = (tree: BlockAbstract[]): boolean => {
        for (const node of tree) {
            const children = getBlockChildren(node)

            if (node.id === activeId) {
                if (children) {
                    const res = findOver(overId, children)
                    if (res) {
                        return true
                    }
                }
                return false
            }

            if (children) {
                const res = recursion(children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return recursion
}

/**
 * 检查是否是自定义视图的后代
 *
 * @export
 * @param {string} target
 * @param {BlockAbstract[]} blocks
 * @returns {boolean}
 */
export function isCustomChildren(targetId: string, blocks: BlockAbstract[]) {
    function findTarget(id: string, tree: BlockAbstract[]) {
        for (const child of tree) {
            if (child.id === id) {
                return true
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = findTarget(id, children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    function find(tree: BlockAbstract[]): boolean {
        for (const child of tree) {
            if (isCustomViewBlock(child) && child.children) {
                const res = findTarget(targetId, child.children)
                if (res) {
                    return true
                }
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = find(children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return find(blocks)
}

/**
 * 检查是否是表单容器的后代
 *
 * @export
 * @param {string} target
 * @param {BlockAbstract[]} blocks
 * @returns {boolean}
 */
export function isFormContainerChildren(targetId: string, blocks: BlockAbstract[]) {
    function findTarget(id: string, tree: BlockAbstract[]) {
        for (const child of tree) {
            if (child.id === id) {
                return true
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = findTarget(id, children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    function find(tree: BlockAbstract[]): boolean {
        for (const child of tree) {
            if (isFormContainerBlock(child) && child.children) {
                const res = findTarget(targetId, child.children)
                if (res) {
                    return true
                }
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = find(children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return find(blocks)
}

/**
 * 递归检查是否有禁止的node
 *
 * @export
 * @param {FlowLayoutNode} target
 * @param {BlockType[]} notAllowedList
 * @returns {boolean}
 */
export function includeNotAllowedBlock(target: BlockAbstract, notAllowedList: BlockType[]): boolean {
    if (notAllowedList.includes(target.type)) {
        return true
    }

    const children = getBlockChildren(target)

    if (children) {
        for (const child of children) {
            const res = includeNotAllowedBlock(child, notAllowedList)
            if (res) {
                return true
            }
        }
    }
    return false
}

export function getNodePath(nodeId: string) {
    function rec(tree: FlowLayoutNode[]): FlowLayoutNode[] {
        for (const node of tree) {
            if (node.id === nodeId) {
                return [node]
            }

            if ((node.type === 'container' || node.type === 'custom') && node.children) {
                const res = rec(node.children)
                if (res.length > 0) {
                    return [node, ...res]
                }
            }
        }

        return []
    }

    return rec
}

/** 递归复制block，替换id */
export function copyBlock(block: BlockAbstract) {
    function recursionCopy(copiedBlock: BlockAbstract): BlockAbstract {
        const newId = `${copiedBlock.type}-${nanoid(12)}`

        if (copiedBlock.type === BlockType.container) {
            return {
                ...copiedBlock,
                id: newId,
                children: copiedBlock.children.map(view => {
                    return {
                        ...view,
                        children: view.children.map(recursionCopy)
                    }
                })
            }
        }

        if (copiedBlock.type === BlockType.formContainer) {
            return { ...copiedBlock, id: newId, children: copiedBlock.children.map(recursionCopy) }
        }

        if (copiedBlock.type === BlockType.view && copiedBlock.config.viewType === 'custom' && copiedBlock.children) {
            return { ...copiedBlock, id: newId, children: copiedBlock.children.map(recursionCopy) }
        }

        return { ...copiedBlock, id: newId }
    }

    return recursionCopy(block)
}

/** 递归设置节点的syncId */
export function deepSetNodeSyncIdByHostNode(block: BlockAbstract, syncHostBlock: BlockAbstract) {
    block.syncId = syncHostBlock.id
    if (block.type === BlockType.container && syncHostBlock.type === BlockType.container) {
        const to = block.children
        const from = syncHostBlock.children
        to.forEach((view, viewIndex) => {
            view.children.forEach((item, index) => {
                deepSetNodeSyncIdByHostNode(item, from[viewIndex].children[index])
            })
        })
    }

    if (block.type === BlockType.formContainer && syncHostBlock.type === BlockType.formContainer) {
        const to = block.children
        const from = syncHostBlock.children
        to.forEach((item, index) => {
            deepSetNodeSyncIdByHostNode(item, from[index])
        })
    }

    if (
        block.type === BlockType.view &&
        block.config.viewType === 'custom' &&
        !!block.children &&
        syncHostBlock.type === block.type &&
        syncHostBlock.config.viewType === 'custom' &&
        !!syncHostBlock.children
    ) {
        const to = block.children
        const from = syncHostBlock.children
        to.forEach((item, index) => {
            deepSetNodeSyncIdByHostNode(item, from[index])
        })
    }
}
