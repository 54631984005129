import { Button, ClickAwayListener, Flex, IconFont, Modal, Popover, Select, Tabs } from '@byecode/ui'
import type { FlowPageMode, WorkflowType } from '@lighthouse/shared'
import { Switch } from '@mantine/core'
import type { ChangeEvent } from 'react'
import React, { useMemo, useRef, useState } from 'react'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import type { RunStatus } from '@/services/types'

import * as SC from './styles'

type TitleMode = 'input' | 'preview'

interface PageHeaderProps {
    name: string
    type: WorkflowType
    tab?: FlowPageMode
    status?: RunStatus
    descriptions?: { id: string; label: string; value: string }[]
    flowEnabled?: boolean
    flowUpdated?: boolean
    showBack?: boolean
    showClose?: boolean
    showLogTab?: boolean
    onNameChange?: (name: string) => void
    onBack?: () => void
    onClose?: () => void
    onFlowEnableChange?: (enabled: boolean) => void
    onDelete?: () => void
    onUpdateAndRelease?: () => void
    onTabChange?: (tab: FlowPageMode) => void
    onLogStatusFilterChange?: (status: RunStatus) => void
}

const statusOptions = [
    { label: '全部', value: 'all' },
    { label: '运行中', value: 'RUNNING' },
    { label: '已完成', value: 'SUCCESS' },
    { label: '已失败', value: 'FAILED' }
]

export const PageHeader: React.FC<PageHeaderProps> = ({
    name,
    type,
    tab,
    status,
    descriptions,
    flowEnabled,
    flowUpdated,
    showBack,
    showClose,
    showLogTab,
    onNameChange,
    onBack,
    onClose,
    onDelete,
    onFlowEnableChange,
    onTabChange,
    onUpdateAndRelease,
    onLogStatusFilterChange
}) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const titleRef = useRef<HTMLInputElement>(null)
    const isLogPage = tab === 'runLog'
    const [titleMode, setTitleMode] = useState<TitleMode>('preview')
    const [open, setOpen] = useState(false)
    const [titleEditOpen, setTitleEditOpen] = useState(false)

    const handleFlowEnableChange = (ev: ChangeEvent<HTMLInputElement>) => {
        onFlowEnableChange?.(ev.target.checked)
    }
    //     <ModalConfirm
    //     title="工作流设计有修改，是否保存？"
    //     content="你修改了工作流设计但没有保存，是否需要保存工作流设计并继续？"
    //     footerExtra={
    //         <Button type="text" size="sm" onClick={blocker.reset}>
    //             返回设计
    //         </Button>
    //     }
    //     onReject={blocker.proceed}
    //     onResolve={handleSaveAndLeave}
    //     okStatus="primary"
    //     okText="保存并继续"
    //     okProps={{ loading: isSaveAndLeaveLoading }}
    //     cancelText="不保存"
    // />
    const handleClose = async () => {
          if (flowUpdated) {
            const isAccepted = await Modal.confirm({
                okStatus: 'primary',
                title: '工作流设计有修改，是否保存？',
                content: '你修改了工作流设计但没有保存，是否需要保存工作流设计并继续？',
                footerExtra: ({ onUnmount }) => (
                    <Button type="text" size="sm" onClick={onUnmount}>
                        返回设计
                    </Button>
                ),
                cancelText: '不保存',
                okText: '保存并继续'
            })
            if (isAccepted) {
                onUpdateAndRelease?.()
            }
        }
        if (!flowEnabled) {
            try {
                const isAccepted = await Modal.confirm({
                    okStatus: 'primary',
                    title: '该工作流未启用',
                    content: '点击按钮不会执行动作',
                    cancelText: '仍不启用',
                    okText: '启用并关闭窗口'
                })

                if (isAccepted) {
                    onFlowEnableChange?.(true)
                }
            } catch {}
        }

        onClose?.()
    }

    const tabsOptions = useMemo(() => {
        if (type === 'automation') {
            return [
                { value: 'flow', label: '流程' },
                { value: 'configure', label: '全局配置' },
                { value: 'runLog', label: '执行记录' }
            ]
        }
        return [
            { value: 'flow', label: '流程' },
            { value: 'runLog', label: '执行记录' }
        ]
    }, [type])

    const popoverCommonContent = (
        <>
            <SC.ListItem
                onClick={() => {
                    setTitleMode('input')
                    setOpen(false)
                    setTitleEditOpen(false)
                    requestAnimationFrame(() => {
                        titleRef.current?.select()
                    })
                }}
            >
                <SC.ListItemIcon type="NotePencil" />
                <SC.ListItemLabel>重命名</SC.ListItemLabel>
            </SC.ListItem>
            <SC.ListItem onClick={onDelete}>
                <SC.ListItemIcon type="Trash" />
                <SC.ListItemLabel>删除</SC.ListItemLabel>
            </SC.ListItem>
        </>
    )

    return (
        <SC.PageHeaderWrapper>
            <SC.PageNavigatorWrapper>
                <SC.PageNavigatorLeft>
                    {showBack && <SC.PageNavigatorBackIcon type="ArrowLeftSmall" size={18} onClick={onBack} />}
                    {titleMode === 'preview' ? (
                        <SC.PageTitlePreviewer onClick={() => setTitleMode('input')}>{name}</SC.PageTitlePreviewer>
                    ) : (
                        <ClickAwayListener onClickAway={() => setTitleMode('preview')}>
                            <SC.PageTitleInput
                                ref={titleRef}
                                defaultValue={name}
                                autoFocus
                                onBlur={ev => {
                                    onNameChange?.(ev.target.value)
                                    setTitleMode('preview')
                                }}
                                onKeyDownCapture={ev => {
                                    if (ev.key === 'Enter') {
                                        onNameChange?.(ev.currentTarget.value)
                                    }
                                }}
                            />
                        </ClickAwayListener>
                    )}
                    {!disabledWithVersion && (
                        <Popover opened={titleEditOpen} onChange={setTitleEditOpen} withinPortal width="auto">
                            <Popover.Target>
                                <SC.PageNavigatorAction type="DotsThree" />
                            </Popover.Target>
                            <Popover.Dropdown>
                                <SC.SettingPopoverWrapper>{popoverCommonContent}</SC.SettingPopoverWrapper>
                            </Popover.Dropdown>
                        </Popover>
                    )}
                </SC.PageNavigatorLeft>
                {showLogTab && (
                    <Tabs
                        value={tab}
                        styles={{
                            content: {
                                gap: '16px'
                            },
                            indicator: {
                                bottom: '-9px',
                                borderRadius: 0,
                                height: '2px'
                            }
                        }}
                        onChange={val => onTabChange?.(val as FlowPageMode)}
                        data={tabsOptions}
                    />
                )}
                <SC.PageNavigatorActionWrapper>
                    {isLogPage ? (
                        <SC.StatusSelectWrapper>
                            <SC.StatusSelectLabel>状态：</SC.StatusSelectLabel>
                            <Select
                                value={status}
                                style={{ width: 140 }}
                                options={statusOptions}
                                onChange={value => {
                                    onLogStatusFilterChange?.(value as RunStatus)
                                }}
                            />
                        </SC.StatusSelectWrapper>
                    ) : (
                        <Flex gap={8}>
                            <Button
                                disabled={!flowUpdated || disabledWithVersion}
                                type="primary"
                                style={{ height: 30 }}
                                onClick={onUpdateAndRelease}
                            >
                                {/* {flowEnabled ? '更新' : '保存'} */}
                                保存
                            </Button>
                            <Switch
                                disabled={disabledWithVersion}
                                color="green"
                                radius={6}
                                styles={{
                                    track: {
                                        fontSize: 12
                                    },
                                    trackLabel: { minWidth: 48 },
                                    thumb: {
                                        borderRadius: 4
                                    }
                                }}
                                size="lg"
                                thumbIcon={
                                    <IconFont
                                        type="Tick"
                                        style={{ fontSize: 12, color: flowEnabled ? 'var(--color-green-500)' : 'var(--color-gray-500)' }}
                                    />
                                }
                                onLabel="已启用"
                                offLabel="未启用"
                                checked={flowEnabled}
                                onChange={handleFlowEnableChange}
                            />
                        </Flex>
                    )}
                    {!disabledWithVersion && (
                        <Popover opened={open} onChange={setOpen} withinPortal width="auto">
                            <Popover.Target>
                                <SC.PageNavigatorAction type="DotsThree" />
                            </Popover.Target>
                            <Popover.Dropdown>
                                <SC.SettingPopoverWrapper>
                                    {popoverCommonContent}
                                    <SC.ListDivider />
                                    {descriptions?.map(({ id, label, value }) => (
                                        <SC.ListDescriptionWrapper key={id}>
                                            <SC.ListDescriptionItemLabel>{label}</SC.ListDescriptionItemLabel>
                                            <SC.ListDescriptionItemValue>{value}</SC.ListDescriptionItemValue>
                                        </SC.ListDescriptionWrapper>
                                    ))}
                                </SC.SettingPopoverWrapper>
                            </Popover.Dropdown>
                        </Popover>
                    )}

                    {showClose && <SC.PageNavigatorAction type="Close" style={{ marginLeft: 8 }} onClick={handleClose} />}
                </SC.PageNavigatorActionWrapper>
            </SC.PageNavigatorWrapper>
            {/* {flowUpdated && flowEnabled && (
                <SC.PageNotificationWrapper>
                    <SC.PageNotificationContentWrapper style={{ marginRight: isSettingShown ? 360 : 0 }}>
                        <SC.PageNotificationContent>您有未更新的修改，这些更改还未实时应用。完成配置以更新。</SC.PageNotificationContent>
                        <SC.PageNotificationActionWrapper>
                            <SC.PageNotificationDiscardAction onClick={onDropDraft}>放弃更改</SC.PageNotificationDiscardAction>
                            <SC.PageNotificationApplyAction onClick={onSaveDraftAsRelease}>更新</SC.PageNotificationApplyAction>
                        </SC.PageNotificationActionWrapper>
                    </SC.PageNotificationContentWrapper>
                </SC.PageNotificationWrapper>
            )} */}
        </SC.PageHeaderWrapper>
    )
}
