import { Toast } from '@byecode/ui'
import { collectBlockAndChildren } from '@lighthouse/block'
import type { BlockAbstract } from '@lighthouse/core'
import { findBlockById, findParentBlockById, getCurrentBlockChildren, useAtomAction } from '@lighthouse/shared'
import { getBrowser } from '@lighthouse/tools'
import { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { clone } from 'rambda'
import { useHotkeys } from 'react-hotkeys-hook'

import { createBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { copiedBoxSelectionNodesSnapshotAtom } from '@/atoms/workSpace/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { BLOCK_NODE_CREATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { useNodeToolbarActions } from './useNodeToolbarActions'
import { copyBlock } from './utils'

type Configure = {
    ref: React.RefObject<HTMLDivElement | null>
    enabled?: boolean
}

/** 选中的节点操作 */
export const useSelectionNodesActions = ({ ref, enabled }: Configure) => {
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const { run: createBlock } = useAtomAction(createBlockAtom)

    const actions = useNodeToolbarActions({ stackId, pageId, rootPageId })

    const handleCopy = useAtomCallback((get, set, copyIds: string[]) => {
        const blocks = get(blocksAtom)[pageId]
        if (!blocks) {
            return
        }
        set(copiedBoxSelectionNodesSnapshotAtom, { copiedIds: [...copyIds], blocks: clone(blocks) })
        Toast.success(`已复制${copyIds.length}个组件`)
    })

    const handlePaste = useAtomCallback(
        async (
            get,
            set,
            snapshot: {
                copiedIds: string[]
                blocks: BlockAbstract[]
            }
        ) => {
            const stack = get(pageStackAtomFamily({ stackId, rootPageId }))
            if (!stack) {
                return
            }
            const { state, blockRuntimeState } = stack
            const { asideType, selectedNodes } = state

            const pastePageBlocks = snapshot.copiedIds.map(id => findBlockById(id, snapshot.blocks)).filter(Boolean) as BlockAbstract[]
            const newBlocks = pastePageBlocks.map(copyBlock)

            const addedBlocksIds = newBlocks.reduce<string[]>(
                (total, curr) => [...total, ...collectBlockAndChildren(curr).map(item => item.id)],
                []
            )

            set(blockCreatingListAtom, s => [...s, ...addedBlocksIds])

            let originNodes: BlockAbstract[] | undefined
            let finalNodes: BlockAbstract[] = []

            set(blocksAtom, draft => {
                const pageBlocks = draft[pageId]
                originNodes = original(pageBlocks)
                if (!pageBlocks) {
                    return
                }

                if (asideType === AsideType.PAGE) {
                    pageBlocks.push(...newBlocks)
                } else if (asideType === AsideType.BLOCK) {
                    if (!selectedNodes || selectedNodes.length !== 1) {
                        return
                    }

                    const selectedNodeId = selectedNodes[0]

                    const targetNode = findBlockById(selectedNodeId, pageBlocks)
                    if (!targetNode) {
                        return
                    }

                    const children = getCurrentBlockChildren(targetNode, blockRuntimeState)
                    if (children) {
                        children.push(...newBlocks)
                    } else {
                        const targetId = targetNode.id
                        const parent = findParentBlockById(targetId, pageBlocks)
                        const parentChildren = parent ? getCurrentBlockChildren(parent, blockRuntimeState) : pageBlocks
                        if (!parentChildren) {
                            return
                        }

                        const index = parentChildren.findIndex(item => item.id === targetId)
                        if (index === -1) {
                            return
                        }

                        parentChildren.splice(index + 1, 0, ...newBlocks)
                    }
                }

                finalNodes = current(pageBlocks)
            })

            await createBlock({ rootPageId, pageId, stackId, createdBlocks: newBlocks, blocks: finalNodes })
            set(blockCreatingListAtom, s => s.filter(id => !addedBlocksIds.includes(id)))

            pageUndoRedoController.add({
                action: BLOCK_NODE_CREATE_ACTION,
                payload: {
                    createdBlocks: newBlocks,
                    prev: originNodes,
                    next: finalNodes
                }
            })
        }
    )

    const handleKeydown = useAtomCallback<void, Parameters<Parameters<typeof useHotkeys>[1]>>((get, set, event, hotkeysEvent) => {
        const copiedBoxSelectionNodesSnapshot = get(copiedBoxSelectionNodesSnapshotAtom)
        const selectedNodes = get(pageStackAtomFamily({ stackId, rootPageId }))?.state.selectedNodes

        const isMac = getBrowser().getBrowserInfo().platform === 'Macintosh'

        if (selectedNodes && selectedNodes.length > 0 && (isMac ? hotkeysEvent.key === '⌘+c' : hotkeysEvent.key === 'ctrl+c')) {
            handleCopy(selectedNodes)
        }

        if (copiedBoxSelectionNodesSnapshot.copiedIds.length > 0 && (isMac ? hotkeysEvent.key === '⌘+v' : hotkeysEvent.key === 'ctrl+v')) {
            if (!ref.current?.contains(event.target as Node)) {
                return
            }
            handlePaste(copiedBoxSelectionNodesSnapshot)
        }

        if (
            selectedNodes &&
            selectedNodes.length > 0 &&
            (isMac ? hotkeysEvent.key === 'Backspace' : ['Delete', 'Backspace'].includes(hotkeysEvent.key))
        ) {
            handleDelete(selectedNodes)
        }
    })

    const handleDelete = useAtomCallback((get, set, ids: string[]) => {
        actions.onRemove(ids)
    })

    useHotkeys('ctrl+c, ⌘+c, ctrl+v, ⌘+v, Delete, Backspace', handleKeydown, { enabled }, [enabled])
}
