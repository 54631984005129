import type { Option } from '@byecode/ui'
import type { DataSourceAbstract, Field, TypeInstanceMap, ViewField } from '@lighthouse/core'
import { type FieldType, type ViewFieldProtocol, DataSourceType, VariableType } from '@lighthouse/core'
import { find, reduce } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { getDefaultOperator, getFieldIcon, getRealField, getVariableFieldId } from '../../utils'
import { DEFAULT_FILTER_VALUE_VARIABLE, FieldTypeTag, getOperatorOption } from '../'
import * as SC from './styles'

interface FieldsProps {
    dataSource?: DataSourceAbstract
    columns: ViewField[]
    prefixName: string
    noSettingInnerType?: Set<TypeInstanceMap>
    disabled?: boolean
}

export const Fields: React.FC<FieldsProps> = ({ dataSource, columns, prefixName, noSettingInnerType, disabled }) => {
    const { control, setValue } = useFormContext()
    const isShowDsName = dataSource?.type === DataSourceType.joinDataSource
    const options = useMemo(() => {
        return reduce<ViewFieldProtocol, Option[]>(
            (list, field) => {
                const { type, title, fieldId, dsName, dsId, innerType } = field
                // trim formula
                if (!innerType || noSettingInnerType?.has(innerType)) {
                    return list
                }
                const icon = getFieldIcon(fieldId, type, innerType)
                // const isShowDsName = id !== dsId
                const newOption = {
                    value: fieldId,
                    label: title,
                    icon,
                    extra: <FieldTypeTag type={type} innerType={innerType} />,
                    describe: isShowDsName && <SC.Describe>{dsName}</SC.Describe>
                }
                return [...list, newOption]
            },
            [],
            columns
        )
    }, [columns, isShowDsName, noSettingInnerType])

    const handleOperator = useCallback(
        (val: string) => {
            const viewField = find(item => item.fieldId === val, columns)
            if (!viewField) {
                return
            }
            const operator = getOperatorOption(viewField)
            setValue(`${prefixName}.operator`, operator?.[0].value)
            setValue(`${prefixName}.paramList`, [DEFAULT_FILTER_VALUE_VARIABLE])
        },
        [columns, prefixName, setValue]
    )

    return (
        <Controller
            name={`${prefixName}.idVariable`}
            control={control}
            render={({ field: ctlField }) => {
                return (
                    <SC.Box>
                        <SC.ParamsSelect
                            styles={{
                                root: {
                                    flex: 1
                                },
                                input: {
                                    minWidth: '120px',
                                    width: '100%'
                                }
                            }}
                            disabled={disabled}
                            searchable
                            options={options}
                            value={getVariableFieldId(ctlField.value)}
                            onChange={val => {
                                if (val !== ctlField.value) {
                                    ctlField.onChange({
                                        type: VariableType.FIELD_ID,
                                        fieldIdVariable: { fieldId: val }
                                    })
                                    handleOperator(val)
                                }
                            }}
                        />
                    </SC.Box>
                )
            }}
        />
    )
}
