import { Anchor, Box, Divider, Group, Text } from '@byecode/ui'
import type { SubFormBlockConfig } from '@lighthouse/core'
import { type FieldInputConfigProtocol, FilterMode } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import {
    fieldGuidanceDocumentMap,
    getAppointField,
    getViewColumns,
    ListItemPaddingByecodeUi,
    notDataSettingInnerTypes,
    pureTextFieldTypes,
    SelectDataSource,
    useAtomData,
    USER_DATASOURCE,
    VariableSourceType,
    ViewFieldFilterConfigure
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList, useDsListAndFieldOptions, useNotAggregateDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { FieldSelect } from '../../../../FieldSelect'
import { ListItemMenu } from '../../ListItemMenu'
import { CommonOpera } from '../../UserOperate'
import type { BaseFieldInputSetting } from '../types'

interface CascadeConfigProps extends Pick<BaseFieldInputSetting, 'prefix'> {
    pointer: string
}

export const CascadeConfig: React.FunctionComponent<CascadeConfigProps> = ({ pointer, prefix }) => {
    const { watch, setValue, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()

    const [cascadePointer = '', fieldPointer = ''] = watch([`${prefix}cascade.cascadePointer`, `${prefix}fieldPointer`])

    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()

    const dataSource = useDataSource(appId, envId, pointer)

    const cascadeDsLists = useNotAggregateDataSourceList(appId, envId)

    const dataSourceList = useDataSourceList(appId, envId)

    const field = dataSource?.schema?.[fieldPointer]

    const isDepartmentField = field?.type === 'department' || field?.type === 'parentDepartment'

    const cascadeDsId = isDepartmentField ? USER_DATASOURCE : cascadePointer

    const cascadeDataSource = useDataSource(appId, envId, cascadeDsId)

    const { viewOptions: cascadeViewOptions, schema: cascadeSchema = {} } = cascadeDataSource ?? {}
    const { fieldOptions: cascadeFieldOptions } = useDsListAndFieldOptions(appId, envId, cascadeDsId)

    const [stackId, pageId, rootPageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || '', s?.rootPageId || ''], [])
    )

    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    const cascadeColumns = getViewColumns({
        tableProps: cascadeViewOptions?.tableProps ?? [],
        schema: cascadeSchema
    })

    const cascadePrimaryField = useMemo(() => {
        if (!cascadeDataSource) {
            return cascadeColumns[0]?.fieldId
        }
        const field = getAppointField(cascadeDataSource, 'ID')
        return field?.id || cascadeColumns[0]?.fieldId
    }, [cascadeColumns, cascadeDataSource])

    const cascadeOptions = useMemo(() => {
        return cascadeFieldOptions.filter(option => pureTextFieldTypes.has(option.type) && option.type !== 'id')
    }, [cascadeFieldOptions])

    const cascadeSelectOptions = useMemo(() => {
        const innerTypeList = new Set(['TEXT', 'NUMBER'])
        return cascadeFieldOptions.filter(option => option.innerType && innerTypeList.has(option.innerType))
    }, [cascadeFieldOptions])

    const cascadeShowSelectOptions = useMemo(() => {
        const innerTypeList = new Set(['TEXT', 'NUMBER'])
        return cascadeFieldOptions.filter(option => option.innerType && innerTypeList.has(option.innerType))
    }, [cascadeFieldOptions])

    const cascadeSortOptions = useMemo(() => {
        return cascadeFieldOptions.filter(option => pureTextFieldTypes.has(option.type))
    }, [cascadeFieldOptions])

    if (isDepartmentField) {
        return null
    }

    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group label="选项内容">
                <Box
                    style={{
                        lineHeight: '20px',
                        padding: '8px 8px 8px 32px',
                        margin: '4px 0',
                        backgroundColor: 'var(--color-gray-100)',
                        borderRadius: 6,
                        color: 'var(--color-gray-500)',
                        fontSize: 12
                    }}
                >
                    <ul>
                        <li>
                            如何构建适用于级联选择的数据表？
                            <Anchor
                                style={{ color: 'var(--color-main)', cursor: 'pointer' }}
                                to={fieldGuidanceDocumentMap['cascade'].url}
                                target="_blank"
                            >
                                点击查看
                            </Anchor>
                            。
                        </li>
                        <li>所有层级累计最多 1000 个选项，超出则按照筛选和排序取前 1000 个选项。</li>
                    </ul>
                </Box>
                <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                    <Text>选项来源</Text>
                    <Controller
                        control={control}
                        name={`${prefix}cascade.cascadePointer`}
                        render={({ field: { value, onChange } }) => (
                            <SelectDataSource
                                placeholder="请选择数据表"
                                value={value}
                                size="md"
                                onChange={onChange}
                                dataSourceList={cascadeDsLists}
                            />
                        )}
                    />
                </ListItemPaddingByecodeUi>
                <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                    <Text>选项值</Text>
                    <Controller
                        name={`${prefix}cascade.cascadeFieldPointer`}
                        render={({ field: { value, onChange } }) => (
                            <FieldSelect
                                dsId={cascadeDsId}
                                disabled={cascadeSelectOptions.length === 0}
                                options={cascadeSelectOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </ListItemPaddingByecodeUi>
                <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                    <Text>显示为</Text>
                    <Controller
                        name={`${prefix}cascade.cascadeShowFieldPointer`}
                        render={({ field: { value, onChange } }) => (
                            <FieldSelect
                                dsId={cascadeDsId}
                                disabled={cascadeShowSelectOptions.length === 0}
                                options={cascadeShowSelectOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </ListItemPaddingByecodeUi>
                <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                    <Text>父层级</Text>
                    <Controller
                        name={`${prefix}cascade.parentFieldPointer`}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FieldSelect
                                dsId={cascadeDsId}
                                disabled={cascadeOptions.length === 0}
                                options={cascadeOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </ListItemPaddingByecodeUi>
                <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                    <Text>排序字段</Text>
                    <Controller
                        name={`${prefix}cascade.sortFieldPointer`}
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <FieldSelect
                                dsId={cascadeDsId}
                                disabled={cascadeSortOptions.length === 0}
                                options={cascadeSortOptions}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </ListItemPaddingByecodeUi>
                <Controller
                    name={`${prefix}cascade.filter`}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                        const filter = value
                        const filtersLength = filter?.expression?.conditions?.length || 0
                        return (
                            <ListItemMenu
                                width="auto"
                                label="数据筛选"
                                compact
                                enablePadding={false}
                                enableHover={false}
                                value={Boolean(filtersLength) && `已设 ${String(filtersLength)} 条`}
                                // icon={<IconFont type="Filter" color="var(--color-gray-400)" size={16} />}
                                popupContent={
                                    <ViewFieldFilterConfigure
                                        dataSource={dataSource}
                                        dataSourceList={dataSourceList}
                                        fieldBlocksWithDsId={fieldBlocksWithDsId}
                                        // sourceDataSource={defaultValueDataSourceList}
                                        sources={sources}
                                        columns={cascadeColumns}
                                        paramsMode={FilterMode.CUSTOM}
                                        primaryField={cascadePrimaryField}
                                        fieldMode={FilterMode.NORMAL}
                                        filter={filter}
                                        noSettingInnerType={notDataSettingInnerTypes}
                                        onFilter={onChange}
                                    />
                                }
                            />
                        )
                    }}
                />
            </Group>
        </>
    )
}

export const CascadeOperation: React.FC<Pick<BaseFieldInputSetting, 'prefix'>> = ({ prefix }) => {
    const { control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    return (
        <>
            <div style={{ padding: '0 8px' }}>
                <Controller
                    control={control}
                    name={`${prefix}cascade.isLastLevel`}
                    render={({ field }) => <CommonOpera label="必须选到最终层级" value={field.value} onChange={v => field.onChange(v)} />}
                />
            </div>
            <div style={{ padding: '0 8px' }}>
                <Controller
                    control={control}
                    name={`${prefix}cascade.showPath`}
                    render={({ field }) => <CommonOpera label="结果显示层级路径" value={field.value} onChange={v => field.onChange(v)} />}
                />
            </div>
        </>
    )
}
