import { Select } from '@byecode/ui'
import type { FlowNode, NodeTypes } from '@lighthouse/shared'
import { CollapseBox, generateVariableValue, NodeFieldPreview, nodeTypeOptions } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as SC from '../../styles'
import { PreviewCard } from './PreviewCard'

export interface ShareToWechatActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
}

export const ShareToWechatActionConfigure: React.FC<ShareToWechatActionConfigureProps> = ({
    prefixName = 'config',
    allParentNodes,
    actionTypeSwitcher
}) => {
    const { control, watch } = useFormContext()

    const [t, d, i] = watch([`${prefixName}.title`, `${prefixName}.desc`, `${prefixName}.imgUrl`])

    const previewData = useMemo(() => {
        const titleVariableValue = generateVariableValue({ innerType: 'TEXT', jsonContent: t })
        const descVariableValue = generateVariableValue({ innerType: 'TEXT', jsonContent: d })
        const imgUrlVariableValue = generateVariableValue({ innerType: 'TEXT', jsonContent: i })
        return {
            title: Array.isArray(titleVariableValue) ? '#标题#' : String(titleVariableValue || '#标题#'),
            desc: Array.isArray(descVariableValue) ? '#副标题#' : String(descVariableValue || '#副标题#'),
            imgUrl: Array.isArray(imgUrlVariableValue) ? '' : String(imgUrlVariableValue)
        }
    }, [d, i, t])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <CollapseBox label="分享配置" required>
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>标题</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.title`}
                                control={control}
                                render={({ field }) => <NodeFieldPreview autoHeight {...field} options={[]} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>副标题</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.desc`}
                                control={control}
                                render={({ field }) => <NodeFieldPreview autoHeight {...field} options={[]} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>图片</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.imgUrl`}
                                control={control}
                                render={({ field }) => <NodeFieldPreview autoHeight {...field} options={[]} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
                <PreviewCard {...previewData} />
            </CollapseBox>
        </SC.Container>
    )
}
