import type { Field } from '@lighthouse/core'
import { findParentCustomViewBlock, getViewOptions, useAtomData } from '@lighthouse/shared'
import { useCallback, useMemo } from 'react'

import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'

export function useVariableCustomDataSource() {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const [pageId, blockId, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(
            s => [s?.pageId ?? '', s?.state.selectedNodes && s.state.selectedNodes.length === 1 ? s.state.selectedNodes[0] : '', s?.blockRuntimeState] as const,
            []
        )
    )
    const blocks = useAtomData(pageBlocksAtom(pageId))

    const customViewDsId = useMemo(() => findParentCustomViewBlock({ id: blockId, blocks, blockRuntimeState })?.config.pointer || '', [blockId, blockRuntimeState, blocks])

    const customViewDataSource = useAtomData(dataSourceAtomFamily({ appId, envId, dsId: customViewDsId }))

    return { customViewDataSource, customViewDsId }
}

export function useVariableCustomViewOption(filterField?: (field: Field) => boolean) {
    const { customViewDataSource } = useVariableCustomDataSource()
    const customViewOption = useMemo(
        () =>
            customViewDataSource &&
            getViewOptions({ dataSource: customViewDataSource, viewType: 'custom', validateFieldType: filterField }),
        [customViewDataSource, filterField]
    )

    return { customViewOption, customViewDataSource }
}
