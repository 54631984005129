import { FindUseLocationType, FindUseObjectContextProvider, useAtomData } from '@lighthouse/shared'
import React, { useCallback } from 'react'

import { lastPageOfStackAtom, pageAtomFamily, pageListAtom } from '@/atoms/page/state'
import type { PageMetaData } from '@/atoms/page/types'
import PageSetting from '@/components/PageSetting'
import { useFindUse } from '@/hooks/useFindUse'

const getPageId = (meta?: PageMetaData) => meta?.pageId || ''

/** 页面设置 */
export const PageSettingController = () => {
    const currentPageId = useAtomData(lastPageOfStackAtom, getPageId)
    const pageContent = useAtomData(pageListAtom, useCallback((list) => list?.find(item => item.id === currentPageId) ,[currentPageId]))
    const findUseObject = useFindUse(FindUseLocationType.PAGE, { id: currentPageId })
    return pageContent ? (
        <FindUseObjectContextProvider value={findUseObject}>
            <PageSetting key={currentPageId} pageData={pageContent} />
        </FindUseObjectContextProvider>
    ) : null
}
