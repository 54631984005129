import type { BarChartOption, BaseChartOption, CalendarViewOptions, GalleryViewOptions, IndicatorOptionProtocol, KanbanViewOptions } from "@lighthouse/core"

 // 日历视图
 export const cleanCalendarConfig: CalendarViewOptions = {
    scheduleTitleField: undefined,
    scheduleStartDateField: undefined,
    scheduleEndDateField: undefined,
    scheduleColorMode: undefined,
    scheduleColor: undefined
}
// 画廊视图
export const cleanGalleryConfig: GalleryViewOptions = {
    headCover: undefined,
    headAvatar: undefined,
    headTitle: undefined,
    headTags: undefined,
    contentTags: undefined,
    footTags: undefined,
    // arrangement: undefined,
    // cols: undefined,
    // size: undefined,
    style: undefined
}
// 看板
export const cleanKanbanConfig: KanbanViewOptions = {
    kanbanGroupConfigure: undefined,
    canSetKanban: undefined,
    canSortKanban: undefined
}

export const cleanBarConfig: Partial<IndicatorOptionProtocol> = {
    appId: '',
    pointer: '',
    showHeadTitle: true,
    title: '指标卡',
    showLabel: false,
    showThousandthSplit: false,
    showUnit: false,
    fieldId: ''
}

export const cleanStriationConfig: Partial<BarChartOption & IndicatorOptionProtocol> = {
    showSecondaryAxis: undefined, // 显示次轴
    secondaryAxis: [], // 次轴
    secondaryMarkLines: [], // 次轴 辅助线
    ySecondaryAxisTitle: undefined, // y次轴标题

    ...cleanBarConfig
}
export const cleanPieConfig: Partial<BarChartOption & IndicatorOptionProtocol> = {
    mainMarkLines: [], // 主轴 辅助线
    showAxisTitle: undefined, // 显示坐标轴标题
    xAxisTitle: undefined, // x坐标轴标题
    yMainAxisTitle: undefined, // y主轴标题
    stacking: undefined, // 展示方式
    showGridLine: undefined, // 显示网格线
    showAbbreviationAxis: undefined, // 显示缩略轴
    showLabel: undefined, // 显示数值标签

    showSecondaryAxis: undefined, // 显示次轴
    secondaryAxis: [], // 次轴
    secondaryMarkLines: [], // 次轴 辅助线
    ySecondaryAxisTitle: undefined, // y次轴标题

    ...cleanBarConfig
}

export const cleanIndicatorConfig: Partial<BaseChartOption & BarChartOption> = {
    pointer: '',
    appId: '',
    dimensions: [], // 维度
    mainAxis: [], // 主轴 原指标
    aggregate: undefined, // 是否聚合
    ruleFilter: undefined, // 筛选器
    xyAxis: undefined, // 排序依据  横轴值/纵轴值
    sortMode: undefined, // 排序规则 正序/倒序
    // theme: '',
    // colors: [],
    showHeadTitle: undefined, // 显示标题
    title: undefined, // 标题
    showLegend: undefined, // 显示图例
    legendTranspose: undefined, // 图例方向

    mainMarkLines: [], // 主轴 辅助线
    showAxisTitle: undefined, // 显示坐标轴标题
    xAxisTitle: undefined, // x坐标轴标题
    yMainAxisTitle: undefined, // y主轴标题
    stacking: undefined, // 展示方式
    showGridLine: undefined, // 显示网格线
    showAbbreviationAxis: undefined, // 显示缩略轴
    showLabel: undefined, // 显示数值标签

    showSecondaryAxis: undefined, // 显示次轴
    secondaryAxis: [], // 次轴
    secondaryMarkLines: [], // 次轴 辅助线
    ySecondaryAxisTitle: undefined // y次轴标题
}
