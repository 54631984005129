import { arrayMove } from '@dnd-kit/sortable';
import type { DataSourceCount } from '@lighthouse/core';
import { type AiFieldStatus, type DataSourceAbstract, type RecordLikeProtocol, DataSourceType, SelectedMode } from '@lighthouse/core';
import { type ImportParams, DEPARTMENT_DATASOURCE, getMainTableRecordId, getRealFieldId, ROLE_DATASOURCE, USER_DATASOURCE } from '@lighthouse/shared';
import { nanoid } from '@lighthouse/tools';
import isDeepEqual from 'fast-deep-equal';
import { current } from 'immer';
import { atom } from 'jotai';
import { findIndex, mergeDeepRight, omit } from 'rambda';

import * as srv from '@/services';

import { applicationVersionAtom, currentAppIdAtom, currentEnvIdAtom } from '../application/state';
import { createRecordAction, deleteRecordAction, getFieldBeforeId } from './helper';
import {
    aiFieldStatusListAtom,
    currentDataSourceIdAtom,
    dataSourceAtomFamily,
    dataSourceCountAtomFamily,
    dataSourceEnvAtom,
    // dataSourceEnvIdAtom,
    dataSourceListAtomFamily,
    dataSourcePoolAtom,
    recordAtomFamily,
    recordPoolAtom,
} from './state';
import type {
    AddDataSourceAtomPayload,
    AddDataSourcesAtomPayload,
    AddRecordAtomPayload,
    CreateAggregationDataPayload,
    CreateAsyncDataSourcePayload,
    CreateDataSourcePayload,
    CreateFieldAtomPayload,
    CreateJoinDataSourcePayload,
    CreateRecordAtomPayload,
    DeleteAiFieldStatusAtomPayload,
    DeleteDataSourceAtomPayload,
    DeleteFieldAtomPayload,
    DeleteRecordAtomPayload,
    DeleteRecordsAtomPayload,
    DeleteViewRecordAtomPayload,
    DuplicateDataSourcePayload,
    FetchDataSourceAtomPayload,
    FetchDataSourceListAtomPayload,
    GetDataSourceListMetaAtomPayload,
    GetDataSourceMetaAtomPayload,
    LoadDataSourceAtomPayload,
    MoveDataSourceAtomPayload,
    SetCurrentDataSourceIdAtomPayload,
    SetDataSourceAtomPayload,
    SetDataSourceIdsAtomPayload,
    SetRecordAtomPayload,
    SetRecordsAtomPayload,
    UpdateCellAtomPayload,
    UpdateDataSourceAtomPayload,
    UpdateDataSourceNameAtomPayload,
    UpdateFieldAtomPayload
} from './types';

/** 设置当前数据源id */
export const setCurrentDataSourceIdAtom = atom(null, (_, set, payload: SetCurrentDataSourceIdAtomPayload) => {
    const { dsId } = payload
    set(currentDataSourceIdAtom, dsId)
})

export const setDataSourceCountAtom = atom(null, (get, set, payload: DataSourceCount) => {
    const { appId, envId, dsId, count } = payload
    const dataSourceCountAtom = dataSourceCountAtomFamily({ appId, envId, dsId })
    set(dataSourceCountAtom, count)
})

export const fetchDataSourceCountAtom = atom(null, async (get, set, payload: FetchDataSourceAtomPayload) => {
    const { dsId, envId, pagination, search, filter, linkFilter, currentRecordId, parentRecordId, recordIds, abort } = payload
    const rowTotal = await srv.getDsCount({
        envId,
        dsId,
        search,
        filter,
        linkFilter,
        currentRecordId,
        parentRecordId,
        recordIds,
        abort
    })
    if (rowTotal === undefined) {
        return
    }
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (!draft) {
                return
            }
            draft.viewOptions.pagination = {
                ...pagination,
                rowTotal
            }
        }
    })
})

/** 设置数据源列表 */
export const setDataSourceListAtom = atom(null, (_, set, payload: SetDataSourceIdsAtomPayload) => {
    const { dataSourceList } = payload
    set(dataSourcePoolAtom, draft => {
        return dataSourceList.reduce<DataSourceAbstract[]>((list, ds) => {
            const dataSource = draft.find(item => item.id === ds.id && item.appId === ds.appId && item.envId === ds.envId)
            if (!dataSource) {
                list.push(ds)
                return list
            }
            list.push({
                ...dataSource,
                records: dataSource.records
            })
            return list
        }, [])
    })
})

/** 获取应用下数据源列表 */
export const fetchDataSourceListAtom = atom(null, async (_, set, payload: FetchDataSourceListAtomPayload) => {
    const { appId, envId } = payload
    const data = await srv.listDs(appId, envId)
    set(setDataSourceListAtom, { dataSourceList: data })
    return data
})

/** 创建数据源 */
export const createDataSourceAtom = atom(null, async (_, set, payload: CreateDataSourcePayload) => {
    const { envId, type } = payload
    const data = await srv.createAndInitDs({ envId, type })
    if (data) {
        await set(fetchDataSourceListAtom, payload)
    }
    return data
})

/** 复制数据源 */
export const duplicateDataSourceAtom = atom(null, async (get, set, payload: DuplicateDataSourcePayload) => {
    const { envId, dsId } = payload
    const appId = get(currentAppIdAtom)
    const data = await srv.duplicateAndInitDs({ envId, dsId })
    if (data) {
        await set(fetchDataSourceListAtom, { appId, envId })
    }
    return data.dsId
})

/** 创建同步数据源 */
export const createAsyncDataSourceAtom = atom(null, async (get, set, payload: CreateAsyncDataSourcePayload) => {
    const { envId, syncAppId, syncEnvId, syncDsId } = payload
    const appId = get(currentAppIdAtom)
    const isCreate = await srv.createSyncDs({ envId, syncAppId, syncEnvId, syncDsId })
    if (isCreate) {
        set(fetchDataSourceListAtom, { appId, envId })
    }
    return isCreate
})

/** 创建同步数据源 */
export const createJoinDataSourceAtom = atom(null, async (get, set, payload: CreateJoinDataSourcePayload) => {
    const { envId, primaryDsId, config } = payload
    const appId = get(currentAppIdAtom)
    const data = await srv.createJoinDs({ envId, primaryDsId, config })
    if (data) {
        set(fetchDataSourceListAtom, { appId, envId })
    }
    return data
})

export const createAggregationDataSourceAtom = atom(null, async (get, set, payload: CreateAggregationDataPayload) => {
    const { envId } = payload
    const appId = get(currentAppIdAtom)
    const data = await srv.createAnalyticsDs(envId)
    if (data) {
        set(fetchDataSourceListAtom, { appId, envId })
    }
    return data
})

/** 导入数据源 */
export const importDataSourceAtom = atom(null, async (get, set, payload: ImportParams) => {
    const appId = get(currentAppIdAtom)
    const { envId } = payload
    const dataSources = await srv.importDataSource(payload)
    if (!dataSources || dataSources.length === 0) {
        return
    }
    if (dataSources?.length) {
        await set(fetchDataSourceListAtom, { appId, envId })
    }
    return dataSources.map(({ id }) => id)
})

/** 删除数据源 */
export const deleteDataSourceAtom = atom(null, async (get, set, payload: DeleteDataSourceAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId } = payload
    const list = get(dataSourcePoolAtom)
    set(dataSourcePoolAtom, draft => {
        const deleteIndex = findIndex(ds => ds.appId === appId && ds.envId === envId && ds.id === dsId, list)
        if (deleteIndex >= 0) {
            draft.splice(deleteIndex, 1)
        }
    })
    const isDelete = await srv.deleteDataSource(payload)
    if (!isDelete) {
        set(dataSourcePoolAtom, list)
        return
    }

    return isDelete
})

/** 更改数据源排序 */
export const moveDataSourceAtom = atom(null, async (get, set, payload: MoveDataSourceAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, oldIndex, newIndex } = payload
    const oldList = get(dataSourceListAtomFamily({ appId, envId }))
    const list = arrayMove(oldList, oldIndex, newIndex)
    const after = list?.[newIndex - 1]?.id
    const before = list?.[newIndex + 1]?.id
    set(setDataSourceListAtom, { dataSourceList: list })
    const isMove = await srv.moveDs({ envId, id: dsId, before, after })
    if (!isMove) {
        set(setDataSourceListAtom, { dataSourceList: oldList })
    }
})

/** 设置数据源数据 */
export const setDataSourceAtom = atom(null, (get, set, payload: SetDataSourceAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, dataSource } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    set(dataSourceAtom, dataSource)
})

/** 添加单个数据源数据 */
export const addDataSourceAtom = atom(null, (_, set, payload: AddDataSourceAtomPayload) => {
    const { dataSource } = payload
    set(dataSourcePoolAtom, draft => {
        const { id, appId, envId } = dataSource
        const index = draft.findIndex(item => item.id === id && item.appId === appId && item.envId === envId)
        if (index >= 0) {
            // const { schema, ...restDataSourceData } = draft[index]
            const originDataSource = current(draft[index])
            const nextDs = mergeDeepRight<DataSourceAbstract>(originDataSource, dataSource)
            if (isDeepEqual(originDataSource, nextDs)) {
                return
            }
            draft[index] = nextDs
        } else {
            draft.push(dataSource)
        }
    })
})

/** 添加多个数据源数据 */
export const addDataSourcesAtom = atom(null, (_, set, payload: AddDataSourcesAtomPayload) => {
    const { dataSources } = payload
    set(dataSourcePoolAtom, draft => {
        dataSources.forEach(dataSource => {
            const { id, appId, envId } = dataSource
            const index = draft.findIndex(item => item.id === id && item.appId === appId && item.envId === envId)
            if (index >= 0) {
                const originDataSource = current(draft[index])
                const nextDs = mergeDeepRight<DataSourceAbstract>(originDataSource, dataSource)
                if (isDeepEqual(originDataSource, nextDs)) {
                    return
                }
                draft[index] = nextDs
            } else {
                draft.push(dataSource)
            }
        })
    })
})

/** 设置记录数据 */
export const setRecordAtom = atom(null, (get, set, payload: SetRecordAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, recordId, record } = payload
    const recordAtom = recordAtomFamily({ appId, envId, dsId, recordId })
    set(recordAtom, record)
})

/** 设置记录数据 */
export const setRecordsAtom = atom(null, (_, set, payload: SetRecordsAtomPayload) => {
    const { records } = payload
    set(recordPoolAtom, records)
})

/** 设置记录数据 */
export const deleteRecordsAtom = atom(null, (_, set, payload: DeleteRecordsAtomPayload) => {
    const { dsId } = payload
    set(recordPoolAtom, draft => {
        return draft.filter(record => record.dsId !== dsId)
    })
})

/** 添加记录数据 */
export const addRecordAtom = atom(null, (_, set, payload: AddRecordAtomPayload) => {
    const { records } = payload
    set(recordPoolAtom, draft => {
        records.forEach(record => {
            const { id, appId, dsId } = record
            const index = draft.findIndex(item => item.id === id && item.appId === appId && item.dsId === dsId)
            if (index >= 0) {
                draft[index] = record
            } else {
                draft.push(record)
            }
        })
    })
})

/** 获取数据源数据 */
export const fetchDataSourceAtom = atom(null, async (get, set, payload: FetchDataSourceAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, pagination, search, sorts, filter, linkFilter, abort, currentRecordId, parentRecordId, recordIds } = payload
    const data = await srv.getDs({
        appId,
        envId,
        dsId,
        pagination,
        search,
        filter,
        linkFilter,
        sorts,
        currentRecordId,
        parentRecordId,
        recordIds,
        abort
    })
    if (!data) {
        return
    }
    const { datasource, records } = data
    set(fetchDataSourceCountAtom, payload)
    datasource.viewOptions.pagination = {
        ...pagination
    }
    datasource.records = records.map(({ id }) => id)
    set(addDataSourceAtom, { dataSource: datasource })
    set(addRecordAtom, { records })
    return datasource
})

/** 加载数据源数据 */
export const loadDataSourceAtom = atom(null, async (get, set, payload: LoadDataSourceAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, pagination, search, sorts, filter, linkFilter, currentRecordId, parentRecordId, recordIds } = payload
    const data = await srv.getDs({
        appId,
        envId,
        dsId,
        pagination,
        search,
        filter,
        linkFilter,
        sorts,
        currentRecordId,
        parentRecordId,
        recordIds
    })
    if (!data) {
        return []
    }
    const { records } = data
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft && draft.records) {
                records.forEach(({ id }) => {
                    draft.records.push(id)
                    draft.viewOptions.pagination = {
                        ...draft.viewOptions.pagination,
                        ...pagination
                    }
                })
            }
        }
    })
    set(addRecordAtom, { records })
    return records
})

export const refreshDataSourceAtom = atom(null, async (get, set, payload: FetchDataSourceAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, pagination, search, filter, sorts, currentRecordId, parentRecordId, abort } = payload
    const data = await srv.getDs({
        appId,
        dsId,
        envId,
        pagination,
        search,
        filter,
        sorts,
        currentRecordId,
        parentRecordId,
        abort
    })
    if (!data) {
        return
    }
    const { datasource, records } = data
    datasource.viewOptions.pagination = pagination
    datasource.records = records.map(item => item.id)
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft) {
                Object.entries(datasource).forEach(([k, v]) => {
                    Reflect.set(draft, k, v)
                })
            }
        }
    })
    set(addRecordAtom, { records })
})

/** 更新数据源数据 */
export const updateDataSourceAtom = atom(null, async (get, set, payload: UpdateDataSourceAtomPayload) => {
    const { envId, dsId, params } = payload
    const appId = get(currentAppIdAtom)
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const oldDataSource = get(dataSourceAtom)
    if (!oldDataSource) {
        return false
    }
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft) {
                Object.entries(params).forEach(([k, v]) => {
                    Reflect.set(draft, k, v)
                })
            }
        }
    })
    const { success } = await srv.updateDataSource({ id: dsId, envId, ...params })
    if (!success) {
        set(setDataSourceAtom, {
            envId,
            dsId,
            dataSource: oldDataSource
        })
    }
    return !!success
})

/** 更新数据源数据 */
export const updateDataSourceNameAtom = atom(null, async (get, set, payload: UpdateDataSourceNameAtomPayload) => {
    const { envId, dsId, name } = payload
    const appId = get(currentAppIdAtom)
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const oldDataSource = get(dataSourceAtom)
    if (!oldDataSource) {
        return false
    }
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft) {
                draft.name = name
            }
        }
    })
    const { success, name: dsName } = await srv.updateDataSource({ id: dsId, envId, name })
    if (success && dsName !== name) {
        set(setDataSourceAtom, {
            envId,
            dsId,
            dataSource: draft => {
                if (draft) {
                    draft.name = dsName
                }
            }
        })
    }
    if (!success) {
        set(setDataSourceAtom, {
            envId,
            dsId,
            dataSource: oldDataSource
        })
    }
    return !!success
})

/** 创建字段 */
export const createFieldAtom = atom(null, async (get, set, payload: CreateFieldAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, config, sourceId, direction } = payload
    const { name, type, dsId: fieldDsId, dsName, innerType: fieldInnerType, ...rest } = config
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const dataSource = get(dataSourceAtom)
    if (!dataSource) {
        return
    }
    const { viewOptions } = dataSource
    const { tableProps } = viewOptions
    const beforeId = getFieldBeforeId(tableProps, sourceId, direction)

    const { innerType, fieldId } = await srv.createField({
        envId,
        dsId,
        type,
        name,
        config: rest,
        // id: newFieldId,
        beforeId
    })
    if (!fieldId) {
        return
    }
    const tableProp = { id: fieldId, visible: true, width: 160 }
    let newTableProps = [...tableProps, tableProp]
    if (sourceId && direction) {
        const index = findIndex(item => item.id === sourceId, tableProps)
        newTableProps = [...tableProps]
        newTableProps.splice(direction === 'left' ? index : index + 1, 0, tableProp)
    }

    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (!draft) {
                return
            }
            draft.schema = { ...draft.schema, [fieldId]: { ...config, id: fieldId, innerType } }
            draft.viewOptions.tableProps = newTableProps
        }
    })
    return innerType
})

/** 更新字段 */
export const updateFieldAtom = atom(null, async (get, set, payload: UpdateFieldAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, fieldId, config } = payload
    const { id, name, type, dsId: fieldDsId, dsName, innerType: fieldInnerType, ...rest } = config
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const oldDataSource = get(dataSourceAtom)
    if (!oldDataSource) {
        return false
    }
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft) {
                draft.schema[fieldId] = config
            }
        }
    })

    const isUpdate = await srv.updateField({ envId, dsId, id: fieldId, config: rest, type, name })
    if (!isUpdate) {
        set(setDataSourceAtom, {
            envId,
            dsId,
            dataSource: oldDataSource
        })
        return false
    }

    return isUpdate
})

/** 删除字段 */
export const deleteFieldAtom = atom(null, async (get, set, payload: DeleteFieldAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { envId, dsId, fieldId } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const oldDataSource = get(dataSourceAtom)
    if (!oldDataSource) {
        return false
    }
    set(setDataSourceAtom, {
        envId,
        dsId,
        dataSource: draft => {
            if (draft) {
                draft.schema = omit(fieldId, draft.schema)
                draft.viewOptions.tableProps = draft.viewOptions.tableProps.filter(item => item.id !== fieldId)
                if (draft.type === DataSourceType.joinDataSource && draft.viewOptions.joinConfig?.conditions) {
                    const realFieldId = getRealFieldId(fieldId)
                    draft.viewOptions.joinConfig.conditions.forEach(condition => {
                        const joinFieldIndex = findIndex(f => f.fieldId === realFieldId, condition.joinFields || [])
                        if (joinFieldIndex >= 0) {
                            condition.joinFields?.splice(joinFieldIndex, 1)
                        }
                    })
                }
            }
        }
    })

    const isDelete = await srv.deleteField({ envId, dsId, id: fieldId })
    if (!isDelete) {
        set(setDataSourceAtom, {
            envId,
            dsId,
            dataSource: oldDataSource
        })
        return false
    }

    return isDelete
})

/** 获取数据源配置数据 */
export const getDataSourceMetaAtom = atom(null, async (_, set, payload: GetDataSourceMetaAtomPayload) => {
    const dataSource = await srv.getDsMeta(payload)
    set(addDataSourceAtom, { dataSource })
    return dataSource
})

/** 获取多个数据源配置数据 */
export const getDataSourceListMetaAtom = atom(null, async (_, set, payload: GetDataSourceListMetaAtomPayload) => {
    const dataSources = await srv.getDsListMeta(payload)
    set(addDataSourcesAtom, { dataSources })
})

/** 创建记录数据 */
export const createRecordAtom = atom(null, async (get, set, payload: CreateRecordAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const { recordId, envId, dsId, content, beforeId } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const newRecordId = recordId || nanoid(32)
    const dataSource = get(dataSourceAtom)
    if (!dataSource) {
        return
    }
    const { schema } = dataSource
    createRecordAction({ id: newRecordId, envId, dsId, schema, beforeId }, get, set)
    const record = await srv.createRecord({ id: newRecordId, appId, envId, dsId, content, beforeId })
    if (!record) {
        deleteRecordAction({ recordIds: [newRecordId], envId, dsId, mode: SelectedMode.CURRENT_PAGE }, get, set)
        return
    }
    if (record) {
        set(setRecordAtom, {
            envId,
            dsId,
            recordId: newRecordId,
            record
        })
    }
    return newRecordId
})

export const updateLocalRecordAtom = atom(null, (get, set, payload: RecordLikeProtocol) => {
    const record = findIndex(item => item.id === payload.id, get(recordPoolAtom))
    set(recordPoolAtom, pool => {
        if (record >= 0) {
            pool[record] = payload
        } else {
            pool.push(payload)
        }
    })
})

/** 更新一条记录的多个字段 */
export const updateRecordAtom = atom(null, async (get, set, payload: Omit<RecordLikeProtocol, 'appId'>) => {
    // 解决连接表问题
    const appId = get(currentAppIdAtom)
    const { envId, dsId, id, content } = payload
    const recordId = getMainTableRecordId(id)
    const backendFields = Object.keys(content).map(f => ({ fieldId: f, value: content[f]?.value }))
    const res = await srv.updateRecord({ appId, envId, dsId, recordId, fields: backendFields })
    if (res) {
        set(recordPoolAtom, draft => {
            // const isEqualRecordId = recordIds.some(id => getMainTableRecordId(id) === getMainTableRecordId(draft[i].id))
            const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                if (cur.appId === appId && cur.envId === envId && cur.dsId === dsId && getMainTableRecordId(cur.id) === recordId) {
                    prev.push(index)
                }
                return prev
            }, [])
            if (recordsIndex.length === 0) {
                return
            }
            recordsIndex.forEach(i => {
                Object.keys(content).forEach(k => {
                    draft[i].content[k] = content[k]
                })
            })
        })
    }
    return res
})

/** 删除记录数据 */
export const deleteRecordAtom = atom(null, async (get, set, payload: DeleteRecordAtomPayload) => {
    const { envId, dsId, recordIds, mode = SelectedMode.CURRENT_PAGE, search } = payload
    const isDelete = await srv.deleteRecord(payload)
    deleteRecordAction({ recordIds, envId, dsId, mode }, get, set)
    return isDelete
})

export const deleteViewRecordAtom = atom(null, async (get, set, payload: DeleteViewRecordAtomPayload) => {
    const { envId, dsId, recordIds, mode } = payload
    let isDeleted = false
    if (mode === 'CURRENT_PAGE' && recordIds) {
        isDeleted = await srv.deleteRecord({ envId, dsId, recordIds, mode })
    } else {
        isDeleted = await srv.deleteViewRecord(payload)
    }
    // deleteRecordAction(recordIds, appId, dsId, set)

    return isDeleted
})

/** 更新单元格 */
export const updateCellAtom = atom(null, async (_, set, payload: UpdateCellAtomPayload, options?: { local?: boolean }) => {
    const { recordId, envId, dsId, fieldId, value } = payload
    let oldRecord: RecordLikeProtocol | undefined
    set(setRecordAtom, {
        dsId,
        envId,
        recordId,
        record: draft => {
            if (draft) {
                oldRecord = current(draft)
                draft.content[fieldId] = value
            }
        }
    })
    if (options?.local) {
        return true
    }
    const content = {
        value: value.value
    }
    const isUpdate = await srv.updateCell({
        envId,
        dsId,
        id: recordId,
        fieldId,
        content
    })

    if (!isUpdate && oldRecord) {
        set(setRecordAtom, {
            dsId,
            envId,
            recordId,
            record: oldRecord
        })
    }
    return isUpdate
})

// /** 设置ai字段状态 */
// export const setAiFieldStatusListAtom = atom(null, (_, set, payload: SetAiFieldStatusListAtomPayload) => {
//     const { aiFieldStatusList } = payload
//     set(aiFieldStatusListAtom, aiFieldStatusList)
// })

/** 设置ai字段状态 */
export const addAiFieldStatusAtom = atom(null, (_, set, payload: AiFieldStatus) => {
    const { dataSourceId, recordId, fieldId } = payload
    set(aiFieldStatusListAtom, draft => {
        const index = findIndex(item => item.dataSourceId === dataSourceId && item.recordId === recordId && item.fieldId === fieldId, draft)
        if (index >= 0) {
            draft[index] = payload
            return
        }
        draft.push(payload)
    })
})

export const deleteAiFieldStatusAtom = atom(null, (_, set, payload: DeleteAiFieldStatusAtomPayload) => {
    const { dsId, recordId, fieldId } = payload
    set(aiFieldStatusListAtom, draft => {
        const index = findIndex(item => item.dataSourceId === dsId && item.recordId === recordId && item.fieldId === fieldId, draft)
        if (index >= 0) {
            draft.splice(index, 1)
        }
    })
})

/** 设置数据源环境id */
export const setDataSourceEnvIdAtom = atom(null, (_, set, id: string) => {
    // set(dataSourceEnvIdAtom, id)
    set(applicationVersionAtom, { envId: id })
})

/** 获取环境数据 */
export const fetchEnvAtom = atom(null, async (get, set) => {
    // const envId = get(dataSourceEnvIdAtom)
    const envId = get(currentEnvIdAtom)
    const data = await srv.getEnv(envId)
    set(dataSourceEnvAtom, data)
    return data
})

export const fetchDataSourceRecordsAtom = atom(null, async (get, set, dsId: string) => {
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    const pagination = {
        currentPage: 1,
        pageSize: 20000
    }
    const data = await srv.getDs({
        appId,
        envId,
        dsId,
        pagination
    })
    if (!data) {
        return
    }
    const { records, datasource } = data
    datasource.records = records.map(({ id }) => id)
    datasource.viewOptions = {
        ...datasource.viewOptions,
        pagination: {
            ...pagination,
            rowTotal: records.length
        }
    }
    set(addDataSourceAtom, { dataSource: datasource })
    set(deleteRecordsAtom, { dsId })
    set(addRecordAtom, { records })
    return records
})

/** 获取数据源下用户列表 */
export const fetchDataSourceUserAtom = atom(null, (get, set) => {
    return set(fetchDataSourceRecordsAtom, USER_DATASOURCE)
})

/** 获取数据源下用户组列表 */
export const fetchDataSourceRoleAtom = atom(null, (get, set) => {
    return set(fetchDataSourceRecordsAtom, ROLE_DATASOURCE)
})

/** 获取数据源下部门列表 */
export const fetchDataSourceDepartmentAtom = atom(null, (get, set) => {
    return set(fetchDataSourceRecordsAtom, DEPARTMENT_DATASOURCE)
})



export const fetchSystemDataSourceAtom = atom(null, (get, set) => {
    set(fetchDataSourceRecordsAtom, USER_DATASOURCE)
    set(fetchDataSourceRecordsAtom, DEPARTMENT_DATASOURCE)
    set(fetchDataSourceRecordsAtom, ROLE_DATASOURCE)
})
