import { Button, closeModal, DesktopModal, IconFont, openModal, Select } from '@byecode/ui'
import { useAtomAsyncAction, WorkflowRunType } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import React, { useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ReactFlowProvider } from 'reactflow'
import styled from 'styled-components'

import { fetchWorkflowListAtom } from '@/atoms/workflow/action'
import { workflowListAtom } from '@/atoms/workflow/state'
import { workflowLabelMap } from '@/constants/flow'
import { FlowDetail } from '@/containers/FlowDetail'
import { addNewTriggerNodeWithEdges } from '@/containers/FlowDetail/utils/addNode'
import * as srv from '@/services'

import { FlowEditor } from '../../FlowEditor'

interface ApprovalFlowSelectorProps {
    value: string
    onChange?: (value: string) => void
}

const SCxApprovalFlowSelectorWrapper = styled.div``

const SCxApprovalFlowWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: 14px;
    border-radius: 6px;
    overflow: hidden;
    background-color: var(--color-gray-100);
`

const SCxApprovalFlowContent = styled.div`
    position: relative;
    margin-left: 50px;
    height: 245px;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

const SCxApprovalFlowEdit = styled(Button)`
    position: absolute;
    top: 6px;
    right: 6px;
`

const SCxApprovalCreatorButton = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 16px;
    font-size: var(--font-size-normal);
    color: var(--color-main);
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const ApprovalFlowSelector: React.FC<ApprovalFlowSelectorProps> = ({ value, onChange }) => {
    const { control, setValue } = useFormContext()
    const approveFlowId = useWatch({
        control,
        name: 'config.approveFlowId'
    })
    const workflows = useAtomValue(workflowListAtom)
    const { run: updateWorkflow } = useAtomAsyncAction(fetchWorkflowListAtom)

    const flowNodesOptions = useMemo(
        () =>
            workflows
                // 审批流且已启用才可以选择
                .filter(workflow => workflow.type === 'approval' /* && workflow.enabled */)
                .map(item => ({
                    label: item.name,
                    value: item.id,
                    icon: 'Approval'
                })),
        [workflows]
    )

    const flow = useMemo(() => {
        return workflows.find(item => item.id === value)
    }, [value, workflows])

    const handleFlowCreate = useCallback(async () => {
        const flowInitInfo = addNewTriggerNodeWithEdges('INITIATE_APPROVE_TRIGGER')

        const { label } = workflowLabelMap['approval']

        const workflowId = await srv.createFlow({
            name: `未命名${label}`,
            type: 'approval',
            runType: WorkflowRunType.SEQUENTIAL,
            description: `未命名${label}的描述`,
            ...flowInitInfo
        })

        if (workflowId) {
            setValue('config.approveFlowId', workflowId)
            updateWorkflow()
        }
    }, [updateWorkflow, setValue])

    const handleOpenFlowModal = useCallback(
        (id?: string, onClose?: () => void) => {
            const flowId = id ?? value
            if (!flowId) {
                return
            }
            const modalId = nanoid()
            openModal({
                id: modalId,
                component: DesktopModal,
                width: 1200,
                styles: {
                    header: { display: 'none' },
                    modal: {
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        height: '80%'
                    },
                    body: {
                        flex: 1,
                        position: 'relative',
                        overflow: 'hidden'
                    }
                },
                withCloseIcon: false,
                children: (
                    <FlowDetail
                        id={flowId}
                        showBack={false}
                        showLogTab={false}
                        showClose
                        onClose={() => {
                            closeModal(modalId)
                            onClose?.()
                        }}
                    />
                )
            })
        },
        [value]
    )

    return (
        <SCxApprovalFlowSelectorWrapper>
            <Select
                searchable
                placeholder="请选择审批对象"
                value={value}
                options={flowNodesOptions}
                onChange={onChange}
                dropdownProps={{
                    extra: (
                        <SCxApprovalCreatorButton onClick={handleFlowCreate}>
                            <IconFont type="Add" style={{ marginRight: 6, color: 'var(--color-gray-500)' }} />
                            创建审批流
                        </SCxApprovalCreatorButton>
                    )
                }}
            />
            {approveFlowId && (
                <SCxApprovalFlowWrapper>
                    <SCxApprovalFlowContent>
                        {flow && flow?.content && (
                            <ReactFlowProvider>
                                <FlowEditor silent type={flow.type} nodes={flow.content.nodes} edges={flow.content.edges} />
                            </ReactFlowProvider>
                        )}
                    </SCxApprovalFlowContent>
                    <SCxApprovalFlowEdit icon={<IconFont type="PencilSimple" />} onClick={() => handleOpenFlowModal(value)}>
                        编辑
                    </SCxApprovalFlowEdit>
                </SCxApprovalFlowWrapper>
            )}
        </SCxApprovalFlowSelectorWrapper>
    )
}
