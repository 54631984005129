import React, { useMemo, useState } from 'react'

import * as SC from './styles'

interface SettingPopoverContentProps {
    name: string
    description: string
    descriptions: { id: string; label: string; value: string }[]
    onDelete: () => void
    onDuplicate: () => void
    onNameChange: (name: string) => void
    onDescriptionChange: (name: string) => void
}

export const SettingPopoverContent: React.FC<SettingPopoverContentProps> = ({
    name,
    description,
    descriptions,
    onDelete,
    onDuplicate,
    onNameChange,
    onDescriptionChange
}) => {
    const [contentStage, setContentStage] = useState('common')

    const handleChangeToDescriptionEditor = () => {
        setContentStage('description')
    }

    const settingContent = useMemo(() => {
        if (contentStage === 'common') {
            return (
                <>
                    <SC.TitleInput
                        size="sm"
                        style={{ marginLeft: 8 }}
                        defaultValue={name}
                        onBlur={ev => onNameChange?.(ev.target.value)}
                        onKeyDownCapture={ev => {
                            if (ev.key === 'Enter') {
                                onNameChange?.(ev.currentTarget.value)
                            }
                        }}
                    />
                    <SC.ListItem onClick={handleChangeToDescriptionEditor}>
                        <SC.ListItemIcon type="NotePencil" />
                        <SC.ListItemLabel>编辑描述</SC.ListItemLabel>
                    </SC.ListItem>
                    <SC.ListItem onClick={onDelete}>
                        <SC.ListItemIcon type="Trash" />
                        <SC.ListItemLabel>删除</SC.ListItemLabel>
                    </SC.ListItem>
                    <SC.ListItem onClick={onDuplicate}>
                        <SC.ListItemIcon type="Duplicate" />
                        <SC.ListItemLabel>复制</SC.ListItemLabel>
                    </SC.ListItem>
                    <SC.ListDivider />
                    {descriptions.map(({ id, label, value }) => (
                        <SC.ListDescriptionWrapper key={id}>
                            <SC.ListDescriptionItemLabel>{label}</SC.ListDescriptionItemLabel>
                            <SC.ListDescriptionItemValue>{value}</SC.ListDescriptionItemValue>
                        </SC.ListDescriptionWrapper>
                    ))}
                </>
            )
        }
        if (contentStage === 'description') {
            return (
                <SC.DescriptionInput
                    style={{ marginLeft: 8 }}
                    defaultValue={description}
                    onChange={ev => onDescriptionChange(ev.target.value)}
                />
            )
        }
    }, [contentStage, description, descriptions, name, onDelete, onDescriptionChange, onDuplicate, onNameChange])

    return <SC.SettingPopoverWrapper>{settingContent}</SC.SettingPopoverWrapper>
}
