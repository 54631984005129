import { Flex, Input } from '@byecode/ui'
import type { EdgeValue } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React, { useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { transform2Integer, transform2Number } from '@/utils/number'

import { SizeIcon } from '../SizeSetting/SizeIcon'

interface RadiusSizeProps {
    title: React.ReactNode
    value?: EdgeValue
    onChange: (val: EdgeValue) => void
}

export const RadiusSize: React.FC<RadiusSizeProps> = ({ title, value, onChange }) => {
    const [moveOpen, setMoveOpen] = useImmer<[boolean, boolean, boolean, boolean]>([false, false, false, false])
    const initValue: EdgeValue = useMemo(() => value || [0, 0, 0, 0], [value])
    const [radiusValue, setRadiusValue] = useImmer<EdgeValue>(initValue)

    const [left, top, right, bottom] = radiusValue

    useUpdateEffect(() => {
        setRadiusValue(initValue)
    }, [initValue])

    const handlePaddingChange = useCallback(
        (value: string, index: number) => {
            const num = value ? transform2Number(value) : undefined
            setRadiusValue(draft => {
                draft[index] = num
            })
        },
        [setRadiusValue]
    )

    const handleMoveChange = useCallback(
        (val: number, index: number) => {
            const v: EdgeValue = [...radiusValue]
            const num = transform2Integer(val)
            v[index] = num
            setRadiusValue(v)
            if (isDeepEqual(v, initValue)) {
                return
            }
            onChange(v)
        },
        [initValue, onChange, radiusValue, setRadiusValue]
    )

    const handleMoveOpen = useCallback(
        (val: boolean, index: number) => {
            setMoveOpen(draft => {
                draft[index] = val
            })
        },
        [setMoveOpen]
    )

    const handleSubmit = useCallback(
        (val: string, index: number) => {
            const num = transform2Number(Number(val).toFixed(2))
            const v: EdgeValue = [...radiusValue]
            v[index] = num
            setRadiusValue(v)
            if (isDeepEqual(v, initValue)) {
                return
            }
            onChange(v)
        },
        [initValue, onChange, radiusValue, setRadiusValue]
    )

    const resizeStyle = useMemo(
        () =>
            moveOpen.map(item => {
                if (!item) {
                    return {}
                }
                return {
                    borderColor: 'var(--color-main)',
                    backgroundColor: 'var(--color-gray-200)'
                }
            }),
        [moveOpen]
    )

    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {title}
                {/* <InheritLabel label="圆角" name="breakPoint.design.radius" /> */}
                <Flex styles={{ root: { width: 180 } }} gap="8px">
                    <Input
                        value={left}
                        placeholder=""
                        styles={{
                            wrapper: resizeStyle[0]
                        }}
                        type='number'
                        prefix={
                            <SizeIcon
                                type="RadiusLeftTop"
                                min={0}
                                value={left}
                                onChangeMove={val => handleMoveOpen(val, 0)}
                                onChange={val => handleMoveChange(val, 0)}
                            />
                        }
                        onFocus={e => {
                            e.target.select()
                        }}
                        onChange={e => {
                            handlePaddingChange(e.target.value, 0)
                        }}
                        onKeyDownCapture={ev => {
                            if (ev.key === 'Enter') {
                                ev.currentTarget.blur()
                            }
                        }}
                        onBlur={ev => {
                            const v = ev.target.value
                            handleSubmit(v, 0)
                        }}
                    />
                    <Input
                        value={top}
                        placeholder=""
                        styles={{
                            wrapper: resizeStyle[1]
                        }}
                        type='number'
                        prefix={
                            <SizeIcon
                                type="RadiusRightTop"
                                min={0}
                                value={left}
                                onChangeMove={val => handleMoveOpen(val, 1)}
                                onChange={val => handleMoveChange(val, 1)}
                            />
                        }
                        onFocus={e => {
                            e.target.select()
                        }}
                        onChange={e => {
                            handlePaddingChange(e.target.value, 1)
                        }}
                        onKeyDownCapture={ev => {
                            if (ev.key === 'Enter') {
                                ev.currentTarget.blur()
                            }
                        }}
                        onBlur={ev => {
                            const v = ev.target.value
                            handleSubmit(v, 1)
                        }}
                    />
                </Flex>
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="flex-end">
                <Flex styles={{ root: { width: 180 } }} gap="8px">
                    <Input
                        value={right}
                        onChange={e => {
                            handlePaddingChange(e.target.value, 2)
                        }}
                        type='number'
                        placeholder=""
                        styles={{
                            wrapper: resizeStyle[2]
                        }}
                        prefix={
                            <SizeIcon
                                type="RadiusLeftBottom"
                                min={0}
                                value={left}
                                onChangeMove={val => handleMoveOpen(val, 2)}
                                onChange={val => handleMoveChange(val, 2)}
                            />
                        }
                        onFocus={e => {
                            e.target.select()
                        }}
                        onKeyDownCapture={ev => {
                            if (ev.key === 'Enter') {
                                ev.currentTarget.blur()
                            }
                        }}
                        onBlur={ev => {
                            const v = ev.target.value
                            handleSubmit(v, 2)
                        }}
                    />
                    <Input
                        value={bottom}
                        onChange={e => {
                            handlePaddingChange(e.target.value, 3)
                        }}
                        type='number'
                        placeholder=""
                        styles={{
                            wrapper: resizeStyle[3]
                        }}
                        prefix={
                            <SizeIcon
                                type="RadiusRightBottom"
                                min={0}
                                value={left}
                                onChangeMove={val => handleMoveOpen(val, 3)}
                                onChange={val => handleMoveChange(val, 3)}
                            />
                        }
                        onFocus={e => {
                            e.target.select()
                        }}
                        onKeyDownCapture={ev => {
                            if (ev.key === 'Enter') {
                                ev.currentTarget.blur()
                            }
                        }}
                        onBlur={ev => {
                            const v = ev.target.value
                            handleSubmit(v, 3)
                        }}
                    />
                </Flex>
            </ListItem4ByecodeUi>
        </>
    )
}
