import type { Workflow } from '@lighthouse/shared'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'

import { applyDraftPayload } from '../utils/applyDraftPayload'

export const workflowListAtom = atomWithImmer<Workflow[]>([])

export const actionFlowListAtom = atom(get => {
    const list = get(workflowListAtom)
    return list.filter(item => item.type === 'action')
})

export const automationFlowListAtom = atom(get => {
    const list = get(workflowListAtom)
    return list.filter(item => item.type === 'automation')
})


export const workflowAtomFamily = atomFamily((id: string) => {
    return atom(get => {
        const list = get(workflowListAtom)
        return find(item => item.id === id, list)
    }, (_, set, payload: Partial<Workflow> | ((draft: Draft<Workflow | undefined>) => void)) =>
        set(workflowListAtom, draft => {
            const workflow = draft.find(item => item.id === id)
            applyDraftPayload(workflow, payload)
        }))
})
