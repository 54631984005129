import { Select } from '@byecode/ui'
import { type DataSourceAbstract,DataSourceType } from '@lighthouse/core'
import type { CreateRecordTriggerConfig, NodeTypes } from '@lighthouse/shared'
import {
    CollapseBox,
    getIsIncludesDisableDataSourceInWorkFlow,
    getTableIcon,
    nodeTypeOptions,
    SelectDataSource,
    useAtomAction
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useSwr from 'swr'

import { getDataSourceMetaAtom } from '@/atoms/dataSource/action'
import { promptSwitchDataSource } from '@/containers/FlowDetail/utils/promptSwitchDataSource'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import * as srv from '@/services'

import * as SC from '../styles'

interface CreateRecordTriggerProps {
    value?: CreateRecordTriggerConfig
    onChange?: (value: CreateRecordTriggerConfig) => void
}

export const CreateRecordTriggerConfigure: React.FC<CreateRecordTriggerProps> = ({ value, onChange }) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { data: listDs = [] } = useSwr(`/fetch/listDs/${appId}`, () => srv.listDs(appId, envId), {
        revalidateOnFocus: false
    })
    const { run: getDataSourceMeta } = useAtomAction(getDataSourceMetaAtom)
    const { control } = useFormContext()
    const options = useMemo(
        () =>
            listDs.reduce<DataSourceAbstract[]>((listDs, item) => {
                const tableIcon = getTableIcon(item)
                if (!getIsIncludesDisableDataSourceInWorkFlow(item) && !item.sync && item.type !== DataSourceType.joinDataSource) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [listDs]
    )

    return (
        <SC.Container>
            <CollapseBox label="触发器配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="nodeType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        disabled
                                        value={field.value}
                                        options={nodeTypeOptions}
                                        onChange={val => field.onChange?.(val as NodeTypes)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>数据表</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.dataSourceId"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => {
                                    return (
                                        <SelectDataSource
                                            // disabled={!!field.value}
                                            value={field.value}
                                            searchable
                                            withinPortal
                                            placeholder="请选择数据表"
                                            onChange={async dsId => {
                                                const isConfirmed = field.value === undefined ? true : await promptSwitchDataSource()
                                                if (isConfirmed) {
                                                    field.onChange(dsId)
                                                    getDataSourceMeta({ envId, dsId })
                                                }
                                            }}
                                            dataSourceList={options}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
