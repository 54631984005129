import { Anchor, Divider, Flex, Group, Select, Text } from '@byecode/ui'
import {
    type FlowNode,
    CURRENCY_OPTIONS,
    getFieldOptions,
    getPrimaryDsSystemFieldId,
    getUpstreamRealDsId,
    pureTextFieldTypes
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FieldSelect } from '@/components/FieldSelect'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'

import { SettingRecordContent } from '../../../SettingRecordContent'
import { PaySuccessAfter } from '../component/PaySuccessAfter'
import * as SC from './styles'

interface PayProps {
    prefixName: string
    allParentNodes?: FlowNode[]
}

const EmptyArray: FlowNode[] = []

const Pay: React.FunctionComponent<PayProps> = props => {
    const { allParentNodes = EmptyArray, prefixName } = props
    const { control, watch } = useFormContext()

    const { type } = useFlow()
    const isActionFlow = Boolean(type)
    const { dsId } = useActionAdderDepParams()
    const nodeId = watch(`${prefixName}.payConfig.nodeId`)

    const dataSourceId = useMemo(() => {
        return isActionFlow ? getUpstreamRealDsId(nodeId, allParentNodes) : dsId
    }, [allParentNodes, dsId, isActionFlow, nodeId])

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dataSourceId ?? '')
    const dataSourceList = useDataSourceList(appId, envId)

    const lookTextFieldOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getFieldOptions(
            dataSource,
            field => pureTextFieldTypes.has(field.type) || (field.type === 'formula' && field.innerType === 'TEXT')
        )
    }, [dataSource])

    const fileFieldOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getFieldOptions(dataSource, field => field.type === 'file')
    }, [dataSource])
    const lookNumberFieldOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getFieldOptions(dataSource, field => field.type === 'number' || (field.type === 'formula' && field.innerType === 'NUMBER'))
    }, [dataSource])

    if (!dataSource) {
        return null
    }

    return (
        <>
            <Group label="支付订单信息">
                <SC.FieldList>
                    <SC.FieldItem>
                        <PopoverQuestion
                            type="orderId"
                            renderTarget={node => (
                                <Flex gap={16}>
                                    <SC.FieldTitle required>订单号</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />

                        <Select
                            value={getPrimaryDsSystemFieldId('ID', { dataSource, dataSourceList })}
                            options={lookTextFieldOptions}
                            styles={{
                                root: {
                                    width: '100%'
                                }
                            }}
                            disabled
                        />
                    </SC.FieldItem>

                    <SC.FieldItem>
                        <PopoverQuestion
                            type="currency"
                            renderTarget={node => (
                                <Flex gap={16}>
                                    <SC.FieldTitle required>货币类型</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />

                        <Controller
                            control={control}
                            name={`${prefixName}.payConfig.currency`}
                            render={({ field }) => (
                                <FieldSelect
                                    options={CURRENCY_OPTIONS}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>

                    <SC.FieldItem>
                        <Flex gap={16}>
                            <SC.FieldTitle required>订单金额</SC.FieldTitle>
                        </Flex>

                        <Controller
                            control={control}
                            name={`${prefixName}.payConfig.amountFieldId`}
                            render={({ field }) => (
                                <FieldSelect
                                    options={lookNumberFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从支付订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                        <Text size={12} lineHeight="18px" color="var(--color-gray-500)" style={{ marginTop: 4 }}>
                            相关货币的最大最小金额限制{' '}
                            <Anchor
                                target="_blank"
                                to="https://docs.stripe.com/currencies#minimum-and-maximum-charge-amounts"
                                style={{ color: 'var(--color-main)' }}
                            >
                                详见{'>'}
                            </Anchor>
                        </Text>
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <PopoverQuestion
                            type="shopName"
                            renderTarget={node => (
                                <Flex gap={16}>
                                    <SC.FieldTitle required>商品名称</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />

                        <Controller
                            control={control}
                            name={`${prefixName}.payConfig.productNameFieldId`}
                            render={({ field }) => (
                                <FieldSelect
                                    options={lookTextFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从支付订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <PopoverQuestion
                            type="shopDescribe"
                            renderTarget={node => (
                                <Flex gap={4}>
                                    <SC.FieldTitle>商品描述</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />
                        <Controller
                            control={control}
                            name={`${prefixName}.payConfig.descFieldId`}
                            render={({ field }) => (
                                <FieldSelect
                                    options={lookTextFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从支付订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <PopoverQuestion
                            type="shopImg"
                            renderTarget={node => (
                                <Flex gap={16}>
                                    <SC.FieldTitle>商品图</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />

                        <Controller
                            control={control}
                            name={`${prefixName}.payConfig.productPictureFieldId`}
                            render={({ field }) => (
                                <FieldSelect
                                    options={fileFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从支付订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                </SC.FieldList>
            </Group>
            <Divider />
            <Group label="支付成功后更新订单">
                <SettingRecordContent
                    prefixName={`${prefixName}.payConfig`}
                    fieldsName="succeedFieldSettings"
                    dataSourceId={dataSource.id}
                    disablePageLink
                    disableFormOption
                    disableViewOption
                    disablePageOption
                />
            </Group>
            <Divider />
            <PaySuccessAfter prefixName={prefixName} dataSource={dataSource} isActionFlow={isActionFlow} />
        </>
    )
}

export default Pay
