import type { CardBlockAbstract } from '@lighthouse/core'
import { ARRANGEMENT, BLOCK_SIZE, CARD_STYLE, DIRECTION } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'

/**
 * 生成图文导航block的配置
 *
 * @returns {CardBlockAbstract['config']}
 */
export const generateCardBlock = (): CardBlockAbstract['config'] => {
    return {
        direction: DIRECTION.vertical,
        layout: ARRANGEMENT.auto,
        list: [
            {
                id: nanoid(),
                action: {
                    trigger: 'click',
                    type: 'none',
                    data: {
                        none: {}
                    }
                },
                heading: {
                    content: [
                        {
                            content: [
                                {
                                    text: '随心搭建',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                },
                iconColor: '#D4380D',
                iconDecoration: {
                    type: 'icon',
                    value: 'WindowFrameIconLibrary'
                },
                introduction: {
                    content: [
                        {
                            content: [
                                {
                                    text: '建设你的技术团队之前，先自己实现你的产品',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                }
            },
            {
                id: nanoid(),
                action: {
                    trigger: 'click',
                    type: 'none',
                    data: {
                        none: {}
                    }
                },
                heading: {
                    content: [
                        {
                            content: [
                                {
                                    text: '轻松发布',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                },
                iconColor: '#389E0D',
                iconDecoration: {
                    type: 'icon',
                    value: 'NotebookMinimalisticIconLibrary'
                },
                introduction: {
                    content: [
                        {
                            content: [
                                {
                                    text: '几个小时就可以完成从设计到上线，告别漫长的开发周期',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                }
            },
            {
                id: nanoid(),
                action: {
                    trigger: 'click',
                    type: 'none',
                    data: {
                        none: {}
                    }
                },
                heading: {
                    content: [
                        {
                            content: [
                                {
                                    text: '持续迭代',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                },
                iconColor: '#096DD9',
                iconDecoration: {
                    type: 'icon',
                    value: 'LayersIconLibrary'
                },
                introduction: {
                    content: [
                        {
                            content: [
                                {
                                    text: '学习简单，团队里的人都轻松可以上手实现',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                }
            },
            {
                id: nanoid(),
                action: {
                    trigger: 'click',
                    type: 'none',
                    data: {
                        none: {}
                    }
                },
                heading: {
                    content: [
                        {
                            content: [
                                {
                                    text: '不断升级',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                },
                iconColor: '#531DAB',
                iconDecoration: {
                    type: 'icon',
                    value: 'Rocket2IconLibrary'
                },
                introduction: {
                    content: [
                        {
                            content: [
                                {
                                    text: '再也不用担心服务器、流量带宽等麻烦的基础设施管理',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                }
            }
        ],
        size: BLOCK_SIZE.sm,
        style: CARD_STYLE.default,
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'visible'
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
