import { Button, IconFont, Input } from '@byecode/ui'
import type { EdgeValue } from '@lighthouse/core'
import isDeepEqual from 'fast-deep-equal';
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { useImmer } from 'use-immer'

import { transform2Integer, transform2Number } from '@/utils/number'

import { SizeIcon } from '../SizeSetting/SizeIcon'
import { useSetPaddingHighlight } from './useSetPaddingHighlight'

interface Props {
    // prefixPath: string
    value?: EdgeValue
    onChange: (val: EdgeValue) => void

    disableHighlight?: boolean
}

export const PaddingInput = ({ value, onChange, disableHighlight }: Props) => {
    // const padding = useWatch({ control, name })
    const initValue: EdgeValue = useMemo(() => value || [0, 0, 0, 0], [value])
    const [paddingValue, setPaddingValue] = useImmer<EdgeValue>(initValue)
    const [moveOpen, setMoveOpen] = useImmer<[boolean, boolean, boolean, boolean]>([false, false, false, false])

    const [left, top, right, bottom] = paddingValue

    const [open, setOpen] = useState(!!(top !== bottom || right !== left))

    useUpdateEffect(() => {
        setPaddingValue(initValue)
    }, [initValue])

    const handlePaddingChange = useCallback(
        (index: number, value: string) => {
            const num = value ? transform2Number(value) : undefined
            setPaddingValue(draft => {
                draft[index] = num
                if (!open) {
                    draft[index + 2] = num
                }
            })
        },
        [open, setPaddingValue]
    )

    const handleMoveChange = useCallback(
        (val: number, index: number) => {
            const v: EdgeValue = [...paddingValue]
            const num = transform2Integer(val)
            v[index] = num
            if (!open) {
                v[index + 2] = num
            }
            setPaddingValue(v)
            if(isDeepEqual(v, initValue)){
                return
            }
            onChange(v)
        },
        [initValue, onChange, open, paddingValue, setPaddingValue]
    )

    const handleMoveOpen = useCallback(
        (val: boolean, index: number) => {
            setMoveOpen(draft => {
                draft[index] = val
            })
        },
        [setMoveOpen]
    )

    const handleTrigger = useCallback(() => {
        if (open && (Number(top) !== Number(bottom) || Number(left) !== Number(right))) {
            const v: EdgeValue = [left, top, left, top]
            if(isDeepEqual(v, initValue)){
                return
            }
            onChange(v)
        }
        setOpen(!open)
    }, [bottom, initValue, left, onChange, open, right, top])

    const handleSubmit = useCallback(
        (val: string, index: number) => {
            const num = transform2Number(Number(val).toFixed(2))
            const v: EdgeValue = [...paddingValue]
            v[index] = num
            if (!open) {
                v[index + 2] = num
            }
            setPaddingValue(v)
            if(isDeepEqual(v, initValue)){
                return
            }
            onChange(v)
        },
        [initValue, onChange, open, paddingValue, setPaddingValue]
    )

    const resizeStyle = useMemo(
        () =>
            moveOpen.map(item => {
                if (!item) {
                    return {}
                }
                return {
                    borderColor: 'var(--color-main)',
                    backgroundColor: 'var(--color-gray-200)'
                }
            }),
        [moveOpen]
    )

    const { onMouseEnterPadding, onMouseLeavePadding } = useSetPaddingHighlight(disableHighlight || moveOpen.some(Boolean))

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: 180, gap: 8 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: 180, gap: 8 }}>
                <Input
                    // prefix={<IconFont type={open ? 'PaddingLeft' : 'PaddingLeftRight'} />}
                    prefix={
                        <SizeIcon
                            type={open ? 'PaddingLeft' : 'PaddingLeftRight'}
                            min={0}
                            value={left}
                            onChangeMove={val => handleMoveOpen(val, 0)}
                            onChange={val => handleMoveChange(val, 0)}
                        />
                    }
                    styles={{
                        wrapper: resizeStyle[0]
                    }}
                    placeholder=""
                    onFocus={e => e.currentTarget.select()}
                    value={left}
                    type='number'
                    style={{ width: 65 }}
                    onChange={ev => handlePaddingChange(0, ev.target.value)}
                    onKeyDownCapture={ev => {
                        if (ev.key === 'Enter') {
                            ev.currentTarget.blur()
                        }
                    }}
                    onBlur={ev => {
                        const v = ev.target.value
                        handleSubmit(v, 0)
                    }}
                    wrapperProps={{
                        onMouseEnter: () => {
                            onMouseEnterPadding('paddingLeft')
                            if (!open) {
                                onMouseEnterPadding('paddingRight')
                            }
                        },
                        onMouseLeave: () => {
                            onMouseLeavePadding('paddingLeft')
                            if (!open) {
                                onMouseLeavePadding('paddingRight')
                            }
                        }
                    }}
                />
                <Input
                    prefix={
                        <SizeIcon
                            type={open ? 'PaddingTop' : 'PaddingTopBottom'}
                            min={0}
                            value={top}
                            onChangeMove={val => handleMoveOpen(val, 1)}
                            onChange={val => handleMoveChange(val, 1)}
                        />
                    }
                    styles={{
                        wrapper: resizeStyle[1]
                    }}
                    type='number'
                    placeholder=""
                    onFocus={e => e.currentTarget.select()}
                    value={top}
                    style={{ width: 65 }}
                    onChange={ev => handlePaddingChange(1, ev.target.value)}
                    onKeyDownCapture={ev => {
                        if (ev.key === 'Enter') {
                            ev.currentTarget.blur()
                        }
                    }}
                    onBlur={ev => {
                        const v = ev.target.value
                        handleSubmit(v, 1)
                    }}
                    wrapperProps={{
                        onMouseEnter: () => {
                            onMouseEnterPadding('paddingTop')
                            if (!open) {
                                onMouseEnterPadding('paddingBottom')
                            }
                        },
                        onMouseLeave: () => {
                            onMouseLeavePadding('paddingTop')
                            if (!open) {
                                onMouseLeavePadding('paddingBottom')
                            }
                        }
                    }}
                />
                <Button
                    type="primary"
                    style={{ backgroundColor: 'var(--color-gray-100)' }}
                    icon={<IconFont type="ArrowOpen" size={16} color="var(--color-gray-500)" />}
                    onClick={() => handleTrigger()}
                />
            </div>
            {open && (
                <div style={{ display: 'flex', flexWrap: 'wrap', width: 180, gap: 8 }}>
                    <Input
                        prefix={
                            <SizeIcon
                                type="PaddingRight"
                                min={0}
                                value={right}
                                onChangeMove={val => handleMoveOpen(val, 2)}
                                onChange={val => handleMoveChange(val, 2)}
                            />
                        }
                        styles={{
                            wrapper: resizeStyle[2]
                        }}
                        placeholder=""
                        onFocus={e => e.currentTarget.select()}
                        value={right}
                        type='number'
                        style={{ width: 65 }}
                        onChange={ev => handlePaddingChange(2, ev.target.value)}
                        onKeyDownCapture={ev => {
                            if (ev.key === 'Enter') {
                                ev.currentTarget.blur()
                            }
                        }}
                        onBlur={ev => {
                            const v = ev.target.value
                            handleSubmit(v, 2)
                        }}
                        wrapperProps={{
                            onMouseEnter: () => {
                                onMouseEnterPadding('paddingRight')
                            },
                            onMouseLeave: () => {
                                onMouseLeavePadding('paddingRight')
                            }
                        }}
                    />
                    <Input
                        prefix={
                            <SizeIcon
                                type="PaddingBottom"
                                min={0}
                                value={bottom}
                                onChangeMove={val => handleMoveOpen(val, 3)}
                                onChange={val => handleMoveChange(val, 3)}
                            />
                        }
                        styles={{
                            wrapper: resizeStyle[3]
                        }}
                        placeholder=""
                        onFocus={e => e.currentTarget.select()}
                        value={bottom}
                        type='number'
                        style={{ width: 65 }}
                        onChange={ev => handlePaddingChange(3, ev.target.value)}
                        onKeyDownCapture={ev => {
                            if (ev.key === 'Enter') {
                                ev.currentTarget.blur()
                            }
                        }}
                        onBlur={ev => {
                            const v = ev.target.value
                            handleSubmit(v, 3)
                        }}
                        wrapperProps={{
                            onMouseEnter: () => {
                                onMouseEnterPadding('paddingBottom')
                            },
                            onMouseLeave: () => {
                                onMouseLeavePadding('paddingBottom')
                            }
                        }}
                    />
                </div>
            )}
        </div>
    )
}
