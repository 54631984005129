import { Anchor, Button, IconFont, Input, Modal, Toast } from '@byecode/ui'
import { Radio, RadioGroup } from '@byecode/ui/components/Radio'
import { CopyButton, ErrorMessage, SERVICES_IP } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import copyToClipboard from 'copy-to-clipboard'
import { find } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { otherPlatformNameMap } from '@/containers/OtherPlatforms/constant'
import { usePageURI } from '@/hooks/usePageURI'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { PLACEHOLDER_PASSWORD } from '../constants'
import * as SC from './styles'
import type { WeChatOfficialAccountFormState, WeChatOfficialAccountMode } from './type'

interface WeChatOfficialAccountProps {
    id?: string
    mode: WeChatOfficialAccountMode
    isPerfectWebsiteInfo?: boolean
    onClose?: () => void
}

const initData: WeChatOfficialAccountFormState = {
    id: '',
    name: '',
    appId: '',
    appSecret: '',
    domainFileName: '',
    domainFileContent: '',
    scope: 'snsapi_userinfo',
    encodingAesKey: '',
    token: ''
}
const WeChatOfficialAccount: React.FunctionComponent<WeChatOfficialAccountProps> = ({ id, mode, isPerfectWebsiteInfo, onClose }) => {
    const { data: otherPlatforms, update: otherPlatformUpdate } = useOtherPlatformList()
    const websiteRef = useRef<HTMLInputElement>(null)
    const { shareUrl, host } = usePageURI({ isProdHost: true })

    const serviceUrl = `https://${host}/byecode/external/wechat/official/callback`

    const [loading, setLoading] = useState(false)
    const [copyLoading, setCopyLoading] = useState(false)
    const account = useMemo(() => {
        const otherPlatform = find(item => item.id === id, otherPlatforms ?? [])
        return otherPlatform && otherPlatform.type === 'WECHAT_OFFICIAL_ACCOUNT'
            ? {
                  ...otherPlatform?.config,
                  name: otherPlatform.name,
                  id,
                  appSecret: PLACEHOLDER_PASSWORD,
                  token: otherPlatform?.config.token && PLACEHOLDER_PASSWORD,
                  encodingAesKey: otherPlatform?.config.encodingAesKey && PLACEHOLDER_PASSWORD
              }
            : initData
    }, [id, otherPlatforms])

    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors }
    } = useForm({
        mode: 'onSubmit',
        defaultValues: account
    })
        console.log("🚀 ~ getValues:", getValues('token'))

    const iconEle = useMemo(() => {
        if (copyLoading) {
            return <IconFont size={16} color="var(--color-green-500)" type="Tick" />
        }
        return <IconFont color="var(--color-gray-400)" type="Copy" size={16} />
    }, [copyLoading])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (copyLoading) {
                setCopyLoading(false)
            }
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [copyLoading])

    useEffect(() => {
        if (websiteRef.current) {
            websiteRef.current.scrollIntoView({ behavior: 'smooth' })
            websiteRef.current.focus()
        }
    }, [])

    const handleAdd = useCallback(
        async (params: WeChatOfficialAccountFormState) => {
            const { name, appId, appSecret, domainFileName, domainFileContent, scope, encodingAesKey, token } = params
            setLoading(true)
            const res = await srv.addIntegration({
                type: 'WECHAT_OFFICIAL_ACCOUNT',
                name,
                params: { appId, appSecret, domainFileName, domainFileContent, scope, encodingAesKey, token }
            })
            setLoading(true)
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleDelete = useCallback(() => {
        const title = getValues('name')
        if (!id) {
            return
        }
        Modal.confirm({
            title: '确认删除',
            content: `确认删除「${otherPlatformNameMap['WECHAT_OFFICIAL_ACCOUNT']}-${title}」。`
        })?.then(async s => {
            if (s) {
                const isSuccess = await srv.deleteIntegration(id)
                if (isSuccess) {
                    otherPlatformUpdate()
                    Toast.success(`已删除「${otherPlatformNameMap['WECHAT_OFFICIAL_ACCOUNT']}-${title}」`)
                    onClose?.()
                }
            }
        })
    }, [getValues, id, onClose, otherPlatformUpdate])

    const handleUpdate = useCallback(
        async (params: WeChatOfficialAccountFormState) => {
            const { name, id, appId, appSecret, domainFileName, domainFileContent, scope, encodingAesKey, token } = params
            if (!id) {
                return
            }
            const res = await srv.updateIntegration({
                type: 'WECHAT_OFFICIAL_ACCOUNT',
                name,
                id,
                params: {
                    appId,
                    appSecret: appSecret === PLACEHOLDER_PASSWORD ? undefined : appSecret,
                    domainFileName,
                    domainFileContent,
                    scope,
                    encodingAesKey: encodingAesKey === PLACEHOLDER_PASSWORD ? undefined : encodingAesKey,
                    token: token === PLACEHOLDER_PASSWORD ? undefined : token
                }
            })
            if (res) {
                otherPlatformUpdate()
                onClose?.()
            }
        },
        [onClose, otherPlatformUpdate]
    )

    const handleInviteSubmit = useCallback(
        (ev: React.FormEvent) => {
            ev.preventDefault()
            handleSubmit(mode === 'add' ? handleAdd : handleUpdate)()
        },
        [handleAdd, handleSubmit, handleUpdate, mode]
    )

    return (
        <SC.Container onSubmit={handleInviteSubmit}>
            <SC.Content>
                <SC.Icon size={40} color="#12B76A" type="WeChatLogo" />
                <SC.Title>{mode === 'add' ? '添加' : '编辑'}微信公众号</SC.Title>

                <SC.Tip>
                    <SC.Icon color="var(--color-main)" type="WarningCircle" />
                    <SC.Text>如果您添加账号遇到问题，请查看帮助文档：</SC.Text>
                    <Anchor
                        target="_blank"
                        style={{ fontWeight: 600, color: 'var(--color-main)' }}
                        to="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/okubts70erqfv6z7"
                    >
                        如何添加微信公众号
                    </Anchor>
                </SC.Tip>
                <SC.Item style={{ marginTop: 20 }}>
                    <SC.Text>
                        账号标题
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="name" errors={errors}>
                        <Input {...register('name', { required: '不能为空' })} />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        开发者ID(AppID)
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="appId" errors={errors}>
                        <Input placeholder="请填入微信公众号的开发者AppId" {...register('appId', { required: '不能为空' })} />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        开发者密码(AppSecret)
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="appSecret" errors={errors}>
                        <Controller
                            name="appSecret"
                            control={control}
                            rules={{ required: '不能为空' }}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="new-password"
                                        type="password"
                                        placeholder="请填入微信公众号的开发者密码"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        域名校验文件名
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="domainFileName" errors={errors}>
                        <Input
                            placeholder="请输入微信公众号提供的域名校验文件名"
                            {...register('domainFileName', { required: '不能为空' })}
                        />
                    </ErrorMessage>
                    <SC.Description>微信公众号提供的域名校验文件名，例如：MP_verify_t1op33AC5w4rNIwE.txt</SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>
                        域名校验文件内容
                        <SC.Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </SC.Text>
                    </SC.Text>
                    <ErrorMessage name="domainFileContent" errors={errors}>
                        <Input
                            placeholder="请输入微信公众号提供的域名校验文件内容"
                            {...register('domainFileContent', { required: '不能为空' })}
                        />
                    </ErrorMessage>
                    <SC.Description>微信公众号提供的域名校验文件内容，例如：E10p22BD7w1rMitt</SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>Callback URL</SC.Text>
                    <Input readOnly defaultValue={shareUrl} disabled placeholder="请输入你的业务回调链接" />
                </SC.Item>
                <SC.Item>
                    <SC.Text>Scopes</SC.Text>
                    <Controller
                        control={control}
                        name="scope"
                        render={({ field }) => (
                            <RadioGroup style={{ gap: 24 }} name="scope" value={field.value} onChange={field.onChange}>
                                <Radio size="xs" label="snsapi_userinfo" value="snsapi_userinfo" />
                                <Radio size="xs" label="snsapi_base" value="snsapi_base" />
                            </RadioGroup>
                        )}
                    />
                    <SC.Description>
                        应用授权作用域，snsapi_base（不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo（弹出授权页面，可通过openid拿到呢称、性别、所在地。并且，即使在未关注的情况下，只要用户授权，也能获取其信息）。详情请见
                        <Anchor
                            target="_blank"
                            style={{ color: 'var(--color-main)', paddingLeft: 4 }}
                            to="https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html  "
                        >
                            微信公众号网页授权文档说明
                        </Anchor>
                        。
                    </SC.Description>
                </SC.Item>
                <Divider
                    style={{ marginTop: 20 }}
                    labelPosition="center"
                    label="若想开启“微信网页扫码登录”功能，需完善下方配置"
                    color="var(--color-gray-400)"
                />
                <SC.Item style={{ marginTop: 8 }}>
                    <SC.Text>令牌(Token)</SC.Text>
                    <ErrorMessage name="token" errors={errors}>
                        <Controller
                            name="token"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Input
                                        ref={websiteRef}
                                        autoComplete="off"
                                        type="password"
                                        placeholder="请输入微信公众号提供的 Token"
                                        // onFocus={() => {
                                        //     if (field.value === PLACEHOLDER_PASSWORD) {
                                        //         field.onChange('')
                                        //     }
                                        // }}
                                        // onBlur={() => {
                                        //     if (!field.value?.trim()) {
                                        //         field.onChange(PLACEHOLDER_PASSWORD)
                                        //     }
                                        // }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        需先登录「
                        <Anchor target="_blank" to="https://mp.weixin.qq.com/">
                            微信公众号
                        </Anchor>
                        」{'>'}「设置与开发」{'>'}「基本配置」{'>'}「服务器配置」找到对应的Token
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>消息加解密密钥(EncodingAESKey)</SC.Text>
                    <ErrorMessage name="encodingAesKey" errors={errors}>
                        <Controller
                            name="encodingAesKey"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <Input
                                        autoComplete="off"
                                        type="password"
                                        placeholder="请输入微信公众号提供的 EncodingAESKey"
                                        onFocus={() => {
                                            if (field.value === PLACEHOLDER_PASSWORD) {
                                                field.onChange('')
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!field.value?.trim()) {
                                                field.onChange(PLACEHOLDER_PASSWORD)
                                            }
                                        }}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                )
                            }}
                        />
                    </ErrorMessage>
                    <SC.Description>
                        需先登录「{' '}
                        <Anchor target="_blank" to="https://mp.weixin.qq.com/">
                            微信公众号
                        </Anchor>
                        」{'>'}「设置与开发」{'>'}「基本配置」{'>'}「服务器配置」找到对应的EncodingAESKey
                    </SC.Description>
                </SC.Item>
                <SC.Item>
                    <SC.Text>服务器地址(URL)</SC.Text>

                    <Input
                        styles={{
                            suffix: {
                                cursor: 'pointer'
                            }
                        }}
                        disabled
                        defaultValue={serviceUrl}
                        suffix={<CopyButton value={serviceUrl} />}
                    />

                    <SC.Description>
                        请复制该链接「
                        <Anchor target="_blank" to="https://mp.weixin.qq.com/">
                            微信公众号
                        </Anchor>
                        」{'>'}「设置与开发」{'>'}「基本配置」{'>'}「服务器配置」{'>'}「修改配置」，粘贴到「URL」输入框，点击「提交」按钮
                    </SC.Description>
                </SC.Item>
            </SC.Content>
            <SC.Footer>
                <SC.LeftFill>
                    {mode === 'update' && (
                        <Button
                            style={{ color: '#D88987', borderColor: '#D88987' }}
                            icon={<SC.Icon type="Trash" color="#D88987" />}
                            size="lg"
                            onClick={() => handleDelete()}
                        >
                            删除账号
                        </Button>
                    )}
                    <Button
                        size="lg"
                        style={{ width: 104 }}
                        onClick={() => {
                            if (copyLoading) {
                                return
                            }
                            setCopyLoading(true)
                            const isCopied = copyToClipboard(SERVICES_IP)
                            if (isCopied) {
                                Toast.success('复制成功')
                            }
                        }}
                        icon={iconEle}
                    >
                        复制IP
                    </Button>
                </SC.LeftFill>

                <SC.RightFill>
                    <Button size="lg" style={{ width: 104 }} onClick={() => onClose?.()}>
                        取消
                    </Button>
                    <Button size="lg" loading={loading} style={{ width: 104 }} type="primary" htmlType="submit">
                        确定
                    </Button>
                </SC.RightFill>
            </SC.Footer>
        </SC.Container>
    )
}

export default WeChatOfficialAccount
