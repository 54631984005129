import { flex } from '@byecode/ui'
import { useAtomAction, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { use } from 'echarts/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { AppSystemPageAtom } from '@/atoms/application/state'
import { addRecordAtom, fetchSystemDataSourceAtom } from '@/atoms/dataSource/action'
import { fetchPageAtom } from '@/atoms/page/action'
import SettingAuthentication from '@/components/ApplicationSetting/SettingAuthentication'
import { ModuleType } from '@/components/ApplicationSetting/type'
import * as srv from '@/services'

import AccountPage from '../PageDetail/AccountPage'
import { PageLayout } from '../PageDetail/PageLayout'
import { PageLeftPanel } from './LeftPanel'

const Container = styled.div`
    position: relative;
    ${flex};
    height: 100%;
    display: flex;
    overflow: hidden;
`

const Content = styled.div`
    flex: 1;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
`

interface PagePathParams {
    rootPageId?: string
    appId?: string
    envId?: string
    dsId?: string
    recordId?: string
    pageId: string
    stackId: string
}

const PageMain = ({ rootPageId, pageId, stackId, appId, envId, dsId, recordId }: PagePathParams) => {
    const { run: addRecord } = useAtomAction(addRecordAtom)
    const [key, setKey] = useState('')

    const { value: pageRes, run: fetchPage, loading } = useAtomAsyncAction(fetchPageAtom)
    const { run: fetchSystemDataSource } = useAtomAction(fetchSystemDataSourceAtom)

    useEffect(() => {
        fetchPage({ pageId, rootPageId: rootPageId || pageId, stackId })
    }, [fetchPage, pageId, rootPageId, stackId])

    const { retry } = useAsyncRetry(async () => {
        if (appId && envId && dsId && recordId) {
            const { datasource, record } = await srv.getRecord({ appId, dsId, envId, recordId })
            addRecord({ records: [record] })
            return { dataSource: datasource, record }
        }
        return null
    }, [pageRes?.dsId, pageId, appId, dsId, recordId])

    const handleFreshPage = useCallback(() => {
        retry()
        fetchSystemDataSource()
        setKey(nanoid())
    }, [fetchSystemDataSource, retry])

    return (
        <PageLayout
            key={key}
            pageId={pageId}
            stackId={stackId}
            loading={!pageRes || loading || pageRes.id !== pageId}
            onFreshPage={handleFreshPage}
        />
    )
}

export const Page: React.FC<PagePathParams> = props => {
    const isShowSystemPage = useAtomData(AppSystemPageAtom)

    return (
        <Container>
            <Content style={{ width: isShowSystemPage ? 'calc(100% - 560px)' : 'auto' }}>
                <PageLeftPanel />
                {isShowSystemPage ? <AccountPage /> : <PageMain {...props} />}
            </Content>

            {isShowSystemPage && <SettingAuthentication />}
        </Container>
    )
}
