import { MultiSelect, Switch } from '@byecode/ui'
import { dateRangeVariableSystemNameMap, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { RenderLabel } from './MultiSelectRenderLabel'

const dateOptions = Object.entries(dateRangeVariableSystemNameMap).map(([key, value]) => ({
    label: value,
    value: key
}))

interface DateFilterProps {
    prefixName: string
}

export const DateFilter: React.FC<DateFilterProps> = ({ prefixName }) => {
    const { control } = useFormContext()
    return (
        <>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>预设时间选项</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.dateVariables`}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <MultiSelect
                                dropdownWidth={220}
                                position="bottom-end"
                                styles={{ root: { width: 180 } }}
                                placeholder="请选择"
                                options={dateOptions}
                                value={value}
                                customInputValueRender={RenderLabel}
                                onChange={val => onChange(val)}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <Text>自定义时间范围</Text>
                <Controller
                    control={control}
                    name={`${prefixName}.isDateRange`}
                    render={({ field: { value, onChange } }) => {
                        return <Switch checked={value} onChange={onChange} />
                    }}
                />
            </ListItem4ByecodeUi>
        </>
    )
}
