import { IframeBlock } from '@lighthouse/block'
import type { IframeBlockAbstract } from '@lighthouse/core'
import React from 'react'

interface IframeBlockControllerProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: IframeBlockAbstract
}

const IframeBlockController: React.FC<IframeBlockControllerProps> = props => {
    return <IframeBlock {...props} />
}

export default IframeBlockController
