import type { FlowEdge, FlowNode } from '@lighthouse/shared'

export const getWithStatusEdges = (nodes: FlowNode[], edges: FlowEdge[]) => {
    const nodesMap = new Map<string, FlowNode>()
    for (const node of nodes) {
        nodesMap.set(node.id, node)
    }

    const withStatusEdges: FlowEdge[] = []

    for (const edge of edges) {
        const sourceNode = nodesMap.get(edge.source)
        const targetNode = nodesMap.get(edge.target)

        if (!sourceNode || !targetNode) {
            continue
        }

        let edgeStatus: 'SUCCESS' | 'FAILED' | undefined

        // if (
        //     (sourceNode.data.status?.status === 'SUCCESS' &&
        //         targetNode.data.status?.status === 'SUCCESS' &&
        //         sourceNode.type !== 'CONDITION') ||
        //     // 条件节点，需要额外判断输出值，是 true 才算成功
        //     (sourceNode.type === 'CONDITION' &&
        //         sourceNode.data.status?.output?.[0]?.find(v => v.label === '执行结果')?.value === true &&
        //         targetNode.data.status?.status === 'SUCCESS')
        // ) {
        //     edgeStatus = 'SUCCESS'
        // }
        if (sourceNode.data.status?.status === 'SUCCESS' &&
            targetNode.data.status?.status === 'SUCCESS') {
            edgeStatus = 'SUCCESS'
        }

        if (sourceNode.data.status?.status === 'FAILED' || targetNode.data.status?.status === 'FAILED') {
            edgeStatus = 'FAILED'
        }

        withStatusEdges.push({
            ...edge,
            data: {
                status: { status: edgeStatus }
            }
        })
    }

    return withStatusEdges
}
