import type { BlockVisibilityProtocol, FilterFormType, FilterVisibleMode, RecordData } from '@lighthouse/core'
import { DEVELOPER, filterRecordChecker, getWindowUrl, useAtomData } from '@lighthouse/shared'
import { find, isEmpty } from 'rambda'
import { useCallback } from 'react'

import { recordPoolAtom } from '@/atoms/dataSource/state'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useDataSourceList } from './useDataSource'
import type { usePageDataSourceForVariableSelector } from './usePage'
import { useUserRecord } from './useUserRecord'

type Props = Pick<ReturnType<typeof usePageDataSourceForVariableSelector>, 'curr' | 'prev'>

export const useVisibilityFilter = ({ prev, curr }: Props) => {
    const userRecord = useUserRecord()

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const prevRecord = useAtomData(
        recordPoolAtom,
        useCallback(
            draft => find(item => item.appId === appId && item.dsId === prev.datasource?.id && item.id === prev.recordId, draft),
            [appId, prev.datasource?.id, prev.recordId]
        )
    )
    const currRecord = useAtomData(
        recordPoolAtom,
        useCallback(
            draft => find(item => item.appId === appId && item.dsId === curr.datasource?.id && item.id === curr.recordId, draft),
            [appId, curr.datasource?.id, curr.recordId]
        )
    )

    return useCallback(
        (payload: { visibility?: BlockVisibilityProtocol; viewRecord?: RecordData; userId?: string }) => {
            const { visibility, viewRecord, userId } = payload
            if(!visibility){
                return true
            }
            const { visibilityFilter, visible } = visibility
            if (!visible || visible === 'VISIBLE') {
                return true
            }
            if (visible === 'HIDDEN') {
                return false
            }
            if(visible === 'FILTER' && userId === DEVELOPER){
                return true
            }
            {return visibilityFilter && !isEmpty(visibilityFilter)
                ? filterRecordChecker({
                    filter: visibilityFilter,
                    dataSourceList,
                    currentRecord: {
                        datasource: curr.datasource,
                        record: currRecord
                    },
                    prevRecord: {
                        datasource: prev.datasource,
                        record: prevRecord
                    },
                    viewRecord,
                    userRecord,
                    pageStackFormState: curr.formState,
                    currentPageLink: getWindowUrl()
                })
                : true}
        },
        [curr.datasource, curr.formState, currRecord, dataSourceList, prev.datasource, prevRecord, userRecord]
    )

}
