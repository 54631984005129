import { Button, IconFont, Popover } from '@byecode/ui'
import React, { useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

const MinMaxRectSizeAdderWrapper = styled.div`
    color: var(--color-gray-900);
`

const SCxListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

interface MinMaxRectSizeAdderProps {
    hideMinWidth?: boolean
    hideMaxHeight?: boolean
    hideMinHeight?: boolean
}

export const MinMaxRectSizeAdder: React.FC<MinMaxRectSizeAdderProps> = ({ hideMinWidth, hideMaxHeight, hideMinHeight }) => {
    const { control, setValue } = useFormContext()
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const values = useWatch({
        control,
        name: ['breakPoint.size.minWidth', 'breakPoint.size.maxWidth', 'breakPoint.size.minHeight', 'breakPoint.size.maxHeight']
    })
    const dropdownOptions = useMemo(() => {
        return [
            { label: '添加最小宽度', value: 'minWidth' },
            { label: '添加最大宽度', value: 'maxWidth' },
            { label: '添加最小高度', value: 'minHeight' },
            { label: '添加最大高度', value: 'maxHeight' }
        ].filter((item, index) => {
            if(hideMinWidth && item.value === 'minWidth'){
                return false
            }
            if (hideMinHeight && item.value === 'minHeight') {
                return false
            }
            if (hideMaxHeight && item.value === 'maxHeight') {
                return false
            }
            return values[index] === undefined
        })
    }, [hideMaxHeight, hideMinHeight, hideMinWidth, values])

    const handleAdd = (value: string) => {
        setValue(`breakPoint.size.${value}`, {
            unit: 'px'
        })
        setDropdownOpened(false)
    }

    if (dropdownOptions.length === 0) {
        return null
    }

    return (
        <MinMaxRectSizeAdderWrapper onClick={ev => ev.stopPropagation()}>
            <Popover opened={dropdownOpened} width={180} onChange={setDropdownOpened}>
                <Popover.Target>
                    <Button type="text" size="sm">
                        <IconFont type="Add" color="var(--color-gray-400)" />
                    </Button>
                </Popover.Target>
                <Popover.Dropdown>
                    {dropdownOptions.map(option => (
                        <SCxListItem key={option.value} onClick={() => handleAdd(option.value)}>
                            {option.label}
                        </SCxListItem>
                    ))}
                </Popover.Dropdown>
            </Popover>
        </MinMaxRectSizeAdderWrapper>
    )
}
