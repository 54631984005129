import { IconFont, Popover } from '@byecode/ui'
import React, { useCallback, useState } from 'react'

import * as SC from './styles'

interface HandleSelectProps {
    children?: React.ReactNode
    id: string
    name: string
    disable?: boolean
    onRemove: (id: string, name: string) => void
    onCopy: (id: string, name: string) => void
}

export const HandleSelect: React.FC<HandleSelectProps> = ({ id, name, disable = false, onRemove, onCopy }) => {
    const [open, setOpen] = useState(false)

    const handleOpenChange = useCallback(
        (v: boolean) => {
            if (disable) {
                return
            }
            setOpen(v)
        },
        [disable]
    )

    return (
        <SC.Container onClick={e => e.stopPropagation()}>
            <Popover opened={open} onChange={handleOpenChange} width={204} withinPortal position="bottom-start" trigger="hover">
                <Popover.Target>
                    <SC.MemberHandle active={open} type="tag" icon={<IconFont type="DotsThree" size={20} fill="var(--color-gray-500)" />} />
                </Popover.Target>
                <Popover.Dropdown>
                    <SC.Option
                        onClick={() => {
                            onCopy(id, name)
                            setOpen(false)
                        }}
                    >
                        <SC.OptionContent>
                            <SC.OptionLabel>复制</SC.OptionLabel>
                        </SC.OptionContent>
                    </SC.Option>
                    <SC.Option
                        onClick={() => {
                            onRemove(id, name)
                            setOpen(false)
                        }}
                    >
                        <SC.OptionContent>
                            <SC.OptionLabel>删除</SC.OptionLabel>
                        </SC.OptionContent>
                    </SC.Option>
                </Popover.Dropdown>
            </Popover>
        </SC.Container>
    )
}
