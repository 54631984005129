import { Select } from '@byecode/ui'
import { collectBlockAndChildren, getFlatAllBlock } from '@lighthouse/block'
import { type BlockAbstract,BlockType } from '@lighthouse/core'
import type { FlowLayoutNode, FlowNode, NodeTypes, WithBlockOption } from '@lighthouse/shared'
import {
    CollapseBox,
    findBlockById,
    nodeTypeOptions,
    scroll2FlowNode,
    transformBlock2FlowLayoutNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { blockHighlightAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { usePreviewType } from '@/hooks/useApplication'

import * as SC from '../../styles'
import { ComponentSelector } from './ComponentSelector'

export interface ScrollToActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
}

const generateOptions = (pageBlocks: BlockAbstract[]) => {
    const blocks = getFlatAllBlock(pageBlocks)
    return blocks
        .map(block => {
            return {
                label: block.title,
                value: block.id,
                block,
                level: 0
            }
        })
        .filter(Boolean) as WithBlockOption[]
}

export const ScrollToActionConfigure: React.FC<ScrollToActionConfigureProps> = ({ actionTypeSwitcher, prefixName = 'config' }) => {
    const { control } = useFormContext()
    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const [pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || ''] as const, [])
    )

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    // const previewType = usePreviewType()

    // const flowNodes = useMemo(
    //     () =>
    //         transformBlock2FlowLayoutNode({
    //             blocks: pageBlocks,
    //             blockRuntimeState,
    //             previewType
    //         }),
    //     [blockRuntimeState, pageBlocks, previewType]
    // )

    const options = useMemo(() => {
        return generateOptions(pageBlocks)
    }, [pageBlocks])

    const handleScroll2FlowNode = useCallback(
        (scrollToId = '') => {
            setBlockHighlight(scrollToId ? [scrollToId] : [])
            scroll2FlowNode(scrollToId)
        },
        [setBlockHighlight]
    )

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <CollapseBox label="定位">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>目标组件</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.scrollToElementId`}
                                control={control}
                                render={({ field }) => (
                                    <ComponentSelector
                                        value={field.value}
                                        onChange={field.onChange}
                                        options={options}
                                        onHover={handleScroll2FlowNode}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
