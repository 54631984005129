import { IconFont, Input } from '@byecode/ui'
import type { ContainerBlockConfig } from '@lighthouse/core'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'

interface PositionInputProps {}

const SCxPositionInputWrapper = styled.div`
    display: flex;
    width: 180px;
`

const SCxPositionDirectionSelector = styled.div`
    position: relative;
    width: 72px;
    height: 72px;
    margin-right: 8px;
    border-radius: 6px;
    background-color: var(--color-gray-100);

    &::after {
        content: '';
        position: absolute;
        left: 24px;
        top: 24px;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: var(--color-gray-300);
    }
`

const SCxPositionDirectionSelectorItem = styled.div`
    display: flex;
    position: absolute;
    left: 30px;
    top: 30px;
    justify-content: center;
    width: 12px;
    height: 12px;
    cursor: pointer;
`

const SCxPositionDirectionSelectorItemIndicator = styled.div<{ active?: boolean }>`
    width: 3px;
    height: 12px;
    border-radius: 4px;
    background-color: ${({ active }) => (active ? 'var(--color-main)' : 'var(--color-gray-300)')};
`

const items = ['left', 'top', 'right', 'bottom']
const getDirectionSelectorItemTransform = (item: string) => {
    switch (item) {
        case 'left': {
            return 'translate(-24px, 0)'
        }
        case 'top': {
            return 'translate(0, -24px)'
        }
        case 'right': {
            return 'translate(24px, 0)'
        }
        case 'bottom': {
            return 'translate(0, 24px)'
        }
        default: {
            return 'translate(0, 0)'
        }
    }
}

const getDirectionSelectorItemIndicatorTransform = (item: string) => {
    switch (item) {
        case 'left': {
            return 'rotate(90deg)'
        }
        case 'right': {
            return 'rotate(-90deg)'
        }
        default: {
            return ''
        }
    }
}

export const PositionInput: React.FC<PositionInputProps> = () => {
    const { setValue, control } = useFormContext<ContainerBlockConfig>()
    const [horizontal, vertical] = useWatch({
        control,
        name: ['breakPoint.position.location.horizontal', 'breakPoint.position.location.vertical']
    })
    const {handleSetBreakKey} = useSetExtendsKeys()

    const handleDirectionSelectorItemClick = (item: string) => {
        if (item === 'left' || item === 'right') {
            setValue('breakPoint.position.location.horizontal', item)
        }
        if (item === 'top' || item === 'bottom') {
            setValue('breakPoint.position.location.vertical', item)
        }
        handleSetBreakKey('breakPoint.position.location')
    }

    return (
        <SCxPositionInputWrapper style={{ width: 180 }}>
            <SCxPositionDirectionSelector>
                {items.map((item, index) => (
                    <SCxPositionDirectionSelectorItem
                        key={item}
                        style={{ transform: getDirectionSelectorItemTransform(item) }}
                        onClick={() => handleDirectionSelectorItemClick(item)}
                    >
                        <SCxPositionDirectionSelectorItemIndicator
                            active={item === horizontal || item === vertical}
                            style={{ transform: getDirectionSelectorItemIndicatorTransform(item) }}
                        />
                    </SCxPositionDirectionSelectorItem>
                ))}
            </SCxPositionDirectionSelector>
            <div>
                <Controller
                    control={control}
                    name="breakPoint.position.location.horizontalValue"
                    render={({ field }) => (
                        <Input
                            prefix={<IconFont type={horizontal === 'left' ? 'Left' : 'Right'} />}
                            radius={0}
                            style={{ width: 100, marginBottom: 8 }}
                            {...field}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="breakPoint.position.location.verticalValue"
                    render={({ field }) => (
                        <Input
                            prefix={<IconFont type={vertical === 'top' ? 'Top' : 'Bottom'} />}
                            radius={0}
                            style={{ width: 100 }}
                            {...field}
                        />
                    )}
                />
            </div>
        </SCxPositionInputWrapper>
    )
}
