import type { DataSourceAbstract, ViewType } from '@lighthouse/core'
import React, { useMemo } from 'react'

import { CalendarStyleSettings } from './CalendarStyleSettings'
import GalleryBlockSettings from './GalleryBlockSettings'
import { TableBlockStyleSettings } from './TableBlockStyleSettings'

export interface DesignProps {
    viewType: ViewType
    dataSource: DataSourceAbstract | undefined
}

export const Design: React.FC<DesignProps> = ({ viewType, dataSource }) => {
    return useMemo(() => {
        switch (viewType) {
            case 'gallery': {
                return <GalleryBlockSettings />
            }

            case 'calendar': {
                return <CalendarStyleSettings dataSource={dataSource} />
            }
            default: {
                return <TableBlockStyleSettings viewType={viewType} dataSource={dataSource} />
            }
        }
    }, [dataSource, viewType])
}
