import type { SubFormBlockConfig } from '@lighthouse/core'

export const generateSubFormBlock = (): SubFormBlockConfig => {
    return {
        columns: [],
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'auto'
            },
            layout: {},
            design: {}
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
