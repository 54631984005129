import { findParentFormBlock, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'

import { blocksAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useVariableCustomDataSource } from '@/hooks/useVariableCustomViewOption'

import { FieldInputSetting } from '../Common/FieldInputSetting'

export interface BlockFieldSettingsProps {
    id: string
    pointer: string
}

const BlockFieldSettings: React.FunctionComponent<BlockFieldSettingsProps> = ({ id: blockId, pointer: pageDsId }) => {
    const [pageId, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.blockRuntimeState] as const, [])
    )

    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const blocks = useAtomData(
        pageBlocksAtom(pageId)
    )

    const parentForm = useMemo(() => findParentFormBlock({ id: blockId, blocks, blockRuntimeState }), [blockId, blocks, blockRuntimeState])

    const mode = useMemo(() => (parentForm ? 'form' : 'field'), [parentForm])

    const { customViewDsId } = useVariableCustomDataSource()

    const pointer = (mode === 'form' ? parentForm?.config.pointer : customViewDsId || pageDsId) ?? ''

    const dataSourceList = useDataSourceList(currentAppId, envId)

    return <FieldInputSetting dataSourceList={dataSourceList} mode={mode} pointer={pointer} />
}

export default BlockFieldSettings
