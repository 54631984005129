import type { ContainerBlockConfig, Direction } from '@lighthouse/core'
import { FLEX_ALIGN } from '@lighthouse/core'
import { DEFAULT_ACTION } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

/**
 * 生成容器block的配置
 *
 * @returns {ContainerBlockConfig}
 */
export const generateContainerBlock = (direction: Direction): ContainerBlockConfig => {
    return {
        viewList: [
            {
                id: nanoid(),
                name: '面板 01'
            }
        ],
        action: DEFAULT_ACTION,
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'visible'
            },
            layout: {
                align: {
                    direction,
                    isDistributedAlignment: false,
                    alignX: FLEX_ALIGN['flex-start'],
                    alignY: FLEX_ALIGN['flex-start']
                },
                gap: 8,
                padding: [4, 4, 4, 4]
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
