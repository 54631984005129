import { Menu } from '@byecode/ui'
import { PAGE_TYPE, PageType } from '@lighthouse/core'
import { PageIconTypeMap, SelectDataSource } from '@lighthouse/shared'
import { Text } from '@mantine/core'
import React, {
    useMemo, useState
} from 'react'
import styled from 'styled-components'

import type { CreatePagePayload } from '@/atoms/page/types'
import { pageTypeToCreateModeMap } from '@/constants/common'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useNotAggregateDataSourceList } from '@/hooks/useDataSource'

import * as CM from '../style'
import type { PageKindType } from '../types'

interface CreatePageProps {
    createType: PageKindType
    disableSelectDataSource?: boolean
    withinPortal?: boolean
    dsId?: string
    onAdd: (v: CreatePagePayload) => void
}

const DATA_PAGE_OPTION = [
    { value: PAGE_TYPE.creator, label: '数据表单页', desc: '用于添加记录数据', icon: PageIconTypeMap[PAGE_TYPE.creator] },
    { value: PAGE_TYPE.document, label: '数据详情页', desc: '用于展示记录数据', icon: PageIconTypeMap[PAGE_TYPE.document] },
    { value: PAGE_TYPE.edit, label: '数据编辑页', desc: '用户编辑记录数据', icon: PageIconTypeMap[PAGE_TYPE.edit] }
]

const SCxTarget = styled.div`
    display: flex;
    align-items: center;

    ${CM.Icon} {
        color: var(--color-gray-500);
    }

    &:hover {
        ${CM.Icon} {
            color: var(--color-black);
        }
    }
`

export const CreatePage: React.FunctionComponent<CreatePageProps> = ({
    createType,
    disableSelectDataSource,
    withinPortal = true,
    dsId,
    onAdd
}) => {
    const [menuOpened, setMenuOpened] = useState(false)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useNotAggregateDataSourceList(appId, envId)

    // dataSourceList.filter(item => item.id !== USER_DATASOURCE || item.type === PAGE_TYPE.document)

    const dropDownEle = useMemo(() => {
        return (
            <>
                {DATA_PAGE_OPTION.map(({ label, value, desc, icon }) => {
                    return disableSelectDataSource ? (
                        <Menu.Item
                            closeMenuOnClick
                            styles={{
                                itemIcon: {
                                    alignItems: 'flex-start!important',
                                    alignSelf: 'flex-start'
                                }
                            }}
                            icon={<CM.Icon type={icon} size={20} style={{ alignSelf: 'flex-start' }} />}
                            // rightSection={<CM.Icon type="ArrowRightSmall" />}
                            onClick={() => onAdd({ dsId: '', name: '', createMode: pageTypeToCreateModeMap[value] })}
                        >
                            <Text color="var(--color-black)" style={{ lineHeight: '20px' }}>
                                {label}
                            </Text>
                            <Text color="var(--color-gray-400)" size={12} style={{ lineHeight: '20px' }}>
                                {desc}
                            </Text>
                        </Menu.Item>
                    ) : (
                        <SelectDataSource
                            key={value}
                            value=""
                            trigger="hover"
                            onChange={v => {
                                const ds = dataSourceList.find(ds => ds.id === v)
                                onAdd({ dsId: v, name: ds?.name, createMode: pageTypeToCreateModeMap[value] })
                                setMenuOpened(false)
                            }}
                            withinPortal
                            position="right-start"
                            width={200}
                            dataSourceList={dataSourceList}
                            renderTarget={opened => (
                                <Menu.Item
                                    styles={{
                                        itemIcon: {
                                            alignItems: 'flex-start!important',
                                            alignSelf: 'flex-start'
                                        },
                                        item: {
                                            background: opened ? 'var(--color-gray-100)' : undefined
                                        }
                                    }}
                                    icon={<CM.Icon type={icon} size={20} style={{ alignSelf: 'flex-start' }} />}
                                    rightSection={<CM.Icon type="ArrowRightSmall" />}
                                >
                                    <Text color="var(--color-black)" style={{ lineHeight: '20px' }}>
                                        {label}
                                    </Text>
                                    <Text color="var(--color-gray-400)" size={12} style={{ lineHeight: '20px' }}>
                                        {desc}
                                    </Text>
                                </Menu.Item>
                            )}
                        />
                    )
                })}
            </>
        )
    }, [dataSourceList, disableSelectDataSource, onAdd])

    return createType === 'default' ? (
        <SCxTarget
            onClick={e => {
                e.stopPropagation()
                onAdd({ name: '新的页面' })
            }}
        >
            <CM.Icon type="Add2" size={22} color="var(--color-main)" />
        </SCxTarget>
    ) : (
        <Menu
            opened={menuOpened}
            position="bottom-start"
            offsetOptions={{
                mainAxis: -2
            }}
            onChange={setMenuOpened}
            withinPortal={withinPortal}
            width={200}
        >
            <Menu.Target>
                <SCxTarget
                    onClick={e => {
                        e.stopPropagation()
                        setMenuOpened(true)
                    }}
                >
                    <CM.Icon type="Add2" size={22} color="var(--color-main)" />
                </SCxTarget>
            </Menu.Target>
            <Menu.Dropdown onClick={e => e.stopPropagation()}>{dropDownEle}</Menu.Dropdown>
        </Menu>
    )
}
