import { type FieldBlockInfo, transformInitialValue } from '@lighthouse/block'
import { type InputValueItem, PAGE_TYPE } from '@lighthouse/core'
import { getFieldInputInitialValue, resolveFilter, useApplicationContext, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import { useCallback, useMemo } from 'react'

import { appRoleAtom } from '@/atoms/application/state'
import { pageAtomFamily, pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useDataSourceList, useRecord } from './useDataSource'
import { usePageDataSourceForVariableSelector } from './usePage'
import { usePageURI } from './usePageURI'
import { useUserRecord } from './useUserRecord'

export function useFieldBlockDefaultValueRender(pointer: string) {
    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()
    // const appId = useCurrentAppID()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const userId = useAtomData(appRoleAtom)

    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const { shareUrl } = usePageURI({ isCurrentPage: true })
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const dataSourceList = useDataSourceList(appId, envId)
    const dataSource = useMemo(() => dataSourceList.find(item => item.id === pointer), [dataSourceList, pointer])
    const { schema } = dataSource ?? {}

    const currentRecord = useRecord(appId, curr.datasource?.envId ?? '', curr.datasource?.id ?? '', curr.recordId || '')
    const prevRecord = useRecord(appId, prev.datasource?.envId ?? '', prev.datasource?.id ?? '', prev.recordId || '')
    const { record: userRecord } = useUserRecord()

    const { initialRecordValue } = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => ({ initialRecordValue: s?.initialRecordValue }), [])
    )

    const [pageType, pageDsId = ''] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.type, s?.dsId], [])
    )

    return useCallback(
        async (fieldBlockInfo: FieldBlockInfo, fieldBlockValueMap?: Record<string, InputValueItem>): Promise<InputValueItem> => {
            const {
                initialValue,
                fieldId,
                inputType,
                id,
                config,
                config: { noRepeat: isCheckRepeat }
            } = fieldBlockInfo
            const field = schema?.[fieldId]
            const user = find(user => user.userId === userId, personOptions)
            const defaultInputValue = await getFieldInputInitialValue({
                config,
                fieldType: field?.type ?? 'text',
                record: currentRecord,
                prevRecord,
                currentUser: user,
                userRecord,
                currentPageUrl: shareUrl,
                dataSourceList,
                personOptions,
                roleOptions,
                departmentOptions,
                onFetchRecord: params => {
                    return srv.getRecordByFilter({
                        ...params,
                        appId,
                        envId,
                        filter: resolveFilter({
                            filter: params.filter,
                            disableResolvedPageData: true,
                            extraParams: {
                                pageStackFormState: fieldBlockValueMap
                            }
                        })
                    })
                }
            })

            const initKanbanRecord = initialRecordValue?.[fieldId]
            if (pageType === PAGE_TYPE.edit) {
                return {
                    value: currentRecord?.content?.[fieldId]?.value ?? defaultInputValue,
                    type: inputType,
                    isCheckRepeat,
                    id,
                    fieldType: field?.type,
                    fieldId
                } as InputValueItem
            }
            const finalValue = initKanbanRecord && field ? transformInitialValue(field.type, initKanbanRecord) : defaultInputValue
            return {
                value: finalValue,
                type: inputType,
                fieldType: field?.type,
                isCheckRepeat,
                id,
                fieldId
            } as InputValueItem
        },
        [
            appId,
            currentRecord,
            dataSourceList,
            departmentOptions,
            envId,
            initialRecordValue,
            pageType,
            personOptions,
            prevRecord,
            roleOptions,
            schema,
            shareUrl,
            userId,
            userRecord
        ]
    )
}
