import type { BlockType } from '@lighthouse/core'

import type { DesignSettingSwitch } from './types'

export const getBlockAndPageDesignLimit = (type: BlockType | 'page' | 'customView'): DesignSettingSwitch => {
    switch (type) {
        case 'page': {
            return {
                size: {
                    disableWidth: true,
                    // disableHeight: true,
                    hideHeight: true,
                    // hideMinMaxRectSizeAdder: true,
                    hideMaxHeight: true,
                    hideMinHeight: true,
                    hideMinWidth: true,
                    hideOverflow: true
                },
                layout: {
                    disableDistributedAlignment: true
                },
                design: {
                    hideRadius: true,
                    hideFill: true,
                    hideTextBorder: true,
                    hideBorder: true,
                    hideShadow: true,
                    hideBlur: true,
                    hideLineColor: true
                }
            }
        }
        case 'breadcrumb': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideOverflow: true
                }
            }
        }
        case 'image':
        case 'video':
        case 'qrBarcode': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideHeight: true,
                    hideMaxHeight: true,
                    hideMinHeight: true
                }
            }
        }
        case 'card':
        case 'collapse':
        case 'field':
        case 'fieldGroup':
        case 'filter':
        case 'subForm':
        case 'view': {
            return {
                size: {
                    disableWidthAuto: true
                }
            }
        }
        case 'tabs': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideOverflow: true,
                },
                design: {
                    hideBlur: true,
                    hideFill: true,
                    hideBorder: true
                }
            }
        }
        case 'buttonGroup':
        case 'chart': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideHeight: true,
                    hideMaxHeight: true,
                    hideMinHeight: true,
                    hideOverflow: true
                }
            }
        }
        case 'container':
        case 'formContainer': {
            return {
                size: {},
                layout: {},
                design: {
                    hideFill: true,
                    hideTextBorder: true,
                    hideLineColor: true
                }
            }
        }

        case 'customView': {
            return {
                size: {
                    disableWidthAuto: true
                },
                layout: {
                    disableDistributedAlignment: true
                },
                design: {
                    hideBlur: true,
                    hideTextBorder: true,
                    hideFill: true,
                    hideLineColor: true
                }
            }
        }

        case 'divider': {
            return {
                size: {
                    disableWidthAuto: true,
                    disableHeightAuto: true,
                    hideOverflow: true
                }
            }
        }
        case 'iframe':
        case 'file': {
            return {
                size: {
                    disableWidthAuto: true,
                    disableHeightAuto: true
                }
            }
        }

        case 'text': {
            return {
                size: {
                },
                design: {
                    hideRadius: true,
                    hideBackground: true,
                    hideVeins: true,
                    hideBlur: true,
                    hideBorder: true,
                    hideLineColor: true
                },
                font: {}
            }
        }

        default: {
            return {}
        }
    }
}

export const OVERFLOW_OPTIONS = [
    {
        label: '隐藏',
        value: 'hidden'
    },
    {
        label: '显示',
        value: 'visible'
    },
    {
        label: '滚动',
        value: 'auto'
    }
]
