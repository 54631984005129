import { Button, Flex, IconFont, Input, Popover, Text, Tooltip } from '@byecode/ui'
import type { IconDecoration } from '@lighthouse/shared'
import { IconPicker, PopoverDropdownWithClose, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'

import { updateAppAtom } from '@/atoms/application/action'
import { appAtom, applicationThemeColorAtom } from '@/atoms/application/state'
import { RoundAvatar } from '@/components/RoundAvatar'
import { PopoverQuota } from '@/components/SpaceQuota/Popover'
import { useApplication } from '@/hooks/useApplication'
import { uploadManagerInAppParams } from '@/utils/auth'

import { VersionList } from './VersionList'

export const ApplicationInfo = () => {
    const [appId, appIcon, appName] = useAtomData(
        appAtom,
        useCallback(s => [s.id, s.icon, s.name], [])
    )

    const themeColor = useAtomData(applicationThemeColorAtom)

    const { run: updateApplication } = useAtomAsyncAction(updateAppAtom)

    const handleChangeAppIcon = useCallback(
        (icon: string) => {
            updateApplication({ icon, id: appId })
        },
        [appId, updateApplication]
    )

    const iconValue = useMemo<IconDecoration>(() => ({ type: 'icon', value: appIcon }), [appIcon])

    const [isNameEditing, setIsNameEditing] = useState(false)

    return (
        <Flex gap={8} alignItems="center">
            <IconPicker
                value={iconValue}
                onChange={val => {
                    handleChangeAppIcon(val?.value ?? '')
                }}
                uploadParams={uploadManagerInAppParams()}
                targetComponent={
                    <div>
                        <RoundAvatar
                            name={appName}
                            iconSize={24}
                            size={32}
                            type="icon"
                            radius="6px"
                            bordered={false}
                            iconColor="var(--color-white)"
                            icon={appIcon || 'Cube'}
                            background={themeColor}
                        />
                    </div>
                }
            />
            {/* <Flex direction="column"> */}
            {isNameEditing ? (
                <Input
                    defaultValue={appName}
                    autoFocus
                    onFocus={e => {
                        e.currentTarget.select()
                    }}
                    onBlur={e => {
                        const { value } = e.currentTarget
                        setIsNameEditing(false)
                        if (!value) {
                            return
                        }
                        updateApplication({ name: value, id: appId })
                    }}
                    onKeyDown={e => {
                        if (e.code === 'Escape') {
                            setIsNameEditing(false)

                            return
                        }

                        if (e.code === 'Enter') {
                            const { value } = e.currentTarget
                            setIsNameEditing(false)

                            if (!value) {
                                return
                            }
                            updateApplication({ name: value, id: appId })
                        }
                    }}
                />
            ) : (
                <Button size="sm" type="text" onClick={() => setIsNameEditing(true)} style={{ margin: '0 -4px' }}>
                    <Text color="var(--color-black)" size={16} weight={500} lineHeight="24px">
                        {appName}
                    </Text>
                </Button>
            )}
            <Popover trigger="click" withinPortal width={360} position="bottom-start">
                <Popover.Target>
                    <Tooltip title="版本列表">
                        <Button
                            type="text"
                            size="xs"
                            style={{ width: 24, height: 24 }}
                            icon={<IconFont type="History" size={16} color="var(--color-gray-400)" />}
                        />
                    </Tooltip>
                </Popover.Target>
                <Popover.Dropdown styles={{ dropdown: { padding: '0 !important' } }}>
                    <VersionList />
                </Popover.Dropdown>
            </Popover>

            <PopoverQuota />
        </Flex>
    )
}
