import type { ChartViewOptions, FilterBlockAbstract } from '@lighthouse/core'
import type { VariableSource } from '@lighthouse/shared'
import { Group4ByecodeUi, useAtomData, VariableSourceType } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'

import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { BaseDataConfigurator } from '../BaseDataConfigurator'
import { DefaultDataSource } from '../DataSource'
import { IndicatorDesign } from '../IndicatorDesign'
import { OperatorConfigurator } from '../OperatorConfigurator'
import { SingleIndicatorConfigurator } from '../SingleIndicatorConfigurator'

interface IndicatorCardConfiguratorProps {}

export const IndicatorCardConfigurator: React.FC<IndicatorCardConfiguratorProps> = () => {
    const { control, getValues } = useFormContext<ChartViewOptions>()
    const appId = useCurrentAppID()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const pointer = useWatch({ control, name: 'pointer' })

    const { dataSourceList, prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const pageBlocks = useAtomData(
        pageBlocksAtom(pageId)
    )

    const dataSource = useMemo(
        () => find(item => item.appId === appId && item.id === pointer, dataSourceList),
        [appId, dataSourceList, pointer]
    )

    // 筛选器blocks
    // const filterBlocks = useMemo(() => {
    //     if (!pageBlocks) {
    //         return []
    //     }
    //     return filterBlock(pageBlocks, block => block.type === 'filter') as FilterBlockAbstract[]
    // }, [pageBlocks])

    const sources: VariableSource[] = useMemo(
        () => [
            {
                sourceType: VariableSourceType.parentPage,
                dataSource: prev.datasource,
                page: prev.page
            },
            {
                sourceType: VariableSourceType.page,
                dataSource: curr.datasource,
                page: curr.page
            }
        ],
        [curr.datasource, curr.page, prev.datasource, prev.page]
    )

    return (
        <>
            <DefaultDataSource />
            {dataSource && (
                <>
                    <Divider color="var(--color-gray-200)" />
                    <SingleIndicatorConfigurator dataSource={dataSource} />
                    <Divider color="var(--color-gray-200)" />
                    <IndicatorDesign />
                    <Divider color="var(--color-gray-200)" />
                    <Group4ByecodeUi label="数据设置">
                        <BaseDataConfigurator dataSource={dataSource} dataSourceList={dataSourceList} sources={sources} />
                    </Group4ByecodeUi>
                    <OperatorConfigurator />
                </>
            )}
        </>
    )
}
