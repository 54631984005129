import { Indicator, Popover } from '@byecode/ui'
import type { DataSourceAbstract, FieldType, FilterableProtocol, TypeInstanceMap, ViewField } from '@lighthouse/core'
import React, { useMemo } from 'react'
import { debounce } from 'throttle-debounce'

import { useDataSourceContext } from '../../contexts'
import { useAppThemeContext } from '../ApplicationContainer'
import { ViewFieldFilterConfigure } from '../ViewFieldFilterConfigure'
import SettingButton from './SettingButton'
import * as SC from './styles'

export interface FilterProps {
    primaryField: string
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    columns: ViewField[]
    data?: FilterableProtocol['filter']
    noSettingInnerType?: Set<TypeInstanceMap>
    isMobile?: boolean
    onChangeFilter?: (data?: FilterableProtocol['filter']) => void
}

export const Filter: React.FC<FilterProps> = ({
    primaryField,
    dataSource,
    dataSourceList,
    columns,
    data,
    noSettingInnerType,
    isMobile,
    onChangeFilter
}) => {
    const theme = useAppThemeContext()
    const filterText = useMemo(() => data?.expression?.conditions?.length || 0, [data?.expression?.conditions?.length])
    const isActive = useMemo(() => filterText > 0, [filterText])
    // const { personOptions } = useDataSourceContext()
    const handleChangeFilter = useMemo(() => debounce(500, val => onChangeFilter?.(val)), [onChangeFilter])

    return (
        <Popover width="auto" trapFocus={false}>
            <Popover.Target>
                <SC.SettingBox isActive={isActive} mainColor={theme.mainColor} tintMainColor={theme.tintMainColor}>
                    <SettingButton isActive={isActive} color={theme.mainColor} icon="Filter" />
                    {!isMobile && (
                        <Indicator disabled={!isActive} color={theme.mainColor} label={String(filterText)} size={16} radius={16} withBorder>
                            <SC.SettingText>筛选</SC.SettingText>
                        </Indicator>
                    )}
                </SC.SettingBox>
            </Popover.Target>
            <Popover.Dropdown>
                <ViewFieldFilterConfigure
                    dataSource={dataSource}
                    dataSourceList={dataSourceList}
                    columns={columns}
                    primaryField={primaryField}
                    filter={data}
                    noSettingInnerType={noSettingInnerType}
                    enableCurrentUser={false}
                    onFilter={handleChangeFilter}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
