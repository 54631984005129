import { Divider, Flex, IconFont, Toast, Tooltip } from '@byecode/ui'
import { Group } from '@byecode/ui/components/Group'
import type { Field, FileBlockAbstract } from '@lighthouse/core'
import {
    fileMaxUploadSize,
    getFileSizeToMB,
    getFileTypeByUrl,
    getIsFindUseInVariable,
    getPageDataOptions,
    getUserDatasourceOptions,
    InnerTypeMapByFieldType,
    ListItemPaddingByecodeUi,
    useAtomData,
    useFindUseObjectContext,
    USER_DATASOURCE,
    VariableSelect
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'
import { uploadManagerInAppParams } from '@/utils/auth'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'

interface FileBlockSettingProps {
    blockId: string
}

const USED_FILE_TYPE = new Set(['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'])

const filter = (field: Field) => field.type === 'file'

const FileBlockSetting: React.FunctionComponent<FileBlockSettingProps> = ({ blockId }) => {
    const designProps = getBlockAndPageDesignLimit('file')
    const { control } = useFormContext<FileBlockAbstract['config']>()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, curr, prev } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const findUseObject = useFindUseObjectContext()

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )
    const uploadInfo = useMemo(() => ({ id: '', label: pageName, groupId: pageId }), [pageId, pageName])

    const userOption = useMemo(() => {
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        if (!userDataSource) {
            return
        }
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: filter
        })
    }, [dataSourceList])

    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filter
            },
            curr.datasource && {
                variableSourceType: curr.variableSourceType,
                datasource: curr.datasource,
                validateFieldType: filter
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [curr.datasource, curr.variableSourceType, prev.datasource, prev.variableSourceType])

    const { customViewOption } = useVariableCustomViewOption(filter)

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SizeSetting {...designProps.size} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="设置">
                        <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between">
                            <Flex gap={4}>
                                <Text>文件来源</Text>
                                <Tooltip title={`文件支持格式：${[...USED_FILE_TYPE].join('、')}`}>
                                    <IconFont type="Question" style={{ cursor: 'pointer' }} color="var(--color-gray-400)" />
                                </Tooltip>
                            </Flex>
                            <Controller
                                name="fileSource"
                                control={control}
                                render={({ field: { value, onChange } }) => {
                                    const isHighLight = getIsFindUseInVariable({
                                        variable: value,
                                        findUseObject,
                                        currentDsId: curr.datasource?.id
                                    })
                                    return (
                                        <VariableSelect
                                            field={{ type: 'file', id: '', dsId: '', name: '', innerType: InnerTypeMapByFieldType['file'] }}
                                            disabledPexels
                                            highlighting={isHighLight}
                                            uploadProps={{
                                                multiple: false,
                                                uploadOptions: {
                                                    info: uploadInfo,
                                                    options: {
                                                        ...uploadManagerInAppParams(),
                                                        fileFilter: (file: File | string, index: number) => {
                                                            if (file instanceof File) {
                                                                if (file.size > fileMaxUploadSize) {
                                                                    Toast.error(
                                                                        `不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`
                                                                    )
                                                                    return false
                                                                }
                                                                if (!USED_FILE_TYPE.has(getFileTypeByUrl(file.name))) {
                                                                    Toast.error('文件格式错误')
                                                                    return false
                                                                }

                                                                return true
                                                            }
                                                            return true
                                                        }
                                                    }
                                                }
                                            }}
                                            options={options}
                                            value={value}
                                            userOption={userOption}
                                            viewOption={customViewOption}
                                            onChange={onChange}
                                        />
                                    )
                                }}
                            />
                        </ListItemPaddingByecodeUi>
                    </Group>

                    <Divider />

                    <VisibilitySetting />
                </>
            )}
        </>
    )
}

export default FileBlockSetting
