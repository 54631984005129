import type { FilterVisibleMode } from "@lighthouse/core";

export const filterVisibleLabelMap: Record<FilterVisibleMode, { icon: string; label: string }> = {
    VISIBLE: {
        icon: '',
        label: '显示'
    },
    HIDDEN: {
        icon: 'NoEye',
        label: '隐藏'
    },
    FILTER: {
        icon: 'EyeScan',
        label: '按条件'
    }

}
