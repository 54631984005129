import { Toast } from '@byecode/ui'
import { collectBlockAndChildren } from '@lighthouse/block'
import type { BaseBreakPointConfigProtocol, BlockAbstract } from '@lighthouse/core'
import { BlockType, DIRECTION, PAGE_TYPE } from '@lighthouse/core'
import type { CollisionArea, ResizeEvent, ResizeRestrict } from '@lighthouse/shared'
import {
    ApplicationPreviewEnum,
    findBlockById,
    findParentBlockById,
    getCurrentBlockChildren,
    isCustomViewBlock,
    isFormContainerBlock,
    mergeSingleBreakPointConfigure
} from '@lighthouse/shared'
import { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { clone, findIndex, mergeDeepRight } from 'rambda'

import { previewTypeAtom } from '@/atoms/application/state'
import { createBlockAtom } from '@/atoms/page/action'
import {
    blockCreatingListAtom,
    blocksAtom,
    outsideDraggingNode,
    pageAtomFamily,
    pageBlocksAtom,
    pageStackAtomFamily
} from '@/atoms/page/state'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { padBlocksByFormContainer } from '@/constants/Block/generate/field'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import {
    includeNotAllowedBlock,
    isActiveInOver,
    isCustomChildren,
    isFormContainerChildren,
    removeBlockById
} from '@/hooks/layoutEngine/utils'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useBlockActions } from '@/hooks/useBlock'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { BLOCK_NODE_CREATE_ACTION, BLOCK_UPDATE_ACTION, NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

export const useLayoutEngineEvents = () => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSources = useDataSourceList(appId, envId)

    const onDragEnd = useAtomCallback<void, [string | null, CollisionArea | null]>(async (get, set, activeId, over) => {
        if (!over) {
            return
        }

        const page = get(pageAtomFamily(pageId))
        const stack = get(pageStackAtomFamily({ rootPageId, stackId }))
        const blocks = get(blocksAtom)[pageId] ?? []

        const addingBlocksData = get(outsideDraggingNode)?.data

        let newBlock: BlockAbstract | undefined
        let originalBlocks: BlockAbstract[] | undefined
        let finalBlocks: BlockAbstract[] = []

        // 如果是从外部添加的，走创建
        if (addingBlocksData) {
            const dataSource = dataSources.find(item => item.id === page?.dsId)

            newBlock = clone(addingBlocksData)

            // 如果创建的是表单容器, 需要额外填充字段block
            if (newBlock.type === BlockType.formContainer && dataSource) {
                const fieldBlocks = padBlocksByFormContainer(dataSource, dataSources)

                newBlock.children = fieldBlocks
            }
        } else if (activeId) {
            newBlock = findBlockById(activeId, blocks)
        }

        if (!newBlock) {
            return
        }

        const overBlock = findBlockById(over.isVirtual ? over.parentId : over.id, blocks)

        // 表单容器、视图及图表block禁止插入到自定义视图内
        if (
            includeNotAllowedBlock(newBlock, [BlockType.formContainer, BlockType.view, BlockType.chart]) &&
            ((over.position === 'in' && overBlock && isCustomViewBlock(overBlock)) ||
                isCustomChildren(over.isVirtual ? over.parentId : over.id, blocks))
        ) {
            Toast.error('视图、图表、表单容器，不允许拖入自定义视图')
            return
        }

        // 子表单只能拖入到表单页面的表单容器中
        if (
            includeNotAllowedBlock(newBlock, [BlockType.subForm]) &&
            (page?.type !== PAGE_TYPE.creator ||
                (over.position === 'in' && overBlock && !isFormContainerBlock(overBlock)) ||
                !isFormContainerChildren(over.isVirtual ? over.parentId : over.id, blocks))
        ) {
            Toast.error('子表单只能拖入到表单页面的表单容器中')
            return
        }

        const addedBlocksIds = addingBlocksData ? collectBlockAndChildren(newBlock).map(item => item.id) : []
        if (addingBlocksData) {
            set(blockCreatingListAtom, s => [...s, ...addedBlocksIds])
        }

        set(blocksAtom, draft => {
            const pageBlocks = draft[pageId]
            originalBlocks = original(pageBlocks)
            if (!newBlock || !pageBlocks) {
                return
            }

            if (!addingBlocksData && activeId) {
                removeBlockById(activeId)(pageBlocks)
            }

            if (over.id === 'root') {
                pageBlocks.push(newBlock)
            } else if (over.position === 'in') {
                const overBlock = findBlockById(over.isVirtual ? over.parentId : over.id, pageBlocks)
                if (!overBlock) {
                    return
                }

                const children = getCurrentBlockChildren(overBlock, stack?.blockRuntimeState)
                if (!children) {
                    return
                }
                children.push(newBlock)
            } else {
                const overParentBlock = findParentBlockById(over.id, pageBlocks)

                if (overParentBlock) {
                    const children = getCurrentBlockChildren(overParentBlock, stack?.blockRuntimeState)
                    if (!children) {
                        return
                    }

                    const index = children.findIndex(item => item.id === over.id)
                    if (index === -1) {
                        return
                    }

                    children.splice(index + (over.position === 'before' ? 0 : 1), 0, newBlock)
                } else if (over.parentId === 'root') {
                    const index = pageBlocks.findIndex(item => item.id === over.id)
                    if (index === -1) {
                        return
                    }
                    pageBlocks.splice(index + (over.position === 'before' ? 0 : 1), 0, newBlock)
                }
            }
            finalBlocks = current(pageBlocks)
        })

        if (addingBlocksData) {
            await set(createBlockAtom, { rootPageId, pageId, stackId, createdBlocks: [newBlock], blocks: finalBlocks })

            set(blockCreatingListAtom, s => s.filter(id => !addedBlocksIds.includes(id)))

            pageUndoRedoController.add({
                action: BLOCK_NODE_CREATE_ACTION,
                payload: {
                    createdBlocks: [newBlock],
                    prev: originalBlocks,
                    next: finalBlocks
                }
            })
        } else {
            srv.updateBlock({ pageId, allBlocks: finalBlocks })
            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: { prev: originalBlocks, next: finalBlocks }
            })
        }
    })

    /** 碰撞时检测合法性 */
    const collisionAreaDetection = useAtomCallback<CollisionArea | null, [string | null, CollisionArea | undefined]>(
        (get, set, activeId, over) => {
            if (!over) {
                return null
            }

            // 忽略自身的相邻拖拽区域
            if (over.id === activeId) {
                return null
            }

            const blocks = get(blocksAtom)[pageId] || []
            const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

            let children: BlockAbstract[] = blocks
            const parentBlock = findBlockById(over.realParentId || over.parentId, blocks)
            if (parentBlock) {
                children = getCurrentBlockChildren(parentBlock, blockRuntimeState) || []
            }

            const index = children.findIndex(item => item.id === over.id)
            // 忽略前后是自身的情况
            if (
                (over.position === 'after' && children[index + 1]?.id === activeId) ||
                (over.position === 'before' && children[index - 1]?.id === activeId)
            ) {
                return null
            }

            if (activeId && isActiveInOver(activeId, over.id)(blocks)) {
                return null
            }

            // // 某些特定 block 有限制
            // const addingBlocksData = get(outsideDraggingNode)?.data
            // const activeBlock = addingBlocksData ?? (activeId ? findBlockById(activeId, blocks) : undefined)
            // if (!activeBlock) {
            //     return null
            // }

            // // 表单容器、视图及图表block禁止插入到自定义视图内
            // const overBlock = findBlockById(over.isVirtual ? over.parentId : over.id, blocks)
            // if (!overBlock) {
            //     return null
            // }

            // if (
            //     includeNotAllowedBlock(activeBlock, [BlockType.formContainer, BlockType.view, BlockType.chart]) &&
            //     ((over.position === 'in' && isCustomViewBlock(overBlock)) ||
            //         isCustomChildren(over.isVirtual ? over.parentId : over.id, blocks))
            // ) {
            //     return null
            // }

            return over
        }
    )

    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    /** block 尺寸拉伸的限制 */
    const getResizeRestrict = useAtomCallback<ResizeRestrict, [string]>((get, set, id) => {
        const blocks = get(pageBlocksAtom(pageId))
        const block = findBlockById(id, blocks)

        const restrict: ResizeRestrict = {
            direction: []
        }

        if (!block) {
            return restrict
        }

        restrict.minWidth = block.config.breakPoint.size.minWidth
        restrict.maxWidth = block.config.breakPoint.size.maxWidth
        restrict.minHeight = block.config.breakPoint.size.minHeight
        restrict.maxHeight = block.config.breakPoint.size.maxHeight

        const designOption = getBlockAndPageDesignLimit(isCustomViewBlock(block) ? 'customView' : block.type)

        if (!designOption.size?.disableWidth) {
            restrict.direction.push(DIRECTION.horizontal)
        }

        if (!designOption.size?.disableHeight && !designOption.size?.hideHeight) {
            restrict.direction.push(DIRECTION.vertical)
        }

        return restrict
    })

    const onResizeEnd = useAtomCallback<void, [string, ResizeEvent, boolean?]>((get, set, id, event, disableUndoRedo) => {
        const changeKey = event.direction === DIRECTION.horizontal ? 'width' : 'height'
        const data = {
            size: {
                [changeKey]: {
                    size: Math.round(event.size),
                    unit: 'px'
                }
            }
        }
        const previewType = get(previewTypeAtom)
        const blocks = get(blocksAtom)
        const pageBlocks = blocks[pageId] || []
        const originBlock = findBlockById(id, pageBlocks)
        if (originBlock) {
            const block: BlockAbstract = { ...originBlock }
            const { config } = block
            if (previewType === ApplicationPreviewEnum.desktop) {
                const newBlock = {
                    ...block,
                    config: {
                        ...config,
                        breakPoint: mergeDeepRight(config.breakPoint, data)
                    }
                } as BlockAbstract
                onUpdateBlock(newBlock, originBlock)
                pageUndoRedoController.add({
                    action: BLOCK_UPDATE_ACTION,
                    payload: { prev: originBlock, next: newBlock }
                })
                return
            }

            const index = findIndex(b => b.id === previewType, config.breakPoints)
            if (index < 0) {
                return
            }
            const base = config.breakPoint
            const currentBreakPoint = config.breakPoints[index]
            const breakPoint = mergeSingleBreakPointConfigure(base, currentBreakPoint)
            const keyPaths = [`size.${changeKey}`]
            Object.assign(breakPoint, {
                breakKeys: [...new Set([...(breakPoint.breakKeys || []), ...keyPaths])]
            })
            const mergeBreakPoint = mergeDeepRight<BaseBreakPointConfigProtocol>(breakPoint, data)
            const newBlock = {
                ...block,
                config: {
                    ...config,
                    breakPoints: [mergeBreakPoint]
                }
            } as BlockAbstract

            onUpdateBlock(newBlock, originBlock)
            if (!disableUndoRedo) {
                pageUndoRedoController.add({
                    action: BLOCK_UPDATE_ACTION,
                    payload: { prev: originBlock, next: newBlock }
                })
            }
        }
    })

    return { onDragEnd, collisionAreaDetection, getResizeRestrict, onResizeEnd }
}
