import { Button, IconFont, usePopoverHeight } from '@byecode/ui'
import type { FieldBlockWithDsId, ViewType } from '@lighthouse/core'
import { FilterMode } from '@lighthouse/core'
import type { FlowNode, VariableSource } from '@lighthouse/shared'
import {
    getAppointField,
    getIsFindUseInFilter,
    getViewColumns,
    notDataSettingInnerTypes,
    useAtomAction,
    useAtomData,
    useFindUseObjectContext,
    VariableSourceType,
    ViewFieldFilterConfigure} from '@lighthouse/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { getDataSourceMetaAtom } from '@/atoms/dataSource/action'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { PopoverButton } from '../PopoverButton'

const SCxContent = styled.div`
    padding: 0 16px;
`

const SCxButton = styled(Button)`
    margin: 8px 0;
`

const SCxDescription = styled.div`
    margin: 4px 0;
    color: var(--color-gray-500);
    line-height: 20px;
    font-size: var(--font-size-sm);
`

interface TriggerFilterProps {
    // allParentNodes: FlowNode[]
    sourceFieldFlowNode?: FlowNode[]
    dataSourceId?: string
    paramsMode?: FilterMode
    fieldMode?: FilterMode
    description?: string
    prefix: string
    filterName?: string
    formDsId?: string
    viewType?: ViewType
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    style?: React.CSSProperties
}

export const TriggerFilter: React.FC<TriggerFilterProps> = ({
    sourceFieldFlowNode,
    dataSourceId = '',
    fieldMode = FilterMode.NORMAL,
    paramsMode = FilterMode.NORMAL,
    filterName = 'filter',
    prefix,
    style,
    fieldBlocksWithDsId,
    formDsId,
    description
}) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const dataSource = useDataSource(appId, envId, dataSourceId)
    const { run: getDataSourceMeta } = useAtomAction(getDataSourceMetaAtom)
    const { control } = useFormContext()
    const findUseObject = useFindUseObjectContext()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const disabledWithVersion = useIsDisabledWithVersion()
    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const [open, setOpen] = useState(false)

    const { type } = useFlow()
    useEffect(() => {
        if (dataSourceId) {
            getDataSourceMeta({ envId, dsId: dataSourceId })
        }
    }, [appId, dataSourceId, envId, getDataSourceMeta])

    const columns = useMemo(
        () =>
            getViewColumns({
                tableProps: dataSource?.viewOptions?.tableProps || [],
                schema: dataSource?.schema || {}
            }),
        [dataSource?.schema, dataSource?.viewOptions?.tableProps]
    )

    const sources: VariableSource[] = useMemo(
        () =>
            type
                ? [
                      {
                          sourceType: VariableSourceType.parentNode,
                          parentNodes: sourceFieldFlowNode || [],
                          dataSourceList
                      }
                  ]
                : [
                      {
                          sourceType: VariableSourceType.parentPage,
                          dataSource: prev.datasource,
                          page: prev.page
                      },
                      {
                          sourceType: VariableSourceType.page,
                          dataSource: curr.datasource,
                          page: curr.page
                      }
                  ],
        [curr.datasource, curr.page, dataSourceList, prev.datasource, prev.page, sourceFieldFlowNode, type]
    )

    const primaryField = useMemo(() => {
        if (!dataSource) {
            return columns[0]?.fieldId
        }
        const field = getAppointField(dataSource, 'ID')
        return field?.id || columns[0]?.fieldId
    }, [columns, dataSource])

    const { height, ref } = usePopoverHeight<HTMLButtonElement>(open)

    return (
        <SCxContent style={style}>
            {description && <SCxDescription>{description}</SCxDescription>}
            <Controller
                name={`${prefix}.${filterName}`}
                control={control}
                render={({ field: { onChange, value } }) => {
                    const filter = value
                    const filtersLength = filter?.expression?.conditions?.length || 0
                    const isHighLight = getIsFindUseInFilter({
                        data: filter,
                        findUseObject,
                        currentDsId: dataSourceId,
                        allParentNodes: sourceFieldFlowNode
                    })
                    return (
                        <PopoverButton
                            withinPortal
                            width="auto"
                            opened={open}
                            onChange={setOpen}
                            positionDependencies={[filter]}
                            popupContent={
                                <ViewFieldFilterConfigure
                                    maxHeight={height}
                                    dataSource={dataSource}
                                    dataSourceList={dataSourceList}
                                    sources={sources}
                                    fieldBlocksWithDsId={fieldBlocksWithDsId}
                                    formDsId={formDsId}
                                    paramsMode={paramsMode}
                                    fieldMode={fieldMode}
                                    columns={columns}
                                    primaryField={primaryField}
                                    filter={filter}
                                    disabled={disabledWithVersion}
                                    noSettingInnerType={notDataSettingInnerTypes}
                                    onFilter={onChange}
                                />
                            }
                        >
                            <SCxButton
                                ref={ref}
                                highlighting={isHighLight}
                                icon={<IconFont type="Setting" size={16} color="var(--color-gray-400)" />}
                                block
                            >
                                {filtersLength > 0 ? `已设 ${filtersLength} 个条件` : '设置条件'}
                            </SCxButton>
                        </PopoverButton>
                    )
                }}
            />
        </SCxContent>
    )
}
