import { Button } from '@byecode/ui/components/Button'
import { Flex } from '@byecode/ui/components/Flex'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Text } from '@byecode/ui/components/Text'
import type { QuotaProtocols, SpaceVersion } from '@lighthouse/core'
import { getFileSizeAccordanceBaseUnit } from '@lighthouse/tools'
import React from 'react'

import type { SpaceQuota } from '@/services/types'

import { openSpaceGrade } from '../SpaceGrade'
import { FileQuotaUpdateButton } from './FileQuotaUpdateButton'

type SpaceQuotaKeysWithTypeNumber<T> = {
    [K in keyof T as T[K] extends QuotaProtocols ? K : never]: T[K]
}

type NumberKeysOfSpaceQuota = keyof SpaceQuotaKeysWithTypeNumber<SpaceQuota>

type DosageItem = {
    id: NumberKeysOfSpaceQuota
    icon: string
    label: string
    primaryColor: string
    toolTipLabel: string | React.ReactNode
    warningColor: string
    renderUsageLabel: (usage: number) => React.ReactNode
    renderQuotaLabel: (usage: number, quota: number) => React.ReactNode
    extra?: React.ReactNode
}

export const dosageList: DosageItem[] = [
    // {
    //     icon: 'BlockApp',
    //     id: 'numberOfApps',
    //     label: '应用数量',
    //     primaryColor: 'var(--color-gray-600)',
    //     toolTipLabel: '空间中可创建的应用数量',
    //     warningColor: 'var(--color-yellow-500)',
    //     renderUsageLabel: usage => usage,
    //     renderQuotaLabel: (usage, quota) => quota
    // },
    {
        icon: 'Publish',
        id: 'numberOfPublishedApps',
        label: '可发布应用数量',
        primaryColor: 'var(--color-gray-600)',
        toolTipLabel: '',
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => usage.toLocaleString(),
        renderQuotaLabel: (usage, quota) => quota.toLocaleString()
    },
    {
        icon: 'PropertyPerson',
        id: 'numberOfUv',
        label: '访客数量(UV)',
        primaryColor: 'var(--color-gray-600)',
        toolTipLabel: '',
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => usage.toLocaleString(),
        renderQuotaLabel: (usage, quota) => quota.toLocaleString()
    },
    {
        icon: 'ActiveNode-Website',
        id: 'numberOfDomain',
        label: '绑定自有域名',
        primaryColor: 'var(--color-gray-600)',
        toolTipLabel: '绑定自己的域名。',
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => usage,
        renderQuotaLabel: (usage, quota) => quota
    },
    {
        icon: 'SpaceTeam',
        id: 'numberOfUsers',
        label: '登录用户数量',
        primaryColor: 'var(--color-gray-600)',
        toolTipLabel: '',
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => usage,
        renderQuotaLabel: (usage, quota) => quota
    },
    {
        icon: 'Email2',
        id: 'sms',
        label: 'ByeCode验证码短信',
        toolTipLabel: '',
        primaryColor: 'var(--color-gray-600)',
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => usage.toLocaleString(),
        renderQuotaLabel: (usage, quota) => quota.toLocaleString(),
        extra: (
            <Flex alignItems="center" onClick={() => openSpaceGrade('purchase')} style={{ cursor: 'pointer' }}>
                <Text color="var(--color-main)" size={12} lineHeight="16px">
                    充值
                </Text>
                <IconFont type="UploadSimple" color="var(--color-main)" />
            </Flex>
        )
    },
    {
        icon: 'Server',
        id: 'numberOfCu',
        label: '算力（cu）',
        primaryColor: 'var(--color-gray-600)',
        toolTipLabel: (
            <div style={{ lineHeight: '20px', width: 240 }}>算力：Computer Unit（cu），计算查询数据表的成本。算力不计算增删改数据。</div>
        ),
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => `${usage.toLocaleString()} cu` ,
        renderQuotaLabel: (usage, quota) => `${quota.toLocaleString()}   每月`
    },
    {
        icon: 'Folder',
        id: 'fileStorage',
        label: '附件储存',
        primaryColor: 'var(--color-gray-600)',
        toolTipLabel: '',
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => getFileSizeAccordanceBaseUnit(usage, 'MB').replace('.0', ''),
        renderQuotaLabel: (usage, quota) => getFileSizeAccordanceBaseUnit(quota, 'MB').replace('.0', ''),
        // extra: (
        //     <FileQuotaUpdateButton />
        // )
    },
    {
        icon: 'NewTableBlock',
        id: 'numberOfRecords',
        label: '数据表总记录数',
        primaryColor: 'var(--color-gray-600)',
        toolTipLabel: '',
        warningColor: 'var(--color-yellow-500)',
        renderUsageLabel: usage => usage.toLocaleString(),
        renderQuotaLabel: (usage, quota) => quota.toLocaleString()
    },
    // {
    //     icon: 'BigTable',
    //     id: 'numberOfWorkflowExecutions',
    //     label: '工作流运行次数',
    //     primaryColor: 'var(--color-gray-600)',
    //     toolTipLabel: '',
    //     warningColor: 'var(--color-yellow-500)',
    //     renderUsageLabel: usage => usage.toLocaleString(),
    //     renderQuotaLabel: (usage, quota) => `${quota.toLocaleString()} 每月`
    // },

    // {
    //     icon: 'Departments',
    //     id: 'apiInvoke',
    //     label: 'API 调用次数',
    //     primaryColor: 'var(--color-gray-600)',
    //     toolTipLabel: '',
    //     warningColor: 'var(--color-yellow-500)',
    //     renderUsageLabel: usage => usage.toLocaleString(),
    //     renderQuotaLabel: (usage, quota) => `${quota.toLocaleString()} 每月`
    // }

    // {
    //     icon: 'ArrowsClockwise',
    //     id: 'numberOfUpdates',
    //     label: '更新次数',
    //     primaryColor: 'var(--color-gray-600)',
    //     toolTipLabel: '数据表数据和页面元素的更新次数',
    //     warningColor: 'var(--color-yellow-500)',
    //     renderUsageLabel: usage => usage.toLocaleString(),
    //     renderQuotaLabel: (usage, quota) => `${quota.toLocaleString()} 每月`
    // },
]

export const spaceGradeFontColorMap: Record<SpaceVersion, string> = {
    ULTIMATE: 'rgba(147, 55, 13, 1)',
    ENTERPRISE: 'rgba(161, 16, 67, 1)',
    PROFESSIONAL: 'rgba(24, 73, 169, 1)',
    ADVANCED: 'rgba(41, 36, 143, 1)',
    STANDARD: 'rgba(85, 81, 255, 1)',
    BASIC: 'var(--color-gray-500)',
    FREE: 'var(--color-gray-500)',
    I_CLICK_ULTIMATE: 'rgba(147, 55, 13, 1)',
    I_CLICK_ENTERPRISE: 'rgba(161, 16, 67, 1)',
    I_CLICK_PROFESSIONAL: 'rgba(24, 73, 169, 1)',
    I_CLICK_ADVANCED: 'rgba(41, 36, 143, 1)',
    I_CLICK_STANDARD: 'rgba(85, 81, 255, 1)',
    I_CLICK_BASIC: 'var(--color-gray-500)',
    I_CLICK_FREE: 'var(--color-gray-500)'
}

export const spaceGradeButtonBackgroundMap: Record<SpaceVersion, string> = {
    ULTIMATE: 'linear-gradient(90deg, #FDF1E6 1.25%, #FCEBD7 100%)',
    ENTERPRISE: 'linear-gradient(90deg, #FFF5F7 1.25%, #FFDEE6 100%)',
    PROFESSIONAL: 'linear-gradient(90deg, #D2EAFF 1.25%, #D9E9FF 100%)',
    ADVANCED: 'linear-gradient(90deg, #FAFAFF 1.25%, #EAEAFF 100%)',
    STANDARD: 'linear-gradient(90deg, #F1F4F8 1.25%, #ECEFF4 100%)',
    BASIC: 'linear-gradient(90deg, #F1F4F8 1.25%, #ECEFF4 100%)',
    FREE: 'linear-gradient(90deg, #F1F4F8 1.25%, #ECEFF4 100%)',
    I_CLICK_ULTIMATE: 'linear-gradient(90deg, #FDF1E6 1.25%, #FCEBD7 100%)',
    I_CLICK_ENTERPRISE: 'linear-gradient(90deg, #FFF5F7 1.25%, #FFDEE6 100%)',
    I_CLICK_PROFESSIONAL: 'linear-gradient(90deg, #D2EAFF 1.25%, #D9E9FF 100%)',
    I_CLICK_ADVANCED: 'linear-gradient(90deg, #FAFAFF 1.25%, #EAEAFF 100%)',
    I_CLICK_STANDARD: 'linear-gradient(90deg, #F1F4F8 1.25%, #ECEFF4 100%)',
    I_CLICK_BASIC: 'linear-gradient(90deg, #F1F4F8 1.25%, #ECEFF4 100%)',
    I_CLICK_FREE: 'linear-gradient(90deg, #F1F4F8 1.25%, #ECEFF4 100%)'
}
