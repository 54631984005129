import type { SpaceVersion } from '@lighthouse/core'
import React from 'react'

import { formatSpaceFeaturesValue } from './utils'

/** 空间等级功能权益 */
export type SpaceLevelFeatures = {
    name: string
    price: number
    /** ******************** 空间 ******************** */
    /** 可发布应用 */
    publishedAppLimit: number

    /** ******************** 应用 ******************** */
    /** 分享与 百度 SEO */
    baiduSEO: boolean
    /** 访客数量(UV) */
    accessUserLimit: number
    /** 访问流量 */
    accessTrafficLimit: number
    /** 流量增购 */
    trafficPurchase: boolean
    /** 多语言版本 */
    multiLanguage: boolean

    /** ******************** 应用分析 ******************** */
    /** 应用访问统计分析 */
    accessStatistics: boolean
    /** 页面访问统计分析 */
    pageStatistics: boolean
    /** 自定义事件采集 */
    customEventCollection: boolean

    /** ******************** 应用发布 ******************** */
    /** 发布至网络 */
    publish: boolean
    /** 绑定自定义域名 */
    bindCustomDomainLimit: number
    /** 自定义子域名 */
    customSubDomain: boolean
    /** 此功能使您的应用可在任何移动端设备上下载，而无需经过应用商店批准。 */
    pwa: boolean
    /** 关闭右下角 ByeCode logo */
    hideByecodeLogo: boolean
    /** 隐藏底部制作信息 */
    hideByecodeFooter: boolean

    /** ******************** 应用用户 ******************** */
    /** 登录用户数量 */
    loggedUserLimit: number
    /** 自定义用户角色及部门 */
    customUserRolesAndDepartments: boolean
    /** 邮箱验证码登录 */
    emailLogin: boolean
    /** 短信验证码登录 */
    smsLogin: boolean
    /** ByeCode验证码短信数量 */
    smsCount: number
    /** 增购ByeCode验证码短信 */
    smsPurchase: boolean
    /** 微信登录 */
    wechatLogin: boolean

    /** ******************** 数据 ******************** */
    /** 算力（cu） */
    computingPower: number
    /** 算力增购 */
    computingPurchase: boolean
    /** 更新次数 */
    dataUpdateLimit: number
    /** 数据导入导出（支持Excel,CSV） */
    dataExport: boolean
    /** 附件存储 */
    fileStorage: number
    /** 附件存储增购 */
    fileStoragePurchase: boolean
    /** 单个附件大小 */
    singleFileSizeLimit: number
    /** 数据表总记录数 */
    totalRecordsLimit: number
    /** 单表数据量 */
    singleTableRecordsLimit: number
    /** 数据批量处理能力 */
    dataBatchProcessing: boolean
    /** 关联表、聚合表、同步表 */
    advanceTable: boolean
    /** 数据源环境 */
    dataEnv: boolean

    /** ******************** 页面 ******************** */
    /** 页面数量 */
    pageLimit: number
    /** 数据页面 */
    viewPage: boolean
    /** 视图组件 */
    viewComponent: boolean
    /** 表单组件 */
    formComponent: boolean
    /** 基础组件 */
    basicComponent: boolean
    /** 操作组件 */
    operationComponent: boolean
    /** 媒体组件 */
    mediaComponent: boolean
    /** 图表组件 */
    chartComponent: boolean
    /** 内容展示组件 */
    displayComponent: boolean

    /** ******************** 工作流 ******************** */
    /** 自动化 */
    automation: boolean
    /** 动作流 */
    actionFLow: boolean
    /** 审批流 */
    approvalFlow: boolean
    /** 工作流运行次数 */
    scheduledWorkflowLimit: number
    /** 工作流运行次数增购 */
    scheduledWorkflowPurchase: boolean
    /** 运行日志保留时长 */
    runLogExpired: number

    /** ******************** 集成 ******************** */
    /** 微信支付 */
    wechatPay: boolean
    /** stripe支付 */
    stripe: boolean
    /** 企业微信机器人 */
    wechatBusinessRobot: boolean
    /** 钉钉 */
    dingTalk: boolean
    /** 短信 */
    sms: boolean
    /** 邮箱 */
    email: boolean

    /** ******************** API ******************** */
    /** API调用次数 */
    apiLimit: number

    /** ******************** 服务支持 ******************** */
    /** 免费模板 */
    template: boolean
    /** 社群咨询答疑 */
    qa: boolean
    /** 1v1专属客户成功专家 */
    dedicatedCustomerSuccessExpert: boolean
    /** 团队培训 */
    teamTraining: boolean
}

type TableStructRowColumn = {
    title: string
    dataKey: keyof SpaceLevelFeatures
    tooltip?: string
    format?: (data: SpaceLevelFeatures, key: keyof SpaceLevelFeatures) => React.ReactNode
}
type TableStruct = {
    title: string
    children: TableStructRowColumn[]
}

export const TABLE_STRUCT: TableStruct[] = [
    {
        title: '空间',
        children: [
            {
                title: '可发布应用数',
                dataKey: 'publishedAppLimit'
            }
        ]
    },

    {
        title: '应用',
        children: [
            {
                title: '分享与 百度 SEO',
                tooltip: '让应用可以被百度搜索引擎搜索到。',
                dataKey: 'baiduSEO'
            },
            {
                title: '访客数量(UV)',
                dataKey: 'accessUserLimit',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}/月</span> : value
                }
            },
            {
                title: '访问流量',
                tooltip: '访问应用页面、文件、图片、音频、视频等过程中产生的流量。',
                dataKey: 'accessTrafficLimit',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}GB/年</span> : value
                }
            },
            {
                title: '流量增购',
                dataKey: 'trafficPurchase'
            },
            {
                title: '多语言版本',
                tooltip: '支持任意语言版本。',
                dataKey: 'multiLanguage'
            }
        ]
    },
    {
        title: '应用分析',
        children: [
            {
                title: '应用访问统计分析',
                dataKey: 'accessStatistics'
            },
            {
                title: '页面访问统计分析',
                dataKey: 'pageStatistics'
            },
            {
                title: '自定义事件采集',
                dataKey: 'customEventCollection'
            }
        ]
    },
    {
        title: '应用发布',
        children: [
            {
                title: '发布至网络',
                dataKey: 'publish'
            },
            {
                title: '绑定自定义域名',
                tooltip: '绑定自己的域名。',
                dataKey: 'bindCustomDomainLimit'
            },
            {
                title: '自定义子域名',
                tooltip: '为了方便您快速上线，ByeCode 提供发布至 (自定义).byecode.site 的功能。',
                dataKey: 'customSubDomain'
            },
            {
                title: '可下载移动&PC端 app（PWA)',
                tooltip: '此功能使您的应用可在任何移动端设备上下载，而无需经过应用商店批准。',
                dataKey: 'pwa'
            },
            {
                title: '关闭右下角 ByeCode logo',
                dataKey: 'hideByecodeLogo'
            },
            {
                title: '隐藏底部制作信息',
                dataKey: 'hideByecodeFooter'
            }
        ]
    },
    {
        title: '应用用户',
        children: [
            {
                title: '登录用户数量',
                dataKey: 'loggedUserLimit'
            },
            {
                title: '自定义用户角色及部门',
                dataKey: 'customUserRolesAndDepartments'
            },
            {
                title: '邮箱验证码登录',
                dataKey: 'emailLogin'
            },
            {
                title: '短信验证码登录',
                dataKey: 'smsLogin'
            },
            {
                title: '微信登录',
                dataKey: 'wechatLogin'
            },
            {
                title: 'ByeCode验证码短信数量',
                dataKey: 'smsCount'
            },
            {
                title: '增购ByeCode验证码短信',
                dataKey: 'smsPurchase'
            }
        ]
    },
    {
        title: '数据',
        children: [
            {
                title: '算力（cu）',
                tooltip: '算力，或 Computer Unit（cu)，计算查询数据表的成本。算力不计算增删改数据。',
                dataKey: 'computingPower',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}cu/月</span> : value
                }
            },
            {
                title: '算力增购',
                dataKey: 'computingPurchase'
            },
            {
                title: '数据导入导出（支持Excel,CSV）',
                dataKey: 'dataExport'
            },
            {
                title: '附件存储',
                dataKey: 'fileStorage',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}GB</span> : value
                }
            },
            {
                title: '附件存储增购',
                dataKey: 'fileStoragePurchase'
            },
            {
                title: '单个附件大小',
                dataKey: 'singleFileSizeLimit',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}MB</span> : value
                }
            },
            {
                title: '数据表总记录数',
                dataKey: 'totalRecordsLimit'
            },
            {
                title: '单表数据量',
                dataKey: 'singleTableRecordsLimit'
            },
            {
                title: '数据批量处理能力',
                dataKey: 'dataBatchProcessing'
            },
            {
                title: '关联表、聚合表、同步表',
                dataKey: 'advanceTable'
            },
            {
                title: '数据源环境',
                dataKey: 'dataEnv'
            },
        ]
    },

    {
        title: '工作流',
        children: [
            {
                title: '自动化',
                dataKey: 'automation'
            },
            {
                title: '动作流',
                dataKey: 'actionFLow'
            },
            {
                title: '审批流',
                dataKey: 'approvalFlow'
            },
            {
                title: '工作流运行次数',
                dataKey: 'scheduledWorkflowLimit',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}/月</span> : value
                }
            },
            {
                title: '工作流运行次数增购',
                dataKey: 'scheduledWorkflowPurchase'
            },
            {
                title: '运行日志保留时长',
                dataKey: 'runLogExpired',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}天</span> : value
                }
            }
        ]
    },
    {
        title: '集成',
        children: [
            {
                title: '微信支付',
                dataKey: 'wechatPay'
            },
            {
                title: 'stripe支付',
                dataKey: 'stripe'
            },
            {
                title: '企业微信机器人',
                dataKey: 'wechatBusinessRobot'
            },
            {
                title: '钉钉',
                dataKey: 'dingTalk'
            },
            {
                title: '短信',
                tooltip: '阿里云短信',
                dataKey: 'sms'
            },
            {
                title: '邮箱',
                tooltip: '腾讯企业邮箱、126网易邮箱',
                dataKey: 'email'
            }
        ]
    },
    {
        title: 'API',
        children: [
            {
                title: 'API调用次数',
                dataKey: 'apiLimit',
                format(data, key) {
                    const { value, isNumber } = formatSpaceFeaturesValue(data[key])
                    return isNumber ? <span style={{ fontFamily: 'DIN-Alternate-Bold' }}>{value}/月</span> : value
                }
            }
        ]
    },
    {
        title: '服务支持',
        children: [
            {
                title: '免费模板',
                dataKey: 'template'
            },
            {
                title: '社群咨询答疑',
                dataKey: 'qa'
            },
            {
                title: '1v1专属客户成功专家',
                dataKey: 'dedicatedCustomerSuccessExpert'
            },
            {
                title: '团队培训',
                dataKey: 'teamTraining'
            }
        ]
    },
    {
        title: '页面',
        children: [
            {
                title: '页面数量',
                dataKey: 'pageLimit'
            },
            {
                title: '数据页面',
                dataKey: 'viewPage'
            },
            {
                title: '视图组件',
                dataKey: 'viewComponent'
            },
            {
                title: '表单组件',
                dataKey: 'formComponent'
            },
            {
                title: '基础组件',
                dataKey: 'basicComponent'
            },
            {
                title: '操作组件',
                dataKey: 'operationComponent'
            },
            {
                title: '媒体组件',
                dataKey: 'mediaComponent'
            },
            {
                title: '图表组件',
                dataKey: 'chartComponent'
            },
            {
                title: '内容展示组件',
                dataKey: 'displayComponent'
            }
        ]
    }
]

export const SPACE_LEVEL_DATA: (SpaceLevelFeatures & { version: SpaceVersion })[] = [
    {
        version: 'FREE',
        name: '免费版',
        price: 0,
        publishedAppLimit: 1,
        baiduSEO: false,
        accessUserLimit: 500,
        accessTrafficLimit: 3,
        trafficPurchase: false,
        multiLanguage: false,
        accessStatistics: false,
        pageStatistics: false,
        customEventCollection: false,
        publish: true,
        bindCustomDomainLimit: 0,
        customSubDomain: true,
        pwa: false,
        hideByecodeLogo: false,
        hideByecodeFooter: false,
        loggedUserLimit: 10,
        customUserRolesAndDepartments: true,
        smsLogin: true,
        smsCount: 10,
        smsPurchase: true,
        wechatLogin: false,
        emailLogin: true,
        computingPower: 10,
        computingPurchase: false,
        dataUpdateLimit: 10000,
        dataExport: true,
        fileStorage: 1,
        fileStoragePurchase: false,
        singleFileSizeLimit: 5,
        totalRecordsLimit: 500,
        singleTableRecordsLimit: 500,
        dataBatchProcessing: false,
        advanceTable: true,
        dataEnv: false,
        pageLimit: -1,
        viewPage: true,
        viewComponent: true,
        formComponent: true,
        basicComponent: true,
        operationComponent: true,
        mediaComponent: true,
        chartComponent: true,
        displayComponent: true,
        automation: true,
        actionFLow: true,
        approvalFlow: false,
        scheduledWorkflowLimit: 100,
        scheduledWorkflowPurchase: false,
        runLogExpired: 7,
        wechatPay: false,
        stripe: false,
        wechatBusinessRobot: false,
        dingTalk: false,
        sms: true,
        email: true,
        apiLimit: 100,
        template: true,
        qa: true,
        dedicatedCustomerSuccessExpert: false,
        teamTraining: false
    },
    {
        version: 'BASIC',
        name: '基础版',
        price: 360,
        publishedAppLimit: 1,
        baiduSEO: true,
        accessUserLimit: 10000,
        accessTrafficLimit: 30,
        trafficPurchase: true,
        multiLanguage: false,
        accessStatistics: true,
        pageStatistics: false,
        customEventCollection: false,
        publish: true,
        bindCustomDomainLimit: 1,
        customSubDomain: true,
        pwa: false,
        hideByecodeLogo: true,
        hideByecodeFooter: false,
        loggedUserLimit: 20,
        customUserRolesAndDepartments: true,
        smsLogin: true,
        smsCount: 50,
        smsPurchase: true,
        wechatLogin: false,
        emailLogin: true,
        computingPower: 100,
        computingPurchase: false,
        dataUpdateLimit: -1,
        dataExport: true,
        fileStorage: 5,
        fileStoragePurchase: false,
        singleFileSizeLimit: 20,
        totalRecordsLimit: 2000,
        singleTableRecordsLimit: 2000,
        dataBatchProcessing: false,
        advanceTable: true,
        dataEnv: false,
        pageLimit: -1,
        viewPage: true,
        viewComponent: true,
        formComponent: true,
        basicComponent: true,
        operationComponent: true,
        mediaComponent: true,
        chartComponent: true,
        displayComponent: true,
        automation: true,
        actionFLow: true,
        approvalFlow: false,
        scheduledWorkflowLimit: 500,
        scheduledWorkflowPurchase: false,
        runLogExpired: 7,
        wechatPay: false,
        stripe: false,
        wechatBusinessRobot: false,
        dingTalk: false,
        sms: true,
        email: true,
        apiLimit: 500,
        template: true,
        qa: true,
        dedicatedCustomerSuccessExpert: false,
        teamTraining: false
    },
    {
        version: 'STANDARD',
        name: '标准版',
        price: 1500,
        publishedAppLimit: 1,
        baiduSEO: true,
        accessUserLimit: 200000,
        accessTrafficLimit: 100,
        trafficPurchase: true,
        multiLanguage: true,
        accessStatistics: true,
        pageStatistics: true,
        customEventCollection: false,
        publish: true,
        bindCustomDomainLimit: 1,
        customSubDomain: true,
        pwa: false,
        hideByecodeLogo: true,
        hideByecodeFooter: true,
        loggedUserLimit: 100,
        customUserRolesAndDepartments: true,
        smsLogin: true,
        smsCount: 200,
        smsPurchase: true,
        wechatLogin: false,
        emailLogin: true,
        computingPower: 1000,
        computingPurchase: false,
        dataUpdateLimit: -1,
        dataExport: true,
        fileStorage: 100,
        fileStoragePurchase: false,
        singleFileSizeLimit: 100,
        totalRecordsLimit: 10000,
        singleTableRecordsLimit: 10000,
        dataBatchProcessing: false,
        advanceTable: true,
        dataEnv: false,
        pageLimit: -1,
        viewPage: true,
        viewComponent: true,
        formComponent: true,
        basicComponent: true,
        operationComponent: true,
        mediaComponent: true,
        chartComponent: true,
        displayComponent: true,
        automation: true,
        actionFLow: true,
        approvalFlow: false,
        scheduledWorkflowLimit: 5000,
        scheduledWorkflowPurchase: false,
        runLogExpired: 7,
        wechatPay: false,
        stripe: false,
        wechatBusinessRobot: false,
        dingTalk: false,
        sms: true,
        email: true,
        apiLimit: 2000,
        template: true,
        qa: true,
        dedicatedCustomerSuccessExpert: false,
        teamTraining: false
    },
    {
        version: 'ADVANCED',
        name: '高级版',
        price: 6800,
        publishedAppLimit: 2,
        baiduSEO: true,
        accessUserLimit: -1,
        accessTrafficLimit: 1000,
        trafficPurchase: true,
        multiLanguage: true,
        accessStatistics: true,
        pageStatistics: true,
        customEventCollection: true,
        publish: true,
        bindCustomDomainLimit: 1,
        customSubDomain: true,
        pwa: false,
        hideByecodeLogo: true,
        hideByecodeFooter: true,
        loggedUserLimit: -1,
        customUserRolesAndDepartments: true,
        smsLogin: true,
        smsCount: 1000,
        smsPurchase: true,
        wechatLogin: true,
        emailLogin: true,
        computingPower: 10000,
        computingPurchase: true,
        dataUpdateLimit: -1,
        dataExport: true,
        fileStorage: 500,
        fileStoragePurchase: true,
        singleFileSizeLimit: 300,
        totalRecordsLimit: 500000,
        singleTableRecordsLimit: 300000,
        dataBatchProcessing: false,
        advanceTable: true,
        dataEnv: true,
        pageLimit: -1,
        viewPage: true,
        viewComponent: true,
        formComponent: true,
        basicComponent: true,
        operationComponent: true,
        mediaComponent: true,
        chartComponent: true,
        displayComponent: true,
        automation: true,
        actionFLow: true,
        approvalFlow: true,
        scheduledWorkflowLimit: 50000,
        scheduledWorkflowPurchase: false,
        runLogExpired: 30,
        wechatPay: true,
        stripe: true,
        wechatBusinessRobot: true,
        dingTalk: true,
        sms: true,
        email: true,
        apiLimit: 10000,
        template: true,
        qa: true,
        dedicatedCustomerSuccessExpert: false,
        teamTraining: true
    },
    {
        version: 'PROFESSIONAL',
        name: '专业版',
        price: 12800,
        publishedAppLimit: 5,
        baiduSEO: true,
        accessUserLimit: -1,
        accessTrafficLimit: 2000,
        trafficPurchase: true,
        multiLanguage: true,
        accessStatistics: true,
        pageStatistics: true,
        customEventCollection: true,
        publish: true,
        bindCustomDomainLimit: 2,
        customSubDomain: true,
        pwa: true,
        hideByecodeLogo: true,
        hideByecodeFooter: true,
        loggedUserLimit: -1,
        customUserRolesAndDepartments: true,
        smsLogin: true,
        smsCount: 2000,
        smsPurchase: true,
        wechatLogin: true,
        emailLogin: true,
        computingPower: 100000,
        computingPurchase: true,
        dataUpdateLimit: -1,
        dataExport: true,
        fileStorage: 1000,
        fileStoragePurchase: true,
        singleFileSizeLimit: -1,
        totalRecordsLimit: 1000000,
        singleTableRecordsLimit: 750000,
        dataBatchProcessing: true,
        advanceTable: true,
        dataEnv: true,
        pageLimit: -1,
        viewPage: true,
        viewComponent: true,
        formComponent: true,
        basicComponent: true,
        operationComponent: true,
        mediaComponent: true,
        chartComponent: true,
        displayComponent: true,
        automation: true,
        actionFLow: true,
        approvalFlow: true,
        scheduledWorkflowLimit: 100000,
        scheduledWorkflowPurchase: false,
        runLogExpired: 180,
        wechatPay: true,
        stripe: true,
        wechatBusinessRobot: true,
        dingTalk: true,
        sms: true,
        email: true,
        apiLimit: 50000,
        template: true,
        qa: true,
        dedicatedCustomerSuccessExpert: true,
        teamTraining: true
    },
    {
        version: 'ENTERPRISE',
        name: '旗舰版',
        price: -1,
        publishedAppLimit: -1,
        baiduSEO: true,
        accessUserLimit: -1,
        accessTrafficLimit: -2,
        trafficPurchase: true,
        multiLanguage: true,
        accessStatistics: true,
        pageStatistics: true,
        customEventCollection: true,
        publish: true,
        bindCustomDomainLimit: 5,
        customSubDomain: true,
        pwa: true,
        hideByecodeLogo: true,
        hideByecodeFooter: true,
        loggedUserLimit: -1,
        customUserRolesAndDepartments: true,
        smsLogin: true,
        smsCount: -2,
        smsPurchase: true,
        wechatLogin: true,
        emailLogin: true,
        computingPower: -2,
        computingPurchase: true,
        dataUpdateLimit: -1,
        dataExport: true,
        fileStorage: -2,
        fileStoragePurchase: true,
        singleFileSizeLimit: -1,
        totalRecordsLimit: -1,
        singleTableRecordsLimit: -1,
        dataBatchProcessing: true,
        advanceTable: true,
        dataEnv: true,
        pageLimit: -1,
        viewPage: true,
        viewComponent: true,
        formComponent: true,
        basicComponent: true,
        operationComponent: true,
        mediaComponent: true,
        chartComponent: true,
        displayComponent: true,
        automation: true,
        actionFLow: true,
        approvalFlow: true,
        scheduledWorkflowLimit: -2,
        scheduledWorkflowPurchase: true,
        runLogExpired: -2,
        wechatPay: true,
        stripe: true,
        wechatBusinessRobot: true,
        dingTalk: true,
        sms: true,
        email: true,
        apiLimit: -1,
        template: true,
        qa: true,
        dedicatedCustomerSuccessExpert: true,
        teamTraining: true
    }
]
