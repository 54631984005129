import type { NodeRenderProps } from '@lighthouse/shared'
import { findBlockById, getBlockWithMergedBreakPoint, useAtomData } from '@lighthouse/shared'
import React, { useMemo } from 'react'

import { pageBlocksAtom } from '@/atoms/page/state'
import BlockRenderController from '@/blockControllers'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { usePreviewType } from '@/hooks/useApplication'
import { useBlockActions } from '@/hooks/useBlock'

export const BlockRender = ({ node, children }: NodeRenderProps) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const previewType = usePreviewType()
    const blocks = useAtomData(pageBlocksAtom(pageId))

    const finalBlock = useMemo(() => {
        const block = findBlockById(node.id, blocks)
        if (!block) {
            return
        }
        return getBlockWithMergedBreakPoint(previewType, block)
    }, [blocks, node.id, previewType])



    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    // if (!isVisible) {
    //     return null
    // }

    if (!finalBlock) {
        return children as JSX.Element
    }

    return (
        <>
            <BlockRenderController blockData={finalBlock} node={node} onBlockChange={onUpdateBlock}>
                {children}
            </BlockRenderController>
        </>
    )
}
