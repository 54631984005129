import { type DataSourceAbstract, PAGE_TYPE } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import { getMainTableRecordId, useAtomData, VariableSourceType } from '@lighthouse/shared'
import { omit } from 'rambda'
import { useCallback, useMemo } from 'react'

import { pageStackOfFieldBlockAtom } from '@/atoms/application/state'
import {
    defaultPageListAtom,
    formPageListAtom,
    pageAtomFamily,
    pageListAtom,
    pageStackAtom,
    pageStackAtomFamily,
    recordEditPageListAtom,
    recordPageListAtom
} from '@/atoms/page/state'
import type { PageMetaData, PageStackTree } from '@/atoms/page/types'
import { useRootPageContext } from '@/contexts/PageContext'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'
import { useDataSourceList } from './useDataSource'

export const usePageList = () => {
    return useAtomData(pageListAtom)
}

export const useDefaultPageList = () => {
    return useAtomData(defaultPageListAtom)
}

export const useRecordPageList = () => {
    return useAtomData(recordPageListAtom)
}

export const useRecordEditPageList = () => {
    return useAtomData(recordEditPageListAtom)
}

export const useFormPageList = () => {
    return useAtomData(formPageListAtom)
}

export const usePage = (id: string) => {
    return useAtomData(pageAtomFamily(id))
}

/**
 * 为数据选择器提供的页面数据，根据页面类型区分上游/当前页面数据
 * 1. 详情页和编辑页取当前页的数据
 * 2. 表单页取上游页面的数据
 * 3. 通过按钮动作打开的页面可能会携带recordId，这时也要取上游页面，但可能上游页面没有数据，需要根据传入的recordId判断dataSource的逻辑
 */
export const usePageDataSourceForVariableSelector = ({
    pageId,
    stackId,
    rootPageId: propRootPageId
}: {
    stackId: string
    pageId: string
    rootPageId?: string
}): {
    dataSourceList: DataSourceAbstract[]
    prev: PrevPageDatasourceForVariable
    curr: CurrPageDatasourceForVariable
} => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)

    const { rootPageId: contextRootPageId } = useRootPageContext()
    const rootPageId = propRootPageId || contextRootPageId

    const page = useAtomData(pageAtomFamily(pageId))
    const isRecordPage = page?.type === PAGE_TYPE.document || page?.type === PAGE_TYPE.edit
    const pageStack = useAtomData(pageStackAtomFamily({ rootPageId, stackId }))
    const pageDatasource = useMemo(
        () => (isRecordPage ? dataSourceList.find(item => item.id === pageStack?.dsId) : undefined),
        [dataSourceList, isRecordPage, pageStack?.dsId]
    )
    const pageRecordId = isRecordPage ? pageStack?.recordId : undefined

    // const isFormPageCarryingTheUpstreamPageRecordId = !!pageRecordId && !isRecordPage

    const upstreamPageStack = useAtomData(
        pageStackAtom,
        useCallback(
            list => {
                let stack: PageMetaData | undefined

                /** 查找上游带有数据信息的栈 */
                function findUpstreamWithRecordInfo(tree: PageStackTree): boolean {
                    if (tree.stackId === stackId) {
                        return true
                    }

                    if (tree.children) {
                        for (let i = tree.children.length - 1; i >= 0; i--) {
                            const res = findUpstreamWithRecordInfo(tree.children[i])
                            if (res) {
                                if (tree.dsId && tree.recordId /*  || isFormPageCarryingTheUpstreamPageRecordId */ && !stack) {
                                    stack = omit('children', tree)
                                }
                                return res
                            }
                        }
                    }

                    return false
                }

                for (const stackTree of list) {
                    findUpstreamWithRecordInfo(stackTree)

                    // 如果已经找到有数据信息的上游stack, 不用执行后面的循环了
                    if (stack) {
                        break
                    }
                }

                return stack
            },
            [stackId]
        )
    )

    const upstreamPageStackFormState = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[upstreamPageStack?.stackId ?? ''], [upstreamPageStack?.stackId])
    )

    const formState = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[stackId], [stackId])
    )

    const upstreamDatasource = useMemo(
        () =>
            dataSourceList.find(
                item => item.id === /* isFormPageCarryingTheUpstreamPageRecordId ? pageStack?.dsId : */ upstreamPageStack?.dsId
            ),
        [dataSourceList, upstreamPageStack?.dsId]
    )
    const upstreamPageRecordId = /* isFormPageCarryingTheUpstreamPageRecordId ? pageRecordId :  */ upstreamPageStack?.recordId
    const upstreamPage = useAtomData(pageAtomFamily(upstreamPageStack?.pageId || ''))

    return useMemo(() => {
        return {
            prev: {
                variableSourceType: VariableSourceType.parentPage,
                page: upstreamPage,
                recordId: getMainTableRecordId(upstreamPageRecordId),
                datasource: upstreamDatasource,
                formState: upstreamPageStackFormState
            },
            curr: {
                variableSourceType: VariableSourceType.page,
                page,
                recordId: getMainTableRecordId(pageRecordId),
                datasource: pageDatasource,
                formState
            },
            dataSourceList
        }
    }, [
        upstreamPage,
        upstreamPageRecordId,
        upstreamDatasource,
        upstreamPageStackFormState,
        page,
        pageRecordId,
        pageDatasource,
        formState,
        dataSourceList
    ])
}
