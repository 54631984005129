import { QrBarcodeBlock, useCustomViewBlockContext } from '@lighthouse/block'
import type { QrBarcodeBlockAbstract } from '@lighthouse/core'
import { generateText, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

interface QrBarcodeBlockControllerProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: QrBarcodeBlockAbstract
}

const QrBarcodeBlockController: React.FC<QrBarcodeBlockControllerProps> = ({ blockData, ...rest }) => {
    const { config } = blockData
    const { content } = config
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { record: customViewRecord } = useCustomViewBlockContext()
    const {
        prev,
        curr: { recordId }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const renderLabel = useVariableValueRender(prev.recordId, recordId)

    const value = useMemo(
        () =>
            generateText(content?.value, {
                variable: {
                    renderLabel: v =>
                        renderLabel(v.attrs.value, {
                            viewRecord: customViewRecord
                        })
                }
            }),
        [content, customViewRecord, renderLabel]
    )

    return <QrBarcodeBlock blockData={blockData} code={value} {...rest} />
}

export default QrBarcodeBlockController
