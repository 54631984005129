import { useAtomCallback } from 'jotai/utils'
import { useRef } from 'react'
import { useUpdateEffect } from 'react-use'

import { containerDesignStateAtom, lastPageOfStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'

type Position = 'paddingLeft' | 'paddingTop' | 'paddingRight' | 'paddingBottom'

export const useSetPaddingHighlight = (disabled?: boolean) => {
    const isLeaveWhenDisabledRef = useRef(true)
    const enterParamsWhenDisabledRef = useRef<Position[]>([])
    const leaveParamsWhenDisabledRef = useRef<Position[]>([])

    const onMouseEnterPadding = useAtomCallback<void, [Position]>((get, set, position) => {
        if (disabled) {
            isLeaveWhenDisabledRef.current = false
            enterParamsWhenDisabledRef.current.push(position)
            return
        }

        const pageStack = get(lastPageOfStackAtom)
        if (!pageStack) {
            return
        }

        const id = pageStack.state.asideType === AsideType.PAGE ? pageStack.pageId : pageStack.state.selectedNodes?.[0]
        if (!id) {
            return
        }

        set(containerDesignStateAtom, draft => {
            Reflect.set(draft, id, { ...draft[id], [position]: true })
        })
    })

    const onMouseLeavePadding = useAtomCallback<void, [Position]>((get, set, position) => {
        if (disabled) {
            isLeaveWhenDisabledRef.current = true
            leaveParamsWhenDisabledRef.current.push(position)
            return
        }

        const pageStack = get(lastPageOfStackAtom)
        if (!pageStack) {
            return
        }

        const id = pageStack.state.asideType === AsideType.PAGE ? pageStack.pageId : pageStack.state.selectedNodes?.[0]
        if (!id) {
            return
        }

        set(containerDesignStateAtom, draft => {
            Reflect.set(draft, id, { ...draft[id], [position]: false })
        })
    })

    useUpdateEffect(() => {
        if (disabled) {
            return
        }

        if (isLeaveWhenDisabledRef.current) {
            leaveParamsWhenDisabledRef.current.forEach(onMouseLeavePadding)
        } else {
            enterParamsWhenDisabledRef.current.forEach(onMouseEnterPadding)
        }

        leaveParamsWhenDisabledRef.current = []
        enterParamsWhenDisabledRef.current = []
    }, [disabled])

    return { onMouseEnterPadding, onMouseLeavePadding }
}
