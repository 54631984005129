import { BlockTypeIconMap } from '@lighthouse/block'
import type { PageType } from '@lighthouse/core'
import { BlockType, DataSourceType, PAGE_TYPE } from '@lighthouse/core'
import type { FindUseADTObject, FindUseLocationConfig } from '@lighthouse/shared'
import {
    FindUseLocationType,
    FindUsePageIconTypeMap,
    getFieldIcon,
    getTableIcon,
    initBlockRuntimeState,
    nodeTypeIconMap,
    triggerNodeType,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { useAtomCallback } from 'jotai/utils'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { setFindUseAtom } from '@/atoms/application/action'
import { openPageStackAtom } from '@/atoms/page/action'
import { blocksAtom, pageBlocksAtom, pageListAtom, pageStackAtom, pageStackIndexHistoriesAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSourceEnvId, useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'

import { FindUseLocationItem } from './FindUseLocationItem'

const SCxList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

interface FindUseLocationItemProps {
    config: FindUseADTObject
    data: FindUseLocationConfig[]
}

export const FindUseLocationList: React.FC<FindUseLocationItemProps> = ({ config, data }) => {
    const navigate = useNavigate()
    const appId = useCurrentAppID()
    const envId = useDataSourceEnvId()

    const pageList = useAtomData(pageListAtom)
    const dataSourceList = useDataSourceList(appId, envId)
    const { run: setFindUse } = useAtomAction(setFindUseAtom)
    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const { run: setPageStackIndexHistories } = useAtomAction(pageStackIndexHistoriesAtom)

    const getBlockIconType = useCallback((type: BlockType) => {
        switch (type) {
            case BlockType.container: {
                return ''
            }
            case BlockType.field: {
                return 'LayerFormContainerBlock'
            }
            case BlockType.chart: {
                return 'DiagramUpBlock'
            }
            case BlockType.view: {
                return 'NewTableBlock'
            }
            default: {
                return BlockTypeIconMap[type]
            }
        }
    }, [])

    const fetchDataSourceFirstRecord = useCallback(
        async ({ dsId }: { dsId: string }) => {
            const res = await srv.getDs({
                appId,
                envId,
                dsId,
                pagination: { currentPage: 1, pageSize: 100 }
            })

            return res?.records?.[0]
        },
        [appId, envId]
    )

    const getPageStackExtraInfo = useCallback(
        async (pageType: PageType, dsId: string) => {
            const firstRecord = await fetchDataSourceFirstRecord({ dsId })
            return pageType === PAGE_TYPE.default ? {} : { dsId, recordId: firstRecord?.id }
        },
        [fetchDataSourceFirstRecord]
    )

    const handleLink = useAtomCallback(async (get, _, val: FindUseLocationConfig) => {
        switch (val.type) {
            case FindUseLocationType.DATASHEET: {
                const { type, ...datasheet } = val
                const { dsId } = datasheet

                const ds = find(item => item.id === dsId, dataSourceList)
                if (!ds) {
                    return
                }
                const { type: dsType } = ds
                setFindUse({
                    config,
                    location: val
                })
                if (dsType === DataSourceType.joinDataSource) {
                    navigate({ pathname: `/${appId}/dataSource/${dsId}` })
                    return
                }
                navigate({ pathname: `/${appId}/aggregate/${dsId}` })
                return
            }

            case FindUseLocationType.FIELD: {
                const { type, ...field } = val
                const { dsId } = field
                setFindUse({
                    config,
                    location: val
                })
                navigate({ pathname: `/${appId}/dataSource/${dsId}` })
                return
            }

            case FindUseLocationType.PAGE: {
                const { type, ...page } = val
                const { pageId, dsId, pageType } = page
                setFindUse({
                    config,
                    location: val
                })
                const stackId = nanoid()
                const pageStackExtraInfo = dsId ? await getPageStackExtraInfo(pageType, dsId) : {}
                openPageStack(
                    stackFactory({
                        appId,
                        pageId,
                        stackId,
                        ...pageStackExtraInfo
                    })
                )
                navigate(`/${appId}/page`)
                return
            }

            case FindUseLocationType.WORKFLOW: {
                const { type, ...workflow } = val
                const { workflowId } = workflow
                setFindUse({
                    config,
                    location: val
                })
                navigate({ pathname: `/${appId}/flow/${workflowId}` })
                return
            }
            // block
            default: {
                const { type, ...block } = val
                const { pageId, dsId, blockId } = block
                const page = find(item => item.id === pageId, pageList)
                if (!page) {
                    return
                }
                const pageStackExtraInfo = page.dsId ? await getPageStackExtraInfo(page.type, page.dsId) : {}
                const blocks = get(pageBlocksAtom(pageId))
                const stackId = nanoid()
                setFindUse({
                    config,
                    location: val
                })
                setPageStack(s => {
                    s.splice(0)
                    const newStack = stackFactory({
                        appId,
                        stackId,
                        pageId,
                        ...pageStackExtraInfo,
                        state: {
                            selectedNodes: [blockId],
                            asideType: AsideType.BLOCK
                        }
                    })
                    initBlockRuntimeState(newStack, blocks)
                    s.push(newStack)
                })
                setPageStackIndexHistories(s => {
                    s.splice(0)
                    s.push([0, stackId])
                })
                // const to = pageStacks.find(item => item.pageId === item.rootPageId && item.pageId === pageId)
                // // 如果要打开的页面已经被打开过，存在stacks中了，就更新他，否则打开一个新的stack
                // if (to && to.pageId === pageId) {
                //     setPageStack(s => {
                //         const stack = equalPageStack({ rootPageId: to.rootPageId, stackId: to.stackId })(s)
                //         if (stack) {
                //             stack.state.selectedNode = blockId
                //             stack.state.asideType = AsideType.BLOCK
                //         }
                //     })
                //     setPageStackIndexHistories(s => {
                //         s
                //     })
                // } else {
                //     openPageStack(
                //         stackFactory({
                //             pageId,
                //             // stackId,
                //             appId,
                //             dsId,
                //             state: {
                //                 selectedNode: blockId,
                //                 asideType: AsideType.BLOCK
                //             }
                //         })
                //     )
                // }
                navigate(`/${appId}/page`)
            }
        }
    })

    const getListData = useCallback(
        (data: FindUseLocationConfig) => {
            switch (data.type) {
                case FindUseLocationType.DATASHEET: {
                    return {
                        key: data.dsId,
                        title: data.dsName,
                        description: data.nodeName,
                        icon: getTableIcon({ type: data.dsType, sync: false })
                    }
                }

                case FindUseLocationType.FIELD: {
                    return {
                        key: data.fieldId,
                        title: data.fieldName,
                        description: `数据表：${data.dsName}`,
                        icon: getFieldIcon(data.fieldId, data.fieldType)
                    }
                }

                case FindUseLocationType.PAGE: {
                    return {
                        key: data.pageId,
                        title: data.pageName,
                        icon: FindUsePageIconTypeMap[data.pageType]
                    }
                }

                case FindUseLocationType.WORKFLOW: {
                    const icon = nodeTypeIconMap[data.nodeType]
                    const isTriggerNode = triggerNodeType.has(data.nodeType)
                    const workFlowTypeText = data.workflowType === 'automation' ? '自动化' : '动作流'
                    return {
                        key: data.nodeId,
                        isWorkFlow: true,
                        title: data.nodeName,
                        isTriggerNode,
                        description: `${workFlowTypeText}：${data.workflowName}`,
                        icon
                    }
                }

                default: {
                    const icon = getBlockIconType(data.blockType)
                    return {
                        key: data.blockId,
                        title: data.blockName,
                        description: `页面：${data.pageName}`,
                        icon
                    }
                }
            }
        },
        [getBlockIconType]
    )

    const list = useMemo(() => {
        return data.map(item => {
            const { key, title, description, icon, isTriggerNode, isWorkFlow } = getListData(item)

            return (
                <FindUseLocationItem
                    key={key}
                    title={title}
                    description={description}
                    icon={icon}
                    isWorkFlow={isWorkFlow}
                    isTriggerNode={isTriggerNode}
                    onLink={() => handleLink(item)}
                />
            )
        })
    }, [data, getListData, handleLink])

    return <SCxList>{list}</SCxList>
}
