import { Divider, IconFont, Modal, Tooltip } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { type FieldVariableConfig, type JoinSetting, VariableType } from '@lighthouse/core'
import type { AppDepartment, AppRole} from '@lighthouse/shared';
import { getIsMatchInnerTypeByOperator, getTableIcon, innerTypeJoinOperatorMap, paramToString } from '@lighthouse/shared'
import { clone, find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import { JoinConfigureModal } from '../../JoinConfigureModal'
import type { MatchConditionProps } from '../../JoinConfigureModal/MatchCondition'
import * as SC from './styles'

interface JoinConfigureItemProps extends Omit<MatchConditionProps, 'prefixName' | 'joinedDs'> {
    value: JoinSetting
    highlighting?: boolean
    personOptions: AppUser[]
    roleOptions: AppRole[]
    departmentOptions: AppDepartment[]
    onChange: (val: JoinSetting) => void
    onDelete: () => void
}

export const JoinConfigureItem: React.FC<JoinConfigureItemProps> = ({
    useDsIds,
    value,
    dataSourceList,
    primaryDsId,
    index,
    highlighting,
    personOptions,
    roleOptions,
    departmentOptions,
    onChange,
    onDelete
}) => {
    const [open, setOpen] = useState(false)

    const handleEdit = useCallback(() => {
        setOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const handleDelete = useCallback(async () => {
        const isConfirm = await Modal.confirm({
            title: '确认删除？',
            content: '删除后将影响关联表结构，同时可能对一些应用的地方产生影响，且不可恢复，请谨慎操作！',
            okStatus: 'error',
            okText: '删除'
        })
        if (isConfirm) {
            onDelete()
        }
    }, [onDelete])

    const { joinConditions = [], joinedDsId } = value

    const joinDataSource = useMemo(() => find(item => item.id === joinedDsId, dataSourceList), [dataSourceList, joinedDsId])

    const getLeftFieldName = useCallback(
        (dsId: string | undefined, fieldId: string | undefined, operatorValue: string | undefined) => {
            const dataSource = find(item => item.id === dsId, dataSourceList)
            if (!dataSource) {
                return {
                    dsName: undefined
                }
            }
            const { schema } = dataSource
            const field = schema[fieldId || '']
            if (!field) {
                return {
                    dsName: dataSource.name,
                    fieldName: undefined
                }
            }

            if (!field.innerType) {
                return {
                    dsName: dataSource.name,
                    fieldName: field.name,
                    operatorName: undefined,
                    field
                }
            }

            if (!field.innerType) {
                return {
                    dsName: dataSource.name,
                    fieldName: field.name,
                    operatorName: undefined,
                    field
                }
            }
            const operatorOptions = innerTypeJoinOperatorMap[field.innerType]
            const operator = find(item => item.value === operatorValue, operatorOptions)
            return {
                dsName: dataSource.name,
                fieldName: field.name,
                operatorName: operator?.label,
                fieldType: field.innerType,
                field
            }
        },
        [dataSourceList]
    )

    const getRightFieldName = useCallback(
        (data: FieldVariableConfig | undefined) => {
            const dsId = data?.fieldVariable?.dsId
            const fieldId = data?.fieldVariable?.fieldId
            const dataSource = find(item => item.id === dsId, dataSourceList)
            if (!dataSource) {
                return {
                    dsName: undefined
                }
            }
            const { schema } = dataSource
            const field = schema[fieldId || '']
            if (!field) {
                return {
                    dsName: dataSource.name,
                    fieldName: undefined
                }
            }
            if (!field.innerType) {
                return {
                    dsName: dataSource.name,
                    fieldName: field.name,
                    fieldType: undefined
                }
            }
            return {
                dsName: dataSource.name,
                fieldName: field.name,
                fieldType: field.innerType
            }
        },
        [dataSourceList]
    )

    const conditionTextList = useMemo(() => {
        return joinConditions.map((condition, i) => {
            const {
                dsName: leftDsName,
                fieldName: leftFieldName,
                operatorName,
                fieldType: leftFieldType,
                field
            } = getLeftFieldName(joinedDsId, condition.leftFieldId, condition.operator)

            if (!leftDsName || !leftFieldName) {
                // eslint-disable-next-line react/no-array-index-key
                return <SC.ErrorText key={i}>找不到匹配字段</SC.ErrorText>
            }

            if (!operatorName) {
                // eslint-disable-next-line react/no-array-index-key
                return <SC.ErrorText key={i}>关联条件异常</SC.ErrorText>
            }

            if (condition.right?.type === VariableType.VALUE) {
                const textIsObject = typeof condition.right.valueVariable?.value === 'object' &&  !Array.isArray(condition.right.valueVariable?.value )
                // eslint-disable-next-line no-negated-condition
                const rightText = field ? paramToString(field, condition.right.valueVariable?.value ?? '', personOptions, roleOptions, departmentOptions) : !textIsObject ? (condition.right.valueVariable?.value) as string : ''

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <SC.Text key={i}>
                        <SC.BoldText>{leftDsName}.</SC.BoldText>
                        {leftFieldName}
                        <SC.Operator>{operatorName}</SC.Operator>
                        {['isEmpty', 'isNotEmpty'].includes(condition.operator || '') ? '' : rightText}
                    </SC.Text>
                )
            }

            const { dsName: rightDsName, fieldName: rightFieldName, fieldType: rightFieldType } = getRightFieldName(condition.right)

            if (
                leftFieldType &&
                rightFieldType &&
                condition.operator &&
                !getIsMatchInnerTypeByOperator(leftFieldType, condition.operator, rightFieldType)
            ) {
                // eslint-disable-next-line react/no-array-index-key
                return <SC.ErrorText key={i}>两侧匹配的字段类型不一致</SC.ErrorText>
            }
            const rightName =
                rightDsName && rightFieldName ? (
                    <SC.Text>
                        <SC.BoldText>{rightDsName}.</SC.BoldText>
                        {rightFieldName}
                    </SC.Text>
                ) : (
                    <SC.ErrorText>找不到匹配字段</SC.ErrorText>
                )

            return (
                // eslint-disable-next-line react/no-array-index-key
                <SC.Text key={i}>
                    <SC.BoldText>{leftDsName}.</SC.BoldText>
                    {leftFieldName}
                    <SC.Operator>{operatorName}</SC.Operator>
                    {['isEmpty', 'isNotEmpty'].includes(condition.operator || '') ? '' : rightName}
                </SC.Text>
            )
        })
    }, [getLeftFieldName, getRightFieldName, joinConditions, joinedDsId, personOptions])

    return (
        <SC.Container highlighting={highlighting}>
            <SC.MatchHeader>
                <SC.MatchTitle>
                    {joinDataSource && (
                        <>
                            <IconFont size={16} type={getTableIcon(joinDataSource)} />
                            {joinDataSource.name}
                        </>
                    )}
                </SC.MatchTitle>
                <SC.MatchHandle>
                    <Tooltip title="编辑关联条件">
                        <SC.OperatorIcon type="edit" size={16} onClick={handleEdit} />
                    </Tooltip>
                    <Tooltip title="删除">
                        <SC.OperatorIcon type="Trash" size={16} onClick={handleDelete} />
                    </Tooltip>
                </SC.MatchHandle>
            </SC.MatchHeader>
            <Divider color="var(--color-gray-200)" />
            <SC.MatchWrapper>
                <SC.MatchLabel>匹配条件</SC.MatchLabel>
                <SC.MatchContent>{conditionTextList}</SC.MatchContent>
            </SC.MatchWrapper>
            <JoinConfigureModal
                index={index}
                useDsIds={useDsIds}
                value={value}
                onConfirm={val => onChange(clone(val))}
                dataSourceList={dataSourceList}
                primaryDsId={primaryDsId}
                open={open}
                onClose={handleClose}
            />
        </SC.Container>
    )
}
