import type { DataSourceAbstract, FormContainerBlockConfig } from '@lighthouse/core'
import { ActionItemPattern, ALIGNMENT, DIRECTION, FLEX_ALIGN } from '@lighthouse/core'
import { generateJson } from '@lighthouse/shared'

import { getInitButton } from './button'
/**
 * 生成表单容器的配置
 *
 * @param {(undefined | DataSourceAbstract)} dataSource
 * @returns {FormContainerBlockConfig}
 */
export const generateFormContainerBlock = (dataSource: undefined | DataSourceAbstract): FormContainerBlockConfig => {
    return {
        pointer: dataSource ? dataSource.id : '',
        submitButton: {
            ...getInitButton(ActionItemPattern.primary),
            name: generateJson('提交'),
            pattern: ActionItemPattern.primary,
            showType: 'name',
            size: 'lg',
            radius: '8',
            align: ALIGNMENT.left
        },
        action: {
            type: 'notification',
            trigger: 'click',
            data: {
                notification: {
                    notificationStatus: 'SUCCESS',
                    notificationContent: {
                        type: 'doc',
                        content: [
                            {
                                type: 'paragraph',
                                content: [
                                    {
                                        type: 'text',
                                        text: '提交成功'
                                    }
                                ]
                            }
                        ]
                    }
                }
            }
        },
        rules: [],
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'visible'
            },
            layout: {
                align: {
                    direction: DIRECTION.vertical,
                    alignX: FLEX_ALIGN['flex-start'],
                    alignY: FLEX_ALIGN['flex-start']
                },
                gap: 8,
                padding: [4, 4, 4, 4]
            },
            design: {}
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
