import { hideScrollBar } from '@byecode/ui'
import type { AutoLayout, LayoutSize, RecordLikeProtocol, VariableADTvalue } from '@lighthouse/core'
import type { FlowLayoutNode, VisibleDomProps } from '@lighthouse/shared'
import {
    combineBackgroundStyle,
    DOM_DATA_NODE_NAME,
    DragPlugin,
    getBackgroundStyle,
    getFieldBlockWithDsId,
    getVeinsStyle,
    LayoutEngine,
    LayoutInteractionEngine,
    PAGE_SCROLL_PARENT_CONTENT,
    PageContainerProvider,
    ResizePlugin,
    scroll2FlowNode,
    transformBlock2FlowLayoutNode,
    useAppContainerContext,
    useApplicationContext,
    useAtomAction,
    useAtomData,
    useFillPickerContext,
    useManualSetMediaQueryRemBase
} from '@lighthouse/shared'
import React, { startTransition, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useLatest } from 'react-use'
import styled from 'styled-components'

import { appRoleAtom } from '@/atoms/application/state'
import {
    blockHighlightAtom,
    containerDesignStateAtom,
    lastPageOfStackAtom,
    pageAtomFamily,
    pageBlocksAtom,
    pageStackAtom,
    pageStackAtomFamily
} from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { PageFieldBlocksProvider, useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useClickAwayOutside } from '@/hooks/layoutEngine/useClickAwayOutside'
import { useSelectionNodesActions } from '@/hooks/layoutEngine/useSelectionNodesActions'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'
import type { FlowLayoutEvents } from '@/utils/flowLayoutEventBus'
import { flowLayoutEmitter } from '@/utils/flowLayoutEventBus'
import { usePageUndoRedo } from '@/utils/undoRedo/page'

import { PageSuspendPagination } from '../PageSuspendPagination'
import { BlockRender } from './BlockRender'
import { BlockToolbar } from './BlockToolbar'
import { useLayoutEngineEvents } from './useLayoutEngineInteract'

const PageContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

const ScrollerArea = styled.div`
    /* background-color: var(--color-white); */
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    ${hideScrollBar}
`

interface PageContentProps {
    loading?: boolean
}

export const PageContent: React.FC<PageContentProps> = () => {
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const isRootPage = rootPageId === pageId
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const endPoint = useManualSetMediaQueryRemBase()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const userId = useAtomData(appRoleAtom)

    const { personOptions, roleOptions, departmentOptions } = useApplicationContext()
    const { scale, disabledPageScroll } = useAppContainerContext()
    const dataSourceList = useDataSourceList(appId, envId)
    const pageDesign = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.breakPoint?.design, [])
    )

    const pageLayout = useAtomData(
        pageAtomFamily(pageId),
        useCallback(
            s => ({
                layout:
                    s?.breakPoint?.layout ||
                    ({ align: { direction: 'vertical' }, gap: 12, padding: [10, 10, 10, 10] } satisfies AutoLayout),
                size: s?.breakPoint?.size || ({ width: { size: 'fill' }, height: { size: 'auto' } } satisfies LayoutSize)
            }),
            []
        )
    )

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const [blockRuntimeState, selectedNodes] = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => [s?.blockRuntimeState, s?.state.selectedNodes] as const, [])
    )
    const singleSelectedNodeId = selectedNodes && selectedNodes.length === 1 ? selectedNodes[0] : undefined

    // useLayoutEffect(() => {
    //     if (singleSelectedNodeId) {
    //         startTransition(() => {
    //             requestAnimationFrame(() => {
    //                 scroll2FlowNode(singleSelectedNodeId)
    //             })
    //         })
    //     }
    // }, [singleSelectedNodeId])

    const previewType = usePreviewType()

    const { curr, prev } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const { recordId: parentRecordId, page: prevPage } = prev
    const { recordId, page: currPage } = curr

    const getIsVisible = useVisibilityFilter({ curr, prev })

    const flowNodes = useMemo(
        () => transformBlock2FlowLayoutNode({ blocks: pageBlocks, blockRuntimeState, previewType, userId, getIsVisible }),
        [pageBlocks, blockRuntimeState, previewType, userId, getIsVisible]
    )

    /** 当前页面是栈顶 */
    const isCurrentStackTop = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId === pageId && s?.stackId === stackId, [pageId, stackId])
    )

    const asideType = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.state.asideType, [])
    )

    const ref = useRef<HTMLDivElement>(null)

    const disabledWithVersion = useIsDisabledWithVersion()

    useClickAwayOutside({ ref, enabled: isCurrentStackTop })

    usePageUndoRedo(
        {
            enabled: isCurrentStackTop && !disabledWithVersion,
            filter(event) {
                return !(event.target instanceof HTMLInputElement && (!event.target.type || event.target.type === 'text'))
            },
            filterPreventDefault: false,
            enableOnTags: ['INPUT']
        },
        [stackId]
    )

    /** ********************************* 栅格的交互事件 start ******************************** */
    // 选中节点
    const onSelectedIdChange = useCallback(
        (ids: string[]) => {
            if (!isCurrentStackTop) {
                return
            }
            setPageStack(draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    stack.state.selectedNodes = ids
                    stack.state.asideType = ids.length === 1 ? AsideType.BLOCK : AsideType.PAGE
                }
            })
        },
        [isCurrentStackTop, rootPageId, setPageStack, stackId]
    )

    // 复制粘贴节点
    useSelectionNodesActions({
        enabled: isCurrentStackTop,
        ref
    })

    const instanceRef = useRef<LayoutInteractionEngine<DragPlugin | ResizePlugin> | null>(null)
    const dragPluginRef = useRef<DragPlugin | null>(null)
    const { collisionAreaDetection, onDragEnd, getResizeRestrict, onResizeEnd } = useLayoutEngineEvents()
    const latestEvents = useLatest({
        onSelectedIdChange,
        collisionAreaDetection,
        onDragEnd,
        getResizeRestrict,
        onResizeEnd
    })

    const mergeRootNodes = useMemo<FlowLayoutNode[]>(
        () => [
            {
                id: 'root',
                type: 'container',
                data: {
                    size: pageLayout.size,
                    layout: pageLayout.layout,
                    design: pageDesign
                },
                children: flowNodes
            }
        ],
        [flowNodes, pageDesign, pageLayout.layout, pageLayout.size]
    )

    useLayoutEffect(() => {
        const el = ref.current
        if (!el) {
            return
        }

        if (instanceRef.current) {
            instanceRef.current.updateOption({
                scale,
                data: mergeRootNodes,
                selectedIds: isCurrentStackTop ? selectedNodes : [],
                notSelectPage: asideType === AsideType.NAVBAR
            })
        } else {
            const dragPlugin = new DragPlugin({
                onDragEnd: latestEvents.current.onDragEnd,
                collisionAreaDetection: latestEvents.current.collisionAreaDetection
            })
            const resizePlugin = new ResizePlugin({
                getResizeRestrict: latestEvents.current.getResizeRestrict,
                // onResizeStart: latestEvents.current.onResizeEnd,
                onResizeEnd: latestEvents.current.onResizeEnd
            })

            const layoutInteractionEngine = new LayoutInteractionEngine({
                data: mergeRootNodes,
                root: el,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                boundary: isRootPage ? document.querySelector('.applicationContainer')!.parentElement! : el,
                onSelect: latestEvents.current.onSelectedIdChange,
                plugins: [dragPlugin, resizePlugin]
            })

            dragPluginRef.current = dragPlugin
            instanceRef.current = layoutInteractionEngine
        }
    }, [scale, mergeRootNodes, latestEvents, selectedNodes, previewType, isCurrentStackTop, isRootPage, asideType])

    useEffect(() => {
        return () => {
            instanceRef.current?.destroy()
        }
    }, [])

    useEffect(() => {
        function beginAdding(e: FlowLayoutEvents['beginAdding']) {
            if (!isCurrentStackTop) {
                return
            }
            dragPluginRef.current?.startDrag(e)
        }
        // 栅格监听从外部添加node
        flowLayoutEmitter.on('beginAdding', beginAdding)

        return () => flowLayoutEmitter.off('beginAdding', beginAdding)
    }, [isCurrentStackTop])

    /** ********************************* 栅格的交互事件 end ******************************** */

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    // const {
    //     prev: { recordId: parentRecordId, page: prevPage },
    //     curr: { recordId, page: currPage }
    // } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const renderLabel = useVariableValueRender(parentRecordId, recordId)
    // 第二个参数record为了自定义视图每项自带的容器消费自定义视图变量
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined, record?: RecordLikeProtocol) => renderLabel(value, { useFileUrl: true, viewRecord: record }),
        [renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    const containerDesignState = useAtomData(
        containerDesignStateAtom,
        useCallback(
            s => {
                return {
                    ...s,
                    root: s[pageId]
                }
            },
            [pageId]
        )
    )

    const blockHighlight = useAtomData(blockHighlightAtom)
    const highlight = useMemo(() => {
        return {
            ...containerDesignState,
            ...Object.fromEntries(blockHighlight.map(id => [id, { self: true }]))
        }
    }, [containerDesignState, blockHighlight])

    /** *************** block render迁移至上层，获取所有字段block start *************** */
    const fieldBlocksWithDsId = useMemo(
        () => getFieldBlockWithDsId({ blocks: pageBlocks, pageDsId: currPage?.dsId, blockRuntimeState }),
        [blockRuntimeState, currPage?.dsId, pageBlocks]
    )
    /** *************** block render迁移至上层，获取所有字段block end *************** */

    const background = pageDesign?.background

    const { palettes } = useFillPickerContext()

    const backgroundStyle: React.CSSProperties = useMemo(() => {
        const styles = {
            ...combineBackgroundStyle([
                getBackgroundStyle(appId, background, parseBackgroundVariableImage, palettes),
                getVeinsStyle(pageDesign?.veins, palettes)
            ])
        }

        return {
            ...styles,
            backgroundColor: background && (styles.backgroundColor || '#fff')
        }
    }, [appId, background, pageDesign?.veins, palettes, parseBackgroundVariableImage])

    const layerLayoutStyle = useMemo(() => {
        return {
            ...backgroundStyle,
            backgroundAttachment: 'fixed'
        }
    }, [backgroundStyle])

    const visibleParams: VisibleDomProps = useMemo(
        () => ({
            currentPage: curr,
            prevPage: prev,
            dataSourceList,
            personOptions,
            roleOptions,
            departmentOptions,
            fieldBlocksWithDsId
        }),
        [curr, dataSourceList, departmentOptions, fieldBlocksWithDsId, personOptions, prev, roleOptions]
    )

    return (
        <PageContainer>
            <PageFieldBlocksProvider value={fieldBlocksWithDsId}>
                <PageContainerProvider endpoint={endPoint}>
                    <ScrollerArea id={PAGE_SCROLL_PARENT_CONTENT}>
                        <LayoutEngine
                            ref={ref}
                            data={flowNodes}
                            size={pageLayout?.size}
                            layout={pageLayout?.layout}
                            design={pageDesign}
                            style={layerLayoutStyle}
                            parseBackgroundVariableImage={parseBackgroundVariableImage}
                            selectedIds={selectedNodes}
                            highlight={highlight}
                            scale={scale}
                            userId={userId}
                            visibleParams={visibleParams}
                            nodeRender={BlockRender}
                        />

                        {isCurrentStackTop && singleSelectedNodeId && (
                            <BlockToolbar
                                selectedId={singleSelectedNodeId}
                                onChangeSelectedId={onSelectedIdChange}
                                onDragBar={e => {
                                    dragPluginRef.current?.startDrag(
                                        e.nativeEvent,
                                        singleSelectedNodeId,
                                        ref.current?.querySelector(`[${DOM_DATA_NODE_NAME}="${singleSelectedNodeId}"]`) || undefined
                                    )
                                }}
                            />
                        )}
                        {/* {stackDisplayType === 'page' && <DomainFiling data={domainSetting} isMobile={isMobile}  />} */}
                    </ScrollerArea>
                    {/* 悬浮视图工具栏 */}
                    <PageSuspendPagination />
                </PageContainerProvider>
            </PageFieldBlocksProvider>

            {/* 创建同步组件 */}
            {/* {creatingSyncNodeId && <CreateSyncModal onCancel={() => setCreatingSyncNodeId(null)} onCreate={onCreateSyncNode} />} */}
        </PageContainer>
    )
}
