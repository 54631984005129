import { Divider, Empty } from '@byecode/ui'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { useAtom } from 'jotai'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSetState } from 'react-use'

import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { createNodeLoadingAtom, lastPageOfStackAtom, outsideDraggingNode, pageAtomFamily } from '@/atoms/page/state'
import { ALL_BLOCK_LIST } from '@/constants/Block/shared'
import type { BlockTreeItem } from '@/constants/Block/type'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { flowLayoutBeginAdding } from '@/utils/flowLayoutEventBus'

import { getBlockOrNode } from '../helper'
import { Search } from '../Search'
import * as CM from '../style'
import { KindBlock } from './KindBlock'
import * as SC from './styles'

interface BlockListProps {
    onBack?: () => void
    onOpenQuestion?: (v: BlockTreeItem) => void
}
interface State {
    searchWord: string
}

export const BlockList: React.FC<BlockListProps> = ({ onBack, onOpenQuestion }) => {
    const [dragNode, setDragNode] = useAtom(outsideDraggingNode)
    const { run: setCreateNodeLoading } = useAtomAction(createNodeLoadingAtom)

    const [{ searchWord }, setState] = useSetState<State>({
        searchWord: ''
    })

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const pageId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.pageId ?? '', [])
    )
    const pageDsId = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.dsId, [])
    )

    const dataSource4Page = useAtomData(dataSourceAtomFamily({ appId, envId, dsId: pageDsId || '' }))

    useEffect(() => {
        // 抬起清除拖拽数据
        const mouseupListener = () => {
            if (dragNode) {
                setDragNode(null)
            }
        }
        window.addEventListener('mouseup', mouseupListener)
        return () => {
            window.removeEventListener('mouseup', mouseupListener)
        }
    }, [dragNode, setCreateNodeLoading, setDragNode, setState])

    const searchBlockList = useMemo(() => {
        return ALL_BLOCK_LIST.map(kind => ({ ...kind, items: kind.items.filter(block => block.name.includes(searchWord)) })).filter(
            v => v.items.length > 0
        )
    }, [searchWord])

    const handleCreate = useCallback(
        (ev: React.MouseEvent<HTMLDivElement>, v: BlockTreeItem) => {
            flowLayoutBeginAdding(ev.nativeEvent)
            const block = getBlockOrNode({ v, appId, dataSource: dataSource4Page })
            if (!block) {
                return
            }
            const dragNodeWithData = { ...v, data: block }

            setDragNode(dragNodeWithData)
        },
        [appId, dataSource4Page, setDragNode]
    )

    return (
        <SC.Container>
            <CM.Header>
                <Search value={searchWord} onChange={val => setState({ searchWord: val })} clearable onBack={onBack} />
            </CM.Header>
            <Divider style={{ margin: '8px 14px 0 14px' }} />
            <SC.ChunkContainer>
                {searchBlockList.map((option, index) => (
                    <React.Fragment key={option.id}>
                        <KindBlock option={option} onCreate={handleCreate} onOpenQuestion={onOpenQuestion} />
                        {index + 1 !== searchBlockList.length && <Divider style={{ margin: '12px 14px' }} />}
                    </React.Fragment>
                ))}
                {searchBlockList.length === 0 && (
                    <Empty
                        styles={{
                            root: {
                                width: '100%',
                                height: 244
                            },
                            wrapper: {
                                gap: 12
                            }
                        }}
                        icon={<CM.Icon type="NoComponent" size={36} />}
                        description="找不到组件"
                    />
                )}
            </SC.ChunkContainer>
        </SC.Container>
    )
}
