import type { ActivityProtocol, BlockAbstract, WorkSpaceAbstract } from '@lighthouse/core'
import { WorkSpaceStruct } from '@lighthouse/core'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import type { status, valueof } from 'react-joyride'

import { applyDraftPayload } from '../utils/applyDraftPayload'

export const workSpaceListAtom = atomWithImmer<WorkSpaceAbstract[]>([])
export const currentWorkSpaceIdAtom = atom('')

export const activityListAtom = atomWithImmer<ActivityProtocol[]>([])

export const workSpaceAtom = atom(
    get => {
        const list = get(workSpaceListAtom)

        const id = get(currentWorkSpaceIdAtom)
        if (!id) {
            return { ...WorkSpaceStruct() }
        }
        const current = list.find(item => item.id === id)
        return current ?? { ...WorkSpaceStruct() }
    },
    (get, set, payload: WorkSpaceAbstract | ((draft: Draft<WorkSpaceAbstract>) => void)) =>
        set(workSpaceListAtom, draft => {
            const id = get(currentWorkSpaceIdAtom)
            const app = draft.find(item => item.id === id)
            if (!app) {
                return
            }

            applyDraftPayload(app, payload)
        })
)
export const isSkipWorkSpaceGuideAtom = atom<boolean>(false)
export const spaceGuideStepAtom = atom<valueof<status>>('idle')

/** 框选栅格节点 */
export const copiedBoxSelectionNodesSnapshotAtom = atom<{ copiedIds: string[]; blocks: BlockAbstract[] }>({
    copiedIds: [],
    blocks: []
})
