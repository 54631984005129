import { Select } from '@byecode/ui'
import { collectBlockAndChildren, getFlatAllBlock } from '@lighthouse/block'
import type { BlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode, FlowNode, NodeTypes, WithBlockOption } from '@lighthouse/shared'
import {
    CollapseBox,
    CONTROLLABLE_BLOCK_TYPES,
    findBlockById,
    nodeTypeOptions,
    scroll2FlowNode,
    transformBlock2FlowLayoutNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { blockHighlightAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { usePreviewType } from '@/hooks/useApplication'

import * as SC from '../../styles'
import { ComponentSelector } from './ComponentSelector'
import { ControlActionSelector } from './ControlActionSelector'

export interface ControlActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
}

const generateOptions = (pageBlocks: BlockAbstract[]) => {
    const blocks = getFlatAllBlock(pageBlocks)
    return blocks
        .map(block => {
            if (!CONTROLLABLE_BLOCK_TYPES.has(block.type)) {
                return false
            }
            return {
                label: block.title,
                value: block.id,
                block,
                level: 0
            }
        })
        .filter(Boolean) as WithBlockOption[]
}
export const ControlActionConfigure: React.FC<ControlActionConfigureProps> = ({ actionTypeSwitcher, prefixName = 'config' }) => {
    const { control, setValue } = useFormContext()
    const controlElementId = useWatch({ control, name: `${prefixName}.controlElementId` })

    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const [pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || ''] as const, [])
    )

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))

    const controlElement = useMemo(() => {
        if (!controlElementId) {
            return
        }
        return findBlockById(controlElementId, pageBlocks)
    }, [controlElementId, pageBlocks])

    const options = useMemo(() => {
        return generateOptions(pageBlocks)
    }, [pageBlocks])

    const handleScroll2FlowNode = useCallback(
        (scrollToId = '') => {
            setBlockHighlight(scrollToId ? [scrollToId] : [])
            scroll2FlowNode(scrollToId)
        },
        [setBlockHighlight]
    )

    const clearBlockHighlight = useCallback(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <CollapseBox label="控制规则">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>选择组件</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.controlElementId`}
                                control={control}
                                render={({ field }) => (
                                    <ComponentSelector
                                        value={field.value}
                                        onChange={id => {
                                            field.onChange(id)
                                            setValue(`${prefixName}.controlAction`, undefined)
                                            if (id) {
                                                const block = findBlockById(id, pageBlocks)
                                                setValue(`${prefixName}.controlElementType`, block?.type)
                                            }
                                            clearBlockHighlight()
                                        }}
                                        options={options}
                                        onHover={handleScroll2FlowNode}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {controlElement && <ControlActionSelector controlElement={controlElement} prefixName={prefixName} />}
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
