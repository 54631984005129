import type { DataSourceAbstract, ViewType } from '@lighthouse/core'
import { USER_DATASOURCE } from '@lighthouse/shared'
import React, { useMemo } from 'react'

import { CalendarOperate } from './Calendar'
import { GalleryOperate } from './Gallery'
import { KanbanOperate } from './Kanban'
import { TableOperate } from './Table'

export interface OperateProps {
    id: string
    viewType: ViewType
    dataSource: DataSourceAbstract
}

export const Operate: React.FC<OperateProps> = ({ id, viewType, dataSource }) => {
    // 合并数据源与视图上的字段

    const OperatorContent = useMemo(() => {
        switch (viewType) {
            case 'table':
            case 'advancedTable':
            case 'list': {
                return TableOperate
            }
            case 'gallery': {
                return GalleryOperate
            }
            case 'kanban': {
                return KanbanOperate
            }
            case 'calendar': {
                return CalendarOperate
            }

            default: {
                return TableOperate
            }
        }
    }, [viewType])

    return <OperatorContent id={id} dataSource={dataSource} isUserDataSource={dataSource.id === USER_DATASOURCE} />
}
