import { Divider, Select } from '@byecode/ui';
import type { SubProcessArg } from '@lighthouse/core';
import type { BaseFlowNode, NodeTypes, VariableTriggerConfig } from '@lighthouse/shared';
import { type FlowNode, canAsUpstreamSingleRecordNodeTypesInLoopParams, CollapseBox, nodeTypeOptions, useAtomAsyncAction } from '@lighthouse/shared';
import { useAtomValue } from 'jotai';
import { find } from 'rambda';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { fetchWorkflowListAtom } from '@/atoms/workflow/action';
import { workflowListAtom } from '@/atoms/workflow/state';

import * as SC from '../../styles';
import { SettingSubProcessContent } from '../component/SettingSubProcessContent';
import { SubProcessSelector } from './SubProcessSelector';

interface HandleSubProcessActionConfigureProps {
    prefixName?: string
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
}

export const HandleSubProcessActionConfigure: React.FC<HandleSubProcessActionConfigureProps> = ({ prefixName = 'config' ,allParentNodes, actionTypeSwitcher }) => {
    const { control, getValues, setValue } = useFormContext()
    const workflows = useAtomValue(workflowListAtom)

    const { run: updateWorkflow } = useAtomAsyncAction(fetchWorkflowListAtom)
    // const loopType = useWatch({ control, name: 'config.loopType' })
    const subProcessId = useWatch({ control, name: `${prefixName}.subProcessId` })

    const allSingeParentNode = useMemo(
        () => allParentNodes.filter(node => canAsUpstreamSingleRecordNodeTypesInLoopParams.has(node.data.nodeType)),
        [allParentNodes]
    )

    // useEffect(() => {
    //     updateWorkflow()
    // }, [updateWorkflow])

    const handleFetchWorkflows = useCallback(() => {
        return updateWorkflow()
    }, [updateWorkflow])

    useEffect(() => {
        const workflow = find(item => item.id === subProcessId && item.type === 'subProcess', workflows)
        if (workflow) {
            const nodeList = workflow.content?.nodes || []
            const variableNode = find(item => item.data.nodeType === 'ARG_TRIGGER', nodeList) as BaseFlowNode<VariableTriggerConfig>
            if (!variableNode) {
                return
            }
            const { config } = variableNode.data
            if (!config || !config?.args) {
                return
            }
            const data: SubProcessArg[] = getValues(`${prefixName}.args`)
            const newParameters = config.args.map(item => {
                const dataItem = find(d => d.id === item.id, data || [])
                return {
                    ...item,
                    value: dataItem?.value
                }
            })
            setValue(`${prefixName}.args`, newParameters)
        }
    }, [getValues, prefixName, setValue, subProcessId, workflows])


    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <Controller
                        name={`${prefixName}.subProcessId`}
                        control={control}
                        render={({ field }) => (
                            <SubProcessSelector
                                workflows={workflows}
                                value={field.value}
                                onFetchWorkflows={handleFetchWorkflows}
                                onChange={val => {
                                    field.onChange?.(val)
                                }}
                            />
                        )}
                    />
                </SC.Content>
            </CollapseBox>
            {subProcessId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="子流程参数值设置">
                        <SC.Content>
                            <SC.Tip>设置子流程的参数值，参数值将会传递到子流程中。</SC.Tip>
                            <Controller
                                name={`${prefixName}.args`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <SettingSubProcessContent
                                            data={field.value}
                                            parentNodes={allSingeParentNode}
                                            allParentNodes={allParentNodes}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </SC.Content>
                    </CollapseBox>
                </>
            )}
        </SC.Container>
    )
}
